import React, { useCallback, useMemo } from "react"
import { BulletRectsItemProps, BulletTooltipProps, ResponsiveBullet } from "@nivo/bullet"
import { Box } from "@mui/material"
import { ThresholdData } from "./ThresholdData"
import { BarChartTooltip } from "./BarChartTooltip"
import { calculatePercent } from "../ChartUtils"
import { RangeComponent } from "./RangeComponent"

interface IProps {
  threshold2022: number
  threshold2025: number
  threshold2028: number
  threshold2031: number
  impact: number
}

export function IcConstructionThresholdChart({
  impact,
  threshold2022,
  threshold2025,
  threshold2028,
  threshold2031,
}: Readonly<IProps>): React.JSX.Element {
  const data: ThresholdData[] = useMemo(() => {
    if (impact < threshold2022 * 1.3) {
      return [
        {
          id: "",
          ranges: [threshold2031, threshold2028, threshold2025, threshold2022, threshold2022 * 1.3],
          measures: [impact],
          markers: [impact],
        },
      ]
    } else {
      return [
        {
          id: "",
          ranges: [threshold2031, threshold2028, threshold2025, threshold2022],
          measures: [impact],
          markers: [impact],
        },
      ]
    }
  }, [impact, threshold2022, threshold2025, threshold2028, threshold2031])

  const percent2022: number = useMemo(() => calculatePercent(impact, threshold2022) - 100, [impact, threshold2022])
  const percent2025: number = useMemo(() => calculatePercent(impact, threshold2025) - 100, [impact, threshold2025])
  const percent2028: number = useMemo(() => calculatePercent(impact, threshold2028) - 100, [impact, threshold2028])
  const percent2031: number = useMemo(() => calculatePercent(impact, threshold2031) - 100, [impact, threshold2031])

  const tooltipFct = useCallback(
    (dataPoint: BulletTooltipProps): React.JSX.Element => (
      <BarChartTooltip
        labelValueList={[
          {
            label: "Seuil 2022 :",
            unit: (
              <span>
                kgeqCO<sub>2</sub>/m²
              </span>
            ),
            value: threshold2022,
            isCompliant: impact < threshold2022,
            percentGap: percent2022,
          },
          {
            label: "Seuil 2025 :",
            unit: (
              <span>
                kgeqCO<sub>2</sub>/m²
              </span>
            ),
            value: threshold2025,
            isCompliant: impact < threshold2025,
            percentGap: percent2025,
          },
          {
            label: "Seuil 2028 :",
            unit: (
              <span>
                kgeqCO<sub>2</sub>/m²
              </span>
            ),
            value: threshold2028,
            isCompliant: impact < threshold2028,
            percentGap: percent2028,
          },
          {
            label: "Seuil 2031 :",
            unit: (
              <span>
                kgeqCO<sub>2</sub>/m²
              </span>
            ),
            value: threshold2031,
            isCompliant: impact < threshold2031,
            percentGap: percent2031,
          },
        ]}
      />
    ),
    [impact, percent2022, percent2025, percent2028, percent2031, threshold2022, threshold2025, threshold2028, threshold2031]
  )

  const rangeComponent = useCallback(
    (props: BulletRectsItemProps) => (
      <RangeComponent {...props} thresholdLabel={["2031", "2028", "2025", "2022", "Non RE2020"]} />
    ),
    []
  )

  return (
    <Box sx={{ height: "150px" }}>
      <ResponsiveBullet
        data={data}
        margin={{ top: 10, right: 90, bottom: 50, left: 90 }}
        spacing={46}
        rangeColors="seq:purple_blue"
        measureColors={["rgba(0, 0, 0, 0.35)"]}
        markerColors={["#000"]}
        tooltip={tooltipFct}
        markerComponent={() => null}
        rangeComponent={rangeComponent}
        animate
      />
    </Box>
  )
}
