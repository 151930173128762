import { Outlet, Route } from "react-router-dom"
import { BSLayout } from "../components/layout/BSLayout"
import RequireAuth from "../components/redirect/require-auth"
import { pagesUrl } from "../core/appConstants"
import { BSModelContextProvider } from "../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSModelListContextProvider } from "../core/context/beem-shot/BSBimModel/BSBimModelContextList"
import { BSModelFileContextProvider } from "../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { BSCategoriesContextProvider } from "../core/context/beem-shot/BSCategory/BSCategoriesContext"
import { BSInputContextProvider } from "../core/context/beem-shot/BSInput/BSInputContext"
import { BSItemContextProvider } from "../core/context/beem-shot/BSItems/BSItemContext"
import { BSMaterialContextProvider } from "../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext"
import { BSCoverImgProjectContextProvider } from "../core/context/beem-shot/BSProject/BSCoverImgProjectContext"
import { BSProjectContextProvider } from "../core/context/beem-shot/BSProject/BSProjectContext"
import { BSProjectListContextProvider } from "../core/context/beem-shot/BSProject/BSProjectListContext"
import { BSVariantContextProvider } from "../core/context/beem-shot/BSVariant/BSVariantContext"
import { CodeReferenceContextProvider } from "../core/context/code-acv/CodeReferenceContext"
import { DepartmentContextProvider } from "../core/context/departmentContext"
import { LotContextProvider } from "../core/context/lot-context"
import { NavBarContextProvider } from "../core/context/nav-bar/AppNavBarContext"
import DomainNameValidatorContextProvider from "../core/context/organization/domain-name-validator-context"
import { OrganizationContextProvider } from "../core/context/organization/organization-context"
import { SideBarContextProvider } from "../core/context/side-bar/side-bar-context"
import { CodesVersion } from "../core/enum/codesVersionEnum"
import { AccountPage } from "../pages/account-page/account-page"
import { BSHomePage } from "../pages/beem-shot/BSHomePage"
import { BSInvitationConfirmationPage } from "../pages/beem-shot/BSInvitationConfirmationPage/BSInvitationConfirmationPage"
import { BSProjectDetailsPage } from "../pages/beem-shot/BSProjectDetailPage/BSProjectDetailsPage"
import { SelectionContextProvider } from "../pages/beem-shot/BSVariantDetailsPage/BSCustomizationTab/components/context/SelectionContext"
import { BSVariantDetailPage } from "../pages/beem-shot/BSVariantDetailsPage/BSVariantDetailsPage"
import { MyOrganization } from "../pages/my-organization-page/my-organization"
import { MyOfferPage } from "../pages/offer/MyOfferPage"
import { ConfigurationHelperPage } from "../pages/support/ConfigurationHelperPage"
import { HelperCenterPage } from "../pages/support/HelpCenterPage"
import { TutorialHelperPage } from "../pages/support/TutorialHelperPage"
import { BSBimModelChoicePage } from "../pages/beem-shot/BSVariantDetailsPage/BSBimModelChoicePage/BSBimModelChoicePage"
import { BSBimModelDetailPage } from "../pages/beem-shot/BSBimModelDetailPage/BSBimModelDetailPage"
import { BSCodesExtraitsContextProvider } from "../core/context/beem-shot/BSCodeExtrait/BSCodesExtraitsContext"
import { BSBimModelImportPage } from "../pages/beem-shot/BSBimModelImportPage/BSBimModelImportPage"
import { BSCalculationPage } from "../pages/beem-shot/BSVariantDetailsPage/BSCalculationPage/BSCalculationPage"
import BSModelInvitationContextProvider from "../core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider"
import { BSVariantResultContextProvider } from "../core/context/beem-shot/BSVariantResult/BSVariantResultContext"

export const BSRoutes = (
  <Route
    element={
      <RequireAuth>
        <OrganizationContextProvider>
          <BSModelInvitationContextProvider>
            <BSProjectListContextProvider>
              <BSProjectContextProvider>
                <BSCoverImgProjectContextProvider>
                  <SideBarContextProvider>
                    <NavBarContextProvider>
                      <DomainNameValidatorContextProvider>
                        <BSLayout />
                      </DomainNameValidatorContextProvider>
                    </NavBarContextProvider>
                  </SideBarContextProvider>
                </BSCoverImgProjectContextProvider>
              </BSProjectContextProvider>
            </BSProjectListContextProvider>
          </BSModelInvitationContextProvider>
        </OrganizationContextProvider>
      </RequireAuth>
    }>
    {/* User account */}
    <Route path={pagesUrl.ACCOUNT_PAGE} element={<AccountPage />} />
    <Route path={pagesUrl.MY_OFFER_PAGE} element={<MyOfferPage />} />
    <Route
      path={pagesUrl.MY_ORGANIZATION_PAGE}
      element={
        <DomainNameValidatorContextProvider>
          <MyOrganization />
        </DomainNameValidatorContextProvider>
      }
    />
    <Route path={pagesUrl.BEEM_SHOT_HELP_CENTER} element={<HelperCenterPage />} />
    <Route path={pagesUrl.BEEM_SHOT_CONFIGURATION_HELPER} element={<ConfigurationHelperPage />} />
    <Route
      path={pagesUrl.BEEM_SHOT_TUTORIAL_HELPER}
      element={
        <BSCategoriesContextProvider>
          <CodeReferenceContextProvider projectCodeVersion={CodesVersion.VERSION_1_6}>
            <TutorialHelperPage />
          </CodeReferenceContextProvider>
        </BSCategoriesContextProvider>
      }
    />

    <Route path={pagesUrl.MY_OFFER_PAGE} element={<MyOfferPage />} />
    {/* Beem Shot main pages */}
    <Route
      element={
        <BSModelListContextProvider>
          <BSVariantContextProvider>
            <DepartmentContextProvider>
              <LotContextProvider>
                <DomainNameValidatorContextProvider>
                  <BSCategoriesContextProvider>
                    <BSInputContextProvider>
                      <BSModelContextProvider>
                        <BSModelFileContextProvider>
                          <BSItemContextProvider>
                            <BSCodesExtraitsContextProvider>
                              <Outlet />
                            </BSCodesExtraitsContextProvider>
                          </BSItemContextProvider>
                        </BSModelFileContextProvider>
                      </BSModelContextProvider>
                    </BSInputContextProvider>
                  </BSCategoriesContextProvider>
                </DomainNameValidatorContextProvider>
              </LotContextProvider>
            </DepartmentContextProvider>
          </BSVariantContextProvider>
        </BSModelListContextProvider>
      }>
      <Route path={pagesUrl.BEEM_SHOT_PROJECTS_DETAIL} element={<BSProjectDetailsPage />} />
      <Route path={pagesUrl.BEEM_SHOT_BIM_MODEL_IMPORT_PAGE} element={<BSBimModelImportPage />} />
      <Route
        path={pagesUrl.BEEM_SHOT_VARIANTS_DETAIL}
        element={
          <SelectionContextProvider>
            <BSVariantResultContextProvider>
              <BSMaterialContextProvider>
                <BSVariantDetailPage />
              </BSMaterialContextProvider>
            </BSVariantResultContextProvider>
          </SelectionContextProvider>
        }
      />
      <Route path={pagesUrl.BEEM_SHOT_BIM_MODEL_DETAIL_PAGE} element={<BSBimModelDetailPage />} />
      <Route path={pagesUrl.BEEM_SHOT_INVITATION_CONFIRMATION} element={<BSInvitationConfirmationPage />} />
      <Route path={pagesUrl.BEEM_SHOT_BIM_MODEL_CHOICE} element={<BSBimModelChoicePage />} />
      <Route path={pagesUrl.BEEM_SHOT_CALCUL} element={<BSCalculationPage />} />
      <Route path={pagesUrl.BEEM_SHOT_HOME} element={<BSHomePage />} />
    </Route>
  </Route>
)
