import { Box, Button, TextField, Typography } from "@mui/material"
import React, { Dispatch, SetStateAction, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DeleteDialog } from "../../../../../components/dialog/DeleteDialog"
import { pagesUrl } from "../../../../../core/appConstants"
import { RseeProjectContext } from "../../../../../core/context/beem-pilot/rsee/rsee-project-context"
import { ProjectTypeEnum } from "../../../../../core/enum/project/projectTypeEnum"
import { ProjectStatusEnum } from "../../../../../core/enum/projectStatusEnum"
import { resolveUrl } from "../../../../../core/services/http-service"
import { SuccessContext } from "../../../../../components/layout/success-snackbar"

type IProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export function DeleteRseeProjectDialog({ open, setOpen }: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()

  const openSuccessSnackbar = useContext(SuccessContext)
  const { rseeProject, handleDeleteRseeProject, updateStatusToArchived } = useContext(RseeProjectContext)

  const [error, setError] = useState<Record<string, string | undefined>>({})
  const [rseeNameToDelete, setRseeNameToDelete] = useState<string>("")

  function handleChangeDeleteDialog(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target

    setError({ ...error, rseeNameToDelete: undefined })
    setRseeNameToDelete(target.value)
  }

  function validateProjectNameToDelete(): boolean {
    const newError: Record<string, string> = {}
    let isValid = true
    const nameValue = rseeNameToDelete?.trim()

    if (!nameValue?.length) {
      newError.rseeNameToDelete = "Veuillez remplir ce champ"
      isValid = false
    }

    if (nameValue !== rseeProject?.projectName) {
      newError.rseeNameToDelete = "Le nom du projet est incorrect"
      isValid = false
    }

    if (nameValue.length > 1000) {
      newError.rseeNameToDelete = "1000 caractères maximum"
      isValid = false
    }

    setError(newError)
    return isValid
  }

  return (
    <DeleteDialog
      id="deleteRseeProject"
      title={`Suppression du projet "${rseeProject?.projectName}"`}
      contentText={
        <>
          {rseeProject?.projectStatus !== ProjectStatusEnum.ARCHIVED && (
            <Box pb={2}>
              <Typography variant="body1" sx={{ fontSize: 19, mb: 2 }}>
                La suppression du projet RSEE n'est possible qu'à l'état Archivé. Voulez-vous passer le statut du projet à
                Archivé ?
              </Typography>
              <Button variant="contained" onClick={updateStatusToArchived}>
                Archiver le projet
              </Button>
            </Box>
          )}

          {rseeProject?.projectStatus === ProjectStatusEnum.ARCHIVED && (
            <>
              <Typography variant="body1" sx={{ fontSize: 19 }}>
                Pour confirmer la suppression, tapez "{rseeProject?.projectName}" ci-dessous (sans les guillemets)
              </Typography>
              <TextField
                required
                id="bsProjectNameToDelete"
                disabled={rseeProject?.projectStatus !== ProjectStatusEnum.ARCHIVED}
                onChange={handleChangeDeleteDialog}
                value={rseeNameToDelete}
                variant="outlined"
                fullWidth
                error={!!error.bsProjectNameToDelete}
                helperText={error.bsProjectNameToDelete}
                sx={{
                  mt: 2,
                  input: { cursor: rseeProject?.projectStatus !== ProjectStatusEnum.ARCHIVED ? "not-allowed" : "text" },
                }}
              />
            </>
          )}
        </>
      }
      handleClose={() => setOpen(false)}
      action={() => {
        if (validateProjectNameToDelete()) {
          handleDeleteRseeProject()
            .then(() => {
              openSuccessSnackbar("Le projet a bien été supprimé")
              setOpen(false)
              navigate(resolveUrl(pagesUrl.BEEM_PILOT_HOME, [], { type: ProjectTypeEnum.RSEE }))
            })
            .finally(() => {
              setOpen(false)
            })
        }
      }}
      isOpen={open}
      disabledActionButton={rseeProject?.projectStatus !== ProjectStatusEnum.ARCHIVED}
    />
  )
}
