import { Box, Grid, Paper, Typography } from "@mui/material"
import React, { useMemo } from "react"
import { BimModelChart } from "../../../../../../../components/charts/BimModelChart/BimModelChart"
import { BSVariantResult } from "../../../../../../../core/dto/beem-shot/BSVariantResult/BSVariantResult"
import { DashboardSectionTitle } from "../../../../../../../components/charts/components/DashboardSectionTitle"
import { IcConstructionThresholdChart } from "../../../../../../../components/charts/ThresholdChart/IcConstructionThresholdChart"
import { BSProjectResult } from "../../../../../../../core/dto/beem-shot/BSProjectResult/BSProjectResult"
import { displayNumber } from "../../../../../../../core/services/helper-service"
import { BSMaterialResult } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { customPalette } from "../../../../../../../../theme"
import { ImpactKPI } from "../../../../../../../components/charts/kpi/ImpactKPI"

interface IProps {
  bsProjectResult: BSProjectResult
  bsVariantResult: BSVariantResult
  allBSMaterialResult: BSMaterialResult[]
}

export function IcConstructionTopLeftPanel({
  bsProjectResult,
  bsVariantResult,
  allBSMaterialResult,
}: Readonly<IProps>): React.JSX.Element {
  const labelIsRE2020: string = useMemo(() => {
    if (bsVariantResult.carbonImpactTotalPerSurface < bsVariantResult.threshold2031) {
      return "Seuil 2031"
    } else if (bsVariantResult.carbonImpactTotalPerSurface < bsVariantResult.threshold2028) {
      return "Seuil 2028"
    } else if (bsVariantResult.carbonImpactTotalPerSurface < bsVariantResult.threshold2025) {
      return "Seuil 2025"
    } else if (bsVariantResult.carbonImpactTotalPerSurface < bsVariantResult.threshold2022) {
      return "Seuil 2022"
    } else {
      return "Non RE2020"
    }
  }, [
    bsVariantResult.carbonImpactTotalPerSurface,
    bsVariantResult.threshold2022,
    bsVariantResult.threshold2025,
    bsVariantResult.threshold2028,
    bsVariantResult.threshold2031,
  ])

  return (
    <Paper sx={{ height: "100%" }}>
      <DashboardSectionTitle label="Score IcConstruction" />
      <Grid container sx={{ pl: 2, pr: 2 }}>
        <Grid item xs={3} container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <ImpactKPI
              carbonImpact={bsVariantResult.carbonImpactTotalPerSurface}
              unit={
                <span>
                  (kgeqCO<sub>2</sub>/m²Sref)
                </span>
              }
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
            <Typography textAlign="center" sx={{ fontWeight: "bold", color: customPalette.dashboardGray }}>
              {labelIsRE2020}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={9} container>
          <Grid item xs={12}>
            <IcConstructionThresholdChart
              impact={bsVariantResult.carbonImpactTotalPerSurface}
              threshold2022={bsVariantResult.threshold2022}
              threshold2025={bsVariantResult.threshold2025}
              threshold2028={bsVariantResult.threshold2028}
              threshold2031={bsVariantResult.threshold2031}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ pt: 5 }}>
          <Box sx={{ mr: 3 }}>
            <BimModelChart allBSMaterialResult={allBSMaterialResult} />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ pt: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h6" sx={{ color: customPalette.dashboardGray }}>
              Surface de référence: {displayNumber(bsVariantResult.surfaceRef)}(m²)
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
