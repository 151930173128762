import AddIcon from "@mui/icons-material/Add"
import { CircularProgress, Grid, IconButton } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SearchField } from "../../../../components/inputs/search-field"
import TeamDetail from "../../../../components/miscellianous/team/team-details"
import { pagesUrl } from "../../../../core/appConstants"
import { RseeProjectContext } from "../../../../core/context/beem-pilot/rsee/rsee-project-context"
import { AdminOrganizationContext } from "../../../../core/context/organization/admin-organization-context"
import { UserContext } from "../../../../core/context/user/user-context"
import { Collaborator } from "../../../../core/dto/user/collaborator"
import { ProjectTypeEnum } from "../../../../core/enum/project/projectTypeEnum"
import { useOrganization } from "../../../../core/hooks/use-organization"
import { useTeams } from "../../../../core/hooks/use-team"
import { isUltimateUser } from "../../../../core/services/authentication-service"
import { getFullNameLabel } from "../../../../core/services/helper-service"
import { resolveUrl } from "../../../../core/services/http-service"
import RseeRevokeDialog from "../../../beem-pilot/RseeProjectDetailPage/components/RseeTeamTab/components/RseeRevokeDialog"

type IProps = {
  isWriting: boolean
}

export function RseeTeam({ isWriting }: IProps): React.JSX.Element {
  const navigate = useNavigate()
  const { getCollaboratorsForOrganization, fetchCollaborators } = useOrganization()
  const { fetchRseeTeam, revokeUserFromRseeProject, addTeamMember } = useTeams()
  const { rseeProject } = useContext(RseeProjectContext)
  const { user } = useContext(UserContext)
  const { organization } = useContext(AdminOrganizationContext)
  const [selectedCollaborator, setSelectedCollaborator] = useState<Collaborator | null>(null)
  const [collaboratorList, setCollaboratorList] = useState<Collaborator[]>([])
  const [currentTeam, setCurrentTeam] = useState<Collaborator[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAdding, setIsAdding] = useState(false)
  const [isRevokeDialogOpen, setIsRevokeDialogOpen] = useState(false)
  const [cognitoUserIdToRevoke, setCognitoUserIdToRevoke] = useState<string | undefined>(undefined)
  const [labelToRevoke, setLabelToRevoke] = useState<string>("")

  useEffect(() => {
    refreshTeamAndCollab()
  }, [getCollaboratorsForOrganization, fetchCollaborators, fetchRseeTeam, rseeProject?.id])

  function refreshTeamAndCollab(): Promise<void> {
    if (rseeProject?.id && !isLoading) {
      const rseeTeamPromise = fetchRseeTeam(rseeProject.id)
      const collaboratorListPromise =
        isUltimateUser(user) && organization?.id ? getCollaboratorsForOrganization(organization?.id) : fetchCollaborators()
      setIsLoading(true)
      return Promise.all([rseeTeamPromise, collaboratorListPromise])
        .then((result) => {
          const teamMembersList = result[0]
          const newCollaboratorsList = result[1]
          const teamSet: Set<string> = new Set(teamMembersList.map((collaborator) => collaborator.cognitoUserId))
          const collaborators = newCollaboratorsList.filter((newCollaborator) => !teamSet.has(newCollaborator.cognitoUserId))
          setCollaboratorList(collaborators)
          setCurrentTeam(teamMembersList)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      return Promise.resolve()
    }
  }

  function getOptionLabel(option: Collaborator): string {
    return option ? getFullNameLabel(option.givenName, option.familyName) : ""
  }

  function onSelectCollaborator(event: any, newValue: Collaborator | null): void {
    setSelectedCollaborator(newValue)
  }

  function onRevokeUser(cognitoUserId: string, firstName: string | undefined, lastName: string | undefined): Promise<void> {
    setCognitoUserIdToRevoke(cognitoUserId)
    if (firstName && lastName) {
      setLabelToRevoke(getFullNameLabel(firstName, lastName))
    } else {
      setLabelToRevoke("")
    }

    setIsRevokeDialogOpen(true)
    return Promise.resolve()
  }

  function revokeUser(): Promise<void> {
    if (rseeProject?.id && cognitoUserIdToRevoke) {
      return revokeUserFromRseeProject(cognitoUserIdToRevoke, rseeProject.id).then(() => {
        if (cognitoUserIdToRevoke === user?.cognitoUserId) {
          navigate(resolveUrl(pagesUrl.PROJECTS_PAGE, [], { type: ProjectTypeEnum.RSEE }))
          return Promise.resolve()
        } else {
          return refreshTeamAndCollab().then(() => Promise.resolve())
        }
      })
    } else {
      return Promise.resolve()
    }
  }

  function onAddTeamMember(): void {
    if (rseeProject?.id && selectedCollaborator?.cognitoUserId) {
      setIsAdding(true)
      addTeamMember(selectedCollaborator.cognitoUserId, rseeProject.id)
        .then((collaborator) => {
          setSelectedCollaborator(null)
          return refreshTeamAndCollab()
        })
        .finally(() => setIsAdding(false))
    }
  }

  function onCloseRevoqueDialog(): void {
    setIsRevokeDialogOpen(false)
  }

  return (
    <Grid item xs={12} container columnSpacing={1}>
      <Grid item xs={6}>
        {currentTeam.map((teamMember: Collaborator) => (
          <TeamDetail
            key={teamMember.cognitoUserId}
            cognitoUserId={teamMember.cognitoUserId}
            firstName={teamMember.givenName}
            lastName={teamMember.familyName}
            revokeAction={onRevokeUser}
            colorAvatar="#FFDD80"
            revokeButton={isWriting}
          />
        ))}
      </Grid>
      <Grid item xs={6}>
        {isWriting && (
          <Grid container sx={{ display: "flex", alignItems: "center" }} columnSpacing={1}>
            <Grid item xs={10}>
              <SearchField
                label="Recherche un collaborateur"
                value={selectedCollaborator}
                options={collaboratorList}
                onChange={onSelectCollaborator}
                getOptionLabel={getOptionLabel}
              />
            </Grid>
            <Grid item xs={2}>
              {isAdding ? (
                <CircularProgress />
              ) : (
                <IconButton onClick={onAddTeamMember}>
                  <AddIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <RseeRevokeDialog
        open={isRevokeDialogOpen}
        revokeRole={revokeUser}
        selectedCognitoId={cognitoUserIdToRevoke}
        selectedUserName={labelToRevoke}
        handleClose={onCloseRevoqueDialog}
      />
    </Grid>
  )
}
