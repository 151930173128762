import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router"
import { useParams } from "react-router-dom"
import CodeExtraitListWithViewer from "../../../components/code-extrait-list-with-viewer/code-extrait-list-with-viewer"
import ViewerIFC from "../../../components/ifc-displayer/ViewerIFC"
import { adminPagesUrl, pagesUrl } from "../../../core/appConstants"
import { CalculationContext } from "../../../core/context/calculation/calculation-context"
import { ProjectContext } from "../../../core/context/project/project-context"
import { SelectedPhaseContext } from "../../../core/context/selected-phase-context"
import { ProjectRoleContext } from "../../../core/context/user/project-role-context"
import { CodeExtrait } from "../../../core/dto/code-extrait/code-extrait"
import { useUtils } from "../../../core/hooks/use-utils"
import { getEnvProperty, PROPERTIES } from "../../../core/services/environment-service"
import { resolveUrl } from "../../../core/services/http-service"
import { TableauViz } from "../../../core/services/tableau/tableau-service"
import { TableauDashboard } from "../../../components/TableauDashboard/TableauDashboard"
import { CodeExtraitDisplay } from "../../../core/dto/code-extrait/CodeExtraitDisplay"
import { codeToKey } from "../../../core/services/code-service"
import { CodesExtraitsListContext } from "../../../core/context/code-acv/codes-extraits-list-context"

enum typeExportEnum {
  NORMAL = "NORMAL",
  PLEIADES = "PLEIADES",
}

const typeExport = ["Export base", "Export variante", "Export base (PLEIADES)", "Export variante (PLEIADES)"]

export default function DashboardsPage(): React.JSX.Element | null {
  const navigate = useNavigate()
  const { getResultCsv } = useUtils()
  const { organizationId } = useParams()

  const url: string = useMemo(() => getEnvProperty(PROPERTIES.REACT_APP_TABLEAU_URL), [])
  const environment: string = useMemo(() => getEnvProperty(PROPERTIES.REACT_APP_ENVIRONMENT), [])

  const { calculation } = useContext(CalculationContext)
  const { project } = useContext(ProjectContext)
  const { selectedPhase } = useContext(SelectedPhaseContext)
  const { codesExtraits } = useContext(CodesExtraitsListContext)

  const [selectedCodeExtrait, setSelectedCodeExtrait] = useState<CodeExtrait | undefined>(undefined)
  const [viewer, setViewerIFC] = useState<ViewerIFC | undefined>(undefined)
  const ifcDisplayerRef = useRef<HTMLDivElement>(null)
  const ifcDisplayerParentRef = useRef(null)

  const [currentTypeExport, setCurrentTypeExport] = React.useState<string>(typeExport[0])

  const calculationId: string | undefined = environment === "local" ? "6422f00b5d8712648a114eb4" : calculation?.id

  const { isOrganizationUltimateUser } = useContext(ProjectRoleContext)

  const viz = useRef<any>(new TableauViz())

  useEffect(() => {
    console.info("calculationId", calculationId)
  }, [calculationId])

  function onDownload(): void {
    if (calculation.id) {
      const variantId = getVariantId()
      const typeExportCsv = getTypeExport()

      getResultCsv(calculation.id, variantId, typeExportCsv).then((response) => {
        if (response) {
          const fileName = `${project.name}(${selectedPhase})_${variantId === 1 ? "base" : "variante"}${
            typeExportCsv === typeExportEnum.PLEIADES ? "_PLEIADE" : ""
          }.csv`
          const fileDownloadUrl = URL.createObjectURL(response)
          const a = document.createElement("a")

          a.href = fileDownloadUrl
          a.download = fileName

          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(fileDownloadUrl)
        }
      })
    }
  }

  function getTypeExport(): string {
    if (currentTypeExport.includes(typeExportEnum.PLEIADES)) {
      return typeExportEnum.PLEIADES
    } else {
      return typeExportEnum.NORMAL
    }
  }

  function getVariantId(): number {
    if (currentTypeExport.includes("base")) {
      return 1
    } else if (currentTypeExport.includes("variante")) {
      return 2
    }
    return 0
  }

  function handleChange(event: SelectChangeEvent): void {
    setCurrentTypeExport(event.target.value)
  }

  const handleClickAcv = useCallback(
    (code: CodeExtraitDisplay): CodeExtraitDisplay => {
      const newCodeExtrait: CodeExtrait | undefined = codesExtraits.find(
        (codeExtrait) => codeToKey(code) === codeToKey(codeExtrait)
      )
      setSelectedCodeExtrait(newCodeExtrait)
      return code
    },
    [codesExtraits]
  )

  return (
    <Box sx={{ width: "100vw", display: "flex", justifyContent: "center" }}>
      <Box sx={{ mt: 2, maxWidth: "1500px", maxHeight: "1070px", flexDirection: "column" }}>
        <Grid container item columnSpacing={2} rowSpacing={2} sx={{ mt: 2, maxWidth: "95vw" }}>
          <Grid item xs={8} />
          <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
            <Box display="flex" justifyContent="right" alignItems="flex-end">
              <Button
                variant="outlined"
                onClick={() =>
                  isOrganizationUltimateUser
                    ? navigate(resolveUrl(adminPagesUrl.PROJECT_CONTROL_BOARD, [organizationId, project?.id]))
                    : navigate(resolveUrl(pagesUrl.PROJECT_CONTROL_BOARD, [project?.id]))
                }>
                Retour
              </Button>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel sx={{ backgroundColor: "white" }}>Export des résultats : </InputLabel>
              <Select onChange={handleChange} value={currentTypeExport} aria-describedby="Export des résultats">
                {typeExport.map((lv) => (
                  <MenuItem key={lv} value={lv}>
                    {lv}
                  </MenuItem>
                ))}
              </Select>

              <Button onClick={onDownload} size="small" variant="contained" sx={{ ml: 5, mt: 1, mr: 5 }}>
                Télécharger
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <CodeExtraitListWithViewer
              selectedCodeExtrait={selectedCodeExtrait}
              setSelectedCodeExtrait={handleClickAcv}
              fullscreenButton
              viewer={viewer}
              setViewerIFC={setViewerIFC}
              ifcDisplayerRef={ifcDisplayerRef}
              ifcDisplayerParentRef={ifcDisplayerParentRef}
              fullscreenTabButton
              isForDashBoardScreen
            />
          </Grid>
        </Grid>
        {calculationId && (
          <Box sx={{ mt: 2 }}>
            <TableauDashboard
              dashboardUrl={url}
              isDataReady
              tableauParameter={[{ name: "calculationFilter", value: [calculationId] }]}
              filterToAdd={[]}
              vizRef={viz}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
