import { Box, CircularProgress } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { BaseSelectInput } from "../../../../../../../components/inputs/select-input/BaseSelectInput"
import { BSItemContext, dtoToForm, formToDto } from "../../../../../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSItem } from "../../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { BSMacroComponent } from "../../../../../../../core/dto/beem-shot/BSMacroComponent/BSMacroComponent"
import { BSMacroComponentType } from "../../../../../../../core/dto/beem-shot/BSMacroComponent/BSMacroComponentType"
import { useBSItem } from "../../../../../../../core/hooks/beem-shot/useBSItem"

interface IProps {
  bsMacroComponentType: BSMacroComponentType
  bsItem: BSItem
  disabled: boolean
}

export type SelectOption<T> = {
  id?: string
  value: T
  label: string | undefined
}

export function MacroComponentSelect({ bsMacroComponentType, bsItem, disabled }: Readonly<IProps>): React.JSX.Element {
  const { bsVariantId } = useParams()

  const { updateBSItemFunction } = useContext(BSItemContext)

  const { getMacroListOptions } = useBSItem()
  const [optionsLoading, setOptionsLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<SelectOption<string>[]>([])
  const [selected, setSelected] = useState<string | undefined>()

  function handleFocusOut(): void {
    saveOptions()
  }

  function handleChange(key: string): void {
    const actualKey = options.find((option) => option.value === key)?.value
    if (actualKey) {
      setSelected(actualKey)
    }
  }

  function saveOptions(): void {
    const dto = dtoToForm(bsItem)
    const currentOption = options.find((option) => option.value === selected)

    dto.bsMacroComponentIds[bsMacroComponentType.name] = currentOption?.id

    updateBSItemFunction(formToDto(dto))
  }

  useEffect(() => {
    if (bsVariantId) {
      setOptionsLoading(true)
      getMacroListOptions(bsVariantId, bsItem.id, bsMacroComponentType)
        .then((response) =>
          response.map((bsMacroComponent: BSMacroComponent) => ({
            id: bsMacroComponent.id,
            label: bsMacroComponent.key.label,
            value: bsMacroComponent.key.name,
          }))
        )
        .then((newOptions) => {
          // Actually, we set the value to 'none'. However, we need to ensure it works with an undefined value in the future.
          setOptions([
            {
              label: "Aucun(e)",
              value: "None",
            },
            ...newOptions,
          ])
        })
        .finally(() => {
          setOptionsLoading(false)
        })
    }
  }, [bsItem.calculType, bsItem.id, getMacroListOptions, bsMacroComponentType, bsVariantId])

  useEffect(() => {
    const initialValue = bsItem.bsMacroComponents.find(
      (bsMacroComponent) => bsMacroComponent.type.name === bsMacroComponentType.name
    )?.key.name

    if (initialValue) {
      setSelected(initialValue)
    } else if (options.length > 0) {
      setSelected(options[0].value)
    }
  }, [options])

  return (
    <Box>
      {selected && !optionsLoading ? (
        <BaseSelectInput
          id={`${bsItem.id}-${bsMacroComponentType.name}`}
          label={bsMacroComponentType.label}
          selectedOption={selected}
          options={options}
          mode="direct"
          handleChange={handleChange}
          isBeemShot={!disabled}
          handleSubmit={handleFocusOut}
          disabled={disabled}
        />
      ) : (
        <CircularProgress />
      )}
    </Box>
  )
}
