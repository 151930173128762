import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined"
import { Box, Grid, Tooltip, Typography } from "@mui/material"
import React from "react"
import { CodeExtraitDisplay } from "../../../../core/dto/code-extrait/CodeExtraitDisplay"

type iProps = {
  codeExtrait?: CodeExtraitDisplay
  codeTitle: string
  codeDescription?: string
  codeColor: string
  highlightBorder?: boolean
  handleClickAcv?: any
  numberElements?: number
  showBorder?: boolean
}

export function CodeDisplayer({
  codeExtrait,
  codeTitle,
  codeDescription,
  codeColor,
  highlightBorder,
  handleClickAcv,
  numberElements,
  showBorder = true,
}: Readonly<iProps>): React.JSX.Element {
  const codeOccurrence: string = codeExtrait?.occurence ? `(${codeExtrait.code}${codeExtrait?.occurence})` : ""

  return (
    <Box width="100%" borderRadius={3} sx={{ background: "#E6F0FB", cursor: "pointer" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
        onClick={handleClickAcv}
        sx={{
          px: showBorder ? 1 : 0,
          py: showBorder ? 0.5 : 0,
          gap: 2,
          border: showBorder ? 4 : 0,
          borderRadius: 2,
          background: "#F5F8F8",
          borderColor: highlightBorder ? "#0BE2A0" : "#ECF3F3",
        }}>
        <Grid container spacing={2}>
          <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
            {codeExtrait ? (
              <Box sx={{ background: codeColor, width: "10px", height: "36px", borderRadius: "7px" }} />
            ) : (
              <Tooltip title="Valeur calculée" arrow placement="top">
                <CalculateOutlinedIcon fontSize="medium" />
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={11} display="flex" flexDirection="column">
            <Typography variant="body2" fontWeight={600}>
              {`${codeTitle} ${codeOccurrence}`}
            </Typography>
            <Typography variant="body2" sx={{ color: "#8398A6" }}>
              {codeDescription}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {numberElements && (
        <Box
          sx={{
            ml: "auto",
            background: "black",
            borderRadius: 2,
            color: "white",
            px: 1,
          }}>
          {numberElements}
        </Box>
      )}
    </Box>
  )
}
