import { Box } from "@mui/material"
import React, { useState } from "react"
import DefaultBsProjectCard from "../../../assets/DefaultBsProjectCard.png"

type ImageDisplayerProps = {
  file: File | undefined
  width?: number | string
  height?: number | string
  isLoading?: boolean
  activateAnimation?: boolean
}

export function ImageDisplayer({
  file,
  width,
  height,
  isLoading,
  activateAnimation,
}: Readonly<ImageDisplayerProps>): React.JSX.Element {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        perspective: "1000px",
        width: width ?? "100%",
        height: height ?? "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Box
        component="img"
        src={file && !isLoading ? URL.createObjectURL(file) : DefaultBsProjectCard}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: 3,
          transition: "transform 0.6s",
          transform: isHovered && file && activateAnimation ? "rotateY(180deg)" : "rotateY(0deg)",
          transformStyle: "preserve-3d",
        }}
      />
    </Box>
  )
}
