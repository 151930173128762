import { useCallback, useContext, useMemo } from "react"
import { ErrorContext } from "../../components/layout/error-snackbar"
import { TokenContext } from "../context/user/token-context"
import { SpringHttpError } from "../dto/spring-http-error"
import { addRequestParams, RequestParam, retry } from "../services/http-service"

type HttpHook = {
  get(url: string, requestParams?: RequestParam[], signal?: AbortSignal): Promise<Response>
  post(url: string, payload: any, requestParams?: RequestParam[]): Promise<Response>
  put(url: string, payload: any, requestParams?: RequestParam[]): Promise<Response>
  postFile(url: string, payload: any, requestParams?: RequestParam[]): Promise<Response>
  putFile(url: string, payload: any, requestParams?: RequestParam[]): Promise<Response>
  deleteRequest(url: string, requestParams?: RequestParam[]): Promise<Response>
  deleteWithId(url: string, payload: any, requestParams?: RequestParam[]): Promise<Response>
}

export function useHttp(): HttpHook {
  const openErrorSnackbar = useContext(ErrorContext)
  const { tokenRef, unsetCookieAndUserId, refreshToken } = useContext(TokenContext)

  const handleError = useCallback(
    async (r: Response): Promise<Response> => {
      try {
        if (r.status === 401) {
          console.error("Echec refresh token")
          await unsetCookieAndUserId()
        } else if (r.status === 404) {
          const clonedResponse = r.clone()
          const err: SpringHttpError = new SpringHttpError(await clonedResponse.json())
          if (err.message === "No message available" && err.error === "Not Found") {
            // The endpoint doesn't exist when trying
            console.info("Le endpoint backend n'existe pas: ", err.path)
            throw new Error("ENDPOINT_DOES_NOT_EXISTS")
          } else if (err.message === "No message available") {
            throw err
          } else {
            console.info("404 : no ressource")
            // Nothing to do: 404 with error message means endpoint exists
            // but resource does not in db. So needs to be handle at component level
          }
        } else if (r.status >= 300) {
          const errorBody: string = await r.text()
          let springHttpError
          try {
            springHttpError = new SpringHttpError(JSON.parse(errorBody))
          } catch (e) {
            if (r.status === 403 || r.status === 401) {
              throw new Error(`${r.status}_FORBIDDEN`)
            } else {
              throw new Error(`Erreur ${r.status}`)
            }
          }
          throw new Error(springHttpError.message)
        }
        return r
      } catch (e: unknown) {
        // Code USER_MUST_BE_SAME_SAME_ORGA is special. It should be an error in the form, not in the snackbar.
        // If more codes have the same behaviour, we should implement something more generic. For now, it's simple enough like that
        if (e instanceof Error && e.message !== "USER_MUST_BE_SAME_SAME_ORGA") {
          openErrorSnackbar(e)
        }
        throw e
      }
    },
    [openErrorSnackbar, unsetCookieAndUserId]
  )

  const handleErrorRetry = useCallback(
    async (r: Response, input: RequestInfo, headers: Headers, options?: RequestInit): Promise<Response> => {
      if (r.status === 401) {
        return refreshToken()
          .then((newToken) => retry(input, newToken, headers, options))
          .then((response: Response) => handleError(response))
          .catch((reason) => reason)
      } else {
        return handleError(r)
      }
    },
    [handleError, refreshToken]
  )

  return useMemo<HttpHook>(
    () => ({
      async get(url: string, requestParams?: RequestParam[], signal?: AbortSignal): Promise<Response> {
        const { finalUrl, headers } = addRequestParams(url, tokenRef.current, requestParams)
        const options: RequestInit = {
          headers,
        }
        // Allow to interrupt the request, especially for file download
        if (signal) {
          options.signal = signal
        }

        const r = await fetch(finalUrl, options)
        return handleErrorRetry(r, finalUrl, headers, options)
      },
      async post(url: string, payload: any, requestParams?: RequestParam[]): Promise<Response> {
        const { finalUrl, headers } = addRequestParams(url, tokenRef.current, requestParams)

        headers.set("Content-Type", "application/json")
        const options: RequestInit = {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }

        const r = await fetch(finalUrl, options)
        return handleErrorRetry(r, finalUrl, headers, options)
      },
      async put(url: string, payload: any, requestParams?: RequestParam[]): Promise<Response> {
        const { finalUrl, headers } = addRequestParams(url, tokenRef.current, requestParams)

        headers.set("Content-Type", "application/json")
        const options: RequestInit = {
          method: "PUT",
          headers,
          body: JSON.stringify(payload),
        }
        const r = await fetch(finalUrl, options)
        return handleErrorRetry(r, finalUrl, headers, options)
      },
      async postFile(url: string, formData: FormData): Promise<Response> {
        const { finalUrl, headers } = addRequestParams(url, tokenRef.current)
        const options: RequestInit = { method: "POST", body: formData, headers }
        const r = await fetch(finalUrl, options)
        return handleErrorRetry(r, finalUrl, headers, options)
      },
      putFile(url: string, formData: FormData): Promise<Response> {
        const { finalUrl, headers } = addRequestParams(url, tokenRef.current)
        const options: RequestInit = { method: "PUT", body: formData, headers }

        return fetch(finalUrl, options).then((r) => handleErrorRetry(r, finalUrl, headers, options))
      },
      // This function is not called "delete" because it is a reserved word in javascript
      async deleteRequest(url: string, requestParams?: RequestParam[]): Promise<Response> {
        const { finalUrl, headers } = addRequestParams(url, tokenRef.current, requestParams)
        const options: RequestInit = { method: "DELETE", headers }

        const r = await fetch(finalUrl, options)
        return handleErrorRetry(r, finalUrl, headers, options)
      },
      async deleteWithId(url: string, id: string | undefined): Promise<Response> {
        const { finalUrl, headers } = addRequestParams(url, tokenRef.current)
        const input: RequestInfo = `${finalUrl}/${id}`
        const options: RequestInit = { method: "DELETE", headers }

        const r = await fetch(input, options)
        return handleErrorRetry(r, input, headers, options)
      },
    }),
    // tokenRef
    [handleErrorRetry]
  )
}
