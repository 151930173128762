import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material"
import React, { useMemo } from "react"
import { DashboardSectionTitle } from "../../../../../../../components/charts/components/DashboardSectionTitle"
import { BimModelChart } from "../../../../../../../components/charts/BimModelChart/BimModelChart"
import { BSMaterialResult } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { displayNumber, sum } from "../../../../../../../core/services/helper-service"
import { BiosourceThresholdChart } from "../../../../../../../components/charts/ThresholdChart/BiosourceThresholdChart"
import { BiosourceeLevel } from "../../../../../../../core/dto/BiosourceLevel/BiosourceLevel"
import { BSVariantResult } from "../../../../../../../core/dto/beem-shot/BSVariantResult/BSVariantResult"
import { ImpactKPI } from "../../../../../../../components/charts/kpi/ImpactKPI"

interface IProps {
  bsVariantResult: BSVariantResult
  allBSMaterialResult: BSMaterialResult[]
  biosourceLevel: BiosourceeLevel | undefined
}

export function BiosourceTopLeftPanel({
  bsVariantResult,
  allBSMaterialResult,
  biosourceLevel,
}: Readonly<IProps>): React.JSX.Element {
  const totalCarbonStock: number = useMemo(
    () => sum(allBSMaterialResult.map((bsMaterialResult) => bsMaterialResult.carbonStockPerSurface)),
    [allBSMaterialResult]
  )

  const labelIsConform = useMemo(() => {
    if (!biosourceLevel) {
      return ""
    } else if (totalCarbonStock < biosourceLevel.lvl1CarbonStockPerSurface2024) {
      return "Non conforme"
    } else if (
      biosourceLevel.lvl1CarbonStockPerSurface2024 <= totalCarbonStock &&
      totalCarbonStock < biosourceLevel.lvl2CarbonStockPerSurface2024
    ) {
      return "Niveau 1"
    } else if (
      biosourceLevel.lvl2CarbonStockPerSurface2024 <= totalCarbonStock &&
      totalCarbonStock < biosourceLevel.lvl3CarbonStockPerSurface2024
    ) {
      return "Niveau 2"
    } else if (biosourceLevel.lvl3CarbonStockPerSurface2024 > 45) {
      return "Niveau 3"
    } else {
      return ""
    }
  }, [biosourceLevel, totalCarbonStock])

  return (
    <Paper sx={{ height: "100%" }}>
      <DashboardSectionTitle label="Score Biosourcé" />
      <Grid container sx={{ pl: 2, pr: 2 }}>
        <Grid item xs={3} container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <ImpactKPI carbonImpact={totalCarbonStock} unit={<span>kgC/m²Sref</span>} />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
            <Typography textAlign="center">{labelIsConform}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={9}>
          {biosourceLevel ? (
            <BiosourceThresholdChart
              biosource={totalCarbonStock}
              thresholdLvl1={biosourceLevel.lvl1CarbonStockPerSurface2024}
              thresholdLvl2={biosourceLevel.lvl2CarbonStockPerSurface2024}
              thresholdLvl3={biosourceLevel.lvl3CarbonStockPerSurface2024}
            />
          ) : (
            <CircularProgress />
          )}
        </Grid>
        <Grid item xs={12} sx={{ pt: 5 }}>
          <Box sx={{ mr: 3 }}>
            <BimModelChart allBSMaterialResult={allBSMaterialResult} />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ pt: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h6">Surface de référence: {displayNumber(bsVariantResult.surfaceRef)}(m²)</Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}
