import { displayDecimal } from "./helper-service"

export function toCsvString<T>(
  data: T[],
  columns: (keyof T)[],
  columnNames: string[] | undefined = undefined,
  separator = ";",
  printHeader = true
): string {
  const rowArray: string[] = []

  // En-têtes CSV
  if (printHeader) {
    if (columnNames && columnNames.length === columns.length) {
      rowArray.push(columnNames.join(separator))
    } else {
      rowArray.push(columns.join(separator))
    }
  }

  data.forEach((item) => {
    const row: string = columns
      .map((col): string => {
        const value: any = item[col] // Accède dynamiquement aux valeurs de l'objet
        return valueToCsvString(value)
      })
      .join(separator)

    rowArray.push(row)
  })

  return rowArray.join("\n")
}

function valueToCsvString(value: any): string {
  if (typeof value === "string") {
    return `"${value.replace(/"/g, '""')}"` // Échapper les guillemets
  } else if (typeof value === "number") {
    return displayDecimal(value, 1)
  } else if (typeof value === "boolean") {
    return value ? "TRUE" : "FALSE"
  } else if (value === undefined || value === null) {
    return ""
  } else {
    return `${value}`
  }
}
