import React, { useCallback, useMemo, useState } from "react"
import { Children } from "../../../../../../../components/miscellianous/children"
import { BSConfirmResetAllDialog } from "./BSConfirmResetAllDialog"

export const BSConfirmResetAllDialogContext = React.createContext<ConfirmResetAllDialogStore>(
  {} as ConfirmResetAllDialogStore
)

export function BSConfirmResetAllDialogContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const [isOpen, setIsOpen] = useState(false)

  const openConfirmResetAllDialog = useCallback((): void => {
    setIsOpen(true)
  }, [])

  const confirmResetDialogStore: ConfirmResetAllDialogStore = useMemo(
    () => ({ openConfirmResetAllDialog }),
    [openConfirmResetAllDialog]
  )
  return (
    <BSConfirmResetAllDialogContext.Provider value={confirmResetDialogStore}>
      <BSConfirmResetAllDialog isOpen={isOpen} handleClose={() => setIsOpen(false)} />
      {children}
    </BSConfirmResetAllDialogContext.Provider>
  )
}

export type ConfirmResetAllDialogStore = {
  openConfirmResetAllDialog(): void
}
