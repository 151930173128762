import { useParams } from "react-router-dom"
import React, { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { IBSModelForm } from "../../components/BSBimModelForm/IBSModelForm"
import { BSBimModel } from "../../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { useForm } from "../../../../core/hooks/form/use-form"
import { nonZero, required, ValidationRule } from "../../../../core/hooks/form/validation"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import { BSProjectContext } from "../../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSBimModelContext } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSBimModelListContext } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelContextList"
import { BSModelFileContext } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { BSBimModelUpdate } from "../../../../core/dto/beem-shot/BSBimModel/BSBimModelUpdate"
import { Children } from "../../../../components/miscellianous/children"
import { zipFile } from "../../../../core/services/zip-services"
import { useBSBimModel } from "../../../../core/hooks/beem-shot/useBSBimModel"
import { BSBimModelCreate } from "../../../../core/dto/beem-shot/BSBimModel/BSBimModelCreate"
import { CachingHelper } from "../../../../components/ifc-displayer/helpers/CachingHelper"
import { CodeExtrait } from "../../../../core/dto/code-extrait/code-extrait"
import { codeExtraitListToCreationDto } from "../../../../core/services/code-service"

export const bsModelFileInputFieldName = "bsModelFileInput"

const ruleSurface1: ValidationRule<IBSModelForm> = {
  fieldName: "surfacePlancher",
  rule: (form: IBSModelForm) => form.surfacePlancher > form.surfaceRef,
  errorMessage: "La surface plancher doit être plus grande que la surface SHAB.",
}

const ruleSurface2: ValidationRule<IBSModelForm> = {
  fieldName: "surfacePlancher",
  rule: (form: IBSModelForm) => form.surfacePlancher >= form.empriseAuSol,
  errorMessage: "La surface plancher doit être plus grande ou égal à l'emprise au sol.",
}

function bimModelDtoToForm(bsBimModel: BSBimModel | undefined): IBSModelForm {
  return bsBimModel
    ? {
        id: bsBimModel.id,
        version: bsBimModel.version === "" ? "" : bsBimModel.version,
        surfaceRef: bsBimModel?.surfaceRef ?? 0,
        surfacePlancher: bsBimModel?.surfacePlancher ?? 0,
        empriseAuSol: bsBimModel?.empriseAuSol ?? 0,
        surfaceComble: bsBimModel?.surfaceComble ?? 0,
        surfaceCombleB: bsBimModel?.surfaceCombleB ?? false,
      }
    : {
        id: "",
        version: "",
        surfaceRef: 0,
        surfacePlancher: 0,
        empriseAuSol: 0,
        surfaceComble: 0,
        surfaceCombleB: false,
      }
}

function formToUpdateDto(
  bsModelForm: IBSModelForm,
  bsBimModelId: string,
  modelHashFile: string | undefined
): BSBimModelUpdate {
  return {
    id: bsBimModelId,
    surfaceRef: bsModelForm.surfaceRef,
    surfacePlancher: bsModelForm.surfacePlancher,
    empriseAuSol: bsModelForm.empriseAuSol,
    surfaceComble: bsModelForm.surfaceComble,
    surfaceCombleB: bsModelForm.surfaceCombleB,
    modelHashFile,
  }
}

function formToCreateDto(bsModelForm: IBSModelForm, bsProjectId: string, modelHashFile: string): BSBimModelCreate {
  return {
    projectId: bsProjectId,
    surfaceRef: bsModelForm.surfaceRef,
    surfacePlancher: bsModelForm.surfacePlancher,
    empriseAuSol: bsModelForm.empriseAuSol,
    surfaceComble: bsModelForm.surfaceComble,
    surfaceCombleB: bsModelForm.surfaceCombleB,
    modelHashFile,
  }
}

export const BimModelFormContext = React.createContext<BimModelFormStore>({} as BimModelFormStore)

export function BimModelFormContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsBimModelId } = useParams()
  const { postBSBimModelAndFile, putBSBimModelAndFile } = useBSBimModel()

  const openErrorSnackbar = useContext(ErrorContext)
  const openSuccessSnackbar = useContext(SuccessContext)
  const { bsProject } = useContext(BSProjectContext)
  const { addOrReplaceBimModelInList } = useContext(BSBimModelListContext)
  const { bsBimModel, updateBSBimModel } = useContext(BSBimModelContext)
  const { file, isCompressed, isModelFileLoading } = useContext(BSModelFileContext)

  const [bsModelFileSelected, setBSModelFileSelected] = useState<File | undefined>(file)
  const [fileErrors, setFileErrors] = useState<Record<string, string | undefined>>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isFileSelectedReady, setIsFileSelectedReady] = useState<boolean>(!isModelFileLoading)

  const isSurfaceUpdated = useRef(false)
  const isModelUpdateRef = useRef(false)

  useEffect(() => {
    if (isModelFileLoading) {
      setIsFileSelectedReady(false)
      setBSModelFileSelected(undefined)
    } else {
      setIsFileSelectedReady(true)
      setBSModelFileSelected(file)
    }
  }, [file, isModelFileLoading])

  const selectFile = useCallback((newFile: File) => {
    isModelUpdateRef.current = true
    setFileErrors({})
    setBSModelFileSelected(newFile)
  }, [])

  const resetFile: () => void = useCallback(() => {
    setBSModelFileSelected(undefined)
  }, [])

  const submitUpdateOnlyBSModelForm: (form: IBSModelForm) => Promise<any> = useCallback(
    (form) => {
      if (!bsProject?.id) {
        openErrorSnackbar(new Error("Erreur: il n'y a pas de projet sélectionné"))
        return Promise.resolve()
      }
      if (!bsModelFileSelected) {
        openErrorSnackbar(new Error("Erreur: il n'y a pas de maquette bim chargée"))
        return Promise.resolve()
      }
      if (!bsBimModel?.id) {
        openErrorSnackbar(new Error("Erreur: il n'y a pas de modèle bim sélectionné"))
        return Promise.resolve()
      }

      const bsModelUpdateDto = formToUpdateDto(form, bsBimModel.id, undefined)
      if (bsBimModelId === "new") {
        openErrorSnackbar(new Error("Erreur: On ne peut pas créer un bimModel sans maquette"))
        return Promise.resolve()
      }

      setIsSubmitting(true)
      return updateBSBimModel(bsModelUpdateDto, bsProject.id).finally(() => {
        setIsSubmitting(false)
        resetFile()
      })
    },
    [bsProject?.id, bsModelFileSelected, bsBimModel?.id, bsBimModelId, updateBSBimModel, openErrorSnackbar, resetFile]
  )

  const additionalHandleChange: () => void = useCallback(() => {
    isSurfaceUpdated.current = true
  }, [isSurfaceUpdated])

  const {
    form: bsModelForm,
    errors: errorsBSModelForm,
    handleChange: handleChangeBSModelForm,
    setRules: setRulesForBSModelForm,
    handleSubmit: handleSubmitBSModelForm,
    validate: validateBSModelForm,
  } = useForm(
    bsBimModelId === "new" ? undefined : bsBimModel,
    bimModelDtoToForm,
    [
      required("surfaceRef"),
      required("empriseAuSol"),
      required("surfacePlancher"),
      nonZero("empriseAuSol"),
      nonZero("surfacePlancher"),
      ruleSurface1,
      ruleSurface2,
    ],
    submitUpdateOnlyBSModelForm,
    additionalHandleChange
  )

  useEffect(() => {
    setRulesForBSModelForm(
      bsModelForm.surfaceCombleB
        ? [
            required("surfaceRef"),
            required("empriseAuSol"),
            required("surfacePlancher"),
            required("surfaceComble"),
            nonZero("empriseAuSol"),
            nonZero("surfacePlancher"),
            nonZero("surfaceComble"),
            ruleSurface1,
            ruleSurface2,
          ]
        : [
            required("surfaceRef"),
            required("empriseAuSol"),
            required("surfacePlancher"),
            nonZero("empriseAuSol"),
            nonZero("surfacePlancher"),
            ruleSurface1,
            ruleSurface2,
          ]
    )
  }, [setRulesForBSModelForm, bsModelForm.surfaceCombleB])

  const validateForm = useCallback(() => {
    let isValid = true
    if (!bsModelFileSelected) {
      isValid = false
      setFileErrors({ [bsModelFileInputFieldName]: "Aucun fichier n'est sélectionné" })
    }

    if (!validateBSModelForm()) {
      isValid = false
    }

    return isValid
  }, [bsModelFileSelected, validateBSModelForm])

  const handleSubmitModelAndFile: (codesExtraits: CodeExtrait[]) => Promise<any> = useCallback(
    async (codesExtraits: CodeExtrait[]) => {
      if (!bsProject?.id) {
        openErrorSnackbar(new Error("Erreur: il n'y a pas de projet sélectionné"))
        return Promise.resolve()
      }
      if (!bsModelFileSelected) {
        openErrorSnackbar(new Error("Erreur: il n'y a pas de maquette bim chargée"))
        return Promise.resolve()
      }

      let fileToSend: File
      if (file?.name?.endsWith(".ifczip") && !isCompressed) {
        fileToSend = await zipFile(bsModelFileSelected)
      } else {
        fileToSend = bsModelFileSelected
      }
      const fileHash = await CachingHelper.hashFile(fileToSend)

      let promise: Promise<BSBimModel>
      if (bsBimModel?.id === undefined) {
        // Creation
        const bsBimModelCreate: BSBimModelCreate = formToCreateDto(bsModelForm, bsProject.id, fileHash)
        promise = postBSBimModelAndFile(bsBimModelCreate, fileToSend)
      } else {
        // Update
        const bsBimModelUpdate = formToUpdateDto(bsModelForm, bsBimModel.id, fileHash)
        const codeExtraitCreationDtoList = codeExtraitListToCreationDto(codesExtraits)
        promise = putBSBimModelAndFile(bsBimModelUpdate, fileToSend, codeExtraitCreationDtoList)
      }

      setIsSubmitting(true)
      return promise
        .then((newBSBimModel: BSBimModel) => {
          addOrReplaceBimModelInList(newBSBimModel)
          openSuccessSnackbar("Le modèle et ses informations ont été chargés avec succès")
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    },
    [
      addOrReplaceBimModelInList,
      bsBimModel?.id,
      bsModelFileSelected,
      bsModelForm,
      bsProject?.id,
      file?.name,
      isCompressed,
      openErrorSnackbar,
      openSuccessSnackbar,
      postBSBimModelAndFile,
      putBSBimModelAndFile,
    ]
  )

  const bimModelFormStore: BimModelFormStore = useMemo(
    () => ({
      bsModelForm,
      bsModelFileSelected,
      errorsBSModelForm,
      isFileSelectedReady,
      isModelUpdateRef,
      isSurfaceUpdated,
      isSubmitting,
      handleSubmitBSModelForm,
      handleChangeBSModelForm,
      handleSubmitModelAndFile,
      setRulesForBSBimModel: setRulesForBSModelForm,
      validateForm,
      selectFile,
      fileErrors,
      resetFile,
    }),
    [
      bsModelForm,
      bsModelFileSelected,
      isSurfaceUpdated,
      isFileSelectedReady,
      errorsBSModelForm,
      isSubmitting,
      handleSubmitBSModelForm,
      handleChangeBSModelForm,
      handleSubmitModelAndFile,
      setRulesForBSModelForm,
      validateForm,
      selectFile,
      fileErrors,
      resetFile,
    ]
  )

  return <BimModelFormContext.Provider value={bimModelFormStore}>{children}</BimModelFormContext.Provider>
}

export interface BimModelFormStore {
  bsModelForm: IBSModelForm
  setRulesForBSBimModel: React.Dispatch<React.SetStateAction<ValidationRule<IBSModelForm>[]>>
  bsModelFileSelected: File | undefined
  errorsBSModelForm: Record<string, string | undefined>
  isModelUpdateRef: React.MutableRefObject<boolean>
  isSurfaceUpdated: React.MutableRefObject<boolean>
  fileErrors: Record<string, string | undefined>
  isFileSelectedReady: boolean
  isSubmitting: boolean

  handleChangeBSModelForm(event: ChangeEvent<HTMLInputElement>): void

  handleSubmitBSModelForm(event?: FormEvent<HTMLFormElement> | FormEvent<HTMLDivElement>): Promise<void>

  handleSubmitModelAndFile(codesExtraits: CodeExtrait[]): Promise<any>

  validateForm(): boolean
  selectFile(newFile: File): void
  resetFile(): void
}
