import React, { useCallback, useMemo, useState } from "react"
import { Children } from "../miscellianous/children"
import { GlobalLoadingStyle } from "./GlobalLoadingStyle"

export const LoadingCursorContext = React.createContext<LoadingCursorStore>({} as LoadingCursorStore)

export function LoadingCursorContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const [isLoading, setIsLoading] = useState(false)

  const showLoadingCursor: () => void = useCallback(() => {
    setIsLoading(true)
  }, [])

  const hideLoadingCursor: () => void = useCallback(() => {
    setIsLoading(false)
  }, [])

  const execWithLoaderCursor: (action: () => void) => void = useCallback(
    (action) => {
      showLoadingCursor()
      // This is a little optimisation.
      // So we need setTimeOut in 0ms. So the browser update the cursor before refreshing the elements
      // If we execute directly the select, the cursor will not be transformed to circularProgress
      setTimeout(() => {
        action()
        hideLoadingCursor()
      }, 0)
    },
    [hideLoadingCursor, showLoadingCursor]
  )

  const loadingCursorStore = useMemo(() => ({ execWithLoaderCursor }), [execWithLoaderCursor])

  return (
    <LoadingCursorContext.Provider value={loadingCursorStore}>
      <GlobalLoadingStyle isLoading={isLoading} />
      {children}
    </LoadingCursorContext.Provider>
  )
}

export interface LoadingCursorStore {
  execWithLoaderCursor(action: () => void): void
}
