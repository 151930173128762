import { Box, Chip, CircularProgress, Tab, Tabs, Tooltip } from "@mui/material"
import React, { useMemo } from "react"
import { customPalette } from "../../../theme"
import { DefaultTypography } from "../typography/default-typography"
import { TabEnum, tabEnumToIndex } from "./tabs"

export interface TabPanelProps {
  id: TabEnum
  Icon: React.ElementType
  label: string
  chips: string | undefined
  content: React.JSX.Element
  isNew?: boolean
  disabled?: boolean
  disabledText?: string
  toolTipText?: string
}

interface ChildComponentProps {
  panels: TabPanelProps[]
  currentTabId: TabEnum

  handleChangeTab(event: React.SyntheticEvent, newValue: TabEnum): void
}

function a11yProps(index: any): { id: string; "aria-controls": string } {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export function CustomTabPanel({ panels, currentTabId, handleChangeTab }: Readonly<ChildComponentProps>): React.JSX.Element {
  const updatedPanels = panels.map((panel) => ({
    ...panel,
    disabled: panel.disabled ?? false,
    disabledText: panel.disabledText !== undefined && panel.disabled ? panel.disabledText : undefined,
    toolTipText: panel.toolTipText,
  }))

  const currentTab = useMemo(() => tabEnumToIndex[currentTabId], [currentTabId])

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        {currentTab < panels.length ? (
          <Tabs
            value={currentTab}
            onChange={(event, newValue) => {
              const selectedPanel = updatedPanels.find((panel) => panel.id === newValue)
              if (selectedPanel?.disabled) {
                event.preventDefault()
              } else {
                handleChangeTab(event, newValue)
              }
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#40548D",
              },
            }}>
            {updatedPanels.map((panel, index) => (
              <Tooltip title={panel.disabledText ?? panel.toolTipText} key={panel.id} arrow>
                <Tab
                  value={panel.id}
                  onClick={(e) => panel.disabled && e.preventDefault()}
                  label={
                    <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                      {panel.Icon && <panel.Icon sx={{ color: panel.id === currentTabId ? "#40548D" : "#8398A6" }} />}
                      <DefaultTypography
                        label={panel.label}
                        color={panel.id === currentTabId ? "#40548D" : "#8398A6"}
                        fontSize="16px"
                        fontWeight={500}
                      />

                      {panel.chips && (
                        <Box sx={{ background: "#CEDEEF", px: 1, borderRadius: 4 }}>
                          <DefaultTypography label={panel.chips} color="#40548D" />
                        </Box>
                      )}
                      {panel.isNew && <Chip sx={{ backgroundColor: customPalette.yellowNew }} label="Nouveau" />}
                    </Box>
                  }
                  {...a11yProps(index)}
                  sx={{ cursor: panel.disabled ? "not-allowed" : "pointer" }}
                />
              </Tooltip>
            ))}
          </Tabs>
        ) : (
          <CircularProgress />
        )}
      </Box>
      {updatedPanels.map((panel) => panel.id === currentTabId && <Box key={panel.id}> {panel.content}</Box>)}
    </>
  )
}
