import CreateIcon from "@mui/icons-material/Create"
import { Grid, IconButton } from "@mui/material"
import React, { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { FicheConfiguree } from "../../../core/dto/fiche-configuree/fiche-configuree"
import { IniesRecord } from "../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../core/dto/material/MaterialRecord"
import { TypeMaterialEnum } from "../../../core/enum/typeMaterialEnum"
import { FicheValue } from "../../../pages/beem-shot/BSVariantDetailsPage/BSCustomizationTab/components/context/SelectionContext"
import { TextInput } from "../text-input/text-input"
import { BSMaterialLibDialog } from "./BSMaterialLib/BSMaterialLibDialog"

interface IProps {
  label: string
  form: FicheValue
  handleChange(newValue: FicheValue): void
}

export function SelectIniesRecordInput({ label, form, handleChange }: Readonly<IProps>): React.JSX.Element {
  const [isOpenMaterialLib, setIsOpenMaterialLib] = useState<boolean>(false)

  const navigate = useNavigate()

  const openMaterialLib = useCallback((): void => {
    setIsOpenMaterialLib(true)
  }, [])

  const handleClose = useCallback((): void => {
    setIsOpenMaterialLib(false)
  }, [])

  const onSelect = useCallback(
    (
      selectedRow: IniesRecord | MaterialRecord | FicheConfiguree,
      type: TypeMaterialEnum,
      coefficient: number,
      newQuantity: number
    ): Promise<void> => {
      let ficheId: string | number
      if (selectedRow instanceof IniesRecord || selectedRow instanceof MaterialRecord) {
        ficheId = selectedRow.iniesId
      } else {
        ficheId = selectedRow.id
      }

      let ficheName: string
      if (selectedRow instanceof IniesRecord || selectedRow instanceof MaterialRecord) {
        ficheName = selectedRow.fdesName.trim()
      } else {
        ficheName = selectedRow.name.trim()
      }

      handleChange({
        ficheId: `${ficheId}`,
        ficheName,
        typeMaterial: type,
        coefficient,
        newQuantity,
        unit: selectedRow.functionalUnit,
      })
      return Promise.resolve()
    },
    [handleChange]
  )

  return (
    <Grid container spacing={1}>
      <BSMaterialLibDialog open={isOpenMaterialLib} handleCloseMaterialLib={handleClose} onSelect={onSelect} form={form} />
      <Grid item xs={3} display="flex" alignItems="center">
        <TextInput
          disabled
          label="Identifiant"
          fieldName="ficheId"
          form={{ ficheId: form.ficheId ?? "" }}
          errors={{}}
          hideBorder
          backgroundColor="#F5F5F5"
          borderRadius={3}
          cursorType="pointer"
          onClick={openMaterialLib}
          tooltipText="Modifier la fiche"
          InputProps={{
            inputProps: {
              style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            },
          }}
        />
      </Grid>
      <Grid item xs={7} display="flex" justifyContent="space-around" alignItems="center">
        <TextInput
          disabled
          label={label}
          fieldName="name"
          form={{ name: form.ficheName ?? "" }}
          errors={{}}
          hideBorder
          backgroundColor="#F5F5F5"
          borderRadius={3}
          cursorType="pointer"
          onClick={openMaterialLib}
          tooltipText="Modifier la fiche"
        />
      </Grid>
      <Grid item xs={2} display="flex" alignItems="center">
        <IconButton onClick={openMaterialLib}>
          <CreateIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
