import { Grid } from "@mui/material"
import React from "react"
import { InfoItem } from "../../../../components/card/components/InfoItem"
import { BSBimModel } from "../../../../core/dto/beem-shot/BSBimModel/BSBimModel"

interface IProps {
  bsBimModel: BSBimModel
}

export function BSBimModelInfo({ bsBimModel }: Readonly<IProps>): React.JSX.Element {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <InfoItem label="Version" value={bsBimModel.version?.toString() ?? ""} />
      </Grid>
      <Grid item xs={6}>
        <InfoItem label="Surface utile ou SHAB" value={bsBimModel.surfaceRef?.toString() ?? ""} />
      </Grid>
      <Grid item xs={6}>
        <InfoItem label="Surface plancher" value={bsBimModel.surfacePlancher?.toString() ?? ""} />
      </Grid>
      <Grid item xs={6}>
        <InfoItem label="Emprise au sol" value={bsBimModel.empriseAuSol?.toString() ?? ""} />
      </Grid>
    </Grid>
  )
}
