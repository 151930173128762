import { Checkbox, MenuItem } from "@mui/material"
import React, { useContext, useMemo } from "react"
import { IFilterOption } from "./IFilterOption"
import { BSFilterContext, BSFilterType } from "./BSFilterContext"

interface IProps {
  option: IFilterOption
  isAllChecked: boolean
  filterType: BSFilterType
  selectAll(): void
  handleChange(option: IFilterOption): void
}

export function FilterMenuItem({
  option,
  isAllChecked,
  filterType,
  selectAll,
  handleChange,
}: Readonly<IProps>): React.JSX.Element {
  const { isNotFilteredOut } = useContext(BSFilterContext)

  const checked = useMemo(() => isNotFilteredOut(option.value, filterType), [filterType, isNotFilteredOut, option.value])

  return option.value === "ALL" ? (
    <MenuItem value={option.value} onClick={selectAll} selected={isAllChecked}>
      <Checkbox checked={isAllChecked} />
      {option.label}
    </MenuItem>
  ) : (
    <MenuItem value={option.value} onClick={() => handleChange(option)} selected={checked}>
      <Checkbox checked={checked} />
      {option.label}
    </MenuItem>
  )
}
