import { BottomNavigation, Box, Tooltip, useTheme } from "@mui/material"
import React, { useContext } from "react"
import { AppSideBarContext } from "../../../core/context/side-bar/side-bar-context"
import CancelButton from "../CancelButton/CancelButton"
import { SubmitButton } from "../SubmitButton/SubmitButton"
import { ValidateButton } from "../ValidateButton/ValidateButton"

interface IProps {
  actionLabel?: string
  cancelLabel?: string
  formId?: string
  isSubmitting?: boolean
  disableActionButton?: boolean
  toolTip?: string

  actionButton?(): void | undefined
  cancelAction?(): void
}

export function CustomBottomNavigation({
  actionLabel = "",
  actionButton,
  cancelAction,
  cancelLabel,
  formId,
  isSubmitting,
  disableActionButton,
  toolTip = "",
}: Readonly<IProps>): React.JSX.Element {
  const theme = useTheme()

  const { drawerWidth } = useContext(AppSideBarContext)

  return (
    <BottomNavigation
      sx={{
        position: "fixed",
        bottom: 20,
        transition: theme.transitions.create("left", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        left: drawerWidth + 20,
        right: 20,
        pr: 2,
        borderRadius: 3,
        height: "8vh",
        gap: 2,
        display: "flex",
        justifyContent: "flex-end",
        alignContent: "center",
        alignItems: "center",
        boxShadow: 2,
      }}>
      {cancelAction && <CancelButton onClick={cancelAction} label={cancelLabel} />}

      <Tooltip title={toolTip}>
        {/*Use a box so the tooltip is displayed even when the button is disabled*/}
        <Box>
          {actionButton && (
            <ValidateButton
              onClick={actionButton}
              label={actionLabel}
              isSubmitting={isSubmitting}
              isDisabled={disableActionButton}
            />
          )}
          {formId && <SubmitButton formId={formId} label={actionLabel} isSubmitting={Boolean(isSubmitting)} />}
        </Box>
      </Tooltip>
    </BottomNavigation>
  )
}
