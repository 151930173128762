import { UsageEnum } from "../../../enum/usageEnum"
import { stringToDate } from "../../../services/date-service"

export interface BSVariantResult {
  id: string
  projectId: string
  variantId: string
  variantName: string
  usage: UsageEnum
  nomUsage: string
  surfaceRef: number
  icConstructionMaxMoyen2022: number
  icConstructionMaxMoyen2025: number
  icConstructionMaxMoyen2028: number
  icConstructionMaxMoyen2031: number
  miCombles: number
  miSurf: number
  miGeo: number
  miInfra: number
  miVrd: number
  miDed2022: number
  miDed2025: number
  miDed2028: number
  miDed2031: number

  carbonImpactTotalPerSurface: number
  carbonImpactTotalPerSurfacePlus20Percent: number
  carbonImpactDed: number
  carbonImpactVrd1: number
  carbonImpactFondation2: number
  carbonImpactProductionElec13: number
  threshold2022: number
  threshold2025: number
  threshold2028: number
  threshold2031: number

  icChantierEnergie: number
  icChantierEau: number
  icChantierTerre: number
  icChantierTotal: number

  icChantierEnergieSref: number
  icChantierEauSref: number
  icChantierTerreSref: number
  icChantierTotalSref: number

  lastModifiedDate: Date | undefined
}

export function bsVariantResultFromDto(data: any): BSVariantResult {
  const bsVariantResult = data as BSVariantResult
  bsVariantResult.lastModifiedDate = stringToDate(data.lastModifiedDate)
  return bsVariantResult
}
