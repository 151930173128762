import React, { useCallback, useContext, useMemo, useState } from "react"
import { BSMaterialResult } from "../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { enumToArray } from "../../../core/services/helper-service"
import { BSMaterialResultStringField, initMap, updateMap } from "../ChartUtils"
import { LoadingCursorContext } from "../../GlobalLoadingStyle/LoadingCursorContext"

export enum BSFilterType {
  LOT,
  ITEM,
  CODE_OCCURRENCE,
  PRODUCT,
  MATERIAL,
}

function setElementToTrue(
  prev: Map<string | undefined, boolean>,
  keyToSet: string | undefined
): Map<string | undefined, boolean> {
  const updatedMap = new Map(prev)
  for (const key of updatedMap.keys()) {
    updatedMap.delete(key)
  }

  updatedMap.set(keyToSet, true)

  return updatedMap
}

function applyFilter(
  filterValues: Map<string | undefined, boolean>,
  fieldName: BSMaterialResultStringField,
  bsMaterialResultList: BSMaterialResult[]
): BSMaterialResult[] {
  return [...bsMaterialResultList].filter((bsMaterialResult) => !!filterValues.get(bsMaterialResult[fieldName]))
}

export const BSFilterContext = React.createContext<BSFilterStore>({} as BSFilterStore)

interface IProps {
  children?: React.ReactNode
  allBSMaterialResult: BSMaterialResult[]
}

export function BSFilterContextProvider({ children, allBSMaterialResult }: Readonly<IProps>): React.JSX.Element {
  const { execWithLoaderCursor } = useContext(LoadingCursorContext)
  const initialLotFilter = useMemo(() => initMap(allBSMaterialResult, "lot"), [allBSMaterialResult])
  const initialItemFilter = useMemo(() => initMap(allBSMaterialResult, "itemType"), [allBSMaterialResult])
  const initialCodeOccurrenceFilter = useMemo(() => initMap(allBSMaterialResult, "codeOccurrence"), [allBSMaterialResult])
  const initialProductFilter = useMemo(() => initMap(allBSMaterialResult, "ficheId"), [allBSMaterialResult])

  const [lotFilter, setLotFilter] = useState<Map<string | undefined, boolean>>(initialLotFilter)
  const [itemFilter, setItemFilter] = useState<Map<string | undefined, boolean>>(initialItemFilter)
  const [codeOccurrenceFilter, setCodeOccurrenceFilter] =
    useState<Map<string | undefined, boolean>>(initialCodeOccurrenceFilter)
  const [productFilter, setProductFilter] = useState<Map<string | undefined, boolean>>(initialProductFilter)

  const getFilter: (filterType: BSFilterType) => Map<string | undefined, boolean> = useCallback(
    (filterType) => {
      switch (filterType) {
        case BSFilterType.LOT:
          return lotFilter
        case BSFilterType.ITEM:
          return itemFilter
        case BSFilterType.CODE_OCCURRENCE:
          return codeOccurrenceFilter
        case BSFilterType.PRODUCT:
          return productFilter
        default:
          console.error("error getFilter")
          return new Map()
      }
    },
    [codeOccurrenceFilter, itemFilter, lotFilter, productFilter]
  )

  const toggleInFilter: (key: string | undefined, filterType: BSFilterType) => void = useCallback(
    (key, filterType) => {
      execWithLoaderCursor(() => {
        switch (filterType) {
          case BSFilterType.LOT:
            setLotFilter((prev) => updateMap(prev, key))
            break
          case BSFilterType.ITEM:
            setItemFilter((prev) => updateMap(prev, key))
            break
          case BSFilterType.CODE_OCCURRENCE:
            setCodeOccurrenceFilter((prev) => updateMap(prev, key))
            break
          case BSFilterType.PRODUCT:
            setProductFilter((prev) => updateMap(prev, key))
            break
          default:
            console.error("error")
        }
      })
    },
    [execWithLoaderCursor]
  )

  const filterOutOthersElements: (key: string | undefined, filterType: BSFilterType) => void = useCallback(
    (key, filterType) => {
      switch (filterType) {
        case BSFilterType.LOT:
          setLotFilter((prev) => setElementToTrue(prev, key))
          break
        case BSFilterType.ITEM:
          setItemFilter((prev) => setElementToTrue(prev, key))
          break
        case BSFilterType.CODE_OCCURRENCE:
          setCodeOccurrenceFilter((prev) => setElementToTrue(prev, key))
          break
        case BSFilterType.PRODUCT:
          setProductFilter((prev) => setElementToTrue(prev, key))
          break
        default:
          console.error("error")
      }
    },
    []
  )

  const resetFilter: (filterType: BSFilterType) => void = useCallback(
    (filterType) => {
      switch (filterType) {
        case BSFilterType.LOT:
          setLotFilter(initialLotFilter)
          break
        case BSFilterType.ITEM:
          setItemFilter(initialItemFilter)
          break
        case BSFilterType.CODE_OCCURRENCE:
          setCodeOccurrenceFilter(initialCodeOccurrenceFilter)
          break
        case BSFilterType.PRODUCT:
          setProductFilter(initialProductFilter)
          break
        default:
          console.error("resetFilter error")
      }
    },
    [initialCodeOccurrenceFilter, initialItemFilter, initialLotFilter, initialProductFilter]
  )

  const resetAllFilters: () => void = useCallback(() => {
    enumToArray(BSFilterType).forEach((filterType) => {
      resetFilter(filterType)
    })
  }, [resetFilter])

  const emptyFilter: (filterType: BSFilterType) => void = useCallback((filterType) => {
    switch (filterType) {
      case BSFilterType.LOT:
        setLotFilter(new Map())
        break
      case BSFilterType.ITEM:
        setItemFilter(new Map())
        break
      case BSFilterType.CODE_OCCURRENCE:
        setCodeOccurrenceFilter(new Map())
        break
      case BSFilterType.PRODUCT:
        setProductFilter(new Map())
        break
      default:
        console.error("emptyFilter error")
    }
  }, [])

  const isNotFilteredOut: (key: string | undefined, filterType: BSFilterType) => boolean = useCallback(
    (key, filterType): boolean => {
      switch (filterType) {
        case BSFilterType.LOT:
          return !!lotFilter.get(key)
        case BSFilterType.ITEM:
          return !!itemFilter.get(key)
        case BSFilterType.CODE_OCCURRENCE:
          return !!codeOccurrenceFilter.get(key)
        case BSFilterType.PRODUCT:
          return !!productFilter.get(key)
        default:
          return false
      }
    },
    [codeOccurrenceFilter, itemFilter, lotFilter, productFilter]
  )

  const getSelectedValues: (filterType: BSFilterType) => (string | undefined)[] = useCallback(
    (filterType) => {
      switch (filterType) {
        case BSFilterType.LOT:
          return Array.from(lotFilter.keys())
        case BSFilterType.ITEM:
          return Array.from(itemFilter.keys())
        case BSFilterType.CODE_OCCURRENCE:
          return Array.from(codeOccurrenceFilter.keys())
        case BSFilterType.PRODUCT:
          return Array.from(productFilter.keys())
        default:
          return []
      }
    },
    [codeOccurrenceFilter, itemFilter, lotFilter, productFilter]
  )

  /**
   * undefined is currently useful for codeOccurrence, for option "Pas de code ACV"
   */
  const isFilterAllChecked: (filterType: BSFilterType, values: (string | undefined)[]) => boolean = useCallback(
    (filterType, values) => {
      for (const value of values) {
        if (value !== "ALL" && !isNotFilteredOut(value, filterType)) {
          return false
        }
      }
      return true
    },
    [isNotFilteredOut]
  )

  const applyLotFilter: (_bsMaterialResultList: BSMaterialResult[]) => BSMaterialResult[] = useCallback(
    (_bsMaterialResultList) => applyFilter(lotFilter, "lot", _bsMaterialResultList),
    [lotFilter]
  )

  const applyItemFilter: (_bsMaterialResultList: BSMaterialResult[]) => BSMaterialResult[] = useCallback(
    (_bsMaterialResultList) => applyFilter(itemFilter, "itemType", _bsMaterialResultList),
    [itemFilter]
  )

  const applyCodeOccurrenceFilter: (_bsMaterialResultList: BSMaterialResult[]) => BSMaterialResult[] = useCallback(
    (_bsMaterialResultList) => applyFilter(codeOccurrenceFilter, "codeOccurrence", _bsMaterialResultList),
    [codeOccurrenceFilter]
  )

  const applyProductFilter: (_bsMaterialResultList: BSMaterialResult[]) => BSMaterialResult[] = useCallback(
    (_bsMaterialResultList) => applyFilter(productFilter, "ficheId", _bsMaterialResultList),
    [productFilter]
  )

  const select: (key: string, filterType: BSFilterType, isAllSelected: boolean) => void = useCallback(
    (key, filterType, isAllSelected) => {
      execWithLoaderCursor(() => {
        const selected = isNotFilteredOut(key, filterType)
        const filter = getFilter(filterType)
        if (selected && filter.size === 1) {
          resetFilter(filterType)
        } else if (isAllSelected) {
          filterOutOthersElements(key, filterType)
        } else {
          toggleInFilter(key, filterType)
        }
      })
    },
    [execWithLoaderCursor, filterOutOthersElements, getFilter, isNotFilteredOut, resetFilter, toggleInFilter]
  )

  const bsFilterStore = useMemo(
    () => ({
      lotFilter,
      itemFilter,
      codeOccurrenceFilter,
      productFilter,
      toggleInFilter,
      resetFilter,
      resetAllFilters,
      emptyFilter,
      applyLotFilter,
      applyItemFilter,
      applyCodeOccurrenceFilter,
      applyProductFilter,
      isNotFilteredOut,
      getSelectedValues,
      filterOutOthersElements,
      isFilterAllChecked,
      select,
    }),
    [
      lotFilter,
      itemFilter,
      codeOccurrenceFilter,
      productFilter,
      toggleInFilter,
      resetFilter,
      resetAllFilters,
      emptyFilter,
      applyLotFilter,
      applyItemFilter,
      applyCodeOccurrenceFilter,
      applyProductFilter,
      isNotFilteredOut,
      getSelectedValues,
      filterOutOthersElements,
      isFilterAllChecked,
      select,
    ]
  )
  return <BSFilterContext.Provider value={bsFilterStore}>{children}</BSFilterContext.Provider>
}

export interface BSFilterStore {
  lotFilter: Map<string | undefined, boolean>
  itemFilter: Map<string | undefined, boolean>
  codeOccurrenceFilter: Map<string | undefined, boolean>
  productFilter: Map<string | undefined, boolean>
  toggleInFilter(key: string | undefined, filterType: BSFilterType): void
  resetFilter(filterType: BSFilterType): void
  resetAllFilters(): void
  emptyFilter(filterType: BSFilterType): void
  applyLotFilter(_bsMaterialResultList: BSMaterialResult[]): BSMaterialResult[]
  applyItemFilter(_bsMaterialResultList: BSMaterialResult[]): BSMaterialResult[]
  applyCodeOccurrenceFilter(_bsMaterialResultList: BSMaterialResult[]): BSMaterialResult[]
  applyProductFilter(_bsMaterialResultList: BSMaterialResult[]): BSMaterialResult[]
  isNotFilteredOut(key: string | undefined, filterType: BSFilterType): boolean
  getSelectedValues(filterType: BSFilterType): (string | undefined)[]
  filterOutOthersElements(key: string | undefined, filterType: BSFilterType): void
  isFilterAllChecked(filterType: BSFilterType, values: (string | undefined)[]): boolean
  select(key: string, filterType: BSFilterType, isAllSelected: boolean): void
}
