import { appConstants } from "../../appConstants"
import { WithId } from "../../type/WithId"

export type ValidationRule<T> = {
  fieldName: keyof T
  errorMessage: string
  rule(form: T): boolean
}

export function required<T>(fieldName: keyof T): ValidationRule<T> {
  return {
    fieldName,
    rule: (form: T) => form[fieldName] !== undefined && form[fieldName] !== null && form[fieldName] !== "",
    errorMessage: "Ce champ est obligatoire",
  }
}

export function requiredSelect<T>(fieldName: keyof T): ValidationRule<T> {
  return {
    fieldName,
    rule: (form) => {
      const value: WithId = form[fieldName] as WithId
      return !!value?.id && value.id !== "empty"
    },
    errorMessage: "Ce champ est obligatoire",
  }
}

export function nonZero<T>(fieldName: keyof T): ValidationRule<T> {
  return {
    fieldName,
    rule: (form: T) => form[fieldName] !== 0,
    errorMessage: "Ce champ ne doit pas être égal à zéro",
  }
}

export function email<T>(fieldName: keyof T): ValidationRule<T> {
  return {
    fieldName,
    rule: (form: T) => {
      const value = form[fieldName]
      const isString = !!value && typeof value === "string"
      if (isString) {
        return appConstants.regex.email.test(value)
      } else {
        return false
      }
    },
    errorMessage: "Le format de l'email est incorrect",
  }
}

export function phone<T>(fieldName: keyof T, isRequired: boolean): ValidationRule<T> {
  return {
    fieldName,
    rule: (form: T) => {
      const value = form[fieldName]
      if (!isRequired && !value) {
        // If not required, empty field is ok
        return true
      } else {
        const isString = !!value && typeof value === "string"
        if (isString) {
          const phoneNumber = value
          if (phoneNumber.startsWith("+") && (phoneNumber.length === 12 || phoneNumber.length === 13)) {
            return appConstants.regex.onlyDigits.test(phoneNumber.substring(1))
          } else {
            return phoneNumber.length === 10 && appConstants.regex.onlyDigits.test(phoneNumber)
          }
        } else {
          return false
        }
      }
    },
    errorMessage: "Le format du numéro de téléphone est incorrect",
  }
}

export function zipCode<T>(fieldName: keyof T): ValidationRule<T> {
  return {
    fieldName,
    rule: (form: any) => appConstants.regex.zipCode.test(form[fieldName]),
    errorMessage: "Ce champ est obligatoire",
  }
}
