import React, { useCallback, useContext } from "react"
import { BSMaterialResult } from "../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSFilterContext, BSFilterType } from "../BSFilterContext"
import { BaseFilterSelect } from "./BaseFilterSelect"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
}

export function FilterLotSelect({ allBSMaterialResult }: Readonly<IProps>): React.JSX.Element {
  const { applyItemFilter, applyCodeOccurrenceFilter, applyProductFilter } = useContext(BSFilterContext)

  const applyFilters = useCallback(
    (_bsMaterialList: BSMaterialResult[]): BSMaterialResult[] =>
      applyItemFilter(applyCodeOccurrenceFilter(applyProductFilter(_bsMaterialList))),
    [applyCodeOccurrenceFilter, applyItemFilter, applyProductFilter]
  )

  return (
    <BaseFilterSelect
      allBSMaterialResult={allBSMaterialResult}
      filterType={BSFilterType.LOT}
      applyFilters={applyFilters}
      label="Chercher un lot"
      emptyLabel=""
      valueFieldName="lot"
      labelFieldName="lotLabel"
    />
  )
}
