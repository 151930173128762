import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import React, { FormEvent, useContext, useState } from "react"
import CancelButton from "../../../../../../components/buttons/CancelButton/CancelButton"
import { SubmitButton } from "../../../../../../components/buttons/SubmitButton/SubmitButton"
import { SearchField } from "../../../../../../components/inputs/search-field"
import { RseeProjectContext } from "../../../../../../core/context/beem-pilot/rsee/rsee-project-context"
import { RseeTeamProjectContext } from "../../../../../../core/context/beem-pilot/rsee/RseeTeamProjectContext"
import { Collaborator } from "../../../../../../core/dto/user/collaborator"
import { getFullNameLabel } from "../../../../../../core/services/helper-service"
import { Transition } from "../../../../../../components/dialog/BaseDialog"

interface IProps {
  open: boolean

  handleClose(): void
}

export function RseeInviter({ open, handleClose }: Readonly<IProps>): React.JSX.Element {
  const { collaboratorList, addRseeTeamMember } = useContext(RseeTeamProjectContext)
  const { rseeProject } = useContext(RseeProjectContext)
  const [selectedCollaborator, setSelectedCollaborator] = useState<Collaborator | null>(null)

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    addRseeTeamMember(selectedCollaborator).finally(() => {
      setSelectedCollaborator(null)
      handleClose()
    })
  }

  function onSelectCollaborator(event: any, newValue: Collaborator | null): void {
    setSelectedCollaborator(newValue)
  }

  function getOptionLabel(option: Collaborator): string {
    return option ? getFullNameLabel(option.givenName, option.familyName) : ""
  }

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose} TransitionComponent={Transition}>
      <Box component="form" id="sendRseeInvitation" onSubmit={handleSubmit} sx={{ m: 1 }}>
        <DialogTitle>
          <Typography variant="subtitle1" fontSize={24} fontWeight={600}>
            Inviter un collaborateur
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" rowGap={2}>
            <Typography variant="body1">
              Vous souhaitez inviter un collaborateur de votre organisation à modifier le projet{" "}
              <span style={{ fontWeight: "bold" }}>{rseeProject?.projectName}</span>. Sélectionnez son nom puis envoyez
              l’invitation. Votre collaborateur recevra une invitation par e-mail ainsi qu’une notification dans la
              plateforme Time To Beem.
            </Typography>

            <Typography variant="subtitle1" fontSize={16} fontWeight={600}>
              Depuis votre organisation
            </Typography>

            <SearchField
              label="Sélectionner un collaborateur"
              value={selectedCollaborator}
              options={collaboratorList}
              onChange={onSelectCollaborator}
              getOptionLabel={getOptionLabel}
              disablePortal={false}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={handleClose} label="Annuler" />
          <SubmitButton label="Envoyer une invitation" formId="sendRseeInvitation" isSubmitting={false} />
        </DialogActions>
      </Box>
    </Dialog>
  )
}
