import { Grid } from "@mui/material"
import React, { useState } from "react"
import { IcConstructionTopLeftPanel } from "./panels/IcConstructionTopLeftPanel"
import { IcConstructionTopRightPanel } from "./panels/IcConstructionTopRightPanel"
import { ButtonDisplayImpactByItemChart } from "../ButtonDisplayImpactByItemChart"
import { IcConstructionBottomLeftPanel } from "./panels/IcConstructionBottomLeftPanel"
import { BSMaterialResult } from "../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSVariantResult } from "../../../../../../core/dto/beem-shot/BSVariantResult/BSVariantResult"
import { BSFilterContextProvider } from "../../../../../../components/charts/filters/BSFilterContext"
import { BSProjectResult } from "../../../../../../core/dto/beem-shot/BSProjectResult/BSProjectResult"
import { IcConstructionBottomRightPanel } from "./panels/IcConstructionBottomRightPanel"
import { BSDashboardSelectionContextProvider } from "../../../../../../components/charts/selection/BSDashboardSelectionContext"

interface IProps {
  bsProjectResult: BSProjectResult
  bsVariantResult: BSVariantResult
  allBSMaterialResult: BSMaterialResult[]
  dashboardRef: React.MutableRefObject<HTMLDivElement | null>
}

export function BSIcConstructionTab({
  bsProjectResult,
  bsVariantResult,
  allBSMaterialResult,
  dashboardRef,
}: Readonly<IProps>): React.JSX.Element {
  const [displayImpactByItems, setDisplayImpactByItems] = useState<boolean>(true)

  return (
    <BSFilterContextProvider allBSMaterialResult={allBSMaterialResult}>
      <BSDashboardSelectionContextProvider>
        <Grid container ref={dashboardRef} columnSpacing={2} rowGap={2} sx={{ pt: 2 }} alignItems="stretch">
          {/* Top panel */}
          <Grid item xs={7}>
            <IcConstructionTopLeftPanel
              bsProjectResult={bsProjectResult}
              bsVariantResult={bsVariantResult}
              allBSMaterialResult={allBSMaterialResult}
            />
          </Grid>
          <Grid item xs={5}>
            <IcConstructionTopRightPanel allBSMaterialResult={allBSMaterialResult} />
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
            <ButtonDisplayImpactByItemChart
              displayImpactByItems={displayImpactByItems}
              setDisplayImpactByItems={setDisplayImpactByItems}
            />
          </Grid>
          {displayImpactByItems && (
            <Grid item xs={3}>
              <IcConstructionBottomLeftPanel allBSMaterialResult={allBSMaterialResult} />
            </Grid>
          )}
          <Grid item xs={displayImpactByItems ? 9 : 12}>
            <IcConstructionBottomRightPanel allBSMaterialResult={allBSMaterialResult} />
          </Grid>
        </Grid>
      </BSDashboardSelectionContextProvider>
    </BSFilterContextProvider>
  )
}
