import React, { useCallback, useContext } from "react"
import { BSMaterialResult } from "../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSFilterContext, BSFilterType } from "../BSFilterContext"
import { BaseFilterSelect } from "./BaseFilterSelect"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
}

export function FilterItemSelect({ allBSMaterialResult }: Readonly<IProps>): React.JSX.Element {
  const { applyLotFilter, applyCodeOccurrenceFilter, applyProductFilter } = useContext(BSFilterContext)

  const applyFilters: (_bsMaterialList: BSMaterialResult[]) => BSMaterialResult[] = useCallback(
    (_bsMaterialList) => applyLotFilter(applyCodeOccurrenceFilter(applyProductFilter(_bsMaterialList))),
    [applyCodeOccurrenceFilter, applyLotFilter, applyProductFilter]
  )

  return (
    <BaseFilterSelect
      allBSMaterialResult={allBSMaterialResult}
      filterType={BSFilterType.ITEM}
      applyFilters={applyFilters}
      label="Chercher un item"
      emptyLabel=""
      valueFieldName="itemType"
      labelFieldName="bsItemLabel"
    />
  )
}
