import { Box, Grid, Tooltip, useTheme } from "@mui/material"
import React, { ChangeEvent, useCallback, useContext, useMemo } from "react"
import { BSFormDialog } from "../../../../../../../components/dialog/BSFormDialog"
import { CheckboxInput } from "../../../../../../../components/inputs/checkbox-input/CheckboxInput"
import { BSNumberInput } from "../../../../../../../components/inputs/number-input/BSNumberInput"
import { SelectIniesRecordInput } from "../../../../../../../components/inputs/select-inies-input/SelectIniesRecordInput"
import { SelectObjectInput, SelectOption } from "../../../../../../../components/inputs/select-input/SelectObjectInput"
import { ErrorContext } from "../../../../../../../components/layout/error-snackbar"
import { BSMaterialResultContext } from "../../../../../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext"
import { LotContext } from "../../../../../../../core/context/lot-context"
import { BSCustomMaterialCreationDto } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSCustomMaterialCreationDto"
import { BSMaterialResultFormDto } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResultFormDto"
import { BSMaterialResultUpdateDto } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResultUpdateDto"
import Lot from "../../../../../../../core/dto/lots/lot"
import SousLot from "../../../../../../../core/dto/lots/sous-lot"
import { UnitEnum } from "../../../../../../../core/enum/unitEnum"
import { useForm } from "../../../../../../../core/hooks/form/use-form"
import { nonZero, requiredSelect } from "../../../../../../../core/hooks/form/validation"
import { eventFromValue, isNumber, trim } from "../../../../../../../core/services/helper-service"
import { unitService } from "../../../../../../../core/services/unit-service"
import { FicheValue, SelectionContext } from "../../context/SelectionContext"

interface Form extends FicheValue {
  lot: Lot | undefined // it's the enum from backend
  sousLot: SousLot | undefined // it's the enum from backend
  re2020CarbonImpactPerSurface: number
}

interface IProps {
  isOpen: boolean
  handleClose(): void
}

export function BSUpdateMaterialResultDialog({ isOpen, handleClose }: Readonly<IProps>): React.JSX.Element {
  const theme = useTheme()
  const { selectedBSItem, selectedBSMaterialResult, replaceAll, replaceAllCoefficient, setReplaceAllCoefficient } =
    useContext(SelectionContext)
  const { addCustomMaterial, updateIniesRecordMaterial } = useContext(BSMaterialResultContext)
  const { lots, sousLots } = useContext(LotContext)
  const openErrorSnackbar = useContext(ErrorContext)

  const lotsOptions: SelectOption<Lot>[] = useMemo(
    () =>
      lots
        ?.filter((lot) => lot.name !== "AUTRE" && lot.name !== "PARCELLE")
        .map((lot) => ({ value: lot, label: lot.label })),
    [lots]
  )

  const dtoToForm = useCallback(
    (bsMaterialResultDto: BSMaterialResultFormDto | undefined): Form =>
      !bsMaterialResultDto
        ? {
            ficheId: undefined,
            ficheName: undefined,
            typeMaterial: undefined,
            newQuantity: 0,
            lot: undefined,
            sousLot: undefined,
            coefficient: 1,
            unit: UnitEnum.NO_UNIT,
            re2020CarbonImpactPerSurface: 0,
          }
        : {
            ficheId: bsMaterialResultDto.ficheId,
            ficheName: trim(bsMaterialResultDto.nomProduit),
            typeMaterial: bsMaterialResultDto.typeMaterial,
            lot: lots.find((lot) => lot.id === bsMaterialResultDto.lot),
            sousLot: sousLots.find((sousLot) => sousLot.id === bsMaterialResultDto.sousLot),
            coefficient: bsMaterialResultDto.coefficient,
            newQuantity: bsMaterialResultDto.formQuantity,
            unit: bsMaterialResultDto.ficheUnite,
            re2020CarbonImpactPerSurface: bsMaterialResultDto.re2020CarbonImpactPerSurface ?? "",
          },
    [lots, sousLots]
  )

  const formToCustomMaterialCreateDto = useCallback(
    (form: Form): BSCustomMaterialCreationDto => {
      if (selectedBSItem?.id && form.ficheId && form.lot && form.sousLot && form.typeMaterial) {
        return {
          bsItemId: selectedBSItem.id,
          ficheId: form.ficheId,
          typeMaterial: form.typeMaterial,
          lot: form.lot.id,
          sousLot: form.sousLot.id,
          quantity: form.newQuantity,
          originalFicheUnite: form.unit,
        }
      } else {
        throw new Error("Des données obligatoires du formulaire n'ont pas été remplies")
      }
    },
    [selectedBSItem]
  )

  const formToUpdateDto = useCallback(
    (form: Form): BSMaterialResultUpdateDto => {
      if (selectedBSMaterialResult?.id && form.ficheId && form.lot && form.sousLot && form.typeMaterial && form.unit) {
        return {
          materialResultId: selectedBSMaterialResult.id,
          ficheId: form.ficheId,
          typeMaterial: form.typeMaterial,
          lot: form.lot.id,
          sousLot: form.sousLot.id,
          quantity: form.newQuantity,
          coefficient: form.coefficient,
          unit: form.unit,
        }
      } else {
        throw new Error("MaJ: Des données obligatoires du formulaire n'ont pas été remplies")
      }
    },
    [selectedBSMaterialResult]
  )

  const submit = useCallback(
    (form: Form): Promise<any> => {
      try {
        if (selectedBSMaterialResult === undefined) {
          const bsMaterialResultCreationDto = formToCustomMaterialCreateDto(form)
          return addCustomMaterial(bsMaterialResultCreationDto).then(() => {
            handleClose()
          })
        } else {
          const bsUpdateMaterialResult = formToUpdateDto(form)
          return updateIniesRecordMaterial(bsUpdateMaterialResult).then(() => {
            handleClose()
          })
        }
      } catch (e: any) {
        openErrorSnackbar(e as Error)
        return Promise.resolve()
      }
    },
    [
      addCustomMaterial,
      formToCustomMaterialCreateDto,
      formToUpdateDto,
      handleClose,
      openErrorSnackbar,
      selectedBSMaterialResult,
      updateIniesRecordMaterial,
    ]
  )

  const { form, errors, isSubmitting, handleSubmit, handleChange, resetForm } = useForm(
    selectedBSMaterialResult,
    dtoToForm,
    [requiredSelect("lot"), requiredSelect("sousLot"), nonZero("newQuantity")],
    submit
  )

  const isOriginalUnit = useMemo(() => {
    if (selectedBSMaterialResult) {
      return form.unit === selectedBSMaterialResult.originalFicheUnite
    } else {
      return true
    }
  }, [form.unit, selectedBSMaterialResult])

  const sousLotsOptions: SelectOption<SousLot>[] = useMemo(
    () =>
      form.lot?.children
        ? form.lot?.children
            ?.filter((sousLot) => sousLot.name !== "AUTRES")
            .map((sousLot) => ({ value: sousLot, label: sousLot.label }))
        : [],
    [form]
  )

  const handleSelectSousLot = useCallback(
    (selectedValue: SousLot | undefined): void => {
      const event = eventFromValue("sousLot", selectedValue)
      handleChange(event)
    },
    [handleChange]
  )

  const handleSelectLot = useCallback(
    (selectedValue: Lot | undefined): void => {
      const event = eventFromValue("lot", selectedValue)
      handleChange(event)
      // Reset sous lot to avoid lot1 with sous lot 5 for example
      handleSelectSousLot(undefined)
    },
    [handleChange, handleSelectSousLot]
  )

  const handleSelectFiche = useCallback(
    (newFicheValue: FicheValue) => {
      handleChange(eventFromValue("ficheId", newFicheValue.ficheId))
      handleChange(eventFromValue("ficheName", newFicheValue.ficheName))
      handleChange(eventFromValue("typeMaterial", newFicheValue.typeMaterial))
      handleChange(eventFromValue("unit", newFicheValue.unit))
      handleChange(eventFromValue("newQuantity", newFicheValue.newQuantity))
      handleChange(eventFromValue("coefficient", newFicheValue.coefficient))
    },
    [handleChange]
  )

  const handleCoefficient = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const coefficient = parseFloat(event.target.value)
      handleChange(eventFromValue("coefficient", coefficient))
    },
    [handleChange]
  )

  function handleReplaceAllCoefficient(event: ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    setReplaceAllCoefficient(target.checked)
  }

  const reCalculatedQuantity = useMemo(
    () => (isNumber(form.coefficient) ? form.coefficient * form.newQuantity : form.newQuantity),
    [form.coefficient, form.newQuantity]
  )

  return (
    <BSFormDialog
      title={selectedBSMaterialResult ? "Surcharger le composant" : "Ajouter un composant"}
      open={isOpen}
      formId="add-material-form"
      formContent={
        <>
          <Grid item xs={6}>
            <SelectObjectInput
              id="lot"
              label="Lot *"
              selectedOption={form.lot}
              options={lotsOptions}
              handleChange={handleSelectLot}
              errors={errors}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectObjectInput
              id="sousLot"
              label="Sous Lot *"
              disabled={!form.lot || form.lot.id === ""}
              selectedOption={form.sousLot}
              options={sousLotsOptions}
              handleChange={handleSelectSousLot}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectIniesRecordInput label="Sélectionner une fiche" form={form} handleChange={handleSelectFiche} />
          </Grid>
          <Grid item xs={4} display="flex" alignItems="center">
            <BSNumberInput
              id="newQuantity"
              label="Quantité *"
              value={form.newQuantity}
              errors={errors}
              handleChange={handleChange}
              unit={unitService.unitEnumToLabel(
                selectedBSMaterialResult ? selectedBSMaterialResult.originalFicheUnite : form.unit
              )} // When it's creation of a custom, we need to display the form.unit
              decimalScale={1}
            />
          </Grid>
          {!isOriginalUnit && (
            <>
              <Grid item xs={4}>
                <BSNumberInput
                  id="coefficient"
                  label="Coefficient appliqué *"
                  value={form.coefficient}
                  errors={errors}
                  handleChange={handleCoefficient}
                  decimalScale={2}
                />
              </Grid>
              <Grid item xs={4}>
                <BSNumberInput
                  id="finalQuantity"
                  label="Nouvelle quantité"
                  value={reCalculatedQuantity}
                  unit={unitService.unitEnumToLabel(form.unit)}
                  decimalScale={2}
                  disabled
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                {replaceAll ? (
                  <Tooltip
                    title="Le coefficient est automatiquement appliqué à toutes les fiches lorsque vous avez fait le choix de faire un remplacement global."
                    placement="left"
                    arrow
                    color={theme.palette.primary.light}>
                    <Box>
                      <CheckboxInput
                        id="replaceAllIniesRecord"
                        label={`Remplacer le coefficient dans toutes les fiches [${form.ficheId}]`}
                        checked
                        disabled
                      />
                    </Box>
                  </Tooltip>
                ) : (
                  <Box>
                    <CheckboxInput
                      id="replaceAllIniesRecord"
                      label={`Remplacer le coefficient dans toutes les fiches [${form.ficheId}]`}
                      checked={replaceAllCoefficient}
                      handleChange={handleReplaceAllCoefficient}
                    />
                  </Box>
                )}
              </Grid>
            </>
          )}
        </>
      }
      isSubmitting={isSubmitting}
      onClose={() => {
        resetForm()
        handleClose()
      }}
      onSubmit={handleSubmit}
    />
  )
}
