import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions"
import React from "react"

type IProps = {
  title?: string
  handleClose(): void
  open: boolean
  dialogContent: React.JSX.Element
}

export const Transition = React.forwardRef(
  (props: TransitionProps & { children: React.ReactElement }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
  )
)

Transition.displayName = "Transition"

export function BaseDialog({ title = "", handleClose, open, dialogContent }: IProps): React.JSX.Element {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Retour
        </Button>
      </DialogActions>
    </Dialog>
  )
}
