import { Typography } from "@mui/material"
import React from "react"
import { displayNumber } from "../../../core/services/helper-service"

interface IProps {
  carbonImpact: number
  unit: React.ReactNode
}

export function ImpactKPI({ carbonImpact, unit }: Readonly<IProps>): React.JSX.Element {
  return (
    <>
      <Typography variant="h2" sx={{ fontWeight: "bold" }}>
        {displayNumber(carbonImpact)}
      </Typography>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: 11 }}>
        {unit}
      </Typography>
    </>
  )
}
