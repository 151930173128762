import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined"
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined"
import { Box, Grid, IconButton, Typography } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { pagesUrl } from "../../../../../core/appConstants"
import { BSBimModelListContext } from "../../../../../core/context/beem-shot/BSBimModel/BSBimModelContextList"
import { BSInputContext } from "../../../../../core/context/beem-shot/BSInput/BSInputContext"
import { BSBimModel } from "../../../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { resolveUrl } from "../../../../../core/services/http-service"
import { BSBimModelInfo } from "./BSBimModelInfo"
import { BSBimModelInfoDialog } from "./BSBimModelInfoDialog"
import { BSDeleteBimModelButton } from "../../../components/BSDeleteBimModelButton/BSDeleteBimModelButton"
import { BSBimModelContext } from "../../../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { formatToFrenchWithHours } from "../../../../../core/services/date-service"
import { customPalette } from "../../../../../../theme"

interface IProps {
  selectedBSBimModel: BSBimModel | undefined

  setSelectedBSBimModel(bsBimModel: BSBimModel | undefined): void
}

export function BSBimModelSelectList({ selectedBSBimModel, setSelectedBSBimModel }: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()
  const { bsProjectId, bsVariantId } = useParams()

  const { bsBimModelListByProject } = useContext(BSBimModelListContext)
  const { setStateBSBimModel } = useContext(BSBimModelContext)
  const { bsInput } = useContext(BSInputContext)

  const [openBsBimModelInfo, setOpenBsBimModelInfo] = useState<boolean>(false)

  useEffect(() => {
    const affectedBsBimModel = bsBimModelListByProject.find((bimModel) => bimModel.id === bsInput?.bsBimModelId)
    setSelectedBSBimModel(affectedBsBimModel)
  }, [bsBimModelListByProject, bsInput?.bsBimModelId, setSelectedBSBimModel])

  const navigateToImportBimModel = useCallback(() => {
    setStateBSBimModel(undefined)
    navigate(
      resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_IMPORT_PAGE, [bsProjectId, "new"], {
        variantId: bsVariantId,
      })
    )
  }, [bsProjectId, bsVariantId, navigate, setStateBSBimModel])

  return (
    <>
      <Grid container justifyContent="center" p={1} rowGap={1}>
        {bsBimModelListByProject.length === 0 ? (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography color="textSecondary">Aucune maquette n'a été importée pour l'instant</Typography>
          </Box>
        ) : (
          bsBimModelListByProject.map((actualBimModel) => (
            <Grid container key={actualBimModel.id}>
              <Grid
                item
                xs={12}
                container
                borderRadius={3}
                boxShadow={5}
                onClick={() => setSelectedBSBimModel(actualBimModel)}
                py={1}
                sx={{
                  ":hover": { cursor: "pointer", boxShadow: 10 },
                  borderRadius: "10px !important", // Parler du problème à Wanda
                  backgroundColor: "#ffffff",
                  boxShadow: "unset",
                  border: () => {
                    if (selectedBSBimModel?.id === actualBimModel.id) {
                      return "3px solid #08CA8F"
                    } else {
                      return "3px solid #ECF3F3"
                    }
                  },
                }}>
                <Grid
                  item
                  xs={8}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  alignContent="center"
                  py={1}>
                  <Typography
                    pl={2}
                    variant="subtitle2"
                    fontSize={12}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}>
                    {actualBimModel.fileName}
                  </Typography>
                </Grid>

                {selectedBSBimModel?.id === actualBimModel.id && (
                  <Grid
                    item
                    xs={4}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    alignContent="center"
                    px={1}>
                    <BSDeleteBimModelButton bsBimModelToDelete={actualBimModel} />
                    <IconButton
                      onClick={() =>
                        navigate(
                          resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_IMPORT_PAGE, [bsProjectId, actualBimModel.id], {
                            variantId: bsVariantId,
                          })
                        )
                      }>
                      <CreateOutlinedIcon />
                    </IconButton>
                  </Grid>
                )}

                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", pl: 2 }}>
                  <Typography variant="subtitle1" fontSize={11} fontWeight={400} sx={{ color: customPalette.subtitleGray }}>
                    {`Dernière mise à jour le ${formatToFrenchWithHours(actualBimModel.lastModifiedDate)}`}
                  </Typography>
                </Grid>

                {selectedBSBimModel?.id === actualBimModel.id && (
                  <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" p={2}>
                    <BSBimModelInfo selectedBsBimModel={actualBimModel} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))
        )}
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        sx={{ cursor: "pointer", ":hover": { color: "#08CA8F" } }}
        onClick={navigateToImportBimModel}>
        <IconButton aria-label="edit" color="inherit">
          <AddCircleOutlineOutlinedIcon fontSize="medium" />
        </IconButton>
        Ajouter une nouvelle maquette beem
      </Box>
      <BSBimModelInfoDialog
        selectedBsBimModel={selectedBSBimModel}
        openBsBimModelInfo={openBsBimModelInfo}
        setOpenBsBimModelInfo={setOpenBsBimModelInfo}
      />
    </>
  )
}
