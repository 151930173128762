import { Typography } from "@mui/material"
import React, { useContext, useMemo } from "react"
import { Box } from "@mui/system"
import { BSMaterialResult } from "../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { customPalette } from "../../../../theme"
import { BSFilterContext } from "../filters/BSFilterContext"
import { BSDashboardSelectionContext } from "../selection/BSDashboardSelectionContext"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
}

export function FdesNumber({ allBSMaterialResult }: Readonly<IProps>): React.JSX.Element {
  const { applyLotFilter, applyItemFilter, applyCodeOccurrenceFilter, applyProductFilter } = useContext(BSFilterContext)
  const { isSelected } = useContext(BSDashboardSelectionContext)

  const fdesNbr: number = useMemo(() => {
    const filteredList = applyLotFilter(
      applyItemFilter(applyCodeOccurrenceFilter(applyProductFilter(allBSMaterialResult)))
    ).filter((bsMaterialResult) => isSelected(bsMaterialResult))
    return new Set(filteredList.map((bsMaterialResult) => bsMaterialResult.ficheId)).size
  }, [allBSMaterialResult, applyCodeOccurrenceFilter, applyItemFilter, applyLotFilter, applyProductFilter, isSelected])

  return (
    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Typography textAlign="center">Nombre de FDES différentes</Typography>
      <Box component="span" sx={{ color: customPalette.dashboardBlue, fontWeight: "bold" }}>
        {fdesNbr}
      </Box>
    </Box>
  )
}
