import InfoIcon from "@mui/icons-material/Info"
import { Box, Grid, Icon, Typography } from "@mui/material"
import React from "react"

type IProps = {
  title: string
  subTitle?: string
}

export function BSBimModelHeader({ title, subTitle }: Readonly<IProps>): React.JSX.Element {
  return (
    <Grid container>
      <Grid container item xs={12} py={2}>
        <Grid item xs={1} />

        <Grid item xs={10} display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h4" fontWeight={600} sx={{ color: "#40548D" }}>
            {title}
          </Typography>
        </Grid>

        <Grid item xs={1} />
      </Grid>
      {subTitle && (
        <Box display="flex" justifyContent="center" gap={1}>
          <Icon>
            <InfoIcon />
          </Icon>
          <Typography variant="body2">{subTitle}</Typography>
        </Box>
      )}
    </Grid>
  )
}
