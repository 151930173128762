import { Box, IconButton, Tooltip } from "@mui/material"
import React, { useCallback, useContext, useMemo, useState } from "react"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
import { BSBimModel } from "../../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { DeleteBSBimModelDialog } from "../../../../components/card/beem-shot/DeleteBSBimModelDialog"
import { DeleteBSBimModelWithMoreVariantDialog } from "../../../../components/card/beem-shot/DeleteBSBimModelWithMoreVariantDialog"
import { BSBimModelContext } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelContext"

interface IProps {
  bsBimModelToDelete: BSBimModel
  onDelete?(): void
}

export function BSDeleteBimModelButton({ bsBimModelToDelete, onDelete }: Readonly<IProps>): React.JSX.Element {
  const { setStateBSBimModel } = useContext(BSBimModelContext)

  const [isOpenDeleteBSBimModel, setIsOpenDeleteBSBimModel] = useState<boolean>(false)
  const [isOpenDeleteBSBimModelWithMoreVariant, setIsOpenDeleteBSBimModelWithMoreVariant] = useState<boolean>(false)

  const isAffectedOnVariant = useMemo(() => bsBimModelToDelete?.variantCount !== 0, [bsBimModelToDelete?.variantCount])

  const handleOpenDeleteBSBimModelDialog = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()

      setStateBSBimModel(bsBimModelToDelete)

      if (isAffectedOnVariant) {
        setIsOpenDeleteBSBimModelWithMoreVariant(true)
      } else {
        setIsOpenDeleteBSBimModel(true)
      }
    },
    [isAffectedOnVariant, bsBimModelToDelete, setStateBSBimModel]
  )
  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <DeleteBSBimModelDialog
        bsBimModel={bsBimModelToDelete}
        isOpenDeleteBSBimModel={isOpenDeleteBSBimModel}
        setIsOpenDeleteBSBimModel={setIsOpenDeleteBSBimModel}
        onDelete={onDelete}
      />
      <DeleteBSBimModelWithMoreVariantDialog
        bsBimModel={bsBimModelToDelete}
        openDeleteBSBimModelWithMoreVariant={isOpenDeleteBSBimModelWithMoreVariant}
        setOpenDeleteBSBimModelWithMoreVariant={setIsOpenDeleteBSBimModelWithMoreVariant}
        onDelete={onDelete}
      />
      <Tooltip title={isAffectedOnVariant ? "Impossible de supprimer une maquette affectée sur une variante" : ""}>
        <Box>
          <IconButton onClick={handleOpenDeleteBSBimModelDialog} disabled={isAffectedOnVariant}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  )
}
