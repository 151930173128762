import { Box, createTheme, CssBaseline, ThemeProvider, useTheme } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { CustomBottomNavigation } from "../../../../../components/buttons/navigate-button/CustomBottomNavigation"
import { BSSubmitDialog } from "../../../../../components/dialog/BSSubmitDialog"
import { SubsetInfo } from "../../../../../components/ifc-displayer/models/SubsetInfo"
import { TabEnum } from "../../../../../components/tabs/tabs"
import { pagesUrl } from "../../../../../core/appConstants"
import { AppNavBarContext, NavbarTypeEnum } from "../../../../../core/context/nav-bar/AppNavBarContext"
import { codeStateEnum } from "../../../../../core/enum/codeStateEnum"
import { resolveUrl } from "../../../../../core/services/http-service"
import { BSBimModelAnalyzer } from "../../../components/BSBimModelAnalyzer"
import { BimModelFormContext } from "../../context/BimModelFormContext"
import { ErrorContext } from "../../../../../components/layout/error-snackbar"
import { BSBimModelContext } from "../../../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { useViewerAnalyze } from "../../../../../core/hooks/viewer/useViewerAnalyze"
import { CodeExtrait } from "../../../../../core/dto/code-extrait/code-extrait"
import { CodeExtraitDisplay } from "../../../../../core/dto/code-extrait/CodeExtraitDisplay"

interface IProps {
  goToUploadStep(): void
}

export function BSBimModelControl({ goToUploadStep }: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { bsProjectId } = useParams()
  const bsVariantId = useMemo(() => searchParams.get("variantId"), [searchParams])

  const { bsBimModel } = useContext(BSBimModelContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader, setActionOnBackButton } = useContext(AppNavBarContext)
  const { bsModelFileSelected, resetFile, handleSubmitModelAndFile, isSubmitting } = useContext(BimModelFormContext)
  const openErrorSnackbar = useContext(ErrorContext)

  const [isCancelProjectOpen, setIsCancelProjectOpen] = useState<boolean>(false)
  const [isModelLoading, setIsModelLoading] = useState<boolean>(false)

  const [codesExtraits, setCodesExtraits] = useState<CodeExtrait[]>([])
  const [selectedCodeExtrait, setSelectedCodeExtrait] = useState<CodeExtraitDisplay>()

  const { viewer, progress, setProgress, analyzeModel, handleClickAcv } = useViewerAnalyze(
    codesExtraits,
    setCodesExtraits,
    selectedCodeExtrait,
    setSelectedCodeExtrait
  )

  useEffect(() => setSelectedCodeExtrait(undefined), [setSelectedCodeExtrait])

  const actionLabel: string = useMemo(() => (bsBimModel ? "la mise à jour" : "l'ajout"), [bsBimModel])

  const theme = useTheme()
  const modifiedTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      background: {
        ...theme.palette.background,
        default: "#ffffff",
        paper: "#ffffff",
      },
    },
  })

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.STEP_HEADER)
    setPreviousUrl(undefined)
    setActionOnBackButton(goToUploadStep)
    setStepHeader(1)
  }, [goToUploadStep, setActionOnBackButton, setPreviousUrl, setStepHeader, setTypeNavBar])

  useEffect(() => {
    if (progress === 100) {
      setIsModelLoading(false)
    } else {
      setIsModelLoading(true)
    }
  }, [progress])

  function cancelUpload(): void {
    openErrorSnackbar(new Error("Une erreur s'est produite pendant le chargement"))
    resetFile()
    setCodesExtraits([])
    setProgress(0)
    setIsModelLoading(false)
    goToUploadStep()
  }

  function cancelAction(): void {
    setIsCancelProjectOpen(true)
  }

  function handleClose(): void {
    setIsCancelProjectOpen(false)
  }

  const navigateBack = useCallback(() => {
    if (bsVariantId) {
      navigate(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_CHOICE, [bsProjectId, bsVariantId]))
    } else {
      navigate(resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProjectId], { tab: TabEnum.BS_BIM_MODEL_POOL }))
    }
  }, [bsProjectId, bsVariantId, navigate])

  function handlePauseProject(): void {
    setIsCancelProjectOpen(false)
    navigateBack()
  }

  function handleClickCodeManquant(codeManquant: SubsetInfo): void {
    setSelectedCodeExtrait(undefined)
    viewer?.manager.subsetsManager.toggleSubsetHighlight(codeManquant)
  }

  function handleCodeVariantChange(codeVariant: codeStateEnum, unHighlight?: boolean): void {
    viewer?.manager.subsetsManager.updateCodeState(codeVariant, unHighlight ?? false)
    if (unHighlight) setSelectedCodeExtrait(undefined)
  }

  const onSubmit: () => void = useCallback(() => {
    handleSubmitModelAndFile(codesExtraits).then(navigateBack)
  }, [navigateBack, handleSubmitModelAndFile, codesExtraits])

  return (
    <ThemeProvider theme={modifiedTheme}>
      <CssBaseline />
      <Box sx={{ backgroundColor: "#FFFFFF", width: "100%", p: 3 }}>
        {bsModelFileSelected !== undefined && (
          <BSBimModelAnalyzer
            bimModelFile={bsModelFileSelected}
            analyzeModel={analyzeModel}
            codesExtraits={codesExtraits}
            setProgress={setProgress}
            handleClickAcv={handleClickAcv}
            selectedCodeExtrait={selectedCodeExtrait}
            handleClickCodeManquant={handleClickCodeManquant}
            handleCodeVariantChange={handleCodeVariantChange}
            cancelUpload={cancelUpload}
            progress={progress}
          />
        )}
      </Box>

      <CustomBottomNavigation
        actionLabel="Valider"
        actionButton={onSubmit}
        cancelLabel="Annuler"
        cancelAction={cancelAction}
        isSubmitting={isModelLoading || isSubmitting}
      />
      <BSSubmitDialog
        isOpen={isCancelProjectOpen}
        onClose={handleClose}
        onSubmit={handlePauseProject}
        titleText="Êtes-vous sûr ?"
        contentText={`Vous souhaitez remettre à plus tard ${actionLabel} de la maquette "${bsModelFileSelected?.name}" ?`}
        cancelButtonLabel="Annuler"
        submitButtonLabel="Remettre à plus tard"
      />
    </ThemeProvider>
  )
}
