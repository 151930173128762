import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { Children } from "../../../components/miscellianous/children"

export enum UrlStateEnum {
  HOME_PAGE = "Home",
  BEEM_SHOT = "Beem Shot",
  BEEM_PILOT = "Beem Pilot",
}

export const AppSideBarContext = React.createContext<AppSideBarStore>({} as AppSideBarStore)

export function getUrlState(path: string): UrlStateEnum | undefined {
  if (path.includes("/beem-shot")) {
    return UrlStateEnum.BEEM_SHOT
  } else if (path.includes("/beem-pilot")) {
    return UrlStateEnum.BEEM_PILOT
  } else if (path.includes("/home")) {
    return UrlStateEnum.HOME_PAGE
  } else {
    return undefined
  }
}

export function SideBarContextProvider({ children }: Children): React.JSX.Element {
  const location = useLocation()

  const [showAppSide, setShowAppSide] = useState<boolean>(false)
  const [drawerWidth, setDrawerWidth] = useState<number>(0)
  const [openAppSide, setOpenAppSide] = useState<boolean>(false)
  const [urlState, setUrlState] = useState<UrlStateEnum | undefined>()
  const [fullScreen, setFullScreen] = useState<boolean>(false)

  useEffect(() => {
    setUrlState(getUrlState(location.pathname))
    return () => {
      setShowAppSide(false)
    }
  }, [location])

  const AppSideBarStore: AppSideBarStore = useMemo(
    () => ({
      showAppSide,
      setShowAppSide,
      urlState,
      setUrlState,
      drawerWidth,
      setDrawerWidth,
      openAppSide,
      setOpenAppSide,
      fullScreen,
      setFullScreen,
    }),
    [drawerWidth, fullScreen, openAppSide, showAppSide, urlState]
  )

  return <AppSideBarContext.Provider value={AppSideBarStore}>{children}</AppSideBarContext.Provider>
}

export type AppSideBarStore = {
  showAppSide: boolean
  setShowAppSide: React.Dispatch<React.SetStateAction<boolean>>
  urlState: UrlStateEnum | undefined
  setUrlState: React.Dispatch<React.SetStateAction<UrlStateEnum | undefined>>
  drawerWidth: number
  setDrawerWidth: React.Dispatch<React.SetStateAction<number>>
  openAppSide: boolean
  setOpenAppSide: React.Dispatch<React.SetStateAction<boolean>>
  fullScreen: boolean
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>
}
