import { Box, Grid, Typography } from "@mui/material"
import React, { useCallback, useContext, useMemo } from "react"
import { chooseColor, ISlice } from "./ISlice"
import { displayDecimal, sum } from "../../../core/services/helper-service"
import { customPalette } from "../../../../theme"
import { calculatePercentString, selectableStyle } from "../ChartUtils"
import { BSFilterContext } from "../filters/BSFilterContext"

interface IProps {
  fullData: ISlice[]
  isAllSelected: boolean
  selectLot(dataPoint: ISlice): void
}

export function DonutLegend({ fullData, isAllSelected, selectLot }: Readonly<IProps>): React.JSX.Element {
  const { lotFilter } = useContext(BSFilterContext)

  const handleClick: (dataPoint: ISlice) => void = useCallback(
    (dataPoint) => {
      selectLot(dataPoint)
    },
    [selectLot]
  )

  const total = useMemo(() => sum(fullData.map((lotInfo) => lotInfo.value)), [fullData])

  return (
    <>
      {fullData.map((lotInfo) => (
        <Grid
          item
          xs={12}
          key={lotInfo.id}
          container
          sx={{
            ...selectableStyle(!isAllSelected && !!lotFilter?.get(lotInfo.id)),
            color: customPalette.dashboardGray,
          }}
          onClick={() => handleClick(lotInfo)}>
          <Grid item xs={7}>
            <Typography noWrap sx={{ fontSize: 13 }}>
              {lotInfo.label}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography textAlign="center" sx={{ fontSize: 13 }}>
              {calculatePercentString(lotInfo.value, total)}
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography textAlign="center" sx={{ fontSize: 13 }}>
              {displayDecimal(lotInfo.value, 1)}
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ height: 15, width: 15, backgroundColor: chooseColor(lotInfo, lotFilter) }} />
          </Grid>
        </Grid>
      ))}
    </>
  )
}
