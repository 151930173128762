import { BimModelLotEnum } from "../../enum/bimModelLotEnum"
import { CodesVersion } from "../../enum/codesVersionEnum"
import { PhaseEnum } from "../../enum/phaseEnum"
import { stringToDate } from "../../services/date-service"
import { currentCodeVersion } from "../../appConstants"

export class BimModel {
  id: string | undefined = undefined
  fileName = ""
  projectId = ""
  phase: PhaseEnum = PhaseEnum.ESQ
  version = ""
  lots: BimModelLotEnum = BimModelLotEnum.ARCHITECTURE
  surfaceHabitable: number | undefined
  surfacePlancher: number | undefined
  empriseAuSol: number | undefined
  surfaceComble: number | undefined
  surfaceMoyenneLogement: number | undefined
  modelHashFile?: string
  codesVersion = currentCodeVersion
  createdDate?: Date
  createdByUser?: string

  static fromDto(data: any): BimModel {
    const bimModel = new BimModel()
    if (data === undefined) {
      return new BimModel()
    }
    bimModel.id = data.id
    bimModel.fileName = data.fileName ?? ""
    bimModel.projectId = data.projectId ?? ""
    bimModel.phase = data.phase ?? PhaseEnum.ESQ
    bimModel.version = data.version ?? ""
    bimModel.lots = data.lots ?? BimModelLotEnum.ARCHITECTURE
    bimModel.surfaceHabitable = data.surfaceHabitable
    bimModel.surfacePlancher = data.surfacePlancher
    bimModel.empriseAuSol = data.empriseAuSol
    bimModel.surfaceComble = data.surfaceComble
    bimModel.surfaceMoyenneLogement = data.surfaceMoyenneLogement
    bimModel.modelHashFile = data.modelHashFile
    bimModel.codesVersion = data.codesVersion ?? CodesVersion.VERSION_1_6
    bimModel.createdDate = stringToDate(data.createdDate)
    bimModel.createdByUser = data.createdByUser
    return bimModel
  }
}
