import { CssBaseline, ThemeProvider } from "@mui/material"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { Amplify, Hub } from "aws-amplify"
import frLocale from "date-fns/locale/fr"
import React from "react"
import "./App.scss"
import InfoSnackbar from "./app/components/layout/InfoSnackbar"
import ErrorBoundary from "./app/components/layout/error-boundary"
import ErrorSnackbar from "./app/components/layout/error-snackbar"
import SuccessSnackbar from "./app/components/layout/success-snackbar"
import { authConfiguration } from "./app/core/authConfiguration"
import { OrganizationContextProvider } from "./app/core/context/organization/organization-context"
import { TokenContextProvider } from "./app/core/context/user/token-context"
import { UserContextProvider } from "./app/core/context/user/user-context"
import { AppRouter } from "./app/router/AppRouter"
import { theme } from "./theme"
import { LoadingCursorContextProvider } from "./app/components/GlobalLoadingStyle/LoadingCursorContext"

Amplify.configure(authConfiguration)

function listener(data: any): void {
  switch (data.payload.event) {
    case "signIn":
      break
    case "signUp":
      break
    case "signOut":
      break
    case "signIn_failure":
      console.info("user sign in failed")
      break
    case "tokenRefresh":
      console.info("token refresh succeeded")
      break
    case "tokenRefresh_failure":
      console.info("token refresh failed")
      break
    case "configured":
      console.info("the Auth module is configured")
      break
    default:
      break
  }
}

Hub.listen("auth", listener)

export default function App(): React.JSX.Element {
  return (
    <ErrorSnackbar>
      <InfoSnackbar>
        <SuccessSnackbar>
          <ErrorBoundary>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
              <ThemeProvider theme={theme}>
                <CssBaseline>
                  <LoadingCursorContextProvider>
                    <TokenContextProvider>
                      <UserContextProvider>
                        <OrganizationContextProvider>
                          <AppRouter />
                        </OrganizationContextProvider>
                      </UserContextProvider>
                    </TokenContextProvider>
                  </LoadingCursorContextProvider>
                </CssBaseline>
              </ThemeProvider>
            </LocalizationProvider>
          </ErrorBoundary>
        </SuccessSnackbar>
      </InfoSnackbar>
    </ErrorSnackbar>
  )
}
