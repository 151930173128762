import { Button } from "@mui/material"
import React from "react"
import { OverridableStringUnion } from "@mui/types"
import { ButtonPropsColorOverrides, ButtonPropsVariantOverrides } from "@mui/material/Button/Button"

interface IProps {
  label: string
  color?: OverridableStringUnion<
    "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning",
    ButtonPropsColorOverrides
  >
  variant?: OverridableStringUnion<"text" | "outlined" | "contained", ButtonPropsVariantOverrides>
  onClick(): void
}

export function DashboardButton({ label, color, variant, onClick }: Readonly<IProps>): React.JSX.Element {
  return (
    <Button variant={variant || "outlined"} color={color} onClick={onClick}>
      {label}
    </Button>
  )
}
