import React, { useMemo } from "react"
import { appConstants } from "../../appConstants"
import { BSBimModel } from "../../dto/beem-shot/BSBimModel/BSBimModel"
import { BSBimModelUpdate } from "../../dto/beem-shot/BSBimModel/BSBimModelUpdate"
import { BSProjectInvitation } from "../../dto/beem-shot/BSBimModel/BSProjectInvitation"
import { BSProjectInvitationCreationDto } from "../../dto/beem-shot/BSBimModel/BSProjectInvitationCreationDto"
import { streamToBlob } from "../../services/file-service"
import { useHttp } from "../use-http"
import { BSBimModelCreate } from "../../dto/beem-shot/BSBimModel/BSBimModelCreate"
import { BSCodeExtraitCreation } from "../../dto/code-extrait/BSCodeExtraitCreation"
import { resolveUrl } from "../../services/http-service"

type BimModelHook = {
  getBimModelById(bsBimModelId: string): Promise<BSBimModel | undefined>
  getBSBimModelFile(
    bsBimModelId: string,
    abortControllerRef: React.MutableRefObject<AbortController | null>
  ): Promise<Blob | undefined>
  putBSBimModel(bsModelForm: BSBimModelUpdate): Promise<BSBimModel>
  postBSBimModelAndFile(bsBimModelCreate: BSBimModelCreate, bsBimModelFile: File): Promise<BSBimModel>
  putBSBimModelAndFile(
    bsBimModelUpdate: BSBimModelUpdate,
    bsBimModelFile: File,
    codeExtraitList: BSCodeExtraitCreation[]
  ): Promise<BSBimModel>
  getAllBimModelByProjectId(bsProjectId: string): Promise<BSBimModel[]>
  postInvitation(bsProjectInvitationCreationDto: BSProjectInvitationCreationDto): Promise<BSProjectInvitation>
  deleteBSBimModelFile(bsBimModelId: string): Promise<void>
}

export function useBSBimModel(): BimModelHook {
  const { get, post, postFile, putFile, deleteRequest } = useHttp()

  return useMemo(
    () => ({
      getBimModelById(bsBimModelId: string): Promise<BSBimModel | undefined> {
        return get(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL, [{ key: "bsBimModelId", value: bsBimModelId }])
          .then((r) => r.json())
          .then((data: any) => BSBimModel.fromDto(data))
      },
      getBSBimModelFile(bsBimModelId, abortControllerRef) {
        // Abort if we were already downloading a file
        if (abortControllerRef.current) {
          abortControllerRef.current.abort()
        }

        const controller = new AbortController()
        abortControllerRef.current = controller
        const signal: AbortSignal = controller.signal

        return get(
          appConstants.apiEndpoints.BEEM_SHOT_BIM_FILE,
          [{ key: "bsBimModelId", value: bsBimModelId }],
          signal
        ).then((res) => streamToBlob(res))
      },
      postBSBimModelAndFile(bsBimModelCreate: BSBimModelCreate, bsBimModelFile: File): Promise<BSBimModel> {
        const formData = new FormData()
        formData.append("bsBimModel", JSON.stringify(bsBimModelCreate))
        formData.append("ifcFile", bsBimModelFile)
        return postFile(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL, formData)
          .then((r) => r.json())
          .then((data) => BSBimModel.fromDto(data))
      },
      putBSBimModel(bsBimModelUpdate: BSBimModelUpdate): Promise<BSBimModel> {
        const formData = new FormData()
        formData.append("bsBimModel", JSON.stringify(bsBimModelUpdate))
        return putFile(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL, formData)
          .then((r) => r.json())
          .then((data) => BSBimModel.fromDto(data))
      },
      putBSBimModelAndFile(
        bsBimModelUpdate: BSBimModelUpdate,
        bsBimModelFile: File,
        codeExtraitCreationList: BSCodeExtraitCreation[] | undefined
      ): Promise<BSBimModel> {
        const formData = new FormData()
        formData.append("bsBimModel", JSON.stringify(bsBimModelUpdate))
        if (codeExtraitCreationList) {
          formData.append("codeExtraitCreationList", JSON.stringify(codeExtraitCreationList))
        }
        formData.append("ifcFile", bsBimModelFile)
        return putFile(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL, formData)
          .then((r) => r.json())
          .then((data) => BSBimModel.fromDto(data))
      },
      getAllBimModelByProjectId(bsProjectId: string): Promise<BSBimModel[]> {
        return get(appConstants.apiEndpoints.BEEM_SHOT_ALL_BIM_MODEL_ID, [{ key: "bsProjectId", value: bsProjectId }])
          .then((r) => r.json())
          .then((data: BSBimModel[]) => data.map((x) => BSBimModel.fromDto(x)))
      },
      postInvitation(bsProjectInvitationCreationDto: BSProjectInvitationCreationDto): Promise<BSProjectInvitation> {
        return post(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL_INVITATION, bsProjectInvitationCreationDto, [])
          .then((r) => r.json())
          .then((invitation: BSProjectInvitation) => invitation)
      },
      sendBackInvitation(bsProjectId: string, bsInvitationId: string): Promise<void> {
        return post(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL_SEND_BACK_INVITATION, [bsProjectId]), {}, [
          {
            key: "bsInvitationId",
            value: bsInvitationId,
          },
        ]).then()
      },
      cancelInvitation(bsInvitationId: string): Promise<void> {
        return post(
          resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL_CANCEL_INVITATION, [bsInvitationId]),
          {},
          []
        ).then()
      },
      deleteBSBimModelFile(bsBimModelId: string): Promise<void> {
        return deleteRequest(appConstants.apiEndpoints.BEEM_SHOT_BIM_MODEL, [
          {
            key: "bsBimModelId",
            value: bsBimModelId,
          },
        ]).then()
      },
    }),
    [deleteRequest, get, post, postFile, putFile]
  )
}
