import React from "react"
import { Box } from "@mui/system"
import { DefaultTypography } from "../../typography/default-typography"
import { customPalette } from "../../../../theme"

type IProps = {
  label: string
  value: string
}

export function InfoItem({ label, value }: Readonly<IProps>): React.JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ border: 2, borderColor: customPalette.lightblue, borderRadius: 2, p: 1, width: "100%", height: "100%" }}>
      <DefaultTypography label={label} fontSize="11px" lineHeight="26.5px" fontWeight={400} color="#8398A6" />
      <DefaultTypography label={value || ""} fontSize="12px" lineHeight="18px" fontWeight={400} />
    </Box>
  )
}
