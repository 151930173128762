import React, { useCallback, useContext, useMemo, useState } from "react"
import { BSMaterialResult } from "../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSFilterType } from "../filters/BSFilterContext"
import { updateMap } from "../ChartUtils"
import { LoadingCursorContext } from "../../GlobalLoadingStyle/LoadingCursorContext"

export const BSDashboardSelectionContext = React.createContext<BSSelectionStore>({} as BSSelectionStore)

interface IProps {
  children?: React.ReactNode
}

export function BSDashboardSelectionContextProvider({ children }: Readonly<IProps>): React.JSX.Element {
  const { execWithLoaderCursor } = useContext(LoadingCursorContext)

  const [itemSelection, setItemSelection] = useState<Map<string | undefined, boolean>>(new Map())
  const [codeOccurrenceSelection, setCodeOccurrenceSelection] = useState<Map<string | undefined, boolean>>(new Map())
  const [rowSelection, setRowSelection] = useState<Map<string | undefined, boolean>>(new Map())

  const select: (key: string, filterType: BSFilterType) => void = useCallback(
    (key, filterType) => {
      execWithLoaderCursor(() => {
        switch (filterType) {
          case BSFilterType.ITEM:
            setItemSelection((prev) => updateMap(prev, key))
            break
          case BSFilterType.CODE_OCCURRENCE:
            setCodeOccurrenceSelection((prev) => updateMap(prev, key))
            break
          case BSFilterType.PRODUCT:
            setRowSelection((prev) => updateMap(prev, key))
            break
          default:
            console.error("select error")
        }
      })
    },
    [execWithLoaderCursor]
  )

  const isSelectedItem: (itemType: string) => boolean = useCallback(
    (itemType) => !!itemSelection.get(itemType),
    [itemSelection]
  )

  const isSelectedCodeOccurrence: (bsMaterialResult: BSMaterialResult) => boolean = useCallback(
    (bsMaterialResult) => !!codeOccurrenceSelection.get(bsMaterialResult.codeOccurrence),
    [codeOccurrenceSelection]
  )

  const isSelectedRow: (bsMaterialResult: BSMaterialResult) => boolean = useCallback(
    (bsMaterialResult) => !!rowSelection.get(bsMaterialResult.id),
    [rowSelection]
  )

  const isItemSelectionEmpty: () => boolean = useCallback(() => itemSelection.size === 0, [itemSelection])

  const isSelected: (bsMaterialResult: BSMaterialResult) => boolean = useCallback(
    (bsMaterialResult) =>
      (isItemSelectionEmpty() && codeOccurrenceSelection.size === 0 && rowSelection.size === 0) ||
      isSelectedItem(bsMaterialResult.itemType) ||
      isSelectedCodeOccurrence(bsMaterialResult) ||
      isSelectedRow(bsMaterialResult),
    [
      isItemSelectionEmpty,
      codeOccurrenceSelection.size,
      isSelectedCodeOccurrence,
      isSelectedItem,
      isSelectedRow,
      rowSelection.size,
    ]
  )

  const selectItem: (itemType: string) => void = useCallback(
    (itemType) => {
      select(itemType, BSFilterType.ITEM)
    },
    [select]
  )

  const selectCodeOccurrence: (bsMaterialResult: BSMaterialResult) => void = useCallback(
    (bsMaterialResult) => {
      // When bsMaterialResult.codeOccurrence === undefined, it selects "Pas de code ACV/Estimé"
      select(bsMaterialResult.codeOccurrence, BSFilterType.CODE_OCCURRENCE)
    },
    [select]
  )

  const selectRow: (bsMaterialResult: BSMaterialResult) => void = useCallback(
    (bsMaterialResult) => {
      select(bsMaterialResult.id, BSFilterType.PRODUCT)
    },
    [select]
  )

  const resetAllSelection: () => void = useCallback(() => {
    setItemSelection(new Map())
    setCodeOccurrenceSelection(new Map())
    setRowSelection(new Map())
  }, [])

  const bsSelectionStore = useMemo(
    () => ({
      itemSelection,
      codeOccurrenceSelection,
      rowSelection,
      selectRow,
      selectCodeOccurrence,
      selectItem,
      isSelectedItem,
      isSelectedCodeOccurrence,
      isSelectedRow,
      isSelected,
      isItemSelectionEmpty,
      resetAllSelection,
    }),
    [
      itemSelection,
      codeOccurrenceSelection,
      rowSelection,
      selectRow,
      selectCodeOccurrence,
      selectItem,
      isSelectedItem,
      isSelectedCodeOccurrence,
      isSelectedRow,
      isSelected,
      isItemSelectionEmpty,
      resetAllSelection,
    ]
  )

  return <BSDashboardSelectionContext.Provider value={bsSelectionStore}>{children}</BSDashboardSelectionContext.Provider>
}

export interface BSSelectionStore {
  itemSelection: Map<string | undefined, boolean>
  codeOccurrenceSelection: Map<string | undefined, boolean>
  rowSelection: Map<string | undefined, boolean>

  selectItem(itemType: string): void

  selectCodeOccurrence(bsMaterialResult: BSMaterialResult): void

  selectRow(bsMaterialResult: BSMaterialResult): void

  isSelectedItem(itemType: string): boolean

  isSelectedCodeOccurrence(bsMaterialResult: BSMaterialResult): boolean

  isSelectedRow(bsMaterialResult: BSMaterialResult): boolean

  isSelected(bsMaterialResult: BSMaterialResult): boolean

  isItemSelectionEmpty(): boolean

  resetAllSelection(): void
}
