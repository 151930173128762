import FolderIcon from "@mui/icons-material/Folder"
import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import React, { FormEvent, useContext, useEffect, useMemo, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { DisplayerMode, UploadImageModal } from "../../../components/image-displayer/upload-image-modal"
import FileInputImage from "../../../components/inputs/file-input/file-input-image"
import { ErrorContext } from "../../../components/layout/error-snackbar"
import { SuccessContext } from "../../../components/layout/success-snackbar"
import TitleH1 from "../../../components/miscellianous/title-h1"
import { appConstants, pagesUrl } from "../../../core/appConstants"
import { DepartmentContext } from "../../../core/context/departmentContext"
import { ProjectContext } from "../../../core/context/project/project-context"
import { ProjectRoleContext } from "../../../core/context/user/project-role-context"
import { Project } from "../../../core/dto/project/project"
import { ProjectCreationOrUpdateDto } from "../../../core/dto/project/project-creation-or-update-dto"
import { Department } from "../../../core/enum/departments"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { RoleEnum } from "../../../core/enum/roleEnum"
import { useProjects } from "../../../core/hooks/projects/use-projects"
import { formatToLocalDateString, isDateValid } from "../../../core/services/date-service"
import { resolveUrl } from "../../../core/services/http-service"
import { BaseSelectInput } from "../../../components/inputs/select-input/BaseSelectInput"

type ProjectFormType = {
  id: string | undefined
  name: string
  street: string
  additional: string
  city: string
  departmentId: string
  businessCode: string
  licenceDate: Date
  powerBiId: string
  status?: ProjectStatusEnum
}

function dtoToForm(project: Project): ProjectFormType {
  return {
    ...project,
  }
}

function formToDto(form: ProjectFormType): ProjectCreationOrUpdateDto {
  return {
    id: form.id,
    name: form.name.trim(),
    street: form.street.trim(),
    additional: form.additional.trim(),
    city: form.city.trim(),
    departmentId: form.departmentId,
    businessCode: form.businessCode.trim(),
    licenceDate: formatToLocalDateString(form.licenceDate),
    powerBiId: form.powerBiId.trim(),
    status: form.status,
  }
}

export default function ProjectForm(): React.JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const { organizationId, projectId } = useParams()

  const { departmentsList } = useContext(DepartmentContext)
  const openErrorSnackbar = useContext(ErrorContext)
  const { project, setProject } = useContext(ProjectContext)
  const { hasRole } = useContext(ProjectRoleContext)
  const openSuccessSnackbar = useContext(SuccessContext)

  const { createProject, updateProject, sendCoverImageFile, fetchCoverImage, deleteCoverImage } = useProjects()

  const [projectForm, setProjectForm] = useState<ProjectFormType>(dtoToForm(project))
  const [error, setError] = useState<Record<string, string | undefined>>({})
  const isCreation: boolean = useMemo(() => projectId === "new", [projectId])
  const isWriting: boolean = useMemo(
    () => (hasRole([RoleEnum.ADMINISTRATOR]) || isCreation) && project.status !== ProjectStatusEnum.ARCHIVED,
    [hasRole, isCreation, project.status]
  )

  /**
   * @deprecated Since 1.6.4, legacy feature "early projects" is no longer supported
   */
  const isEarlyProject = location.pathname.includes("early-project")
  const title = isCreation ? "Créer le projet" : "Mettre à jour le projet"

  const fileTypeAccepted = ".png,.jpeg,.jpg"
  const acceptedExtensions = [".png", ".jpeg", ".jpg"]
  const [openImageModal, setOpenImageModal] = useState<boolean>(false)

  const [file, setFile] = useState<File | undefined>()
  const [tmpFile, setTmpFile] = useState<File | undefined>()
  const [validateImage, setValidateImage] = useState<boolean>(false)

  const [imageTextError, setImageTextError] = useState<string>()

  useEffect(() => {
    if (project.fileName && project?.id && project.awsFileKey) {
      fetchCoverImage(project.id).then(async (blob) => {
        if (blob) {
          const fileName = project.fileName
          const newFile = new File([blob], fileName, { type: fileTypeAccepted })
          setFile(newFile)
        }
      })
    }
  }, [fetchCoverImage, project.awsFileKey, project.fileName, project.id])

  useEffect(() => {
    if (project) {
      setProjectForm(project)
    }
  }, [project])

  function validateCoverImage(size: number, height: number, width: number, filename: string): void {
    if (size > appConstants.miscellaneous.PROJECT_IMG_MAX_SIZE * 1024 * 1024) {
      setImageTextError(
        `Le fichier est trop volumineux. La taille maximale est de ${appConstants.miscellaneous.PROJECT_IMG_MAX_HEIGTH} Mo.`
      )
      setValidateImage(false)
    } else if (
      height > appConstants.miscellaneous.PROJECT_IMG_MAX_HEIGTH ||
      width > appConstants.miscellaneous.PROJECT_IMG_MAX_WIDTH
    ) {
      setImageTextError(
        `Le fichier est trop volumineux. La taille maximale est de ${appConstants.miscellaneous.PROJECT_IMG_MAX_HEIGTH} x ${appConstants.miscellaneous.PROJECT_IMG_MAX_HEIGTH}.`
      )
      setValidateImage(false)
    } else if (!acceptedExtensions.includes(filename.toLowerCase().substring(filename.lastIndexOf(".")))) {
      setImageTextError(`Le format du fichier n'est pas valide. Voici les extensions acceptés: ${fileTypeAccepted}`)
      setValidateImage(false)
    } else {
      setValidateImage(true)
    }
  }

  function onChange(coverImageFile: File): void {
    const image = new Image()
    image.src = URL.createObjectURL(coverImageFile)
    image.onload = () => {
      const width = image.width
      const height = image.height
      validateCoverImage(coverImageFile.size, height, width, coverImageFile.name)
    }
    setOpenImageModal(true)
    setTmpFile(coverImageFile)
  }

  function validate(): boolean {
    const newError: Record<string, any> = {}
    let isFormValid = true

    const emptyCheckFields: (keyof ProjectCreationOrUpdateDto)[] = ["name", "street", "city", "businessCode", "departmentId"]
    emptyCheckFields.forEach((field) => {
      const value = projectForm[field]
      if (projectForm && !(value instanceof Date) && !value?.length) {
        newError[field] = "Veuillez remplir ce champ"
        isFormValid = false
      }
    })

    if (isEarlyProject && projectForm.powerBiId === "") {
      newError.powerBiId = "Veuillez remplir ce champ"
      isFormValid = false
    }

    const tooLargeCheckFields: (keyof ProjectCreationOrUpdateDto)[] = [...emptyCheckFields, "additional"]
    tooLargeCheckFields.forEach((field) => {
      const value = projectForm[field]
      if (projectForm && value && !(value instanceof Date) && value.length > 1000) {
        newError[field] = "1000 caractères maximum"
        isFormValid = false
      }
    })

    if (!projectForm.departmentId) {
      newError.zipCode = "Veuillez remplir ce champ"
      isFormValid = false
    }

    if (!isDateValid(projectForm.licenceDate)) {
      newError.licenceDate = "Cette date est invalide"
      isFormValid = false
    }

    setError(newError)
    return isFormValid
  }

  function sendCoverImage(id: string | undefined): void {
    if (tmpFile === undefined) {
      openErrorSnackbar(new Error("Erreur: il n'y a pas de fichier chargé"))
      return
    }
    if (id === undefined) {
      openErrorSnackbar(new Error("Aucun projet n'est sélectionné."))
      return
    }

    sendCoverImageFile(tmpFile, id).catch((e) => {
      console.error("Erreur lors de l'enregistrement de l'image: ", e)
      setImageTextError("Erreur lors de l'enregistrement de l'image. Veuillez rafraîchir votre page")
      setFile(undefined)
    })
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault()

    if (validate()) {
      const organizationIdValue = isEarlyProject && organizationId ? organizationId : ""
      if (isCreation) {
        const dto = formToDto(projectForm)
        createProject(dto, isEarlyProject, organizationIdValue)
          .then((newProject: Project) => {
            setProject(newProject)
            navigate(resolveUrl(pagesUrl.PROJECT_TEAM_CREATION, [newProject.id]))
          })
          .catch((err: Error) => {
            openErrorSnackbar(err)
          })
      } else {
        const dto = formToDto(projectForm)
        updateProject(dto)
          .then((updatedProject: Project) => {
            setProject(updatedProject)
            openSuccessSnackbar("Projet mis à jour avec succès")
            navigate(resolveUrl(pagesUrl.PROJECT_CONTROL_BOARD, [updatedProject.id]))
          })
          .catch((err: Error) => {
            openErrorSnackbar(err)
          })
      }
    }
    return Promise.resolve(undefined)
  }

  function updateNewProject(name: string, value: any): void {
    setProjectForm({
      ...projectForm,
      [name]: value,
    })
  }

  function changeDate(date: Date | null): void {
    if (date === null) {
      return
    }
    setError({ ...error, licenceDate: undefined })
    updateNewProject("licenceDate", date)
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target
    let value: any

    if (target.type === "checkbox") {
      value = target.checked
    } else {
      value = target.value
      setProjectForm({
        ...projectForm,
        [target.id]: value,
      })
    }
    const name = target.id

    if (error[name]) {
      setError({ ...error, [name]: undefined })
    }

    updateNewProject(name, value)
  }

  function getGoBackUrl(): string {
    if (project.id) {
      return resolveUrl(pagesUrl.PROJECT_CONTROL_BOARD, [project.id])
    } else {
      return pagesUrl.PROJECTS_PAGE
    }
  }

  function cancelUpload(): void {
    setTmpFile(undefined)
    setOpenImageModal(false)
  }

  function deleteImage(): void {
    if (project?.id) {
      deleteCoverImage(project.id).then(() => {
        setFile(undefined)
      })
    }
  }

  function saveFile(): void {
    if (!isCreation) {
      sendCoverImage(project.id)
    }

    setFile(tmpFile)
    setOpenImageModal(false)
  }

  function handleCloseModal(): void {
    setOpenImageModal(false)
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ pl: 10, pr: 10 }}>
      <UploadImageModal
        file={tmpFile}
        saveFile={saveFile}
        cancelUpload={cancelUpload}
        validateImage={validateImage}
        imageTextError={imageTextError}
        fileType={fileTypeAccepted}
        open={openImageModal}
        handleClose={handleCloseModal}
        imageTitle="Valider la photo de couverture"
        isloading
        displayMode={DisplayerMode.SAVE}
      />
      <Grid container columnSpacing={2} sx={{ alignItems: "baseline" }}>
        <Grid item xs={8} display="flex" justifyContent="flex-start" alignItems="baseline">
          <Grid item xs={0.5}>
            <Link to={getGoBackUrl()} style={{ textDecoration: "none", color: "inherit" }}>
              <FolderIcon fontSize="large" />
            </Link>
          </Grid>
          <Grid item xs={11.5}>
            <Link to={getGoBackUrl()} style={{ textDecoration: "none", color: "inherit" }}>
              <TitleH1 text={isCreation ? "Nouveau projet" : "Editer le projet"} />
            </Link>
          </Grid>
        </Grid>

        <Grid item xs={4} display="flex" justifyContent="flex-end" justifyItems="center" alignItems="center">
          {!isCreation && (
            <Link to={getGoBackUrl()}>
              <Button type="button" variant="outlined" sx={{ ml: 1 }}>
                Retour
              </Button>
            </Link>
          )}

          {isWriting && (
            <Button type="submit" variant="contained" sx={{ ml: 1 }}>
              {title}
            </Button>
          )}
        </Grid>
      </Grid>

      <Box>
        <Typography fontWeight="bold" variant="h5" fontSize={20} sx={{ mt: 4, mb: 2 }}>
          INFORMATIONS GÉNÉRALES
        </Typography>

        <Grid container rowSpacing={2} columnSpacing={3} display="flex">
          <Grid item xs={12} sm={6}>
            <TextField
              id="name"
              value={projectForm?.name}
              label="Nom du projet"
              onChange={handleChange}
              error={!!error.name}
              helperText={error.name}
              variant="outlined"
              fullWidth
              disabled={!isWriting}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="businessCode"
              value={projectForm?.businessCode}
              label="Code de l'affaire"
              onChange={handleChange}
              variant="outlined"
              fullWidth
              error={!!error.businessCode}
              helperText={error.businessCode}
              disabled={!isWriting}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <DatePicker
              label="Date de dépôt de permis"
              value={projectForm?.licenceDate}
              onChange={changeDate}
              renderInput={(params) => (
                <TextField variant="outlined" error={!!error.licenceDate} helperText={error.licenceDate} {...params} />
              )}
              disabled={!isWriting}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="street"
              label="Adresse"
              onChange={handleChange}
              value={projectForm?.street}
              variant="outlined"
              fullWidth
              error={!!error.street}
              helperText={error.street}
              disabled={!isWriting}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="additional"
              label="Complément d'adresse"
              onChange={handleChange}
              value={projectForm?.additional}
              variant="outlined"
              fullWidth
              disabled={!isWriting}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="city"
              label="Ville"
              onChange={handleChange}
              value={projectForm?.city}
              variant="outlined"
              fullWidth
              error={!!error.city}
              helperText={error.city}
              disabled={!isWriting}
            />
          </Grid>
          <Grid item xs={6}>
            <BaseSelectInput
              id="departmentId"
              label="Département"
              mode="event"
              handleEventChange={handleChange}
              options={departmentsList.map((depart: Department) => ({
                value: depart.departmentId,
                label: depart.departmentName,
              }))}
              selectedOption={projectForm.departmentId}
              errors={error}
              margin="none"
              nullable
            />
          </Grid>
          {isEarlyProject && (
            <Grid item xs={12} sm={6}>
              <TextField
                id="powerBiId"
                label="ID Power BI"
                required
                onChange={handleChange}
                value={projectForm?.powerBiId}
                variant="outlined"
                fullWidth
                error={!!error.powerBiId}
                helperText={error.powerBiId}
                disabled={!isWriting}
              />
            </Grid>
          )}
          {!isCreation && (
            <Grid item xs={12} sm={6}>
              <FileInputImage
                onChange={onChange}
                cancelUpload={cancelUpload}
                deleteImage={deleteImage}
                file={file}
                fileType={fileTypeAccepted}
                disabled={!isWriting}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}
