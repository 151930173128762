import { Box, CircularProgress, createTheme, CssBaseline, Grid, ThemeProvider, useTheme } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { CustomBottomNavigation } from "../../../components/buttons/navigate-button/CustomBottomNavigation"
import { BSSubmitDialog } from "../../../components/dialog/BSSubmitDialog"
import { BSIfcDisplayer } from "../../../components/ifc-displayer/BSIfcDisplayer"
import { ViewerTypes } from "../../../components/ifc-displayer/enums/ViewerTypes"
import { TabEnum } from "../../../components/tabs/tabs"
import { pagesUrl } from "../../../core/appConstants"
import { BSModelFileContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { CodeReferenceContext } from "../../../core/context/code-acv/CodeReferenceContext"
import { AppNavBarContext, NavbarTypeEnum } from "../../../core/context/nav-bar/AppNavBarContext"
import { resolveUrl } from "../../../core/services/http-service"
import { BSBimModelOverview } from "./components/BSBimModelOverview"
import { BSBimModelContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { useViewerAnalyze } from "../../../core/hooks/viewer/useViewerAnalyze"
import { CodeExtrait } from "../../../core/dto/code-extrait/code-extrait"
import { CodeExtraitDisplay } from "../../../core/dto/code-extrait/CodeExtraitDisplay"

export function BSBimModelDetailPage(): React.JSX.Element {
  const navigate = useNavigate()

  const { bsProjectId } = useParams()
  const [searchParams] = useSearchParams()

  const { bsProject } = useContext(BSProjectContext)
  const { bsBimModel } = useContext(BSBimModelContext)
  const { file } = useContext(BSModelFileContext)
  const { setTypeNavBar, setActionOnBackButton, setStepHeader } = useContext(AppNavBarContext)
  const { isCodeAcvLoading } = useContext(CodeReferenceContext)

  const [isCancelProjectOpen, setIsCancelProjectOpen] = useState<boolean>(false)

  const [codesExtraits, setCodesExtraits] = useState<CodeExtrait[]>([])
  const [selectedCodeExtrait, setSelectedCodeExtrait] = useState<CodeExtraitDisplay>()

  const { onChangeModel, setProgress, handleClickAcv } = useViewerAnalyze(
    codesExtraits,
    setCodesExtraits,
    selectedCodeExtrait,
    setSelectedCodeExtrait
  )

  useEffect(() => setSelectedCodeExtrait(undefined), [setSelectedCodeExtrait])

  const theme = useTheme()
  const modifiedTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      background: {
        ...theme.palette.background,
        default: "#ffffff",
        paper: "#ffffff",
      },
    },
  })

  const navigateBack = useCallback(() => {
    const bsVariantId = searchParams.get("variantId")
    if (bsVariantId) {
      navigate(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_CHOICE, [bsProjectId, bsVariantId]))
    } else {
      navigate(resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProjectId], { tab: TabEnum.BS_BIM_MODEL_POOL }))
    }
  }, [bsProjectId, searchParams, navigate])

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.STEP_HEADER)
    setActionOnBackButton(navigateBack)
    setStepHeader(0)
  }, [navigateBack, setActionOnBackButton, setStepHeader, setTypeNavBar])

  function handleClose(): void {
    setIsCancelProjectOpen(false)
  }

  function handlePauseProject(): void {
    setIsCancelProjectOpen(false)
    navigateBack()
  }

  return (
    <ThemeProvider theme={modifiedTheme}>
      <CssBaseline />

      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "70vh" }}>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={5} sm={5}>
            {bsBimModel ? <BSBimModelOverview selectedBSBimModel={bsBimModel} /> : <CircularProgress />}
          </Grid>

          <Grid item xs={7}>
            {!isCodeAcvLoading && file ? (
              <BSIfcDisplayer
                file={file}
                onChangeModel={onChangeModel}
                setSelectedCodeExtrait={handleClickAcv}
                setExternalProgress={setProgress}
                type={ViewerTypes.CODE_VERIFICATION}
                codesExtraits={codesExtraits}
                showFileName
                height="70vh"
              />
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>

      <CustomBottomNavigation actionLabel="" cancelLabel="Retour" cancelAction={navigateBack} />
      <BSSubmitDialog
        isOpen={isCancelProjectOpen}
        onClose={handleClose}
        onSubmit={handlePauseProject}
        titleText="Êtes-vous sûr ?"
        contentText={
          <>
            Vous souhaitez remettre à plus tard la création/mise à jour du projet "{bsProject?.name}" ? <br />
            Vous pouvez reprendre son édition à tout moment depuis la liste des projets Beem Shot.
          </>
        }
        cancelButtonLabel="Annuler"
        submitButtonLabel="Mettre en pause"
      />
    </ThemeProvider>
  )
}
