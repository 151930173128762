import { IconButton, Tooltip } from "@mui/material"
import React, { useContext } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { BSFilterContext } from "../BSFilterContext"
import { BSDashboardSelectionContext } from "../../selection/BSDashboardSelectionContext"

export function ResetFilterButton(): React.JSX.Element {
  const { resetAllFilters } = useContext(BSFilterContext)
  const { resetAllSelection } = useContext(BSDashboardSelectionContext)

  return (
    <Tooltip title="Vider les filtres" placement="bottom">
      <IconButton
        onClick={() => {
          resetAllFilters()
          resetAllSelection()
        }}
        aria-label="reset filters">
        <CloseIcon />
      </IconButton>
    </Tooltip>
  )
}
