import React, { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from "react"
import { stringToNumber } from "../../services/helper-service"
import { ValidationRule } from "./validation"

export function focusOnFormError(errors: Record<string, string | undefined>): void {
  const keysList = Object.keys(errors)
  if (keysList?.length > 0) {
    const ref: HTMLElement | null = document.getElementById(keysList[0])
    if (ref) {
      ref.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }
}

export function useForm<T extends object, D>(
  dto: D,
  dtoToForm: (dto: D) => T,
  validationRules: ValidationRule<T>[],
  submit: (form: T) => Promise<any>,
  additionalHandleChange?: () => void
): FormHook<T> {
  const [rules, setRules] = useState(validationRules)
  const valueFromDto = useMemo(() => dtoToForm(dto), [dto, dtoToForm])
  const [form, setForm] = useState<T>(valueFromDto)
  const [errors, setErrors] = useState<Record<string, string | undefined>>({})
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    setForm(valueFromDto)
  }, [valueFromDto])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const target = event.target
      const name = target.id
      let value: string | number | boolean

      if (target.type === "checkbox") {
        value = target.checked
      } else if (target.type === "number") {
        value = stringToNumber(target.value)
      } else {
        value = target.value
      }

      setErrors((prevValue) => {
        const newErrors = { ...prevValue }
        delete newErrors[name]
        return newErrors
      })

      setForm((prevValue: T) => ({ ...prevValue, [name]: value }))

      if (additionalHandleChange) {
        additionalHandleChange()
      }
    },
    [additionalHandleChange]
  )

  const validate = useCallback(() => {
    let isValid = true
    const newError: Record<string, string | undefined> = {}

    rules.forEach((validationRule) => {
      const { fieldName, errorMessage, rule } = validationRule
      const fieldKey = fieldName as string

      if (!rule(form)) {
        isValid = false
        // Only keep display first error message for field
        if (!newError[fieldKey]) {
          newError[fieldKey] = errorMessage
        }
      }
    })

    focusOnFormError(newError)
    setErrors(newError)

    return isValid
  }, [form, rules])

  const resetForm = useCallback((): void => {
    setForm(valueFromDto)
  }, [valueFromDto])

  const handleSubmit = useCallback(
    (event?: FormEvent<HTMLFormElement>, shouldResetForm = true) => {
      if (event) {
        event.preventDefault()
      }

      if (validate()) {
        setIsSubmitting(true)
        return submit(form)
          .then((response) => {
            if (shouldResetForm) {
              resetForm()
            }
            return response
          })
          .finally(() => setIsSubmitting(false))
      } else {
        return Promise.resolve()
      }
    },
    [form, resetForm, submit, validate]
  )

  useEffect(() => {
    resetForm()
  }, [resetForm])

  return useMemo(
    () => ({
      form,
      errors,
      isSubmitting,
      handleSubmit,
      handleChange,
      resetForm,
      setRules,
      setForm,
      validate,
    }),
    [errors, form, handleChange, handleSubmit, isSubmitting, resetForm, setRules, setForm, validate]
  )
}

interface FormHook<T> {
  form: T
  errors: Record<string, string | undefined>
  isSubmitting: boolean
  setRules: React.Dispatch<React.SetStateAction<ValidationRule<T>[]>>
  setForm: React.Dispatch<React.SetStateAction<T>>
  handleSubmit(event?: FormEvent<HTMLFormElement> | FormEvent<HTMLDivElement>, shouldResetForm?: boolean): Promise<void>
  handleChange(event: ChangeEvent<HTMLInputElement>): void
  validate(): boolean
  resetForm(): void
}
