import { UnitEnum } from "../enum/unitEnum"
import { FunctionalUnitEnum } from "../enum/functionalUnitEnum"
import { IniesRecord } from "../dto/material/IniesRecord"
import { MaterialRecord } from "../dto/material/MaterialRecord"
import { FicheConfiguree } from "../dto/fiche-configuree/fiche-configuree"

export type UnitLabel = {
  unit: UnitEnum
  label: string
}

export const unitLabelList: UnitLabel[] = [
  { unit: UnitEnum.METER, label: "m" },
  { unit: UnitEnum.SQUARE_METER, label: "m²" },
  { unit: UnitEnum.CUBIC_METER, label: "m³" },
  { unit: UnitEnum.KG, label: "kg" },
  { unit: UnitEnum.PER_UNIT, label: "Nombre d'unités" },
  { unit: UnitEnum.UNKNOWN, label: "Unité inconnue" },
]

export function getFunctionalUnit(selectedRow: IniesRecord | MaterialRecord | FicheConfiguree): FunctionalUnitEnum {
  return FunctionalUnitEnum[selectedRow?.functionalUnit as keyof typeof FunctionalUnitEnum]
}

type UnitServiceType = {
  unitEnumToLabel(unitEnum: UnitEnum | undefined): string
  labelToUnitEnum(label: string | undefined): UnitEnum
}

function UnitService(): UnitServiceType {
  return {
    unitEnumToLabel(unitEnum: UnitEnum | undefined): string {
      if (!unitEnum) {
        return ""
      }
      const index = unitLabelList.findIndex((unitLabel) => unitLabel.unit === unitEnum)
      if (index === -1) {
        return "Unité inconnue"
      } else {
        return unitLabelList[index].label
      }
    },
    labelToUnitEnum(label: string | undefined): UnitEnum {
      if (!label) {
        return UnitEnum.UNKNOWN
      }
      const index = unitLabelList.findIndex((unitLabel) => unitLabel.label === label)
      if (index === -1) {
        return UnitEnum.UNKNOWN
      } else {
        return unitLabelList[index].unit
      }
    },
  }
}

export const unitService = UnitService()

export function getLabelForIniesUnit(unit: keyof typeof FunctionalUnitEnum | string | undefined): string {
  const key = unit ?? FunctionalUnitEnum.NO_UNIT
  if (Object.keys(FunctionalUnitEnum).includes(key)) {
    return FunctionalUnitEnum[key as keyof typeof FunctionalUnitEnum]
  } else {
    return key
  }
}
