import { Box, Button, TextField, Typography } from "@mui/material"
import React, { Dispatch, SetStateAction, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DeleteDialog } from "../../../../components/dialog/DeleteDialog"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import { pagesUrl } from "../../../../core/appConstants"
import { BSProjectContext, formToUpdateDto } from "../../../../core/context/beem-shot/BSProject/BSProjectContext"
import { ProjectStatusEnum } from "../../../../core/enum/projectStatusEnum"
import { resolveUrl } from "../../../../core/services/http-service"

type IProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export function DeleteBSProjectDialog({ open, setOpen }: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()

  const { bsProject, handleDeleteBsProject, updateProject } = useContext(BSProjectContext)
  const openSuccessSnackbar = useContext(SuccessContext)

  const [error, setError] = useState<Record<string, string | undefined>>({})
  const [bsProjectNameToDelete, setBsProjectNameToDelete] = useState<string>("")

  function handleChangeDeleteDialog(event: React.ChangeEvent<HTMLInputElement>): void {
    const target = event.target

    setError({ ...error, bsProjectNameToDelete: undefined })
    setBsProjectNameToDelete(target.value)
  }

  function validateProjectNameToDelete(): boolean {
    const newError: Record<string, string> = {}
    let isValid = true
    const nameValue = bsProjectNameToDelete?.trim()

    if (!nameValue?.length) {
      newError.bsProjectNameToDelete = "Veuillez remplir ce champ"
      isValid = false
    }

    if (nameValue !== bsProject?.name) {
      newError.bsProjectNameToDelete = "Le nom du projet est incorrect"
      isValid = false
    }

    if (nameValue.length > 1000) {
      newError.bsProjectNameToDelete = "1000 caractères maximum"
      isValid = false
    }

    setError(newError)
    return isValid
  }

  function handleArchiveProject(): void {
    if (bsProject) {
      const bsProjectToSend = bsProject
      bsProjectToSend.status = ProjectStatusEnum.ARCHIVED

      updateProject(formToUpdateDto(bsProjectToSend))
    }
  }

  return (
    <DeleteDialog
      id="deleteRseeProject"
      title={`Suppression du projet "${bsProject?.name}"`}
      contentText={
        <>
          {bsProject?.status !== ProjectStatusEnum.ARCHIVED && (
            <Box pb={2}>
              <Typography variant="body1" sx={{ fontSize: 19, mb: 2 }}>
                La suppression du projet RSEE n'est possible qu'à l'état Archivé. Voulez-vous passer le statut du projet à
                Archivé ?
              </Typography>
              <Button variant="contained" onClick={handleArchiveProject}>
                Archiver le projet
              </Button>
            </Box>
          )}

          {bsProject?.status === ProjectStatusEnum.ARCHIVED && (
            <>
              <Typography variant="body1" sx={{ fontSize: 19 }}>
                Pour confirmer la suppression, tapez "{bsProject?.name}" ci-dessous (sans les guillemets)
              </Typography>
              <TextField
                required
                id="bsProjectNameToDelete"
                disabled={bsProject?.status !== ProjectStatusEnum.ARCHIVED}
                onChange={handleChangeDeleteDialog}
                value={bsProjectNameToDelete}
                variant="outlined"
                fullWidth
                error={!!error.bsProjectNameToDelete}
                helperText={error.bsProjectNameToDelete}
                sx={{
                  mt: 2,
                  input: { cursor: bsProject?.status !== ProjectStatusEnum.ARCHIVED ? "not-allowed" : "text" },
                }}
              />
            </>
          )}
        </>
      }
      handleClose={() => setOpen(false)}
      action={() => {
        if (validateProjectNameToDelete()) {
          handleDeleteBsProject().then(() => {
            openSuccessSnackbar("Le projet a bien été supprimé")
            setOpen(false)
            navigate(resolveUrl(pagesUrl.BEEM_SHOT_HOME, [], {}))
          })
        }
      }}
      isOpen={open}
      disabledActionButton={bsProject?.status !== ProjectStatusEnum.ARCHIVED}
    />
  )
}
