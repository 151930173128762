import { Paper } from "@mui/material"
import React from "react"
import { DashboardSectionTitle } from "../../../../../../../components/charts/components/DashboardSectionTitle"
import { BSMaterialResult } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { ImpactDonutsChart } from "../../../../../../../components/charts/DonutCharts/ImpactDonutsChart"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
}

export function BiosourceTopRightPanel({ allBSMaterialResult }: Readonly<IProps>): React.JSX.Element {
  return (
    <Paper sx={{ height: "100%" }}>
      <DashboardSectionTitle label="Répartition des points par lot RE2020" />
      <ImpactDonutsChart allBSMaterialResult={allBSMaterialResult} impactFieldName="carbonStockPerSurface" />
    </Paper>
  )
}
