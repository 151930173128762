import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined"
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined"
import { Box } from "@mui/material"
import React from "react"
import { formatToFrench } from "../../../core/services/date-service"
import { DefaultTypography } from "../../typography/default-typography"

type IProps = {
  organizationBusinessName: string
  lastModifiedDate: Date
  numberDocument: string
  teamNumber: string
  iconProject: React.JSX.Element
}

export function InfoCard({
  organizationBusinessName,
  lastModifiedDate,
  numberDocument,
  teamNumber,
  iconProject,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <Box display="flex" justifyContent="space-between" sx={{ width: "100%" }}>
      <Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <HomeWorkOutlinedIcon />
          <Box sx={{ pl: 1 }}>
            <DefaultTypography label={organizationBusinessName || ""} fontSize="12px" lineHeight="16px" fontWeight={400} />
          </Box>
        </Box>

        <DefaultTypography
          label={`Mise à jour le ${formatToFrench(new Date(lastModifiedDate))}`}
          fontSize="10px"
          lineHeight="18px"
          fontWeight={400}
          color="#8398A6"
        />
      </Box>

      <Box display="flex" flexDirection="row" gap={2}>
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          sx={{ background: "#5376BD", height: "30px", px: 1, py: 0.5, borderRadius: 2 }}>
          {/*<IconFileAnalytics style={{ color: "#ffffff" }} />*/}
          {iconProject}
          <DefaultTypography label={numberDocument ?? "0"} fontSize="14px" fontWeight={400} color="#ffffff" />
        </Box>

        <Box
          display="flex"
          alignItems="center"
          gap={1}
          sx={{ background: "#000", height: "30px", px: 1, py: 0.5, borderRadius: 2 }}>
          <PersonOutlinedIcon fontSize="small" sx={{ color: "#fff" }} />
          <DefaultTypography label={teamNumber ?? "1"} fontSize="14px" fontWeight={400} color="#ffffff" />
        </Box>
      </Box>
    </Box>
  )
}
