import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { BSMaterialResult } from "../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { getComparator, getNextOrder, Order } from "../../ChartUtils"
import { BSFilterContext, BSFilterType } from "../../filters/BSFilterContext"
import { customPalette } from "../../../../../theme"
import { NormalRow } from "./NormalRow"
import { NumericKeys } from "../../../../core/services/helper-service"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
  fieldName: NumericKeys<BSMaterialResult>
  valueColumnLabel: string
}

export function CarbonImpactPerFdesChart({
  allBSMaterialResult,
  fieldName,
  valueColumnLabel,
}: Readonly<IProps>): React.JSX.Element {
  const { applyLotFilter, applyItemFilter, applyCodeOccurrenceFilter, applyProductFilter, isFilterAllChecked } =
    useContext(BSFilterContext)

  const [valueSortOrder, setValueSortOrder] = useState<Order | undefined>("desc")

  const filteredList = useMemo(
    () => applyLotFilter(applyItemFilter(applyCodeOccurrenceFilter(applyProductFilter(allBSMaterialResult)))),
    [applyLotFilter, applyItemFilter, applyCodeOccurrenceFilter, applyProductFilter, allBSMaterialResult]
  )

  const [bsMaterialResultList, setBSMaterialResultList] = useState<BSMaterialResult[]>(filteredList)

  const maxValue: number = useMemo(() => {
    const numbers: number[] = filteredList.map((bsMaterialResult) => bsMaterialResult[fieldName])
    return Math.max(...numbers)
  }, [fieldName, filteredList])

  const sortByValue: (order: Order | undefined) => void = useCallback(
    (order) => {
      console.log("sortByValue")
      if (order) {
        const comparator = getComparator(order, fieldName)
        const sortedArray = [...filteredList].sort((a: BSMaterialResult, b: BSMaterialResult) => comparator(a, b))
        setBSMaterialResultList(sortedArray)
      } else {
        setBSMaterialResultList(filteredList)
      }
    },
    [fieldName, filteredList]
  )

  const sortByValueWithNextOrder: () => void = useCallback(() => {
    const nextOrder = getNextOrder(valueSortOrder)
    setValueSortOrder(nextOrder)
    /**
     * We do not need to call sortByValue here because it will be called by the useEffect below
     * If we call it here it will be called twice
     */
    // sortByValue(nextOrder)
  }, [valueSortOrder])

  useEffect(() => {
    // It's here to update the state when the filtered list has changed
    sortByValue(valueSortOrder)
  }, [filteredList, sortByValue, valueSortOrder])

  const isAllCodeOccurrenceSelected = useMemo(() => {
    const strings: (string | undefined)[] = filteredList.map((_bsMaterialResult) => _bsMaterialResult.codeOccurrence)
    return isFilterAllChecked(BSFilterType.CODE_OCCURRENCE, strings)
  }, [filteredList, isFilterAllChecked])

  const isAllProductSelected = useMemo(() => {
    const strings = filteredList.map((_bsMaterialResult) => _bsMaterialResult.ficheId)
    return isFilterAllChecked(BSFilterType.PRODUCT, strings)
  }, [filteredList, isFilterAllChecked])

  return (
    <TableContainer sx={{ color: customPalette.dashboardGray, overflowX: "scroll" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Item</TableCell>
            <TableCell align="center">Provenance</TableCell>
            <TableCell align="center">MacroComposant</TableCell>
            <TableCell align="center">Nom du produit</TableCell>
            <TableCell align="center">ID fiche</TableCell>
            <TableCell align="center">Quantité</TableCell>
            <TableCell align="center">Unité</TableCell>
            <TableCell align="center" sortDirection={valueSortOrder}>
              <TableSortLabel
                active={!!valueSortOrder}
                direction={valueSortOrder}
                onClick={sortByValueWithNextOrder}
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    cursor: "pointer",
                  },
                }}>
                {valueColumnLabel}
              </TableSortLabel>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {bsMaterialResultList.map((bsMaterialResult) => (
            <NormalRow
              key={bsMaterialResult.id}
              bsMaterialResult={bsMaterialResult}
              fieldName={fieldName}
              maxValue={maxValue}
              isAllCodeOccurrenceSelected={isAllCodeOccurrenceSelected}
              isAllProductSelected={isAllProductSelected}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
