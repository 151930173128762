import { Grid } from "@mui/material"
import React, { ChangeEvent, useCallback, useContext, useMemo } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { BSBimModelInviter } from "../../../../components/BSBimModelInviter"
import { resolveUrl } from "../../../../../../core/services/http-service"
import { pagesUrl } from "../../../../../../core/appConstants"
import { useForm } from "../../../../../../core/hooks/form/use-form"
import { required } from "../../../../../../core/hooks/form/validation"
import { BSModelInvitationContext } from "../../../../../../core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider"
import { BSProjectContext } from "../../../../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSInputContext } from "../../../../../../core/context/beem-shot/BSInput/BSInputContext"
import { BSProjectInvitationCreationDto } from "../../../../../../core/dto/beem-shot/BSBimModel/BSProjectInvitationCreationDto"

export interface IBSInvitationForm {
  projectId?: string
  userOrganizationName: string
  lastName: string
  cognitoUserId: string
}

function dtoToForm(): IBSInvitationForm {
  return {
    projectId: "",
    userOrganizationName: "",
    lastName: "",
    cognitoUserId: "",
  }
}

function formToDto(form: IBSInvitationForm, bsProjectId: string): BSProjectInvitationCreationDto {
  return {
    projectId: bsProjectId,
    cognitoUserId: form.cognitoUserId,
  }
}

export function BSUploadInvitationTab(): React.JSX.Element {
  const navigate = useNavigate()
  const { bsBimModelId } = useParams()
  const [searchParams] = useSearchParams()
  const bsVariantId = useMemo(() => searchParams.get("variantId"), [searchParams])

  const { refreshBSInput } = useContext(BSInputContext)
  const { bsProject } = useContext(BSProjectContext)

  const { invitation, sendBSModelInvitation } = useContext(BSModelInvitationContext)

  const submitInvitation: (form: IBSInvitationForm) => Promise<any> = useCallback(
    async (form: IBSInvitationForm) => {
      if (bsProject?.id) {
        sendBSModelInvitation(formToDto(form, bsProject.id))
          .then(() => {
            refreshBSInput()
          })
          .then(() => {
            if (bsVariantId) {
              navigate(
                resolveUrl(pagesUrl.BEEM_SHOT_INVITATION_CONFIRMATION, [bsProject.id, bsBimModelId], {
                  variantId: bsVariantId,
                })
              )
            } else {
              navigate(resolveUrl(pagesUrl.BEEM_SHOT_INVITATION_CONFIRMATION, [bsProject.id, bsBimModelId]))
            }
          })
      }
    },
    [bsProject?.id, sendBSModelInvitation, refreshBSInput, navigate, bsBimModelId, bsVariantId]
  )

  const { form, errors, handleChange, handleSubmit } = useForm(
    invitation,
    dtoToForm,
    [required("cognitoUserId")],
    submitInvitation
  )

  const handleChangeWithEvent = useCallback(
    (cognitoUserId: string | undefined): void => {
      const event = {
        target: {
          id: "cognitoUserId",
          value: cognitoUserId,
        },
      } as unknown as ChangeEvent<HTMLInputElement>
      handleChange(event)
    },
    [handleChange]
  )

  return (
    <Grid
      component="form"
      id="send-invitation-form-id"
      onSubmit={handleSubmit}
      container
      justifyContent="center"
      alignItems="center"
      sx={{ display: "flex", height: "50vh", width: "100%" }}>
      <Grid item xs={3} />
      <Grid
        container
        item
        xs={6}
        rowGap={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          background: "white",
          boxShadow: 2,
          borderRadius: 8,
          p: 5,
        }}>
        <BSBimModelInviter
          selectedCognitoUserId={form.cognitoUserId}
          handleChange={handleChangeWithEvent}
          errors={{ ...errors }}
        />
      </Grid>
      <Grid item xs={3} />
    </Grid>
  )
}
