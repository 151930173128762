import { Box, Grid } from "@mui/material"
import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { pagesUrl } from "../../../core/appConstants"
import { RseeProjectCardDto } from "../../../core/dto/rsee/rsee-project-card"
import { resolveUrl } from "../../../core/services/http-service"
import IconFolder from "../../custom-icons/icon-folder"
import { DefaultTypography } from "../../typography/default-typography"
import { ProjectCardStatus } from "../beem-master/project-status-card"
import { InfoCard } from "../components/InfoCard"
import { InfoItem } from "../components/InfoItem"
import { IconFileAnalytics } from "../../custom-icons/icon-file-analytics"
import { displayNumber } from "../../../core/services/helper-service"

type IProps = {
  rseeProjectCard: RseeProjectCardDto
}

export function RseeProjectCard({ rseeProjectCard }: Readonly<IProps>): React.JSX.Element {
  const navigateUrl = useMemo(
    () => resolveUrl(pagesUrl.BEEM_PILOT_PROJECT, [rseeProjectCard.projectId]),
    [rseeProjectCard.projectId]
  )

  return (
    <Link to={navigateUrl} style={{ width: "100%", minHeight: 100, textDecoration: "none", color: "inherit" }}>
      <Grid
        container
        rowGap={2}
        width="100%"
        sx={{
          backgroundColor: "#fff",
          borderRadius: 3,
          p: 3,
          boxShadow: "2px 4px 20px 0px rgba(0, 0, 0, 0.05)",
          transition: "all 0.2s ease-in-out",
          ":hover": {
            cursor: "pointer",
            boxShadow: 10,
            transform: "translateY(-5px)",
          },
        }}>
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item xs={2}>
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              borderRadius={2}
              p={1}
              px={3}
              sx={{ background: "#E6F0FB" }}>
              <IconFolder style={{ fontSize: "30px" }} />
            </Box>
          </Grid>
          <Grid item xs={3} display="flex" justifyContent="flex-end">
            <ProjectCardStatus status={rseeProjectCard.projectStatus} />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="left" alignItems="center">
            <DefaultTypography label={rseeProjectCard.projectName} fontSize="16px" lineHeight="24px" fontWeight={600} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <InfoItem label="N° d'affaire" value={rseeProjectCard.businessCode || ""} />
        </Grid>

        <Grid item xs={12}>
          <InfoCard
            organizationBusinessName={rseeProjectCard.projectOrganizationBusinessName}
            lastModifiedDate={rseeProjectCard.lastModifiedDate}
            numberDocument={displayNumber(rseeProjectCard.numberDocument)}
            teamNumber={rseeProjectCard.teamNumber}
            iconProject={<IconFileAnalytics style={{ color: "#ffffff" }} />}
          />
        </Grid>
      </Grid>
    </Link>
  )
}
