import { Grid } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined"
import { pagesUrl } from "../../../core/appConstants"
import { BSProjectCardDto } from "../../../core/dto/beem-shot/BSProject/BSProjectCardDto"
import { useBSProject } from "../../../core/hooks/beem-shot/useBSProject"
import { resolveUrl } from "../../../core/services/http-service"
import { ImageDisplayer } from "../../image-displayer/image-displayer"
import { fileTypeAccepted } from "../../image-displayer/upload-image-modal"
import { DefaultTypography } from "../../typography/default-typography"
import { ProjectCardStatus } from "../beem-master/project-status-card"
import { InfoCard } from "../components/InfoCard"
import { InfoItem } from "../components/InfoItem"

type IProps = {
  bsProjectCardInfo: BSProjectCardDto
  hasCoverImage: boolean
}

export function BSProjectCard({ bsProjectCardInfo, hasCoverImage }: Readonly<IProps>): React.JSX.Element {
  const { fetchCoverImage } = useBSProject()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [file, setFile] = useState<File | undefined>()

  useEffect(() => {
    if (bsProjectCardInfo.hasCoverImg) {
      setIsLoading(true)
      fetchCoverImage(bsProjectCardInfo.id)
        .then(async (blob) => {
          if (blob) {
            const newFile = new File([blob], bsProjectCardInfo.coverImgFileName, { type: fileTypeAccepted })
            setFile(newFile)
          }
        })
        .finally(() => setIsLoading(false))
    }
  }, [
    fetchCoverImage,
    bsProjectCardInfo.coverImgFileName,
    bsProjectCardInfo.id,
    hasCoverImage,
    bsProjectCardInfo.hasCoverImg,
  ])

  const navigateUrl: string = useMemo(
    () => resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProjectCardInfo?.id]),
    [bsProjectCardInfo?.id]
  )

  return (
    <Link to={navigateUrl} style={{ width: "100%", minHeight: 100, textDecoration: "none", color: "inherit" }}>
      <Grid
        container
        gap={2}
        width="100%"
        alignItems="center"
        sx={{
          backgroundColor: "#fff",
          borderRadius: 3,
          p: 3,
          boxShadow: "2px 4px 20px 0px rgba(0, 0, 0, 0.05)",
          transition: "all 0.2s ease-in-out",
          ":hover": {
            cursor: "pointer",
            boxShadow: 10,
            transform: "translateY(-5px)",
          },
        }}>
        <Grid item xs={12} container justifyContent="space-between">
          <Grid
            item
            xs={2}
            display="flex"
            sx={{
              overflow: "hidden",
            }}>
            <ImageDisplayer file={file} height={70} width={70} isLoading={isLoading} activateAnimation />
          </Grid>

          <Grid item xs={3} display="flex" justifyContent="flex-end">
            <ProjectCardStatus status={bsProjectCardInfo.status} />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="left" alignItems="center">
            <DefaultTypography label={bsProjectCardInfo.name} fontSize="16px" lineHeight="24px" fontWeight={600} />
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={1}>
          <Grid item xs={6}>
            <InfoItem label="N° d'affaire" value={bsProjectCardInfo.businessCode || ""} />
          </Grid>
          <Grid item xs={6}>
            <InfoItem label="Département" value={`${bsProjectCardInfo.departmentName || ""}`} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <InfoCard
            organizationBusinessName={bsProjectCardInfo.projectOrganizationBusinessName}
            lastModifiedDate={bsProjectCardInfo.lastModifiedDate}
            numberDocument={bsProjectCardInfo.variantNumber ?? "0"}
            teamNumber={bsProjectCardInfo.teamNumber}
            iconProject={<DonutSmallOutlinedIcon style={{ color: "#ffffff" }} />}
          />
        </Grid>
      </Grid>
    </Link>
  )
}
