import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react"
import ViewerIFC from "../../../components/ifc-displayer/ViewerIFC"
import { CodeExtraitDisplay } from "../../dto/code-extrait/CodeExtraitDisplay"
import { codeToKey } from "../../services/code-service"

export function useViewerDisplay(
  codesExtraits: CodeExtraitDisplay[],
  selectedCodeExtrait: CodeExtraitDisplay | undefined,
  setSelectedCodeExtrait: Dispatch<SetStateAction<CodeExtraitDisplay | undefined>>
): BSViewerContextStore {
  const [typesToElementIdsMap, setTypesToElementIdsMap] = useState<Map<number, number[]>>(new Map())
  const [viewer, setViewer] = useState<ViewerIFC | undefined>(undefined)
  const [progress, setProgress] = useState<number>(0)

  /**
   * Update the viewer colors with the new "codes extraits" we got from here,
   * the method should be passed to ifc-displayer for the onChangeModel param.
   * Note: this will automatically update the viewer colors when the code extraits
   * contains element ids and colors coming from the backend.
   * @param {number} modelId the model id in the viewer
   * @param {ViewerIFC} viewerIfc the viewer ifc object
   */
  const onChangeModel: (modelId: number, viewerIfc: ViewerIFC) => void = useCallback(
    (modelId, viewerIfc) => {
      setViewer(viewerIfc)
      if (codesExtraits.length !== 0 && viewer) {
        viewerIfc.manager.subsetsManager.setCodeColors(modelId, codesExtraits, typesToElementIdsMap).then(() => {
          if (selectedCodeExtrait) {
            viewerIfc?.manager.subsetsManager.highlightCodeExtrait(selectedCodeExtrait)
          }
        })
      }
    },
    [codesExtraits, selectedCodeExtrait, typesToElementIdsMap, viewer]
  )

  useEffect(() => {
    if (viewer) {
      onChangeModel(viewer.modelId ?? 0, viewer)
    }
  }, [onChangeModel, viewer])

  const handleClickAcv: (codeExtrait: CodeExtraitDisplay, disableViewerHighlight?: boolean) => CodeExtraitDisplay =
    useCallback(
      (codeExtrait, disableViewerHighlight) => {
        if (
          !disableViewerHighlight &&
          `${codeExtrait.code + codeExtrait.occurence}` !==
            `${selectedCodeExtrait ? selectedCodeExtrait.code + selectedCodeExtrait.occurence : ""}`
        ) {
          viewer?.manager.subsetsManager.highlightCodeExtrait(codeExtrait)
        }

        const newCode = codesExtraits.find((code) => codeToKey(code) === codeToKey(codeExtrait))
        setSelectedCodeExtrait(newCode)
        return codeExtrait
      },
      [selectedCodeExtrait, viewer?.manager.subsetsManager, codesExtraits, setSelectedCodeExtrait]
    )

  return useMemo(
    () => ({
      typesToElementIdsMap,
      setTypesToElementIdsMap,
      viewer,
      setViewer,
      progress,
      setProgress,
      onChangeModel,
      handleClickAcv,
    }),
    [typesToElementIdsMap, progress, viewer, onChangeModel, handleClickAcv]
  )
}

export interface BSViewerContextStore {
  typesToElementIdsMap: Map<number, number[]>
  setTypesToElementIdsMap: Dispatch<SetStateAction<Map<number, number[]>>>
  viewer: ViewerIFC | undefined
  setViewer: Dispatch<SetStateAction<ViewerIFC | undefined>>
  progress: number
  setProgress: Dispatch<SetStateAction<number>>
  onChangeModel(modelId: number, viewerIfc: ViewerIFC): void
  handleClickAcv(codeExtrait: CodeExtraitDisplay, disableViewerHighlight?: boolean): CodeExtraitDisplay
}
