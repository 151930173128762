import React from "react"
import { Global } from "@emotion/react"

const globalLoadingCursor = {
  "body, *": {
    cursor: "wait !important",
  },
}

interface IProps {
  isLoading: boolean
}

export function GlobalLoadingStyle({ isLoading }: Readonly<IProps>): React.JSX.Element {
  return <Global styles={isLoading ? globalLoadingCursor : undefined} />
}
