import Settings from "@mui/icons-material/Settings"
import { Box, Grid, Typography } from "@mui/material"
import LinearProgress from "@mui/material/LinearProgress"
import * as React from "react"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { pagesUrl } from "../../core/appConstants"
import { AppNavBarContext } from "../../core/context/nav-bar/AppNavBarContext"
import { OrganizationContext } from "../../core/context/organization/organization-context"
import { AppSideBarContext } from "../../core/context/side-bar/side-bar-context"
import { isNumber } from "../../core/services/helper-service"

interface IProps {
  label: string
  maxLicense: number | undefined
  countLicense: number | undefined
  isOptionActive: boolean | undefined
  backgroundColor?: string | undefined
}

export function OfferCard({ label, maxLicense, countLicense, isOptionActive, backgroundColor }: IProps): React.JSX.Element {
  const location = useLocation()

  const { organization } = React.useContext(OrganizationContext)
  const { setShowAppSide } = React.useContext(AppSideBarContext)
  const { setTitle, setPreviousUrl, setBreadcrumbSteps, setIcon } = React.useContext(AppNavBarContext)

  useEffect(() => {
    setShowAppSide(true)
    setTitle("Mon offre")
    setIcon(<Settings />)
    setBreadcrumbSteps([{ label: "Mon offre" }])
    setPreviousUrl(pagesUrl.HOME)
  }, [setShowAppSide, location, setTitle, setPreviousUrl, setIcon, setBreadcrumbSteps])

  function getNumberIndicator(firstNum?: number, secondNum?: number, isActive = true): React.JSX.Element {
    return (
      <Box display="flex" flexDirection="row" gap={1} py={1}>
        <Typography fontSize={32} fontWeight={600}>
          {firstNum}
        </Typography>

        <Typography fontSize={18} fontWeight={600} pt={0.5}>
          / {isActive ? formatMax(secondNum) : 0}
        </Typography>
      </Box>
    )
  }

  function formatMax(max: number | undefined): number | string | React.JSX.Element {
    if (max === undefined || !isNumber(max)) {
      return 0
    } else if (max < 0) {
      // This means infinite licence available
      return <span style={{ fontWeight: 900 }}>∞</span>
    } else {
      return max
    }
  }

  function normalizeTo100(value: number, max: number): number {
    if (max === 0 || max === undefined) {
      return 100
    } else if (max === -1) {
      return 0
    }
    return (value / max) * 100
  }

  return isOptionActive ? (
    <Grid item xs={4} p={1}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100%"
        p={3}
        borderRadius={3}
        sx={{ backgroundColor: backgroundColor || "#E6F0FB", color: backgroundColor ? "white" : "" }}>
        {getNumberIndicator(countLicense, maxLicense, isOptionActive)}
        <Typography variant="body1">{label}</Typography>
        <LinearProgress
          variant="determinate"
          value={normalizeTo100(countLicense ?? 0, maxLicense || 0)}
          sx={{
            height: 10,
            borderRadius: 5,
            backgroundColor: (theme) => (theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[200]),
            "& .MuiLinearProgress-bar": {
              borderRadius: 5,
              backgroundColor: "#08CA8F",
            },
          }}
        />
      </Box>
    </Grid>
  ) : (
    <Grid item xs={3.9} />
  )
}
