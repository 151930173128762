import { Grid, Step, StepLabel, Stepper, Toolbar } from "@mui/material"
import React, { useContext, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { theme } from "../../../../theme"
import { AppNavBarContext } from "../../../core/context/nav-bar/AppNavBarContext"
import { AppSideBarContext, UrlStateEnum } from "../../../core/context/side-bar/side-bar-context"
import { BackButtonNavbar } from "../../buttons/back-button/BackButtonNavbar"
import "../app-navbar-v2.scss"

export function StepHeaderNavbar(): React.JSX.Element {
  const { urlState } = useContext(AppSideBarContext)
  const { stepHeader } = useContext(AppNavBarContext)

  const location = useLocation()

  const steps = useMemo(() => {
    if (urlState === UrlStateEnum.BEEM_SHOT) {
      if (location.pathname.endsWith("/import")) {
        return ["Importer la maquette", "Contrôler la maquette"]
      }
    } else if (urlState === UrlStateEnum.BEEM_PILOT) {
      return ["Importer le fichier RSEE", "Diagnostic de l'import"]
    }
    return []
  }, [location.pathname, urlState])

  return (
    <Toolbar
      disableGutters
      sx={{
        color: "white",
        borderRadius: 5,
        backgroundColor: "#070F27",
        zIndex: -5000,
        ...theme.mixins.toolbar,
      }}>
      <Grid container display="flex" justifyContent="space-between" alignContent="center">
        <BackButtonNavbar />

        <Grid item xs={7} display="flex" justifyContent="center" alignContent="center">
          <Stepper
            alternativeLabel
            activeStep={stepHeader}
            sx={{
              m: 1,
              width: "100%",
              "& .MuiStepConnector-line": {
                borderColor: "#D3D3D3",
                borderWidth: 4,
                m: 0,
              },
              [`& .MuiStepConnector-lineHorizontal`]: {
                borderColor: "#D3D3D3", // Grey by default
              },
              [`& .Mui-completed .MuiStepConnector-line`]: {
                borderColor: "#0BE2A0", // Green if step is completed
              },
              [`& .Mui-active .MuiStepConnector-line`]: {
                borderColor: "#0BE2A0", // Green if step is active
              },
            }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  sx={{
                    textAlign: "center",
                    "& .MuiSvgIcon-root ": {
                      textAlign: "center",
                      color: "#FFFFFF61",
                      border: 1,
                      p: "2px",
                      borderRadius: "50px",
                      m: 0,
                    },
                    "& .MuiSvgIcon-root.Mui-active": { textAlign: "center", color: "#0BE2A0", zIndex: 9999 },
                    "& .MuiSvgIcon-root.Mui-completed": { textAlign: "center", color: "#0BE2A0", zIndex: 9999 },
                    "& .MuiStepLabel-label": { textAlign: "center", color: "#FFFFFF61" },
                    "& .MuiStepLabel-label.Mui-completed": { textAlign: "center", color: "#FFFFFF61" },
                    "& .MuiStepLabel-label.Mui-active": { textAlign: "center", color: "#ffffff" },
                  }}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>

        <Grid item xs={2} />
      </Grid>
    </Toolbar>
  )
}
