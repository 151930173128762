import { useMemo } from "react"
import { appConstants } from "../../appConstants"
import { CodeExtraitCreation } from "../../dto/code-extrait/code-extrait-creation"
import { useHttp } from "../use-http"

type CodeHook = {
  refreshAllVariantCalcul(bsBimModelId: string, codeExtrait: CodeExtraitCreation[], bsProjectId: string): Promise<void>
}

export function useBSCode(): CodeHook {
  const { post } = useHttp()

  return useMemo(
    () => ({
      refreshAllVariantCalcul(bsBimModelId: string, codeExtrait: CodeExtraitCreation[], bsProjectId: string) {
        return post(appConstants.apiEndpoints.BEEM_SHOT_REFRESH_CALCULATION, codeExtrait, [
          {
            key: "bsBimModelId",
            value: bsBimModelId,
          },
          {
            key: "bsProjectId",
            value: bsProjectId,
          },
        ]).then(() => undefined)
      },
    }),
    [post]
  )
}
