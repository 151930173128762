import React, { useCallback, useContext } from "react"
import { ConfirmationDialog } from "../../../../../../../components/dialog/confirmation-dialog"
import { BSMaterialResultContext } from "../../../../../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext"
import { BSVariantContext } from "../../../../../../../core/context/beem-shot/BSVariant/BSVariantContext"

interface IProps {
  isOpen: boolean
  handleClose(): void
}

export function BSConfirmResetAllDialog({ isOpen, handleClose }: Readonly<IProps>): React.JSX.Element {
  const { resetAllMaterial } = useContext(BSMaterialResultContext)
  const { selectedVariant } = useContext(BSVariantContext)

  const handleResetMaterial = useCallback((): Promise<void> => {
    if (selectedVariant?.id) {
      return resetAllMaterial(selectedVariant?.id).then(() => handleClose())
    }
    return Promise.resolve()
  }, [handleClose, resetAllMaterial, selectedVariant])

  return (
    <ConfirmationDialog
      id="reset-bs-material-result"
      title="Confirmation de la suppression"
      contentText="Êtes-vous sûr de vouloir rétablir tous les réglages par défaut ?"
      handleClose={handleClose}
      action={handleResetMaterial}
      open={isOpen}
    />
  )
}
