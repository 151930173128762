import { Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { BarDatum, ResponsiveBar } from "@nivo/bar"
import React, { useCallback, useContext, useMemo } from "react"
import { BSMaterialResult } from "../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { interpolateColor, lightenColor } from "../../../core/services/color-service"
import { displayNumber, NumericKeys } from "../../../core/services/helper-service"
import { BSFilterContext, BSFilterType } from "../filters/BSFilterContext"
import { selectableStyle } from "../ChartUtils"
import { BSDashboardSelectionContext } from "../selection/BSDashboardSelectionContext"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
  columnLabel: string
  fieldName: NumericKeys<BSMaterialResult>
  highestColor: string
  middleColor: string
  lowestColor: string
}

export function BSSortedBarChart({
  allBSMaterialResult,
  columnLabel,
  fieldName,
  highestColor,
  middleColor,
  lowestColor,
}: Readonly<IProps>): React.JSX.Element {
  const { select, applyLotFilter, applyCodeOccurrenceFilter, applyProductFilter, isFilterAllChecked, isNotFilteredOut } =
    useContext(BSFilterContext)
  const { isSelected } = useContext(BSDashboardSelectionContext)

  const filteredList = useMemo(
    () =>
      applyLotFilter(applyCodeOccurrenceFilter(applyProductFilter(allBSMaterialResult))).filter((bsMaterialResult) =>
        isSelected(bsMaterialResult)
      ),
    [allBSMaterialResult, applyCodeOccurrenceFilter, applyLotFilter, applyProductFilter, isSelected]
  )

  const totalSum = useMemo(
    () =>
      filteredList
        .map((bsMaterialResult) => bsMaterialResult[fieldName])
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
    [fieldName, filteredList]
  )

  const fullData: BarDatum[] = useMemo(() => {
    const result: Map<string, BarDatum> = new Map()

    filteredList.forEach((bsMaterialResult) => {
      let dataPoint: BarDatum | undefined = result.get(bsMaterialResult.bsItemLabel)
      if (!dataPoint) {
        dataPoint = { item: bsMaterialResult.bsItemLabel, carbonImpactPercent: 0, itemType: bsMaterialResult.itemType }
        result.set(bsMaterialResult.bsItemLabel, dataPoint)
      }
      ;(dataPoint.carbonImpactPercent as number) += (bsMaterialResult[fieldName] / totalSum) * 100
    })

    return Array.from(result.values()).sort((a, b) => (b.carbonImpactPercent as number) - (a.carbonImpactPercent as number))
  }, [fieldName, filteredList, totalSum])

  const maxValue: number = useMemo(() => {
    const numbers = fullData.map((dataPoint) => dataPoint.carbonImpactPercent as number)
    return Math.max(...numbers)
  }, [fullData])

  const isAllSelected = useMemo(() => {
    const strings = fullData.map((dataPoint) => dataPoint.itemType as string)
    return isFilterAllChecked(BSFilterType.ITEM, strings)
  }, [fullData, isFilterAllChecked])

  const handleClick = useCallback(
    (dataPoint: BarDatum): void => {
      const itemType = dataPoint.itemType as string
      select(itemType, BSFilterType.ITEM, isAllSelected)
    },
    [select, isAllSelected]
  )

  const isItemSelected = useCallback(
    (dataPoint: BarDatum) => isNotFilteredOut(dataPoint.itemType as string, BSFilterType.ITEM),
    [isNotFilteredOut]
  )

  const getColor: (dataPoint: BarDatum) => string = useCallback(
    (dataPoint) => {
      const carbonImpactPercent = dataPoint.carbonImpactPercent as number
      let baseColor
      if (carbonImpactPercent > 0) {
        const ratio = carbonImpactPercent / maxValue
        baseColor = interpolateColor(middleColor, highestColor, ratio)
      } else {
        baseColor = lowestColor
      }

      if (isItemSelected(dataPoint)) {
        return baseColor
      } else {
        return lightenColor(baseColor, 0.2)
      }
    },
    [highestColor, isItemSelected, lowestColor, maxValue, middleColor]
  )

  return (
    <>
      {/* Header */}
      <Grid container spacing={2} sx={{ pl: 2, pr: 2 }}>
        <Grid item xs={5}>
          <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
            Item
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" sx={{ fontSize: 12 }}>
            {columnLabel}
          </Typography>
        </Grid>
        <Grid item xs={4} />
      </Grid>

      {/* Chart */}
      {fullData.map((dataPoint) => (
        <Grid
          key={dataPoint.item}
          container
          onClick={() => handleClick(dataPoint)}
          sx={{
            ...selectableStyle(!isAllSelected && isItemSelected(dataPoint)),
            mt: 1,
            pl: 2,
            pr: 2,
          }}>
          <Grid item xs={5} sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontSize: 12 }}>
              {dataPoint.item}
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="body2" sx={{ fontSize: 12, textAlign: "center" }}>
              {displayNumber(dataPoint.carbonImpactPercent as number)}%
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ height: "35px", width: "100%" }}>
              <ResponsiveBar
                data={[dataPoint]}
                keys={["carbonImpactPercent"]}
                indexBy="item"
                margin={{}}
                padding={0}
                layout="horizontal"
                valueScale={{ type: "linear" }}
                colors={getColor(dataPoint)}
                maxValue={maxValue}
                enableLabel={false}
                enableGridY={false}
                legends={[]}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                animate={false}
                isInteractive={false}
              />
            </Box>
          </Grid>
        </Grid>
      ))}
    </>
  )
}
// "#C22E2E"
// "#EACFCF"
