import { Box } from "@mui/material"
import React, { useCallback, useState } from "react"
import { BSBimModelForm } from "./components/BSBimModelForm/BSBimModelForm"
import { BSBimModelControl } from "./components/BSBimModelControl/BSBimModelControl"
import { BimModelFormContextProvider } from "./context/BimModelFormContext"

export function BSBimModelImportPage(): React.JSX.Element {
  return (
    <BimModelFormContextProvider>
      <BSBimModelImportPageCore />
    </BimModelFormContextProvider>
  )
}

function BSBimModelImportPageCore(): React.JSX.Element {
  const [isControlStep, setIsControlStep] = useState<boolean>(false)

  const goToControlStep = useCallback(() => {
    setIsControlStep(true)
  }, [])

  const goToUploadStep = useCallback(() => {
    setIsControlStep(false)
  }, [])

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexShrink={1}
      justifyContent="center"
      flexDirection="column"
      alignContent="top"
      alignItems="center">
      {!isControlStep ? (
        <BSBimModelForm goToControlStep={goToControlStep} />
      ) : (
        <BSBimModelControl goToUploadStep={goToUploadStep} />
      )}
    </Box>
  )
}
