import { Box } from "@mui/material"
import React, { useContext, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { IconRseeShareLink } from "../../../components/custom-icons/icon-rsee-share-link"
import { IconUserCheck } from "../../../components/custom-icons/icon-user-check"
import { CustomTabPanel, TabPanelProps } from "../../../components/tabs/CustomTabPanel"
import { TabEnum } from "../../../components/tabs/tabs"
import { pagesUrl } from "../../../core/appConstants"
import { RseeDocumentListContext } from "../../../core/context/beem-pilot/rsee/rsee-document-list-context"
import { RseeProjectContext } from "../../../core/context/beem-pilot/rsee/rsee-project-context"
import { RseeTeamProjectContext } from "../../../core/context/beem-pilot/rsee/RseeTeamProjectContext"
import { AppNavBarContext } from "../../../core/context/nav-bar/AppNavBarContext"
import { AppSideBarContext } from "../../../core/context/side-bar/side-bar-context"
import { ProjectStatusEnum } from "../../../core/enum/projectStatusEnum"
import { resolveUrl } from "../../../core/services/http-service"
import { RseeShareLinkTab } from "./components/RseeShareLinkTab/RseeShareLinkTab"
import { RseeTeamTab } from "./components/RseeTeamTab/RseeTeamTab"
import { RseeDocumentListTab } from "./components/RseeDocumentListTab/RseeDocumentListTab"
import { IconFileAnalytics } from "../../../components/custom-icons/icon-file-analytics"

export function RseeProjectDetailPage(): React.JSX.Element {
  const { rseeProjectId } = useParams()

  const { setShowAppSide } = useContext(AppSideBarContext)
  const { currentTeam } = useContext(RseeTeamProjectContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader, setShowProjectStatus, setTitle, setBreadcrumbSteps } =
    useContext(AppNavBarContext)

  const { rseeProject, refreshProject } = useContext(RseeProjectContext)
  const { rseeDocumentList } = useContext(RseeDocumentListContext)

  const location = useLocation()

  const [tabs, setTabs] = React.useState<TabEnum>(TabEnum.FICHIER_RSEE)

  useEffect(() => {
    if (rseeProjectId) {
      refreshProject()
    }
  }, [refreshProject, rseeProjectId])

  useEffect(() => {
    setPreviousUrl(pagesUrl.BEEM_PILOT_HOME)
    setShowProjectStatus(true)
    setTitle(rseeProject?.projectName ?? "")
    setBreadcrumbSteps([
      { label: "Beem Pilot", url: resolveUrl(pagesUrl.BEEM_PILOT_HOME, []) },
      { label: rseeProject?.projectName ?? "" },
    ])

    return () => {
      setShowProjectStatus(false)
      setTitle("BEEM PILOT")
    }
  }, [
    location,
    rseeProject?.projectName,
    setBreadcrumbSteps,
    setPreviousUrl,
    setShowProjectStatus,
    setStepHeader,
    setTitle,
    setTypeNavBar,
  ])

  useEffect(() => {
    setShowAppSide(true)
  }, [setShowAppSide])

  function handleChange(event: React.SyntheticEvent, newValue: TabEnum): void {
    setTabs(newValue)
  }

  const tabTest: TabPanelProps[] = [
    {
      id: TabEnum.FICHIER_RSEE,
      Icon: IconFileAnalytics,
      label: "Fichier RSEE",
      chips: rseeDocumentList?.length.toString(),
      content: <RseeDocumentListTab />,
    },
    {
      id: TabEnum.EQUIPE,
      Icon: IconUserCheck,
      label: "Équipe",
      chips: currentTeam.length.toString(),
      content: <RseeTeamTab />,
    },
    {
      id: TabEnum.LIEN_DE_PARTAGE,
      Icon: IconRseeShareLink,
      label: "Lien de partage",
      chips: undefined,
      content: <RseeShareLinkTab />,
    },
  ]

  return (
    <Box display="flex" flexDirection="column" height="78vh">
      {rseeProject && rseeProject.projectStatus !== ProjectStatusEnum.IN_PROGRESS && (
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            color: "white",
            borderRadius: 4,
            backgroundColor: "#070F27",
          }}>
          Mode lecture
        </Box>
      )}
      <CustomTabPanel panels={tabTest} currentTabId={tabs} handleChangeTab={handleChange} />
    </Box>
  )
}
