import { Grid } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { BSVariantResult } from "../../../../../../core/dto/beem-shot/BSVariantResult/BSVariantResult"
import { BSMaterialResult } from "../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { ButtonDisplayImpactByItemChart } from "../ButtonDisplayImpactByItemChart"
import { BSFilterContextProvider } from "../../../../../../components/charts/filters/BSFilterContext"
import { BiosourceTopLeftPanel } from "./panels/BiosourceTopLeftPanel"
import { BiosourceTopRightPanel } from "./panels/BiosourceTopRightPanel"
import { BiosourceBottomRightPanel } from "./panels/BiosourceBottomRightPanel"
import { BiosourceBottomLeftfPanel } from "./panels/BiosourceBottomLeftfPanel"
import { useBSDashboard } from "../../../../../../core/hooks/beem-shot/useBSDashboard"
import { BiosourceeLevel } from "../../../../../../core/dto/BiosourceLevel/BiosourceLevel"
import { BSDashboardSelectionContextProvider } from "../../../../../../components/charts/selection/BSDashboardSelectionContext"

interface IProps {
  bsVariantResult: BSVariantResult
  allBSMaterialResult: BSMaterialResult[]
  dashboardRef: React.MutableRefObject<HTMLDivElement | null>
}

export function BSBiosourceTab({ bsVariantResult, allBSMaterialResult, dashboardRef }: Readonly<IProps>): React.JSX.Element {
  const { fetchBiosourceThresholds } = useBSDashboard()
  const [biosourceLevel, setBiosourceLevel] = useState<BiosourceeLevel | undefined>()
  const [displayImpactByItems, setDisplayImpactByItems] = useState<boolean>(true)

  useEffect(() => {
    fetchBiosourceThresholds(bsVariantResult.usage).then((_biosourceLevel) => {
      setBiosourceLevel(_biosourceLevel)
    })
  }, [bsVariantResult.usage, fetchBiosourceThresholds])

  const biosourceFilteredList: BSMaterialResult[] = useMemo(
    () => allBSMaterialResult.filter((bsMaterial) => !!bsMaterial.carbonStockPerSurface), // This filters out the 0 and the undefined valuez
    [allBSMaterialResult]
  )

  return (
    <BSFilterContextProvider allBSMaterialResult={biosourceFilteredList}>
      <BSDashboardSelectionContextProvider>
        <Grid container ref={dashboardRef} columnSpacing={4} rowGap={2} sx={{ pt: 2 }} alignItems="stretch">
          {/* Top panel */}
          <Grid item xs={8}>
            <BiosourceTopLeftPanel
              bsVariantResult={bsVariantResult}
              allBSMaterialResult={biosourceFilteredList}
              biosourceLevel={biosourceLevel}
            />
          </Grid>
          <Grid item xs={4}>
            <BiosourceTopRightPanel allBSMaterialResult={biosourceFilteredList} />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
            <ButtonDisplayImpactByItemChart
              displayImpactByItems={displayImpactByItems}
              setDisplayImpactByItems={setDisplayImpactByItems}
            />
          </Grid>
          {displayImpactByItems && (
            <Grid item xs={3}>
              <BiosourceBottomLeftfPanel allBSMaterialResult={biosourceFilteredList} />
            </Grid>
          )}
          <Grid item xs={displayImpactByItems ? 9 : 12}>
            <BiosourceBottomRightPanel allBSMaterialResult={biosourceFilteredList} />
          </Grid>
        </Grid>
      </BSDashboardSelectionContextProvider>
    </BSFilterContextProvider>
  )
}
