import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react"
import { CachingHelper } from "../../../components/ifc-displayer/helpers/CachingHelper"
import { Children } from "../../../components/miscellianous/children"
import { useBimModel } from "../../hooks/bim-model/use-bim-model"
import { ProjectContext } from "../project/project-context"
import { SelectedPhaseContext } from "../selected-phase-context"
import { BimModelContext } from "./bim-model-context"

export type BimModelFileStore = {
  file?: File
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  isCompressed: boolean
  setIsCompressed: React.Dispatch<React.SetStateAction<boolean>>
  setFile: Dispatch<SetStateAction<File | undefined>>
}

/**
 * @deprecated
 */
export const BimModelFileContext = React.createContext<BimModelFileStore>({
  loading: false,
  file: undefined,
} as BimModelFileStore)

/**
 * @deprecated
 */
export default function BimModelFileContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { project } = useContext(ProjectContext)
  const { selectedPhase } = useContext(SelectedPhaseContext)
  const { bimModel } = useContext(BimModelContext)
  const { fetchBimModelFile, fetchBimModelByProjectIdAndPhases } = useBimModel()
  const [isCompressed, setIsCompressed] = useState<boolean>(false)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(
    () => {
      if (!loading) {
        if (project?.id && selectedPhase && bimModel.phase === selectedPhase && bimModel.modelHashFile) {
          // fetch the bim model information from the server so we know the file name and hash
          setLoading(true)
          // get the cached file if it exists
          CachingHelper.getCachedFile(bimModel.modelHashFile, bimModel.fileName)
            .then((cach) => {
              if (cach) {
                console.info("Get file from cache")
                // present the cached file
                setFile(cach)
                setLoading(false)
              } else {
                console.info("Download file from server")
                // fetch the file from the server because we don't have it in the cache
                if (!project.id || !selectedPhase) return
                fetchBimModelFile(project.id, selectedPhase).then(async (blob) => {
                  if (blob) {
                    // create a file from the blob and cache it
                    const fileName = bimModel.fileName && bimModel.fileName.length > 0 ? bimModel.fileName : "Model.ifc"
                    const newFile = new File([blob], fileName, { type: "application/ifc" })
                    CachingHelper.cacheFile(newFile)
                    // set the file in the context
                    setFile(newFile)
                    setLoading(false)
                  } else {
                    setLoading(false)
                  }
                })
              }
            })
            .then(() => console.info("End of Downloading"))
        } else {
          setFile(undefined)
        }
      }
    },
    // loading
    [fetchBimModelByProjectIdAndPhases, fetchBimModelFile, project, selectedPhase, bimModel]
  )

  useEffect(() => {
    if (file?.name?.endsWith(".ifczip")) {
      setIsCompressed(true)
    }
  }, [file])

  const fileStore: BimModelFileStore = useMemo(
    () => ({
      file,
      setFile,
      loading,
      setLoading,
      isCompressed,
      setIsCompressed,
    }),
    [file, isCompressed, loading]
  )

  return <BimModelFileContext.Provider value={fileStore}>{children}</BimModelFileContext.Provider>
}
