import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { CheckboxInput } from "../../../../../../../components/inputs/checkbox-input/CheckboxInput"
import { BSItemContext, formToDto } from "../../../../../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSProjectContext } from "../../../../../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../../../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { BSItem } from "../../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { BSItemSubCategory } from "../../../../../../../core/dto/beem-shot/BSItem/BSItemSubCategory"
import { useBSItem } from "../../../../../../../core/hooks/beem-shot/useBSItem"
import { stringToNumber } from "../../../../../../../core/services/helper-service"
import { BSItemAccordion } from "./BSItemAccordion"
import { useAccordion } from "./useAccordion"
import { BSCodesExtraitsContext } from "../../../../../../../core/context/beem-shot/BSCodeExtrait/BSCodesExtraitsContext"
import ViewerIFC from "../../../../../../../components/ifc-displayer/ViewerIFC"

interface IProps {
  subCategory: BSItemSubCategory
  expandedSubCategory: string
  viewer: ViewerIFC | undefined

  handleExpandedSubCategory(toExpand?: string): void
}

export function SubCategoryAccordion({
  subCategory,
  expandedSubCategory,
  handleExpandedSubCategory,
  viewer,
}: Readonly<IProps>): React.JSX.Element {
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)
  const { selectedBSItem, setSelectedBSItem, bsItemsForSubCategory, setBSItemsForSubCategory, updateBSItemFunction } =
    useContext(BSItemContext)
  const { setSelectedCodeExtrait } = useContext(BSCodesExtraitsContext)

  const { getBSItemsForSubCategory } = useBSItem()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { expanded: expandedBSItemId, expand, handleExpanded } = useAccordion()

  useEffect(() => {
    if (selectedBSItem) {
      expand(selectedBSItem.id)
    } else {
      expand("")
    }
  }, [selectedBSItem, expand])

  useEffect(() => {
    if (selectedVariant?.id && expandedSubCategory === subCategory.name) {
      setIsLoading(true)
      getBSItemsForSubCategory(selectedVariant.id, subCategory)
        .then((response) => {
          setBSItemsForSubCategory(response)
        })
        .finally(() => setIsLoading(false))
    }
  }, [bsProject, expandedSubCategory, getBSItemsForSubCategory, selectedVariant?.id, setBSItemsForSubCategory, subCategory])

  function handleChangeCheckbox(bsItem: BSItem): void {
    updateBSItemFunction(
      formToDto({
        id: bsItem.id,
        projectId: bsItem.projectId,
        calculType: bsItem.calculType,
        codeExtrait: bsItem.codeExtrait,
        bsMacroComponentIds: bsItem.bsMacroComponents.reduce(
          (acc: { [x: string]: any }, x: { type: { name: string | number }; id: any }) => {
            acc[x.type.name] = x.id
            return acc
          },
          {} as Record<string, string>
        ),
        ignored: !bsItem.ignored,
      })
    )
  }

  function sortBSItem(a: BSItem, b: BSItem): number {
    if (a.type.label < b.type.label) {
      return -1
    } else if (a.type.label > b.type.label) {
      return 1
    } else {
      return stringToNumber(a.codeExtrait?.occurence) - stringToNumber(b.codeExtrait?.occurence)
    }
  }

  const expandSubCategory = useCallback(
    (e: React.SyntheticEvent, toOpen: boolean) => {
      setSelectedBSItem(undefined)
      viewer?.manager.subsetsManager.restoreAllSubsetsMaterial()
      if (selectedBSItem && !toOpen) {
        handleExpandedSubCategory("")
      } else {
        handleExpandedSubCategory(subCategory.name)
      }
    },
    [handleExpandedSubCategory, selectedBSItem, setSelectedBSItem, subCategory.name, viewer?.manager.subsetsManager]
  )

  return (
    <Accordion
      id={subCategory.name}
      expanded={expandedSubCategory === subCategory.name}
      onChange={expandSubCategory}
      elevation={0}
      square
      disableGutters
      TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{subCategory.label}</AccordionSummary>
      <AccordionDetails>
        {isLoading ? (
          <Box width="100%" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          bsItemsForSubCategory?.sort(sortBSItem).map((bsItem) => (
            <Box id={`${bsItem?.codeExtrait?.code}${bsItem?.codeExtrait?.occurence}`} key={bsItem.id} display="flex">
              <CheckboxInput
                id={subCategory.name}
                checked={!bsItem.ignored}
                handleChange={() => handleChangeCheckbox(bsItem)}
                label=""
              />
              <BSItemAccordion
                key={bsItem.id}
                bsItem={bsItem}
                expandedBSItem={expandedBSItemId}
                handleExpandedBSItem={handleExpanded}
                disabled={bsItem.ignored}
                viewer={viewer}
              />
            </Box>
          ))
        )}
      </AccordionDetails>
    </Accordion>
  )
}
