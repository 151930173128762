import { Button, CircularProgress } from "@mui/material"
import React from "react"
import { Box } from "@mui/system"

interface IProps {
  label: string
  isSubmitting: boolean
  onClick(): void
}

export function DashboardButtonSecondary({ label, isSubmitting, onClick }: Readonly<IProps>): React.JSX.Element {
  return isSubmitting ? (
    <Box sx={{ pl: 5, pr: 5 }}>
      <CircularProgress color="secondary" />
    </Box>
  ) : (
    <Button variant="outlined" color="secondary" onClick={onClick}>
      {label}
    </Button>
  )
}
