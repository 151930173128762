import { MaterialHelper } from "../../components/ifc-displayer/helpers/MaterialHelper"

function numberToWord(num: number): string {
  const ones = [
    "",
    " one",
    " two",
    " three",
    " four",
    " five",
    " six",
    " seven",
    " eight",
    " nine",
    " ten",
    " eleven",
    " twelve",
    " thirteen",
    " fourteen",
    " fifteen",
    " sixteen",
    " seventeen",
    " eighteen",
    " nineteen",
  ]
  const tens = ["", "", " twenty", " thirty", " forty", " fifty", " sixty", " seventy", " eighty", " ninety"]
  const hundred = " hundred"
  let output = ""
  const numString = num.toString()

  if (num === 0) {
    return "dontAddBigSufix"
  }
  //the case of 10, 11, 12 ,13, .... 19
  if (num < 20) {
    output = ones[num]
    return output
  }

  //100 and more
  if (numString.length === 3) {
    output = ones[parseInt(numString.charAt(0), 10)] + hundred
    output += tens[parseInt(numString.charAt(1), 10)]
    output += ones[parseInt(numString.charAt(2), 10)]
    return output
  }

  output += tens[parseInt(numString.charAt(0), 10)]
  output += ones[parseInt(numString.charAt(1), 10)]

  return output
}

export function generateColor(name: string, occurrence: string): string {
  const toGenerateName = `${name}${numberToWord(parseInt(occurrence, 10))}` // TODO: Why do we need to convert the occurrence to a number ?

  let currentColor = "#"
  let hash = 0

  let i
  /* eslint-disable no-bitwise */
  for (i = 0; i < toGenerateName.length; i += 1) {
    hash = toGenerateName.charCodeAt(i) + ((hash << 5) - hash)
  }
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    const valueString = value.toString(16)
    currentColor += valueString.substring(valueString.length - 2)
  }

  return MaterialHelper.fixColor(`${currentColor}`)
}

export function lightenColor(hex1: string, opacity: number): string {
  // Enlever le '#' si présent
  const hex = hex1.replace("#", "")

  // Convertir le hex en composantes RGB
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  // Mélanger avec du blanc
  // const newR = Math.min(255, Math.floor(r + (255 - r) * factor))
  // const newG = Math.min(255, Math.floor(g + (255 - g) * factor))
  // const newB = Math.min(255, Math.floor(b + (255 - b) * factor))

  // Retourner la nouvelle couleur hex
  // return `#${newR.toString(16).padStart(2, "0")}${newG.toString(16).padStart(2, "0")}${newB.toString(16).padStart(2, "0")}`
  // Retourner la nouvelle couleur rgba
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

export function interpolateColor(color1: string, color2: string, factor = 0.5): string {
  // Convertit les couleurs hex en { r, g, b }
  const c1 = hexToRgb(color1)
  const c2 = hexToRgb(color2)

  // Interpolation linéaire de chaque canal
  const r = Math.round(c1.r + factor * (c2.r - c1.r))
  const g = Math.round(c1.g + factor * (c2.g - c1.g))
  const b = Math.round(c1.b + factor * (c2.b - c1.b))

  // Retour en format hex
  return rgbToHex(r, g, b)
}

function hexToRgb(hex: string): { r: number; g: number; b: number } {
  // Retire éventuellement le "#"
  const cleanHex = hex.replace(/^#/, "")
  // Cas hex court "FFF" => "FFFFFF"
  const fullHex =
    cleanHex.length === 3
      ? cleanHex
          .split("")
          .map((c) => c + c)
          .join("")
      : cleanHex

  const num = parseInt(fullHex, 16)
  return {
    r: (num >> 16) & 255,
    g: (num >> 8) & 255,
    b: num & 255,
  }
}

function rgbToHex(r: number, g: number, b: number): string {
  const toHex = (c: number): string => c.toString(16).padStart(2, "0")
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}
