import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined"
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined"
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined"
import { Box, Button, Grid, Typography } from "@mui/material"
import React, { useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ValidateButton } from "../../../../components/buttons/ValidateButton/ValidateButton"
import { TabEnum } from "../../../../components/tabs/tabs"
import { BodyTypography } from "../../../../components/typography/body-typography"
import { pagesUrl } from "../../../../core/appConstants"
import { BSInputContext } from "../../../../core/context/beem-shot/BSInput/BSInputContext"
import { BSVariantContext } from "../../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { getLabelText, SeuilEnum } from "../../../../core/enum/beem-shot/SeuilEnum"
import { CalculStatusEnum } from "../../../../core/enum/calculStatusEnum"
import { formatToFrenchWithHours } from "../../../../core/services/date-service"
import { displayNumber } from "../../../../core/services/helper-service"
import { resolveUrl } from "../../../../core/services/http-service"
import { TitleCalculTypography } from "./TitleCalculTypography"
import { WaitingCalculationCard } from "./cards/WaitingCalculationCard"
import { BSVariantResultContext } from "../../../../core/context/beem-shot/BSVariantResult/BSVariantResultContext"

type IProps = {
  handleChange(event: React.SyntheticEvent, newValue: TabEnum): void
  isWritingMode: boolean
}

export function BSCalculCard({ handleChange, isWritingMode }: IProps): React.JSX.Element | null {
  const navigate = useNavigate()
  const { bsProjectId } = useParams()

  const { selectedVariant } = useContext(BSVariantContext)
  const { bsInput } = useContext(BSInputContext)
  const { bsVariantResult } = useContext(BSVariantResultContext)

  function getDifference(seuil: SeuilEnum): number {
    switch (seuil) {
      case SeuilEnum.INVALIDE:
      case SeuilEnum.SEUIL_2022:
        return selectedVariant?.diffIc2022 ?? 0.0
      case SeuilEnum.SEUIL_2025:
        return selectedVariant?.diffIc2025 ?? 0.0
      case SeuilEnum.SEUIL_2028:
        return selectedVariant?.diffIc2028 ?? 0.0
      case SeuilEnum.SEUIL_2031:
        return selectedVariant?.diffIc2031 ?? 0.0
      default:
        return 0.0
    }
  }

  switch (selectedVariant?.calculStatus) {
    case CalculStatusEnum.WAITING_FOR_MODEL:
      return (
        <Box>
          <TitleCalculTypography label="Calcul de l'impact carbone" />
          <Box
            display="flex"
            justifyContent="center"
            borderRadius={2}
            flexDirection="column"
            alignItems="center"
            alignContent="center"
            m={3}
            p={3}
            gap={[0, 2]}>
            <AccessTimeOutlinedIcon fontSize="large" />
            <BodyTypography label="Pas encore de maquette !" textAlign="center" />
            <BodyTypography
              label="Il manque la maquette numérique pour configurer les informations obligatoires à renseigner. Vous pourrez ainsi calculer le bilan de l'impact carbone."
              textAlign="center"
            />
          </Box>
        </Box>
      )
    case CalculStatusEnum.WAITING_FOR_CONFIGURATION:
      return (
        <>
          <TitleCalculTypography label="Calcul de l'impact carbone" />
          <Box
            display="flex"
            justifyContent="center"
            borderRadius={2}
            flexDirection="column"
            alignItems="center"
            alignContent="center"
            m={3}
            pt={3}
            gap={[0, 2]}
            sx={{ background: "#fff" }}>
            <EmojiObjectsOutlinedIcon fontSize="large" />
            <BodyTypography
              label="La maquette numérique a bien été importée. Nous vous invitons à configurer les informations obligatoires à renseigner pour le calcul de l'impact carbone de votre projet."
              textAlign="center"
            />
            {isWritingMode && (
              <ValidateButton
                label="Configurer et calculer l'impact carbone"
                onClick={() =>
                  navigate(resolveUrl(pagesUrl.BEEM_SHOT_CALCUL, [bsProjectId, selectedVariant?.id, bsInput?.bsBimModelId]))
                }
              />
            )}
          </Box>
        </>
      )
    case CalculStatusEnum.CALCUL_IN_PROGRESS:
      return <WaitingCalculationCard />
    case CalculStatusEnum.READY:
      return (
        <>
          <TitleCalculTypography label="Bilan de l'impact carbone" />
          <Box display="flex" borderRadius={2} flexDirection="column" gap={[0, 2]}>
            <Grid container pt={2}>
              <Grid item xs={8}>
                <Typography variant="body1" sx={{ p: 0 }}>
                  Ic contruction
                </Typography>
                <Box display="flex" flexDirection="row" alignItems="end" alignContent="baseline" columnGap={2}>
                  <Typography variant="h3">{displayNumber(selectedVariant.lastImpactCarbone) ?? 0.0}</Typography>
                  <Typography>kgeqCO2/m²Sref</Typography>
                </Box>
              </Grid>

              <Grid item xs={1} />

              <Grid
                item
                xs={3}
                borderRadius={3}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                p={2}
                sx={{ backgroundColor: "#F5F8F8" }}>
                <Typography variant="body2" sx={{ color: "#718491" }}>
                  Calculé le
                </Typography>
                <Typography>{formatToFrenchWithHours(bsVariantResult?.lastModifiedDate)}</Typography>
              </Grid>
            </Grid>

            <Box
              display="inline-flex"
              flexDirection="row"
              alignContent="center"
              alignItems="center"
              columnGap={1}
              justifyContent="space-between"
              px={2}
              borderRadius={3}
              sx={{
                background: selectedVariant.higherSeuilValide === SeuilEnum.INVALIDE ? "#CD0808" : "#009069",
                width: "fit-content",
              }}>
              {selectedVariant.higherSeuilValide === SeuilEnum.INVALIDE ? (
                <ReportProblemOutlinedIcon sx={{ color: "white" }} />
              ) : (
                <CheckOutlinedIcon sx={{ color: "white" }} />
              )}
              <Typography variant="subtitle2" color="white">
                {getLabelText(selectedVariant.higherSeuilValide)}
              </Typography>
              <Typography variant="body2" color="white">
                {`${selectedVariant.higherSeuilValide === SeuilEnum.INVALIDE ? "+" : ""}   ${getDifference(
                  selectedVariant.higherSeuilValide
                ).toFixed(2)}%`}
              </Typography>
            </Box>

            <Grid container>
              <Grid item xs={8} display="flex" alignItems="center">
                <Typography
                  onClick={(e) => {
                    if (handleChange) {
                      handleChange(e, TabEnum.TABLEAU_DE_BORD)
                    }
                  }}
                  textTransform="none"
                  sx={{
                    fontSize: "14px",
                    color: "#070F27",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}>
                  Voir le tableau de bord
                </Typography>
              </Grid>
              {isWritingMode && (
                <Grid
                  item
                  xs={4}
                  display="flex"
                  justifyContent="right
                ">
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate(
                        resolveUrl(pagesUrl.BEEM_SHOT_CALCUL, [bsProjectId, selectedVariant?.id, bsInput?.bsBimModelId], {
                          from: pagesUrl.BEEM_SHOT_VARIANTS_DETAIL,
                        })
                      )
                    }}>
                    Mettre à jour
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      )
    default:
      return null
  }
}
