import { Box, CircularProgress, Grid } from "@mui/material"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CustomBottomNavigation } from "../../../../components/buttons/navigate-button/CustomBottomNavigation"
import { pagesUrl } from "../../../../core/appConstants"
import { BSBimModelContext } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSProjectContext } from "../../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { resolveUrl } from "../../../../core/services/http-service"
import { useBSDashboard } from "../../../../core/hooks/beem-shot/useBSDashboard"
import { BSProjectResult } from "../../../../core/dto/beem-shot/BSProjectResult/BSProjectResult"
import { BSMaterialResult } from "../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSModelFileContextProvider } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { DashboardButton } from "../../../../components/charts/components/DashboardButton"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { downloadPDF, saveAsFile } from "../../../../core/services/helper-service"
import { toCsvString } from "../../../../core/services/csv-service"
import { BSDashboardTabEnum } from "./components/BSDashboardTabEnum"
import { BSIcConstructionTab } from "./components/BSIcConstructionTab/BSIcConstructionTab"
import { BSBiosourceTab } from "./components/BSBiosourceTab/BSBiosourceTab"
import { BSVariantResultContext } from "../../../../core/context/beem-shot/BSVariantResult/BSVariantResultContext"
import { DashboardButtonSecondary } from "../../../../components/charts/components/DashboardButtonSecondary"

export function BSDashboard(): React.JSX.Element {
  return (
    <BSModelFileContextProvider>
      <BSDashboardCore />
    </BSModelFileContextProvider>
  )
}

function BSDashboardCore(): React.JSX.Element {
  const navigate = useNavigate()
  const openErrorSnackbar = useContext(ErrorContext)
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)
  const { bsBimModel } = useContext(BSBimModelContext)
  const { bsVariantResult } = useContext(BSVariantResultContext)

  const { getBSProjectResult, getBSMaterialResultList } = useBSDashboard()

  const dashboardIcConstructionRef = useRef<HTMLDivElement | null>(null)
  const dashboardBiosourceRef = useRef<HTMLDivElement | null>(null)
  const [bsProjectResult, setBSProjectResult] = useState<BSProjectResult | undefined>()
  const [allBSMaterialResult, setAllBSMaterialResult] = useState<BSMaterialResult[] | undefined>()
  const [tab, setTab] = useState<BSDashboardTabEnum>(BSDashboardTabEnum.ICCONSTRUCTION)
  const [isDownloadingPdf, setIsDownloadingPdf] = useState<boolean>(false)
  const [isDownloadingCsv, setIsDownloadingCsv] = useState<boolean>(false)

  useEffect(() => {
    if (bsProject?.id) {
      getBSProjectResult(bsProject.id).then((response) => {
        setBSProjectResult(response)
      })
    }
  }, [bsProject?.id, getBSProjectResult])

  useEffect(() => {
    if (selectedVariant?.id) {
      getBSMaterialResultList(selectedVariant?.id).then((response) => {
        const bsMaterialResults = response.filter(
          (bsMaterialResult) =>
            bsMaterialResult.re2020CarbonImpactPerSurface < -0.05 || bsMaterialResult.re2020CarbonImpactPerSurface > 0.05
        )

        setAllBSMaterialResult(bsMaterialResults)
      })
    }
  }, [getBSMaterialResultList, selectedVariant?.id])

  const handleDownloadPdf: () => void = useCallback(() => {
    const pdfName = `${bsProjectResult?.projectName}-${bsVariantResult?.variantName}`
    if (tab === BSDashboardTabEnum.ICCONSTRUCTION && dashboardIcConstructionRef.current) {
      setIsDownloadingPdf(true)
      downloadPDF(dashboardIcConstructionRef.current, pdfName).finally(() => {
        setIsDownloadingPdf(false)
      })
    } else if (tab === BSDashboardTabEnum.BIOSOURCE && dashboardBiosourceRef.current) {
      setIsDownloadingPdf(true)
      downloadPDF(dashboardBiosourceRef.current, pdfName).finally(() => {
        setIsDownloadingPdf(false)
      })
    } else {
      openErrorSnackbar(new Error("Erreur: impossible de récupérer le tableau de bord"))
    }
  }, [bsProjectResult?.projectName, bsVariantResult?.variantName, openErrorSnackbar, tab])

  const handleDownloadCsv: () => void = useCallback(() => {
    const columns: (keyof BSMaterialResult)[] = [
      "bsItemLabel",
      "codeOccurrence",
      "bsMacroComponentLabel",
      "lotLabel",
      "sousLotLabel",
      "nomProduit",
      "ficheId",
      "declarationType",
      "quantity",
      "ficheUniteLabel",
      "re2020CarbonImpactPerSurface",
    ]

    const columnNames: string[] = [
      "Item",
      "Provenance",
      "Macro composant",
      "Lot",
      "Sous lot",
      "Nom du produit",
      "Fiche Id",
      "Type de déclaration",
      "Quantité",
      "Unité",
      "Impact carbone (/m²Sref)",
    ]

    const fileName = `${bsProjectResult?.projectName}-${bsVariantResult?.variantName}.csv`

    if (allBSMaterialResult) {
      setIsDownloadingCsv(true)
      const csvContent = toCsvString(allBSMaterialResult, columns, columnNames)
      const blob = new Blob([`\uFEFF${csvContent}`], { type: "text/csv;charset=utf-8;" }) // BOM ajouté pour Excel
      saveAsFile(blob, fileName)
      setIsDownloadingCsv(false)
    } else {
      openErrorSnackbar(new Error("Les données ne sont pas prêtes à être téléchargées"))
    }
  }, [allBSMaterialResult, bsProjectResult?.projectName, bsVariantResult?.variantName, openErrorSnackbar])

  const switchToICConstruction: () => void = useCallback(() => {
    setTab(BSDashboardTabEnum.ICCONSTRUCTION)
  }, [])

  const switchToBiosource: () => void = useCallback(() => {
    setTab(BSDashboardTabEnum.BIOSOURCE)
  }, [])

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      sx={{ userSelect: "none" }}>
      {bsProjectResult && bsVariantResult && allBSMaterialResult ? (
        <>
          <Grid container columnSpacing={4} rowGap={2} sx={{ pt: 2 }}>
            {/* Top button */}
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <DashboardButton
                  label="IcConstruction"
                  onClick={switchToICConstruction}
                  variant={tab === BSDashboardTabEnum.ICCONSTRUCTION ? "contained" : "outlined"}
                />
                <DashboardButton
                  label="Biosourcé"
                  onClick={switchToBiosource}
                  variant={tab === BSDashboardTabEnum.BIOSOURCE ? "contained" : "outlined"}
                />
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <DashboardButtonSecondary label="Export PDF" isSubmitting={isDownloadingPdf} onClick={handleDownloadPdf} />
                <DashboardButtonSecondary
                  label="Télécharger Excel"
                  isSubmitting={isDownloadingCsv}
                  onClick={handleDownloadCsv}
                />
              </Box>
            </Grid>
          </Grid>

          {tab === BSDashboardTabEnum.ICCONSTRUCTION ? (
            <BSIcConstructionTab
              bsProjectResult={bsProjectResult}
              bsVariantResult={bsVariantResult}
              allBSMaterialResult={allBSMaterialResult}
              dashboardRef={dashboardIcConstructionRef}
            />
          ) : (
            <BSBiosourceTab
              bsVariantResult={bsVariantResult}
              allBSMaterialResult={allBSMaterialResult}
              dashboardRef={dashboardBiosourceRef}
            />
          )}
          <Box sx={{ height: "1000px" }} />
        </>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" alignContent="center" height="60vh" width="100%">
          <CircularProgress />
        </Box>
      )}
      <CustomBottomNavigation
        actionButton={() =>
          navigate(resolveUrl(pagesUrl.BEEM_SHOT_CALCUL, [bsProject?.id, selectedVariant?.id, bsBimModel?.id]))
        }
        actionLabel="Mettre à jour le calcul"
      />
    </Box>
  )
}
