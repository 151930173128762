import React, { useCallback, useContext, useMemo, useState } from "react"
import { Children } from "../../../../../../../components/miscellianous/children"
import { BSItem } from "../../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { BSMaterialResultFormDto } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResultFormDto"
import { SelectionContext } from "../../context/SelectionContext"
import { BSUpdateMaterialResultDialog } from "./BSCustomMaterialDialog"

export const BSUpdateMaterialResultDialogContext = React.createContext<BSUpdateMaterialDialogStore>(
  {} as BSUpdateMaterialDialogStore
)

export function BSUpdateMaterialResultDialogContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const { selectedBSItem, setSelectedBSItem, setSelectedBSMaterialResult } = useContext(SelectionContext)

  const onClose = useCallback((): void => {
    setIsOpen(false)
  }, [])

  const openDialog = useCallback(
    (newBSItem: BSItem | undefined, bsMaterialResult: BSMaterialResultFormDto | undefined): void => {
      if (newBSItem) {
        setSelectedBSItem(newBSItem)
        setSelectedBSMaterialResult(bsMaterialResult)
        setIsOpen(true)
      }
    },
    [setSelectedBSItem, setSelectedBSMaterialResult]
  )

  const customMaterialDialogStore = useMemo(() => ({ openCustomMaterialDialog: openDialog }), [openDialog])

  return (
    <BSUpdateMaterialResultDialogContext.Provider value={customMaterialDialogStore}>
      {selectedBSItem && <BSUpdateMaterialResultDialog isOpen={isOpen} handleClose={onClose} />}
      {children}
    </BSUpdateMaterialResultDialogContext.Provider>
  )
}

export type BSUpdateMaterialDialogStore = {
  openCustomMaterialDialog(bsItem: BSItem | undefined, bsMaterialResult: BSMaterialResultFormDto | undefined): void
}
