import React, { useMemo, useState } from "react"
import { Children } from "../../../../../../components/miscellianous/children"
import { BSItem } from "../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { BSMaterialResultFormDto } from "../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResultFormDto"
import { TypeMaterialEnum } from "../../../../../../core/enum/typeMaterialEnum"
import { UnitEnum } from "../../../../../../core/enum/unitEnum"

export interface FicheValue {
  ficheId: string | undefined
  ficheName: string | undefined
  typeMaterial: TypeMaterialEnum | undefined
  coefficient: number
  newQuantity: number
  unit: UnitEnum
}

export const SelectionContext = React.createContext<SelectionStore>({} as SelectionStore)

export function SelectionContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [selectedBSItem, setSelectedBSItem] = useState<BSItem | undefined>()
  const [selectedBSMaterialResult, setSelectedBSMaterialResult] = useState<BSMaterialResultFormDto | undefined>()
  const [replaceAll, setReplaceAll] = useState<boolean>(false)
  const [replaceAllCoefficient, setReplaceAllCoefficient] = useState<boolean>(false)

  const selectionStore = useMemo(
    () => ({
      isSubmitting,
      selectedBSItem,
      selectedBSMaterialResult,
      replaceAll,
      replaceAllCoefficient,
      setReplaceAllCoefficient,
      setReplaceAll,
      setSelectedBSMaterialResult,
      setSelectedBSItem,
      setIsSubmitting,
    }),
    [isSubmitting, replaceAll, selectedBSItem, selectedBSMaterialResult, replaceAllCoefficient, setReplaceAllCoefficient]
  )
  return <SelectionContext.Provider value={selectionStore}>{children}</SelectionContext.Provider>
}

export type SelectionStore = {
  selectedBSItem: BSItem | undefined
  selectedBSMaterialResult: BSMaterialResultFormDto | undefined
  isSubmitting: boolean
  replaceAll: boolean
  replaceAllCoefficient: boolean
  setReplaceAllCoefficient: React.Dispatch<React.SetStateAction<boolean>>
  setReplaceAll: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedBSItem: React.Dispatch<React.SetStateAction<BSItem | undefined>>
  setSelectedBSMaterialResult: React.Dispatch<React.SetStateAction<BSMaterialResultFormDto | undefined>>
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
}
