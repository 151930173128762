import { Checkbox, FormControlLabel } from "@mui/material"
import React, { ChangeEvent } from "react"

interface IProps {
  id: string
  label: string
  checked: boolean
  disabled?: boolean
  handleChange?(event: ChangeEvent<HTMLInputElement>): void
}

export function CheckboxInput({ id, label, checked, disabled = false, handleChange }: Readonly<IProps>): React.JSX.Element {
  return (
    <FormControlLabel
      onClick={(e) => {
        e.stopPropagation()
      }}
      control={<Checkbox id={id} checked={checked} disabled={disabled} onChange={handleChange} />}
      label={label}
    />
  )
}
