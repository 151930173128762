import { Box, Tab, Tabs } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { BSUploadModelTab } from "./tabs/BSUploadModelTab"
import { BSUploadInvitationTab } from "./tabs/BSUploadInvitationTab"
import { resolveUrl } from "../../../../../core/services/http-service"
import { pagesUrl } from "../../../../../core/appConstants"
import { AppNavBarContext, NavbarTypeEnum } from "../../../../../core/context/nav-bar/AppNavBarContext"
import { BSModelFileContext } from "../../../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { CustomBottomNavigation } from "../../../../../components/buttons/navigate-button/CustomBottomNavigation"
import { BimModelFormContext } from "../../context/BimModelFormContext"
import { TabEnum } from "../../../../../components/tabs/tabs"
import { BSModelInvitationContext } from "../../../../../core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider"

interface IProps {
  goToControlStep(): void
}

export function BSBimModelForm({ goToControlStep }: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const { bsProjectId, bsBimModelId } = useParams()
  const [searchParams] = useSearchParams()

  const { setTypeNavBar, setPreviousUrl, setStepHeader, setActionOnBackButton } = useContext(AppNavBarContext)
  const { isModelFileLoading } = useContext(BSModelFileContext)
  const { isModelUpdateRef, isSurfaceUpdated, handleSubmitBSModelForm, validateForm } = useContext(BimModelFormContext)
  const { isModelInvitationLoading } = useContext(BSModelInvitationContext)

  const [selectedTab, setSelectedTab] = useState<number>(0)

  const navigateBack = useCallback(() => {
    const bsVariantId = searchParams.get("variantId")

    if (bsVariantId) {
      navigate(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_CHOICE, [bsProjectId, bsVariantId]))
    } else if (!location.state?.from.endsWith("/detail")) {
      navigate(resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProjectId], { tab: TabEnum.BS_BIM_MODEL_POOL }))
    } else {
      navigate(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_DETAIL_PAGE, [bsProjectId, bsBimModelId]))
    }
  }, [searchParams, location.state?.from, navigate, bsProjectId, bsBimModelId])

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.STEP_HEADER)
    setActionOnBackButton(navigateBack)
    setStepHeader(0)
  }, [setTypeNavBar, setPreviousUrl, setActionOnBackButton, navigateBack, setStepHeader])

  const cancelButtonAction = useCallback(() => {
    navigateBack()
  }, [navigateBack])

  function handleChangeTabs(event: React.SyntheticEvent, newValue: number): void {
    setSelectedTab(newValue)
  }

  const getBottomButtonLabel = useCallback(() => {
    if (isModelUpdateRef.current) {
      return "Suivant"
    } else {
      return "Valider"
    }
  }, [isModelUpdateRef])

  const onNextButton = useCallback(() => {
    if (selectedTab === 0) {
      const isFormValid = validateForm()
      if (!isFormValid) {
        // If the form is invalid, stay on the page
        return
      }

      if (isModelUpdateRef.current) {
        // Model has been modified, go to control
        goToControlStep()
      } else if (!isModelUpdateRef.current && isSurfaceUpdated.current) {
        // Model has not been modified but surface were, submit directly
        handleSubmitBSModelForm().then(() => {
          navigateBack()
        })
      } else if (!isModelUpdateRef.current && !isSurfaceUpdated.current) {
        // Nothing changed. TODO: add navigate back
        navigateBack()
      }
    } else {
      // Invitation tab, so should handle submit invitation
    }
  }, [goToControlStep, handleSubmitBSModelForm, isModelUpdateRef, isSurfaceUpdated, navigateBack, selectedTab, validateForm])

  return (
    <>
      <Box display="flex" justifyContent="center" width="50%">
        <Tabs
          value={selectedTab}
          onChange={handleChangeTabs}
          aria-label="Tabs to choose between importing yourself or send invitation to someone"
          variant="fullWidth"
          TabIndicatorProps={{ style: { display: "none" } }}
          textColor="inherit"
          sx={{ my: 3, backgroundColor: "white", borderRadius: 2, height: "1vh" }}>
          <Tab
            label="Je paramètre et importe la maquette"
            id="tab-0"
            sx={{ backgroundColor: selectedTab === 0 ? "#E6F0FB" : "", borderRadius: "15px", height: "1vh" }}
          />
          <Tab
            label="J’invite un acteur à paramétrer et importer"
            id="tab-1"
            sx={{
              backgroundColor: selectedTab === 1 ? "#E6F0FB" : "",
              borderRadius: "15px",
              height: "1vh",
              cursor: "pointer",
              "&.Mui-disabled": {
                pointerEvents: "auto",
                cursor: "not-allowed",
              },
            }}
          />
        </Tabs>
      </Box>

      {selectedTab === 0 && (
        <>
          <BSUploadModelTab />
          <CustomBottomNavigation
            actionLabel={getBottomButtonLabel()}
            actionButton={onNextButton}
            cancelLabel="Annuler"
            cancelAction={cancelButtonAction}
            isSubmitting={isModelFileLoading || isModelInvitationLoading}
          />
        </>
      )}

      {selectedTab === 1 && (
        <>
          <BSUploadInvitationTab />
          <CustomBottomNavigation
            actionLabel={"Envoyer l'invitation"}
            formId="send-invitation-form-id"
            cancelLabel="Annuler"
            cancelAction={cancelButtonAction}
            isSubmitting={isModelFileLoading || isModelInvitationLoading}
          />
        </>
      )}
    </>
  )
}
