import React, { useCallback, useContext } from "react"
import { BSMaterialResult } from "../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSFilterContext, BSFilterType } from "../BSFilterContext"
import { BaseFilterSelect } from "./BaseFilterSelect"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
}

export function FilterCodeOccurrenceSelect({ allBSMaterialResult }: Readonly<IProps>): React.JSX.Element {
  const { applyLotFilter, applyItemFilter } = useContext(BSFilterContext)

  const applyFilters = useCallback(
    (_bsMaterialList: BSMaterialResult[]): BSMaterialResult[] => applyLotFilter(applyItemFilter(_bsMaterialList)),
    [applyLotFilter, applyItemFilter]
  )

  return (
    <BaseFilterSelect
      allBSMaterialResult={allBSMaterialResult}
      filterType={BSFilterType.CODE_OCCURRENCE}
      applyFilters={applyFilters}
      label="Chercher un code ACV"
      emptyLabel="Estimé"
      valueFieldName="codeOccurrence"
      labelFieldName="codeOccurrence"
    />
  )
}
