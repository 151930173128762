import { Box, Grid } from "@mui/material"
import React, { useContext } from "react"
import { Link } from "react-router-dom"
import { pagesUrl } from "../../../core/appConstants"
import { RseeProjectContext } from "../../../core/context/beem-pilot/rsee/rsee-project-context"
import { RseeDocument } from "../../../core/dto/rsee/rsee-document"
import { formatToFrench } from "../../../core/services/date-service"
import { resolveUrl } from "../../../core/services/http-service"
import { DefaultTypography } from "../../typography/default-typography"
import { RseeProjectCardSeuil } from "./rsee-project-seuil-card"
import { IconFileAnalytics } from "../../custom-icons/icon-file-analytics"

type IProps = {
  rseeDocument: RseeDocument
}

export function RseeDocumentCard({ rseeDocument }: Readonly<IProps>): React.JSX.Element {
  const { rseeProject } = useContext(RseeProjectContext)

  function getNavigateUrl(): string {
    return resolveUrl(pagesUrl.BEEM_PILOT_DOCUMENT_DASHBOARD, [rseeProject?.id, rseeDocument.id])
  }

  return (
    <Link to={getNavigateUrl()} style={{ width: "100%", minHeight: 100, textDecoration: "none", color: "inherit" }}>
      <Grid
        container
        rowGap={1}
        width="100%"
        sx={{
          backgroundColor: "#fff",
          borderRadius: 3,
          p: 3,
          boxShadow: "2px 4px 20px 0px rgba(0, 0, 0, 0.05)",
        }}>
        <Grid container item display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Grid item xs={2}>
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              borderRadius={2}
              p={1}
              px={3}
              sx={{ background: "#E6F0FB" }}>
              <IconFileAnalytics style={{ fontSize: "30px", color: "#678ECA" }} />
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="left" alignItems="center">
          <DefaultTypography
            label={`${rseeDocument.filename}`}
            fontSize="16px"
            lineHeight="24px"
            fontWeight={600}
            wordWrap="break-word"
          />
        </Grid>

        <Grid item xs={12} container flexGrow={1} display="flex" justifyContent="space-between" alignItems="center">
          <Grid
            item
            xs={6}
            display="flex"
            flexDirection="column"
            sx={{ border: 2, borderColor: "#F5F8F8", borderRadius: 2, p: 1 }}>
            <DefaultTypography
              label="Étude thermique"
              fontSize="11px"
              lineHeight="26.5px"
              fontWeight={400}
              color="#8398A6"
            />
            <DefaultTypography label={rseeDocument.labelDateRset} fontSize="12px" lineHeight="18px" fontWeight={400} />
          </Grid>

          <Grid
            item
            xs={6}
            display="flex"
            flexDirection="column"
            sx={{ border: 2, borderColor: "#F5F8F8", borderRadius: 2, p: 1 }}>
            <DefaultTypography
              label="Étude environnementale"
              fontSize="11px"
              lineHeight="26.5px"
              fontWeight={400}
              color="#8398A6"
            />
            <DefaultTypography label={rseeDocument.labelDateEtude} fontSize="12px" lineHeight="18px" fontWeight={400} />
          </Grid>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" minHeight={50} borderRadius={2}>
          <RseeProjectCardSeuil isConforme={rseeDocument.compliant} height={50} />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <DefaultTypography
            label={`Mise à jour le ${formatToFrench(rseeDocument.lastModifiedDate)}`}
            fontSize="12px"
            lineHeight="18px"
            fontWeight={400}
            color="#8398A6"
          />
        </Grid>
      </Grid>
    </Link>
  )
}
