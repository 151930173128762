import { CircularProgress, Grid, Grow, Switch, Typography } from "@mui/material"
import React, { ChangeEvent, useContext, useState } from "react"
import { Box } from "@mui/system"
import { MissingInfoCode } from "../../../../components/MissingInfoCode"
import WarningDialog from "../../../../../../components/dialog/warning-dialog"
import { FileInput } from "../../../../../../components/inputs/file-input/FileInput"
import { TextInput } from "../../../../../../components/inputs/text-input/text-input"
import { DefaultTypography } from "../../../../../../components/typography/default-typography"
import { NumberInput } from "../../../../../../components/inputs/number-input/NumberInput"
import { BimModelFormContext, bsModelFileInputFieldName } from "../../../context/BimModelFormContext"

export function BSUploadModelTab(): React.JSX.Element {
  const {
    bsModelForm,
    isFileSelectedReady,
    errorsBSModelForm,
    selectFile,
    fileErrors,
    bsModelFileSelected,
    handleChangeBSModelForm,
    isModelUpdateRef,
  } = useContext(BimModelFormContext)

  const [progress, setProgress] = useState<number>(100)
  const [openWarning, setOpenWarning] = useState<boolean>(false)

  function onChangeFile(modelFile: File): void {
    // The number 125829120 represents 120 megabytes (MB) in bytes.
    // This is calculated as follows: 120 MB * 1024 KB/MB * 1024 Bytes/KB = 125829120 Bytes.
    if (modelFile.size > 125829120) {
      setOpenWarning(true)
    }

    selectFile(modelFile)
    isModelUpdateRef.current = true
  }

  function onChangeSwitch(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    const e: ChangeEvent<HTMLInputElement> = {
      target: {
        id: event.target.id,
        checked,
        type: "checkbox",
      },
    } as ChangeEvent<HTMLInputElement>
    handleChangeBSModelForm(e)
  }

  return (
    <Grid container columnSpacing={2} justifyContent="center" alignItems="center" sx={{ display: "flex" }}>
      <Grid item xs={4} columnGap={2} sx={{ display: "flex", height: "100%" }}>
        <MissingInfoCode />
      </Grid>
      <Grid item xs={6} columnGap={2} justifyContent="center" alignItems="center" sx={{ display: "flex" }}>
        <WarningDialog
          title="Attention"
          contentText={
            <>
              <p>
                Vous avez chargé une maquette numérique IFC de <strong>{bsModelFileSelected?.size ?? 1 / 1048576} Mo</strong>
                . Le temps de chargement risque d'être plus long. Pour de meilleures performances, nous recommandons
                d'utiliser des maquettes numériques IFC de moins de <strong>120 Mo</strong>.
              </p>
              <p>
                <em>Astuce :</em> Pour réduire la taille de votre maquette IFC dans des logiciels tels que{" "}
                <strong>Revit</strong> ou <strong>ArchiCAD</strong>, n'exportez pas les éléments non essentiels et simplifiez
                la géométrie des objets en utilisant des niveaux de détail appropriés (<strong>LOD 100</strong> ou{" "}
                <strong>200</strong> suffisant).
              </p>
            </>
          }
          handleClose={() => setOpenWarning(false)}
          open={openWarning}
        />
        <Grow in>
          {bsModelForm && (
            <Box
              boxShadow="2px 4px 20px 0px rgba(0, 0, 0, 0.05)"
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                borderRadius: "25px",
                p: 3,
              }}>
              <Box
                sx={{
                  minHeight: "300px", // File input should be around 300x300px. Useful for Circular progress
                  minWidth: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {isFileSelectedReady ? (
                  <FileInput
                    fieldName={bsModelFileInputFieldName}
                    errors={fileErrors}
                    onChange={onChangeFile}
                    file={bsModelFileSelected}
                    progress={progress}
                    fileType=".ifc,.ifczip"
                  />
                ) : (
                  <CircularProgress />
                )}
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextInput
                    fieldName="version"
                    form={bsModelForm}
                    alternativeValue="1.0"
                    label="Version de maquette"
                    disabled
                    errors={errorsBSModelForm}
                  />
                </Grid>
              </Grid>

              <DefaultTypography label="Renseignez les surfaces en m²" />

              <Grid container spacing={2} py={2}>
                <Grid item xs={4}>
                  <NumberInput
                    variant="outlined"
                    backgroundColor="white"
                    id="surfaceRef"
                    label="Surface utile ou SHAB *"
                    mode="event"
                    handleEventChange={handleChangeBSModelForm}
                    value={bsModelForm.surfaceRef}
                    errors={errorsBSModelForm}
                    unit="m²"
                  />
                </Grid>

                <Grid item xs={4}>
                  <NumberInput
                    variant="outlined"
                    backgroundColor="white"
                    id="surfacePlancher"
                    label="Surface plancher *"
                    mode="event"
                    handleEventChange={handleChangeBSModelForm}
                    value={bsModelForm.surfacePlancher}
                    errors={errorsBSModelForm}
                    unit="m²"
                  />
                </Grid>

                <Grid item xs={4}>
                  <NumberInput
                    variant="outlined"
                    backgroundColor="white"
                    id="empriseAuSol"
                    label="Emprise au sol *"
                    mode="event"
                    handleEventChange={handleChangeBSModelForm}
                    value={bsModelForm.empriseAuSol}
                    errors={errorsBSModelForm}
                    unit="m²"
                  />
                </Grid>
              </Grid>

              <Box display="flex" alignItems="center">
                <Switch id="surfaceCombleB" checked={bsModelForm.surfaceCombleB} onChange={onChangeSwitch} />
                <DefaultTypography label="Le batiment comporte des combles aménagés" />
              </Box>

              {bsModelForm.surfaceCombleB && (
                <Grid container spacing={2} pt={2}>
                  <Grid item xs={5}>
                    <NumberInput
                      variant="outlined"
                      backgroundColor="white"
                      id="surfaceComble"
                      label="Surface des combles aménagés *"
                      mode="event"
                      handleEventChange={handleChangeBSModelForm}
                      value={bsModelForm.surfaceComble}
                      errors={errorsBSModelForm}
                      unit="m²"
                    />
                  </Grid>
                </Grid>
              )}

              <Typography> Tous les champs sont obligatoires.</Typography>
            </Box>
          )}
        </Grow>
      </Grid>
    </Grid>
  )
}
