import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Children } from "../../../../components/miscellianous/children"
import { BSItem } from "../../../dto/beem-shot/BSItem/BSItem"
import { BSItemSubCategory } from "../../../dto/beem-shot/BSItem/BSItemSubCategory"
import { BSItemUpdateDto } from "../../../dto/beem-shot/BSItem/BSItemUpdateDto"
import { CodeExtrait } from "../../../dto/code-extrait/code-extrait"
import { CalculTypeEnum } from "../../../enum/beem-shot/CalculTypeEnum"
import { useBSInput } from "../../../hooks/beem-shot/useBSInput"
import { useBSItem } from "../../../hooks/beem-shot/useBSItem"
import { BSVariantContext } from "../BSVariant/BSVariantContext"
import { CodeExtraitDisplay } from "../../../dto/code-extrait/CodeExtraitDisplay"
import { codeToKey } from "../../../services/code-service"

export interface IForm {
  id: string
  projectId: string
  calculType: CalculTypeEnum
  codeExtrait: CodeExtrait | undefined
  bsMacroComponentIds: Record<string, string | undefined>
  ignored: boolean
}

export function formToDto(form: IForm, type?: string): BSItemUpdateDto {
  return type
    ? {
        id: form.id,
        projectId: form.projectId,
        calculType: CalculTypeEnum[type as keyof typeof CalculTypeEnum],
        codeExtrait: form.codeExtrait,
        bsMacroComponentIds: form.bsMacroComponentIds,
        ignored: form.ignored,
      }
    : {
        id: form.id,
        projectId: form.projectId,
        calculType: form.calculType,
        codeExtrait: form.codeExtrait,
        bsMacroComponentIds: form.bsMacroComponentIds,
        ignored: form.ignored,
      }
}

export function dtoToForm(dto: BSItem | undefined): IForm {
  return dto
    ? {
        id: dto.id,
        projectId: dto.projectId,
        calculType: dto.calculType,
        codeExtrait: dto.codeExtrait,
        bsMacroComponentIds: dto.bsMacroComponents.reduce((acc, x) => {
          acc[x.type.name] = x.id
          return acc
        }, {} as Record<string, string>),
        ignored: dto.ignored,
      }
    : {
        id: "",
        projectId: "",
        calculType: CalculTypeEnum.STANDARD,
        codeExtrait: undefined,
        bsMacroComponentIds: {},
        ignored: false,
      }
}

export const BSItemContext = React.createContext<BSItemStore>({} as BSItemStore)

export function BSItemContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { selectedVariant } = useContext(BSVariantContext)

  const { getBSItems, updateBSItem } = useBSItem()
  const { updateAllUnmodifiedBSItemsForBSInput } = useBSInput()

  const [bsItems, setBSItems] = useState<BSItem[]>([])
  const [bsItemsForSubCategory, setBSItemsForSubCategory] = useState<BSItem[]>([])
  const [selectedBSItem, setSelectedBSItem] = useState<BSItem | undefined>(undefined)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [isSubmittingExtractedCode] = useState<boolean>(false)

  const subCategoriesForCategory = useMemo(() => {
    const acc: Record<string, BSItemSubCategory[]> = {}

    bsItems?.forEach((x: BSItem) => {
      if (!acc[x.category.name]) {
        acc[x.category.name] = []
      }
      acc[x.category.name].push(x.subCategory)
    })

    return acc
  }, [bsItems])

  const updateAllBSItems = useCallback(
    (bsVariantId: string): Promise<void> =>
      updateAllUnmodifiedBSItemsForBSInput(bsVariantId).then((bsItemList) => {
        setBSItems(bsItemList)
      }),
    [updateAllUnmodifiedBSItemsForBSInput]
  )

  const refreshAllBSItems = useCallback((): Promise<void | BSItem[]> => {
    if (selectedVariant?.id) {
      return getBSItems(selectedVariant.id).then((newBSItemList) => {
        setBSItems(newBSItemList)
        return newBSItemList
      })
    } else {
      setBSItems([])
      return Promise.resolve()
    }
  }, [selectedVariant?.id, getBSItems])

  const updateItemList: (bsItemList: BSItem[], updatedItem: BSItem) => BSItem[] = useCallback((bsItemList, updatedItem) => {
    const index = bsItemList.findIndex((item) => item.id === updatedItem.id)
    if (index !== -1) {
      const updatedBsItems = [...bsItemList]
      updatedBsItems[index] = updatedItem
      return updatedBsItems
    } else {
      return bsItemList
    }
  }, [])

  const updateBSItemFunction: (bsItemToUpdate: BSItemUpdateDto) => Promise<any> = useCallback(
    (bsItemToUpdate) => {
      setIsUpdating(true)
      return updateBSItem(bsItemToUpdate)
        .then((newBsItem) => {
          setBSItems((prevBsItems) => updateItemList(prevBsItems, newBsItem))
          setBSItemsForSubCategory((prevBsItems) => updateItemList(prevBsItems, newBsItem))
        })
        .finally(() => setIsUpdating(false))
    },
    [updateBSItem, updateItemList]
  )

  const selectBSItemFromCodeExtrait: (codeExtrait: CodeExtraitDisplay | undefined) => void = useCallback(
    (codeExtrait) => {
      if (codeExtrait) {
        const item = bsItems?.find((bsItem) => {
          if (bsItem.codeExtrait !== undefined) {
            return codeToKey(bsItem.codeExtrait) === codeToKey(codeExtrait)
          } else {
            return false
          }
        })
        setSelectedBSItem(item)
      } else {
        setSelectedBSItem(undefined)
      }
    },
    [bsItems]
  )

  useEffect(() => {
    refreshAllBSItems()
  }, [refreshAllBSItems])

  const bsItemStore = useMemo(
    () => ({
      bsItems,
      setBSItems,
      bsItemsForSubCategory,
      setBSItemsForSubCategory,
      updateBSItemFunction,
      selectedBSItem,
      setSelectedBSItem,
      isUpdating,
      subCategoriesForCategory,
      refreshAllBSItems,
      updateAllBSItems,
      isSubmittingExtractedCode,
      selectBSItemFromCodeExtrait,
    }),
    [
      bsItems,
      bsItemsForSubCategory,
      updateBSItemFunction,
      selectedBSItem,
      isUpdating,
      subCategoriesForCategory,
      refreshAllBSItems,
      updateAllBSItems,
      isSubmittingExtractedCode,
      selectBSItemFromCodeExtrait,
    ]
  )
  return <BSItemContext.Provider value={bsItemStore}>{children}</BSItemContext.Provider>
}

export type BSItemStore = {
  bsItems: BSItem[]
  setBSItems: Dispatch<SetStateAction<BSItem[]>>
  bsItemsForSubCategory: BSItem[]
  setBSItemsForSubCategory: Dispatch<SetStateAction<BSItem[]>>
  selectedBSItem: BSItem | undefined
  setSelectedBSItem: React.Dispatch<React.SetStateAction<BSItem | undefined>>
  isUpdating: boolean
  subCategoriesForCategory: Record<string, BSItemSubCategory[]>
  updateBSItemFunction(bsItemToUpdate: BSItemUpdateDto): Promise<void>
  refreshAllBSItems(): Promise<void | BSItem[]>
  updateAllBSItems(bsInputId: string): Promise<void>
  isSubmittingExtractedCode: boolean
  selectBSItemFromCodeExtrait(codeExtrait: CodeExtraitDisplay | undefined): void
}
