import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { compareDesc } from "date-fns"
import { Children } from "../../../../components/miscellianous/children"
import { BSBimModel } from "../../../dto/beem-shot/BSBimModel/BSBimModel"
import { useBSBimModel } from "../../../hooks/beem-shot/useBSBimModel"

/**
 * Modify the actual list. Does not clone it
 * @param list
 */
function sortByLastModifiedDate(list: BSBimModel[]): void {
  list.sort((a: BSBimModel, b: BSBimModel) => compareDesc(a.lastModifiedDate ?? 0, b.lastModifiedDate ?? 0))
}

export const BSBimModelListContext = React.createContext<BSModelListStore>({} as BSModelListStore)

export function BSModelListContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsProjectId } = useParams()

  const { getAllBimModelByProjectId } = useBSBimModel()

  const [bsBimModelListByProject, setBSBimModelListByProject] = useState<BSBimModel[]>([])

  const refreshBimModelListByProjectId = useCallback(() => {
    if (bsProjectId) {
      return getAllBimModelByProjectId(bsProjectId).then((list) => {
        sortByLastModifiedDate(list)
        setBSBimModelListByProject(list)
      })
    } else {
      return Promise.resolve()
    }
  }, [bsProjectId, getAllBimModelByProjectId])

  useEffect(() => {
    refreshBimModelListByProjectId()
  }, [refreshBimModelListByProjectId])

  const removeBimModelFromList: (bsBimModelId: string) => void = useCallback(
    (bsBimModelId) => {
      setBSBimModelListByProject([...bsBimModelListByProject].filter((bsBimModel) => bsBimModel.id !== bsBimModelId))
    },
    [bsBimModelListByProject]
  )

  const addOrReplaceBimModelInList: (bsBimModel: BSBimModel) => void = useCallback(
    (bsBimModel) => {
      setBSBimModelListByProject((prevList) => {
        const index = prevList.findIndex((model) => model.id === bsBimModel.id)
        let newList: BSBimModel[]
        if (index !== -1) {
          newList = [...prevList]
          newList[index] = bsBimModel
        } else {
          newList = [...prevList, bsBimModel]
        }
        sortByLastModifiedDate(newList)
        return newList
      })
    },
    [setBSBimModelListByProject]
  )

  const bsModelStore: BSModelListStore = useMemo(
    () => ({
      bsBimModelListByProject,
      removeBimModelFromList,
      addOrReplaceBimModelInList,
      refreshBimModelListByProjectId,
    }),
    [addOrReplaceBimModelInList, bsBimModelListByProject, refreshBimModelListByProjectId, removeBimModelFromList]
  )
  return <BSBimModelListContext.Provider value={bsModelStore}>{children}</BSBimModelListContext.Provider>
}

export interface BSModelListStore {
  bsBimModelListByProject: BSBimModel[]
  removeBimModelFromList(bsBimModelId: string): void
  addOrReplaceBimModelInList(bsBimModel: BSBimModel): void
  refreshBimModelListByProjectId(): Promise<void>
}
