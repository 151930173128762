import { Box, CircularProgress, Grid } from "@mui/material"
import React, { FormEvent, useCallback, useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CustomBottomNavigation } from "../../../../../components/buttons/navigate-button/CustomBottomNavigation"
import { BSIfcDisplayer } from "../../../../../components/ifc-displayer/BSIfcDisplayer"
import { ViewerTypes } from "../../../../../components/ifc-displayer/enums/ViewerTypes"
import { ErrorContext } from "../../../../../components/layout/error-snackbar"
import { storeTab, TabEnum, TabPageEnum } from "../../../../../components/tabs/tabs"
import { pagesUrl } from "../../../../../core/appConstants"
import { BSModelFileContext } from "../../../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { BSItemContext } from "../../../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSProjectContext } from "../../../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { CodeReferenceContext } from "../../../../../core/context/code-acv/CodeReferenceContext"
import { CalculStatusEnum } from "../../../../../core/enum/calculStatusEnum"
import { focusOnFormError } from "../../../../../core/hooks/form/use-form"
import { resolveUrl } from "../../../../../core/services/http-service"
import { Questionnaires } from "./Questionnaires"
import { SimplifiedQuestionnaireContext, SimplifiedQuestionnaireProvider } from "./SimplifiedQuestionnaireContext"
import { BSCodesExtraitsContext } from "../../../../../core/context/beem-shot/BSCodeExtrait/BSCodesExtraitsContext"
import { CodeExtraitDisplay } from "../../../../../core/dto/code-extrait/CodeExtraitDisplay"
import { useViewerDisplay } from "../../../../../core/hooks/viewer/useViewerDisplay"

export function BSCalculationForm(): React.JSX.Element {
  return (
    <SimplifiedQuestionnaireProvider>
      <BSCalculationFormCore />
    </SimplifiedQuestionnaireProvider>
  )
}

function BSCalculationFormCore(): React.JSX.Element {
  const navigate = useNavigate()
  const { bsProjectId, bsVariantId } = useParams()

  const openErrorSnackbar = useContext(ErrorContext)
  const { file } = useContext(BSModelFileContext)
  const { bsProject, startEstimating } = useContext(BSProjectContext)
  const { codesExtraits, setCodesExtraits, selectedCodeExtrait, setSelectedCodeExtrait } = useContext(BSCodesExtraitsContext)
  const { selectedVariant, updateCalculStatusState, fetchAllBSVariants } = useContext(BSVariantContext)
  const { updateAllBSItems, selectedBSItem, setSelectedBSItem, bsItemsForSubCategory, selectBSItemFromCodeExtrait } =
    useContext(BSItemContext)
  const { validate, clearOnDestroy, errors } = useContext(SimplifiedQuestionnaireContext)
  const { isCodeAcvLoading } = useContext(CodeReferenceContext)
  const needsToChangeTab = useRef<boolean>(false)
  const needsToStartEstimating = useRef<boolean>(false)
  const isRefreshingBSItems = useRef<boolean>(false)

  const [progress, setProgress] = useState<number>(0)

  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [isCalculating, setIsCalculating] = useState<boolean>(false)

  const { viewer, onChangeModel, handleClickAcv } = useViewerDisplay(
    codesExtraits,
    selectedCodeExtrait,
    setSelectedCodeExtrait
  )

  const selectCodeAcv: (codeExtrait: CodeExtraitDisplay, disableViewerHighlight?: boolean) => CodeExtraitDisplay =
    useCallback(
      (codeExtrait, disableViewerHighlight) => {
        selectBSItemFromCodeExtrait(codeExtrait)
        handleClickAcv(codeExtrait, disableViewerHighlight)
        return codeExtrait
      },
      [handleClickAcv, selectBSItemFromCodeExtrait]
    )

  useEffect(() => setSelectedCodeExtrait(undefined), [setSelectedCodeExtrait])

  useEffect(() => {
    if (progress !== 100) {
      document.body.style.cursor = "wait"
    } else {
      document.body.style.cursor = "default"
    }

    return () => {
      document.body.style.cursor = "default"
    }
  }, [progress])

  useEffect(() => {
    if (selectedCodeExtrait !== undefined && selectedBSItem !== undefined) {
      const ref: any = document.getElementById(selectedBSItem.id)
      if (ref) {
        ref.scrollIntoView({ behavior: "smooth", block: "start" })
      }
    }
  }, [selectedCodeExtrait, selectedBSItem, bsItemsForSubCategory])

  function cancelUpload(): void {
    setCodesExtraits([])
    setProgress(0)
  }

  function cancelAction(): void {
    return navigate(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, bsVariantId]))
  }

  function handleSubmit(event: FormEvent<HTMLFormElement> | FormEvent<HTMLDivElement>): void {
    event.preventDefault()
    submit()
  }

  function submit(): void {
    const isValid = validate()
    if (!bsProject?.id) {
      throw new Error("Pas de projet sélectionnée")
    }
    const projectId: string = bsProject.id
    if (!selectedVariant?.id) {
      throw new Error("Pas de variante sélectionnée")
    }
    const variantId: string = selectedVariant?.id

    if (isValid && !isCalculating) {
      setIsCalculating(true)
      if (isRefreshingBSItems.current) {
        // We don't want to start estimating if bs items are being refreshed because this will cause a "mongo transaction conflict error"
        // But we want the circular progress to be displayed
        needsToStartEstimating.current = true
        return
      }

      clearOnDestroy()
        .then(() => {
          updateCalculStatusState(CalculStatusEnum.WAITING_FOR_CONFIGURATION, variantId)
        })
        .then(() => startEstimating())
        .then(() => {
          fetchAllBSVariants(projectId)
        })
        .then(() => {
          updateAllBSItems(variantId)
        })
        .then(() => {
          storeTab({ tab: TabEnum.TABLEAU_DE_BORD, selectedId: bsVariantId }, TabPageEnum.VARIANT_ACTIVE_TAB)
          navigate(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, variantId]))
        })
        .finally(() => {
          setIsCalculating(false)
        })
        .catch(() => {
          updateCalculStatusState(CalculStatusEnum.WAITING_FOR_CONFIGURATION, variantId)
        })
    } else {
      openErrorSnackbar(new Error("Vous devez corriger un des champs du questionnaire simplifié"))
    }
  }

  function changeTab(event: React.SyntheticEvent, newValue: number): void {
    if (progress === 100) {
      viewer?.manager.subsetsManager.restoreAllSubsetsMaterial()
      viewer?.manager.subsetsManager.changeType(
        newValue === 0 ? ViewerTypes.NO_HIGHLIGHT_CODE : ViewerTypes.CODE_VERIFICATION
      )

      const isFormValid = validate()

      if (!isFormValid) {
        if (newValue === 1 && bsProjectId) {
          openErrorSnackbar(new Error("Un des champs du questionnaire simplifié n'est pas valide"))

          // useRef in futur if problem with DOM
          if (Object.keys(errors).length > 0) {
            focusOnFormError(errors)
          }
        }
      } else if (newValue === 1 && bsVariantId) {
        isRefreshingBSItems.current = true
        clearOnDestroy()
          .then(() => {
            setSelectedTab(newValue)
            setSelectedCodeExtrait(undefined)
            setSelectedBSItem(undefined)
            return updateAllBSItems(bsVariantId)
          })
          .then(() => {
            if (needsToChangeTab.current) {
              setSelectedTab(0)
              setSelectedCodeExtrait(undefined)
              setSelectedBSItem(undefined)
              needsToChangeTab.current = false
            }
          })
          .finally(() => {
            isRefreshingBSItems.current = false
            if (needsToStartEstimating.current) {
              needsToStartEstimating.current = false
              submit()
            }
          })
      } else if (isRefreshingBSItems.current) {
        needsToChangeTab.current = true
      } else {
        setSelectedTab(newValue)
        setSelectedCodeExtrait(undefined)
        setSelectedBSItem(undefined)
      }
    }
  }

  return (
    <>
      <Box id="Content">
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={5} id="questionnaire" component="form" onSubmit={handleSubmit}>
            <Questionnaires
              selectedTab={selectedTab}
              handleChange={changeTab}
              progress={progress}
              isSubmitting={isRefreshingBSItems.current}
              viewer={viewer}
            />
          </Grid>
          <Grid item xs={7}>
            {!isCodeAcvLoading && file ? (
              <BSIfcDisplayer
                file={file}
                onChangeModel={onChangeModel}
                codesExtraits={codesExtraits}
                setSelectedCodeExtrait={selectCodeAcv}
                type={ViewerTypes.CALCULATION}
                height="70vh"
                setExternalProgress={setProgress}
                showFileName
                cancelUpload={cancelUpload}
              />
            ) : (
              <Box display="flex" justifyContent="center" alignItems="center" width="100%" minHeight="100%">
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <CustomBottomNavigation
        actionLabel="Calculer l'impact carbone"
        formId="questionnaire"
        cancelLabel="Annuler"
        cancelAction={cancelAction}
        isSubmitting={isCalculating}
      />
    </>
  )
}
