import SendIcon from "@mui/icons-material/Send"
import { Box, Grid, Typography } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { CustomBottomNavigation } from "../../../components/buttons/navigate-button/CustomBottomNavigation"
import { InfoContext } from "../../../components/layout/InfoSnackbar"
import { ErrorContext } from "../../../components/layout/error-snackbar"
import { pagesUrl } from "../../../core/appConstants"
import { BSModelInvitationContext } from "../../../core/context/beem-shot/BSBimModel/BSModelInvitationContextProvider"
import { AppNavBarContext, NavbarTypeEnum } from "../../../core/context/nav-bar/AppNavBarContext"
import { resolveUrl } from "../../../core/services/http-service"

export function BSInvitationConfirmationPage(): React.JSX.Element {
  const navigate = useNavigate()
  const { bsProjectId, bsBimModelId } = useParams()
  const [searchParams] = useSearchParams()
  const bsVariantId = useMemo(() => searchParams.get("variantId"), [searchParams])

  const openErrorSnackbar = useContext(ErrorContext)
  const openInfoSnackbar = useContext(InfoContext)
  const { setTypeNavBar, setActionOnBackButton } = useContext(AppNavBarContext)
  const { emailUser, invitation } = useContext(BSModelInvitationContext)

  const navigateBack = useCallback(() => {
    if (bsVariantId) {
      navigate(
        resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_IMPORT_PAGE, [bsProjectId, bsBimModelId], {
          variantId: bsVariantId,
        })
      )
    } else {
      navigate(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_IMPORT_PAGE, [bsProjectId, bsBimModelId]))
    }
  }, [bsVariantId, navigate, bsProjectId, bsBimModelId])

  useEffect(() => {
    if (!invitation) {
      openInfoSnackbar("Aucune invitation n'a été envoyée")
      setActionOnBackButton(navigateBack)
    }
  }, [
    bsProjectId,
    bsBimModelId,
    invitation,
    navigate,
    openErrorSnackbar,
    openInfoSnackbar,
    setActionOnBackButton,
    navigateBack,
  ])

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.HEAD_WITHOUT_STEP)
    setActionOnBackButton(navigateBack)
  }, [setTypeNavBar, setActionOnBackButton, navigateBack])

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignContent="center"
      alignItems="center"
      sx={{ marginBottom: "10vh", p: 3 }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ display: "flex", height: "100%", width: "100%" }}>
        <Grid item xs={3} />
        <Grid
          item
          xs={6}
          rowGap={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            height: "100%",
            background: "white",
            borderRadius: 8,
            p: 8,
            backgroundColor: "#E6F0FB",
          }}>
          <SendIcon fontSize="large" />
          <Typography variant="h5" fontWeight={600}>
            Invitation envoyée !
          </Typography>
          <Typography fontSize={20} fontWeight={500}>
            {emailUser} a été invité à rejoindre votre projet.
          </Typography>
        </Grid>
        <Grid item xs={3} />
      </Grid>

      <CustomBottomNavigation actionLabel="terminer" actionButton={navigateBack} />
    </Box>
  )
}
