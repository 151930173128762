import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from "@mui/material"
import React, { useContext } from "react"
import { BSCategoriesContext } from "../../../../core/context/beem-shot/BSCategory/BSCategoriesContext"
import { BSItemContext } from "../../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSItemList } from "./components/BSItemList/BSItemList"
import { BSConfirmResetDialogContextProvider } from "./components/dialog/BSConfirmResetDialog/BSConfirmResetDialogContext"
import { BSUpdateMaterialResultDialogContextProvider } from "./components/dialog/BSCustomMaterialDialog/BSUpdateMaterialResultDialogContext"
import { BSConfirmResetAllDialogContext } from "./components/dialog/BSCustomResetAllDialog/BSConfirmResetAllDialogContext"
import { BSDeleteCustomDialogContextProvider } from "./components/dialog/BSDeleteCustomDialog/BSDeleteCustomDialogContext"
import { useAccordion } from "../BSCalculationPage/BSCalculationForm/components/AdvancedQuestionnaire/useAccordion"

export function BSCustomizationTab(): React.JSX.Element {
  const { expanded: categoryExpanded, handleExpanded: handleExpandedCategory } = useAccordion()
  const { expanded: subcategoryExpanded, handleExpanded: handleSubCategoryExpanded } = useAccordion()

  const { bsCategories } = useContext(BSCategoriesContext)
  const { subCategoriesForCategory } = useContext(BSItemContext)
  const { openConfirmResetAllDialog } = useContext(BSConfirmResetAllDialogContext)

  return (
    <Box display="flex" flexDirection="column" gap={1} pt={2}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="h6">Personnalisez vos calculs</Typography>
        <Button
          onClick={(event) => {
            event.stopPropagation()
            openConfirmResetAllDialog()
          }}>
          Réinitialiser le calcul
        </Button>
      </Box>

      <BSUpdateMaterialResultDialogContextProvider>
        <BSConfirmResetDialogContextProvider>
          <BSDeleteCustomDialogContextProvider>
            {bsCategories
              .filter((bsCategory) => Object.keys(subCategoriesForCategory).includes(bsCategory.name))
              .map((bsCategory) => (
                <Accordion
                  id={bsCategory.name}
                  key={bsCategory.name}
                  expanded={categoryExpanded === bsCategory.name}
                  onChange={() => {
                    handleSubCategoryExpanded("")
                    handleExpandedCategory(bsCategory.name)
                  }}
                  disableGutters
                  square
                  sx={{
                    borderRadius: 6,
                    minWidth: "100%",
                  }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>{bsCategory.label}</AccordionSummary>
                  <AccordionDetails>
                    {bsCategory?.subCategories
                      ?.filter((subCategory) =>
                        subCategoriesForCategory[bsCategory.name]?.some((sc) => sc.name === subCategory.name)
                      )
                      .map((subCategory) => (
                        <Accordion
                          id={subCategory.name}
                          key={subCategory.name}
                          expanded={subcategoryExpanded === subCategory.name}
                          onChange={() => {
                            handleSubCategoryExpanded(subCategory.name)
                          }}
                          elevation={0}
                          square
                          disableGutters
                          TransitionProps={{ unmountOnExit: true }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>{subCategory.label}</AccordionSummary>
                          <AccordionDetails>
                            <BSItemList subCategory={subCategory} />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </AccordionDetails>
                </Accordion>
              ))}
          </BSDeleteCustomDialogContextProvider>
        </BSConfirmResetDialogContextProvider>
      </BSUpdateMaterialResultDialogContextProvider>
    </Box>
  )
}
