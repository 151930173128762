import { Dispatch, SetStateAction, useContext, useEffect, useMemo } from "react"
import ViewerIFC from "../../../components/ifc-displayer/ViewerIFC"
import { CodeExtraitDisplay } from "../../dto/code-extrait/CodeExtraitDisplay"
import { useBSParseBim } from "../beem-shot/useBSParseBim"
import { BSProjectContext } from "../../context/beem-shot/BSProject/BSProjectContext"
import { CodeExtrait } from "../../dto/code-extrait/code-extrait"
import { useViewerDisplay } from "./useViewerDisplay"

export function useViewerAnalyze(
  codesExtraits: CodeExtrait[],
  setCodesExtraits: Dispatch<SetStateAction<CodeExtrait[]>>,
  selectedCodeExtrait: CodeExtraitDisplay | undefined,
  setSelectedCodeExtrait: Dispatch<SetStateAction<CodeExtraitDisplay | undefined>>
): BSViewerContextStore {
  const { bsProject } = useContext(BSProjectContext)
  const {
    viewer,
    setViewer,
    progress,
    setProgress,
    setTypesToElementIdsMap,
    typesToElementIdsMap,
    onChangeModel,
    handleClickAcv,
  } = useViewerDisplay(codesExtraits, selectedCodeExtrait, setSelectedCodeExtrait)

  const { analyzeModel } = useBSParseBim({
    version: bsProject?.codesVersion,
    setProgress,
    setTypesToElementIdsMap,
    setViewer,
    setCodesExtraits,
  })

  useEffect(() => {
    if (viewer) {
      analyzeModel(viewer.modelId ?? 0, viewer)
    }
  }, [analyzeModel, viewer])

  return useMemo(
    () => ({
      typesToElementIdsMap,
      setTypesToElementIdsMap,
      viewer,
      setViewer,
      progress,
      setProgress,
      onChangeModel,
      handleClickAcv,
      analyzeModel,
    }),
    [
      typesToElementIdsMap,
      setTypesToElementIdsMap,
      viewer,
      setViewer,
      progress,
      setProgress,
      onChangeModel,
      handleClickAcv,
      analyzeModel,
    ]
  )
}

export interface BSViewerContextStore {
  typesToElementIdsMap: Map<number, number[]>
  setTypesToElementIdsMap: Dispatch<SetStateAction<Map<number, number[]>>>
  viewer: ViewerIFC | undefined
  setViewer: Dispatch<SetStateAction<ViewerIFC | undefined>>
  progress: number
  setProgress: Dispatch<SetStateAction<number>>
  onChangeModel(modelId: number, viewerIfc: ViewerIFC): void
  handleClickAcv(codeExtrait: CodeExtraitDisplay, disableViewerHighlight?: boolean): CodeExtraitDisplay
  analyzeModel(modelId: number, ifc: ViewerIFC): Promise<void>
}
