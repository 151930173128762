import { SxProps, Theme } from "@mui/material"
import { displayDecimal } from "../../core/services/helper-service"
import { BSMaterialResult } from "../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { customPalette } from "../../../theme"

export type BSMaterialResultStringField = {
  [Key in keyof BSMaterialResult]: BSMaterialResult[Key] extends string ? Key : never
}[keyof BSMaterialResult]

export const displayedLots = [
  "CHANTIER",
  "VRD_1",
  "FONDATION_2",
  "SUPERSTRUCTURE_3",
  "CHARPENTE_4",
  "CLOISONNEMENT_5",
  "FACADE_6",
  "REVETEMENT_7",
  "CVC_8",
  "SANITAIRES_9",
  "CFO_10",
  "CFA_11",
  "ELEVATEUR_12",
  "PRODUCTION_ELECTRICITE_13",
]

export const lotToColor = (lot: string): string => {
  switch (lot) {
    case "VRD_1":
      return "#BAB0AC"
    case "FONDATION_2":
      return "#E15759"
    case "SUPERSTRUCTURE_3":
      return "#8CD17D"
    case "CHARPENTE_4":
      return "#86BCB6"
    case "CLOISONNEMENT_5":
      return "#B6992D"
    case "FACADE_6":
      return "#F28E2B"
    case "REVETEMENT_7":
      return "#59A14F"
    case "CVC_8":
      return "#4E79A7"
    case "SANITAIRES_9":
      return "#FF9D9A"
    case "CFO_10":
      return "#A0CBE8"
    case "CFA_11":
      return "#79706E"
    case "ELEVATEUR_12":
      return "#F1CE63"
    case "PRODUCTION_ELECTRICITE_13":
      return "#FFBE7D"
    case "CHANTIER":
      return "#499894"
    case "ENERGIE":
      return "#ED1C24"
    case "EAU":
      return "#ED1C24"
    case "PARCELLE":
      return "#ED1C24"
    case "AUTRE":
      return "#ED1C24"
    case "UNKNOWN":
      return "#ED1C24"
    default:
      return "#ED1C24"
  }
}

export const lotToLabel = (lot: string): string => {
  switch (lot) {
    case "VRD_1":
      return "1. VRD (Voirie et réseaux divers)"
    case "FONDATION_2":
      return "2. Fondations et infrastructures"
    case "SUPERSTRUCTURE_3":
      return "3. Superstructure - Maçonnerie"
    case "CHARPENTE_4":
      return "4. Couverture - Etanchéité - Charpente - Zinguerie"
    case "CLOISONNEMENT_5":
      return "5. Cloisonnement - Doublage - Plafonds suspendus - Menuiseries intérieures"
    case "FACADE_6":
      return "6. Façades et menuiseries extérieures"
    case "REVETEMENT_7":
      return "7. Revêtements des sols, murs et plafonds - Chape - Produits de décoration"
    case "CVC_8":
      return "8. CVC (Chauffage - Ventilation - Refroidissement - Eau chaude sanitaire)"
    case "SANITAIRES_9":
      return "9. Installations sanitaires"
    case "CFO_10":
      return "10 Réseaux d'énergie (CFO)"
    case "CFA_11":
      return "11. Réseaux de com (CFA)"
    case "ELEVATEUR_12":
      return "12. Appareils élévateurs et autres équipements de transport intérieur"
    case "PRODUCTION_ELECTRICITE_13":
      return "13. Équipement de production locale d'électricité"
    case "CHANTIER":
      return "Chantier"
    case "ENERGIE":
      return "Énergie"
    case "EAU":
      return "Eau"
    case "PARCELLE":
      return "Parcelle"
    case "AUTRE":
      return "Autres"
    case "UNKNOWN":
      return "Inconnu"
    default:
      return lot
  }
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T): 1 | -1 | 0 {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export type Order = "asc" | "desc"

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  if (order === "desc") {
    return (a, b) => descendingComparator(a, b, orderBy)
  } else {
    return (a, b) => -descendingComparator(a, b, orderBy)
  }
}

export function getNextOrder(order: Order | undefined, reverse = false): Order | undefined {
  if (reverse) {
    switch (order) {
      case undefined:
        return "asc"
      case "asc":
        return "desc"
      case "desc":
        return undefined
      default:
        return undefined
    }
  } else {
    switch (order) {
      case undefined:
        return "desc"
      case "desc":
        return "asc"
      case "asc":
        return undefined
      default:
        return undefined
    }
  }
}

export function calculatePercent(value: number, total: number): number {
  return (value / total) * 100
}

export function calculatePercentString(value: number, total: number): string {
  return `${displayDecimal((value / total) * 100, 1)}%`
}

export function initMap(
  allBSMaterialResult: BSMaterialResult[],
  fieldName: BSMaterialResultStringField
): Map<string | undefined, boolean> {
  const fieldValueSet: Set<string> = new Set(allBSMaterialResult.map((bsMaterialResult) => bsMaterialResult[fieldName]))
  const fieldValueMap: Map<string | undefined, boolean> = new Map()
  fieldValueSet.forEach((element) => {
    fieldValueMap.set(element, true)
  })
  return fieldValueMap
}

export function initList(allBSMaterialResult: BSMaterialResult[], fieldName: BSMaterialResultStringField): string[] {
  const fieldValueSet: Set<string> = new Set(allBSMaterialResult.map((bsMaterialResult) => bsMaterialResult[fieldName]))
  return Array.from(fieldValueSet)
}

export function updateMap(
  prev: Map<string | undefined, boolean>,
  key: string | undefined
): Map<string | undefined, boolean> {
  const updatedMap = new Map(prev)
  if (updatedMap.get(key)) {
    updatedMap.delete(key)
  } else {
    updatedMap.set(key, true)
  }
  return updatedMap
}

export function selectableStyle(isSelected: boolean): SxProps<Theme> {
  return {
    cursor: "pointer",
    backgroundColor: isSelected ? customPalette.selectedElement : undefined,
    "&:hover": {
      backgroundColor: customPalette.hoveredElement,
    },
  }
}
