import { Grid, Paper } from "@mui/material"
import React from "react"
import { BSMaterialResult } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { DashboardSectionTitle } from "../../../../../../../components/charts/components/DashboardSectionTitle"
import { Filters } from "../../../../../../../components/charts/filters/Filters"
import { FdesNumber } from "../../../../../../../components/charts/kpi/FdesNumber"
import { CarbonImpactPerFdesChart } from "../../../../../../../components/charts/tables/IniesRecordChart/CarbonImpactPerFdesChart"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
}

export function BiosourceBottomRightPanel({ allBSMaterialResult }: Readonly<IProps>): React.JSX.Element {
  return (
    <Paper sx={{ height: "100%" }}>
      <DashboardSectionTitle label="Point biosourcé par FDES" />
      <Grid container spacing={2} sx={{ pl: 2, pr: 2 }}>
        <Grid item xs={16} container columns={16} spacing={2} sx={{ pr: 2 }}>
          <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <FdesNumber allBSMaterialResult={allBSMaterialResult} />
          </Grid>
          <Filters allBSMaterialResult={allBSMaterialResult} />
        </Grid>
        <Grid item xs={12} container spacing={2} sx={{ overflowY: "scroll" }}>
          <CarbonImpactPerFdesChart
            allBSMaterialResult={allBSMaterialResult}
            fieldName="carbonStockPerSurface"
            valueColumnLabel="Biosourcé"
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
