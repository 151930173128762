import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import { Box } from "@mui/material"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined"
import { IconBox } from "../../../components/custom-icons/icon-box"
import { CustomTabPanel, TabPanelProps } from "../../../components/tabs/CustomTabPanel"
import { isProjectDetailTab, TabEnum, tabEnumFromStringNumber } from "../../../components/tabs/tabs"
import { pagesUrl } from "../../../core/appConstants"
import { BSBimModelListContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelContextList"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { AppNavBarContext } from "../../../core/context/nav-bar/AppNavBarContext"
import { AppSideBarContext } from "../../../core/context/side-bar/side-bar-context"
import { CalculStatusEnum } from "../../../core/enum/calculStatusEnum"
import { resolveUrl } from "../../../core/services/http-service"
import { BSDashboardVariantTab } from "./tabs/BSDashboardVariantTab"
import { BSVariantList } from "./tabs/BSVariantList"
import { BSBimModelList } from "./tabs/BSBimModelList"
import { displayNumber } from "../../../core/services/helper-service"

export function BSProjectDetailsPage(): React.JSX.Element {
  const { bsProjectId } = useParams()
  const [searchParams] = useSearchParams()

  const { bsProject } = useContext(BSProjectContext)
  const { bsVariants } = useContext(BSVariantContext)
  const { setShowAppSide } = useContext(AppSideBarContext)
  const { bsBimModelListByProject } = useContext(BSBimModelListContext)

  const { setTypeNavBar, setPreviousUrl, setStepHeader, setShowProjectStatus, setTitle, setBreadcrumbSteps } =
    useContext(AppNavBarContext)

  const [tab, setTab] = useState<TabEnum>(TabEnum.BS_VARIANTS)

  const variantCount: number = useMemo(() => (bsVariants ? bsVariants.length : 0), [bsVariants])

  useEffect(() => {
    setPreviousUrl(pagesUrl.BEEM_SHOT_HOME)
    setShowProjectStatus(true)
    setTitle(bsProject?.name ?? "")
    setBreadcrumbSteps([{ label: "Beem Shot", url: resolveUrl(pagesUrl.BEEM_SHOT_HOME, []) }, { label: "Projet" }])
    return () => {
      setShowProjectStatus(false)
      setTitle("BEEM SHOT")
    }
  }, [
    bsProject?.name,
    bsProjectId,
    setBreadcrumbSteps,
    setPreviousUrl,
    setShowProjectStatus,
    setStepHeader,
    setTitle,
    setTypeNavBar,
    setShowAppSide,
  ])

  useEffect(() => {
    const urlTabParam = searchParams.get("tab")
    const tabEnumParam = tabEnumFromStringNumber(urlTabParam)

    let tabEnum: TabEnum
    if (tabEnumParam && isProjectDetailTab(tabEnumParam)) {
      tabEnum = tabEnumParam
    } else {
      tabEnum = TabEnum.BS_VARIANTS
    }

    setTab(tabEnum)
  }, [bsBimModelListByProject, bsProjectId, searchParams])

  useEffect(() => {
    setShowAppSide(true)
  }, [setShowAppSide])

  function handleChangeTab(event: React.SyntheticEvent, newValue: TabEnum): void {
    setTab(newValue)
  }

  const bimModelCount: string = useMemo(
    () => (bsBimModelListByProject ? bsBimModelListByProject.length.toString() : "0"),
    [bsBimModelListByProject]
  )

  const isCompareVariantDisabled: boolean = useMemo(
    () => bsVariants && bsVariants.filter((bsVariant) => bsVariant.calculStatus === CalculStatusEnum.READY).length < 2,
    [bsVariants]
  )

  const projectDetailTabs: TabPanelProps[] = [
    {
      id: TabEnum.BS_VARIANTS,
      Icon: DonutSmallOutlinedIcon,
      label: "Variantes",
      chips: displayNumber(variantCount),
      content: <BSVariantList />,
    },
    {
      id: TabEnum.BS_BIM_MODEL_POOL,
      Icon: IconBox,
      label: "Maquettes BEEM",
      chips: bimModelCount,
      content: <BSBimModelList />,
      isNew: true,
      toolTipText: "Ajoutez, modifiez ou supprimez les maquettes Beem de vos variantes depuis cet espace.",
    },
    {
      id: TabEnum.BS_COMPARE_VARIANTES,
      Icon: FormatListBulletedIcon,
      label: "Comparaison Variantes",
      chips: undefined,
      content: <BSDashboardVariantTab />,
      disabled: isCompareVariantDisabled,
      disabledText:
        "Vous devez lancer le calcul d'au minimum deux variantes avant de pouvoir visualiser le tableau de comparaison",
    },
  ]

  return (
    <Box display="flex" flexDirection="column" height="78vh">
      <CustomTabPanel panels={projectDetailTabs} currentTabId={tab} handleChangeTab={handleChangeTab} />
    </Box>
  )
}
