import React, { useCallback, useContext, useMemo, useState } from "react"
import { Children } from "../../../../../../../components/miscellianous/children"
import { BSItem } from "../../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { BSMaterialResultFormDto } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResultFormDto"
import { SelectionContext } from "../../context/SelectionContext"
import { BSConfirmResetDialog } from "./BSConfirmResetDialog"

export const BSConfirmResetDialogContext = React.createContext<ConfirmResetDialogStore>({} as ConfirmResetDialogStore)

export function BSConfirmResetDialogContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { setSelectedBSItem, setSelectedBSMaterialResult } = useContext(SelectionContext)
  const [isOpen, setIsOpen] = useState(false)

  const openConfirmResetDialog = useCallback(
    (newBSItem: BSItem | undefined, bsMaterialResult: BSMaterialResultFormDto | undefined): void => {
      if (newBSItem) {
        setSelectedBSItem(newBSItem)
        setSelectedBSMaterialResult(bsMaterialResult)
        setIsOpen(true)
      }
    },
    [setSelectedBSItem, setSelectedBSMaterialResult]
  )

  const confirmResetDialogStore: ConfirmResetDialogStore = useMemo(
    () => ({ openConfirmResetDialog }),
    [openConfirmResetDialog]
  )
  return (
    <BSConfirmResetDialogContext.Provider value={confirmResetDialogStore}>
      <BSConfirmResetDialog isOpen={isOpen} handleClose={() => setIsOpen(false)} />
      {children}
    </BSConfirmResetDialogContext.Provider>
  )
}

export type ConfirmResetDialogStore = {
  openConfirmResetDialog(newBSItem: BSItem | undefined, bsMaterialResult: BSMaterialResultFormDto | undefined): void
}
