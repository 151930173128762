import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"
import { Box, Grid, Typography } from "@mui/material"
import React, { useContext } from "react"
import { RseeProjectContext } from "../../../core/context/beem-pilot/rsee/rsee-project-context"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { AppNavBarContext } from "../../../core/context/nav-bar/AppNavBarContext"

export function BackButtonNavbar(): React.JSX.Element {
  const { navBarNavigateBack, icon } = useContext(AppNavBarContext)
  const { rseeProject } = useContext(RseeProjectContext)
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)

  return (
    <Grid item xs={3} container display="flex" flexDirection="row" alignContent="center" alignItems="center">
      <Box display="flex" flexDirection="row" alignItems="center" overflow="hidden" sx={{ pl: 2 }}>
        <ArrowBackOutlinedIcon onClick={navBarNavigateBack} fontSize="medium" sx={{ cursor: "pointer" }} />
        <Box
          display="flex"
          alignItems="center"
          sx={{ borderRadius: 2, background: "#b7ffe3", p: 1, m: 1, border: 3, borderColor: "white" }}>
          {icon}
        </Box>
        <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          <Typography variant="body1" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {rseeProject?.projectName}
          </Typography>
          <Typography variant="body1" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {selectedVariant?.name}
          </Typography>

          <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            {bsProject?.name}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}
