import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import React, { useCallback, useContext, useEffect } from "react"
import { BSItemContext } from "../../../../../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSItemCategory } from "../../../../../../../core/dto/beem-shot/BSItem/BSItemCategory"
import { SubCategoryAccordion } from "./SubCategoryAccordion"
import { useAccordion } from "./useAccordion"
import ViewerIFC from "../../../../../../../components/ifc-displayer/ViewerIFC"

interface IProps {
  category: BSItemCategory
  expandedCategory: string
  viewer: ViewerIFC | undefined

  handleExpandedCategory(toExpand?: string): void
}

export function CategoryAccordion({
  category,
  expandedCategory,
  handleExpandedCategory,
  viewer,
}: Readonly<IProps>): React.JSX.Element {
  const { selectedBSItem, setSelectedBSItem, subCategoriesForCategory } = useContext(BSItemContext)

  const { expanded: subcategoryExpanded, expand, handleExpanded: handleExpandedSubCategory } = useAccordion()

  useEffect(() => {
    if (selectedBSItem) {
      expand(selectedBSItem?.subCategory.name)
    }
  }, [selectedBSItem, expand])

  const expandCategory = useCallback(
    (e: React.SyntheticEvent, toOpen: boolean) => {
      handleExpandedSubCategory("")
      viewer?.manager.subsetsManager.restoreAllSubsetsMaterial()
      setSelectedBSItem(undefined)
      if (selectedBSItem && !toOpen) {
        // Close or stay open depending on selectedBSItem category
        handleExpandedCategory(selectedBSItem?.category?.name)
      } else {
        // Open Category
        handleExpandedCategory(category.name)
      }
    },
    [
      category.name,
      handleExpandedSubCategory,
      handleExpandedCategory,
      selectedBSItem,
      setSelectedBSItem,
      viewer?.manager.subsetsManager,
    ]
  )

  return (
    <Accordion
      id={category.name}
      expanded={expandedCategory === category.name}
      onChange={expandCategory}
      disableGutters
      square
      sx={{
        borderRadius: 6,
        m: 1,
        minWidth: "100%",
      }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{category.label}</AccordionSummary>
      <AccordionDetails>
        {category?.subCategories
          ?.filter((subCategory) => subCategoriesForCategory[category.name]?.some((sc) => sc.name === subCategory.name))
          .map((subCategory) => (
            <SubCategoryAccordion
              key={subCategory.name}
              subCategory={subCategory}
              expandedSubCategory={subcategoryExpanded}
              handleExpandedSubCategory={handleExpandedSubCategory}
              viewer={viewer}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  )
}
