import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { CachingHelper } from "../../../../components/ifc-displayer/helpers/CachingHelper"
import { Children } from "../../../../components/miscellianous/children"
import { BSBimModel } from "../../../dto/beem-shot/BSBimModel/BSBimModel"
import { useBSBimModel } from "../../../hooks/beem-shot/useBSBimModel"
import { BSBimModelContext } from "./BSBimModelContext"

export const BSModelFileContext = React.createContext<BSModelFileStore>({} as BSModelFileStore)

export function BSModelFileContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsBimModel, bsBimModelIdRef } = useContext(BSBimModelContext)

  const { getBSBimModelFile } = useBSBimModel()

  const [isCompressed, setIsCompressed] = useState<boolean>(false)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [isModelFileLoading, setIsModelFileLoading] = useState<boolean>(false)
  const [isModelFileLoaded, setIsModelFileLoaded] = useState<boolean>(false)
  const abortControllerRef = useRef<AbortController | null>(null)

  // It's very important for this function to use bsBimModelIdRef
  // The state "bsBimModel" is not updated too slowly. For the use case which we added the function, it's outdated.
  const isStillSameBSModel: (bsBimModelToFetch: BSBimModel | undefined) => boolean = useCallback(
    (bsBimModelToFetch: BSBimModel | undefined) => bsBimModelToFetch?.id === bsBimModelIdRef.current,
    [bsBimModelIdRef]
  )

  const fetchBimModelFromCacheOrServer: (bsBimModelToFetch: BSBimModel) => void = useCallback(
    (bsBimModelToFetch: BSBimModel) => {
      if (!isModelFileLoading) {
        // fetch the bim model information from the server so we know the file name and hash
        setIsModelFileLoading(true)

        // get the cached file if it exists
        CachingHelper.getCachedFile(bsBimModelToFetch.modelHashFile, bsBimModelToFetch.fileName)
          .then((cach) => {
            if (cach) {
              // present the cached file
              console.info("Get file from cache")
              // loading file takes time. I had an issue where bimModel had changed before the end of loading
              // If the bim model has changed, we should not update the state
              if (isStillSameBSModel(bsBimModelToFetch)) {
                setFile(cach)
              }
              setIsModelFileLoading(false)
              setIsModelFileLoaded(true)
            } else {
              console.info("Download file from server")
              // fetch the file from the server because we don't have it in the cache
              if (!bsBimModelToFetch?.id) {
                return
              }

              getBSBimModelFile(bsBimModelToFetch.id, abortControllerRef)
                .then(async (blob) => {
                  if (blob) {
                    // loading file takes time. I had an issue where bimModel had changed before the end of loading
                    // If the bim model has changed, we should not update the state
                    if (isStillSameBSModel(bsBimModelToFetch)) {
                      // create a file from the blob and cache it
                      const fileName =
                        bsBimModelToFetch.fileName && bsBimModelToFetch.fileName.length > 0
                          ? bsBimModelToFetch.fileName
                          : "Model.ifc"
                      const newFile = new File([blob], fileName, { type: "application/ifc" })
                      CachingHelper.cacheFile(newFile)
                      // set the file in the context
                      setFile(newFile)
                    }
                    setIsModelFileLoading(false)
                  } else {
                    setIsModelFileLoading(false)
                  }
                })
                .catch((error) => {
                  if (error instanceof DOMException && error.name === "AbortError") {
                    console.info("Download bim model file cancelled. Bim Model Id:", bsBimModelToFetch.id)
                  }
                })
            }
          })
          .then(() => console.info("End of Downloading"))
          .finally(() => {
            setIsModelFileLoaded(true)
          })
      } else {
        setFile(undefined)
        setIsModelFileLoaded(true)
      }
    },
    // isModelFileLoading
    [getBSBimModelFile, isStillSameBSModel]
  )

  useEffect(() => {
    if (bsBimModel?.modelHashFile) {
      fetchBimModelFromCacheOrServer(bsBimModel)
    } else {
      setFile(undefined)
    }
  }, [bsBimModel, fetchBimModelFromCacheOrServer])

  useEffect(() => {
    if (file?.name?.endsWith(".ifczip")) {
      setIsCompressed(true)
    }
  }, [file])

  const fileStore: BSModelFileStore = useMemo(
    () => ({
      file,
      isModelFileLoading,
      isModelFileLoaded,
      setIsModelFileLoading,
      isCompressed,
      setIsCompressed,
    }),
    [file, isCompressed, isModelFileLoaded, isModelFileLoading]
  )

  return <BSModelFileContext.Provider value={fileStore}>{children}</BSModelFileContext.Provider>
}

export type BSModelFileStore = {
  file?: File
  isModelFileLoading: boolean
  isModelFileLoaded: boolean
  isCompressed: boolean
  setIsModelFileLoading: Dispatch<SetStateAction<boolean>>
  setIsCompressed: React.Dispatch<React.SetStateAction<boolean>>
}
