import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from "@mui/material"
import React, { useCallback, useMemo, useState } from "react"
import { BSMaterialResultFormDto } from "../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResultFormDto"
import { FicheConfiguree } from "../../../../../core/dto/fiche-configuree/fiche-configuree"
import { IniesRecord } from "../../../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../../../core/dto/material/MaterialRecord"
import { isNumber, stringToNumber } from "../../../../../core/services/helper-service"
import { unitService } from "../../../../../core/services/unit-service"
import { FicheValue } from "../../../../../pages/beem-shot/BSVariantDetailsPage/BSCustomizationTab/components/context/SelectionContext"
import CancelButton from "../../../../buttons/CancelButton/CancelButton"
import { ValidateButton } from "../../../../buttons/ValidateButton/ValidateButton"
import { DefaultTypography } from "../../../../typography/default-typography"
import { TextInputTypography } from "../../../../typography/textInputTypography"
import { NumberInput } from "../../../number-input/NumberInput"

interface IProps {
  openWarningFunctionnalUnit: boolean
  selectedRow: IniesRecord | MaterialRecord | FicheConfiguree
  isSubmitting: boolean
  selectedBSMaterialResult: BSMaterialResultFormDto
  form: FicheValue
  onSubmit(coefficient: number, quantity: number): void
  handleClose(): void
}

export function WarningFunctionalUnitDialog({
  openWarningFunctionnalUnit,
  onSubmit,
  isSubmitting,
  selectedRow,
  selectedBSMaterialResult,
  form,
  handleClose,
}: Readonly<IProps>): React.JSX.Element {
  const [coefficient, setCoefficient] = useState<number>(form.coefficient)
  const [formQuantity, setFormQuantity] = useState<number>(form.newQuantity)

  const finalQuantity = useMemo(
    () => (isNumber(coefficient) ? coefficient * formQuantity : formQuantity),
    [coefficient, formQuantity]
  )

  const handleFormQuantity = useCallback((value: string) => {
    setFormQuantity(stringToNumber(value))
  }, [])

  const handleCoefficient = useCallback((value: string) => {
    setCoefficient(stringToNumber(value))
  }, [])

  return (
    <Dialog maxWidth="md" fullWidth open={openWarningFunctionnalUnit} onClose={handleClose}>
      <DialogTitle>Êtes-vous sûr de vouloir affecter cette fiche ?</DialogTitle>
      <DialogContent color="black" sx={{ px: 2 }}>
        <Grid display="flex" flexDirection="row" container rowGap={1}>
          <Grid item xs={12}>
            <Typography variant="body2" pb={1}>
              {`Indiquez le coefficient nécessaire pour convertir votre quantité de ${
                unitService.unitEnumToLabel(selectedBSMaterialResult?.originalFicheUnite) ?? ""
              } à ${unitService.unitEnumToLabel(selectedRow?.functionalUnit)}.`}
            </Typography>
            <Typography variant="body2" pb={1} sx={{ fontStyle: "italic" }}>
              Par exemple : pour convertir un isolant d'une surface de 100 m² avec une épaisseur de 0,2 m en m³, multipliez
              la surface par le coefficient 0,2 afin d'obtenir le volume en m³.
            </Typography>
          </Grid>
          <Grid item xs={2} display="flex" flexDirection="column">
            <Typography
              sx={{
                fontSize: "11px",
                fontWeight: 600,
                lineHeight: "26.5px",
                textAlign: "left",
              }}>
              Unité:
            </Typography>
          </Grid>

          <Grid
            item
            xs={5}
            display="flex"
            flexDirection="column"
            sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
            <TextInputTypography label="Unité actuelle" />
            <DefaultTypography
              label={unitService.unitEnumToLabel(selectedBSMaterialResult?.originalFicheUnite)}
              fontSize="12px"
              lineHeight="18px"
              fontWeight={400}
            />
          </Grid>

          <Grid
            item
            xs={5}
            display="flex"
            flexDirection="column"
            sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
            <TextInputTypography label="Unité de la fiche" />
            <DefaultTypography
              label={unitService.unitEnumToLabel(selectedRow?.functionalUnit)}
              fontSize="12px"
              lineHeight="18px"
              fontWeight={400}
            />
          </Grid>

          {selectedBSMaterialResult?.extractedQuantities && (
            <Grid display="flex" flexDirection="row" container rowGap={1}>
              <Grid item xs={2} display="flex" flexDirection="column">
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: 600,
                    lineHeight: "26.5px",
                    textAlign: "left",
                  }}>
                  Quantité extraite:
                </Typography>
              </Grid>

              <Grid display="flex" container item xs={10} rowGap={1}>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  flexDirection="column"
                  sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                  <TextInputTypography label="Hauteur (m)" />
                  <DefaultTypography
                    label={selectedBSMaterialResult?.extractedQuantities.height?.toFixed(1).toString() ?? ""}
                    fontSize="12px"
                    lineHeight="18px"
                    fontWeight={400}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  flexDirection="column"
                  sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                  <TextInputTypography label="Nombre" />
                  <DefaultTypography
                    label={selectedBSMaterialResult?.extractedQuantities.number?.toFixed(1).toString() ?? ""}
                    fontSize="12px"
                    lineHeight="18px"
                    fontWeight={400}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  flexDirection="column"
                  sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                  <TextInputTypography label="Longueur (m)" />
                  <DefaultTypography
                    label={selectedBSMaterialResult?.extractedQuantities.length?.toFixed(1).toString() ?? ""}
                    fontSize="12px"
                    lineHeight="18px"
                    fontWeight={400}
                  />
                </Grid>

                <Grid
                  item
                  xs={6}
                  display="flex"
                  flexDirection="column"
                  sx={{ border: 2, borderColor: "#DDE6E8", borderRadius: 2, p: 1 }}>
                  <TextInputTypography label="Surface (m²)" />
                  <DefaultTypography
                    label={selectedBSMaterialResult?.extractedQuantities.surface?.toFixed(1).toString() ?? ""}
                    fontSize="12px"
                    lineHeight="18px"
                    fontWeight={400}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Divider sx={{ my: 2 }} />

        <Grid display="flex" flexDirection="row" container>
          <Grid item xs={2} display="flex" flexDirection="column">
            <Typography
              sx={{
                fontSize: "11px",
                fontWeight: 600,
                lineHeight: "26.5px",
                textAlign: "left",
              }}>
              Modifier la quantité :
            </Typography>
          </Grid>

          <Grid item xs={10} display="flex" justifyContent="space-between">
            <Grid item xs={4}>
              <NumberInput
                id="quantity"
                label="Quantité originale"
                value={formQuantity}
                fullWidth={false}
                decimalScale={1}
                variant="outlined"
                handleChange={handleFormQuantity}
              />
            </Grid>
            <Grid item xs={4}>
              <NumberInput
                id="coefficient"
                label="Coefficient *"
                value={coefficient}
                fullWidth={false}
                decimalScale={2}
                variant="outlined"
                handleChange={(value) => handleCoefficient(value)}
              />
            </Grid>
            <Grid item xs={4}>
              <NumberInput
                id="newQuantity"
                label="Quantité modifiée"
                value={finalQuantity}
                fullWidth={false}
                decimalScale={1}
                variant="outlined"
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={handleClose} label="Annuler" />
        <ValidateButton
          onClick={() => {
            onSubmit(coefficient, formQuantity)
            handleClose()
          }}
          label="Confirmer"
          isSubmitting={isSubmitting}
        />
      </DialogActions>
    </Dialog>
  )
}
