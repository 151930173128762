import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { BSModelPublicContext, BSModelPublicContextProvider } from "./context/BSModelPublicContext"
import { CodeExtraitListPublicContext, CodeExtraitListPublicContextProvider } from "./context/CodeExtraitListPublicContext"
import ViewerIFC from "../../../components/ifc-displayer/ViewerIFC"
import IfcDisplayer from "../../../components/ifc-displayer/ifc-displayer"
import { ViewerTypes } from "../../../components/ifc-displayer/enums/ViewerTypes"
import { BimModelExtensionContextProvider, DashboardExtensionContext } from "./context/DashboardExtensionContext"
import { CodeExtraitDisplay } from "../../../core/dto/code-extrait/CodeExtraitDisplay"

export function BimModelExtensionPage(): React.JSX.Element {
  return (
    <BimModelExtensionContextProvider>
      <BSModelPublicContextProvider>
        <CodeExtraitListPublicContextProvider>
          <BimModelPublicCore />
        </CodeExtraitListPublicContextProvider>
      </BSModelPublicContextProvider>
    </BimModelExtensionContextProvider>
  )
}

function BimModelPublicCore(): React.JSX.Element {
  const { selectedData, setSelectedCodeExtrait } = useContext(DashboardExtensionContext)
  const { bimModelFile } = useContext(BSModelPublicContext)
  const { codeExtraitList } = useContext(CodeExtraitListPublicContext)

  const [isSettingColorsIfc, setIsSettingColorsIfc] = useState(false)
  const viewerIfcRef = useRef<ViewerIFC | undefined>(undefined)

  function onChangeModel(modelId: number, viewer: ViewerIFC): void {
    viewerIfcRef.current = viewer
    if (codeExtraitList.length !== 0 && !isSettingColorsIfc) {
      setIsSettingColorsIfc(true)
      viewer.manager.subsetsManager.setCodeColors(modelId, codeExtraitList).finally(() => setIsSettingColorsIfc(false))
    }
  }

  useEffect(() => {
    viewerIfcRef.current?.manager.subsetsManager.highlightCodesExtraitsList(selectedData)
  }, [selectedData])

  const handleClickAcv = useCallback(
    (code: CodeExtraitDisplay): CodeExtraitDisplay => {
      setSelectedCodeExtrait(code)
      return code
    },
    [setSelectedCodeExtrait]
  )

  if (bimModelFile) {
    return (
      <IfcDisplayer
        file={bimModelFile}
        type={ViewerTypes.FOR_DASHBOARD}
        codesExtraits={codeExtraitList}
        onChangeModel={onChangeModel}
        showFileName
        setSelectedCodeExtrait={handleClickAcv}
        height="500px"
      />
    )
  } else {
    return <div>Pas de maquette numérique</div>
  }
}
