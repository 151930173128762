import { FormControl, InputLabel, Select } from "@mui/material"
import React, { useCallback, useContext, useMemo } from "react"
import { BSMaterialResult } from "../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSFilterContext, BSFilterType } from "../BSFilterContext"
import { customPalette } from "../../../../../theme"
import { IFilterOption } from "../IFilterOption"
import { FilterMenuItem } from "../FilterMenuItem"
import { BSMaterialResultStringField } from "../../ChartUtils"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
  filterType: BSFilterType
  label: string
  emptyLabel: string
  valueFieldName: BSMaterialResultStringField
  labelFieldName: BSMaterialResultStringField
  applyFilters(bsMaterialResultList: BSMaterialResult[]): BSMaterialResult[]
}

export function BaseFilterSelect({
  allBSMaterialResult,
  filterType,
  label,
  emptyLabel,
  valueFieldName,
  labelFieldName,
  applyFilters,
}: Readonly<IProps>): React.JSX.Element {
  const { toggleInFilter, resetFilter, emptyFilter, getSelectedValues, isFilterAllChecked } = useContext(BSFilterContext)

  const filteredList = useMemo(() => applyFilters(allBSMaterialResult), [allBSMaterialResult, applyFilters])

  const options: IFilterOption[] = useMemo(() => {
    const uniqueItemTypes: Set<string> = new Set()

    const optionsWithoutAll = filteredList
      .filter((bsMaterialResult) => {
        if (uniqueItemTypes.has(bsMaterialResult[valueFieldName])) {
          return false
        }
        uniqueItemTypes.add(bsMaterialResult[valueFieldName])
        return true
      })
      .map((bsMaterialResult) => ({
        value: bsMaterialResult[valueFieldName],
        label: bsMaterialResult[labelFieldName] ?? emptyLabel,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    return [{ value: "ALL", label: "Tout" }, ...optionsWithoutAll]
  }, [filteredList, valueFieldName, labelFieldName, emptyLabel])

  const isAllChecked: boolean = useMemo(() => {
    const optionValues = options.map((option) => option.value)
    return isFilterAllChecked(filterType, optionValues)
  }, [filterType, isFilterAllChecked, options])

  const updateSelectedList: (newValue: string | undefined) => void = useCallback(
    (newValue) => {
      toggleInFilter(newValue, filterType)
    },
    [filterType, toggleInFilter]
  )

  const selectAll: () => void = useCallback(() => {
    if (isAllChecked) {
      emptyFilter(filterType)
    } else {
      resetFilter(filterType)
    }
  }, [emptyFilter, filterType, isAllChecked, resetFilter])

  const handleChange: (option: IFilterOption) => void = useCallback(
    (option) => {
      updateSelectedList(option.value)
    },
    [updateSelectedList]
  )

  const selectedValues: (string | undefined)[] = useMemo(
    () => (isAllChecked ? getSelectedValues(filterType).concat("ALL") : getSelectedValues(filterType)),
    [filterType, getSelectedValues, isAllChecked]
  )

  return (
    <FormControl
      fullWidth
      sx={{
        backgroundColor: customPalette.textPrimaryWhite,
      }}>
      <InputLabel>{label}</InputLabel>
      <Select
        id="item-select"
        aria-describedby="item-select"
        labelId="item-select-id-label"
        multiple
        label={label}
        value={selectedValues}
        renderValue={(selected) => {
          if (isAllChecked) {
            return "Tout"
          } else if (selected.length === 0) {
            return "Vide"
          } else if (selected.length === 1) {
            return options.find((option) => option.value === selected[0])?.label || "Aucun"
          } else {
            return "Sélection multiple"
          }
        }}
        MenuProps={{ autoFocus: false }}>
        {options.map((option) => (
          <FilterMenuItem
            key={option.value ?? ""}
            filterType={filterType}
            option={option}
            handleChange={handleChange}
            selectAll={selectAll}
            isAllChecked={isAllChecked}
          />
        ))}
      </Select>
    </FormControl>
  )
}
