import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { CodeExtrait } from "../../../dto/code-extrait/code-extrait"
import { Children } from "../../../../components/miscellianous/children"
import { BSItemContext } from "../BSItems/BSItemContext"
import { BSItem } from "../../../dto/beem-shot/BSItem/BSItem"
import { CodeExtraitDisplay } from "../../../dto/code-extrait/CodeExtraitDisplay"

export const BSCodesExtraitsContext = React.createContext<BSCodeExtraitStore>({} as BSCodeExtraitStore)

export function BSCodesExtraitsContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsItems, selectedBSItem } = useContext(BSItemContext)

  const [codesExtraits, setCodesExtraits] = useState<CodeExtrait[]>([])
  const [selectedCodeExtrait, setSelectedCodeExtrait] = useState<CodeExtraitDisplay | undefined>(undefined)

  const updateBSCodeExtraitList = useCallback((bsItemsToExtract: BSItem[]) => {
    const codes: CodeExtrait[] = bsItemsToExtract
      .map((actualBSItem) => actualBSItem.codeExtrait)
      .filter((code): code is CodeExtrait => code !== undefined)

    setCodesExtraits(codes)
  }, [])

  useEffect(() => {
    if (bsItems) {
      updateBSCodeExtraitList(bsItems)
    }
  }, [bsItems, updateBSCodeExtraitList])

  // Select codeExtrait when bsItem is selected
  useEffect(() => {
    if (selectedBSItem?.codeExtrait) {
      setSelectedCodeExtrait(selectedBSItem.codeExtrait)
    } else {
      setSelectedCodeExtrait(undefined)
    }
  }, [selectedBSItem?.codeExtrait])

  const bsCodeExtraitStore = useMemo(
    () => ({
      codesExtraits,
      setCodesExtraits,
      selectedCodeExtrait,
      setSelectedCodeExtrait,
    }),
    [codesExtraits, selectedCodeExtrait]
  )
  return <BSCodesExtraitsContext.Provider value={bsCodeExtraitStore}>{children}</BSCodesExtraitsContext.Provider>
}

export interface BSCodeExtraitStore {
  codesExtraits: CodeExtrait[]
  setCodesExtraits: Dispatch<SetStateAction<CodeExtrait[]>>
  selectedCodeExtrait: CodeExtraitDisplay | undefined
  setSelectedCodeExtrait: Dispatch<SetStateAction<CodeExtraitDisplay | undefined>>
}
