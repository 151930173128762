import { TextField, Typography } from "@mui/material"
import React, { useCallback, useContext, useState } from "react"
import { BSBimModelContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSBimModel } from "../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { DeleteDialog } from "../../dialog/DeleteDialog"
import { SuccessContext } from "../../layout/success-snackbar"

type IProps = {
  bsBimModel: BSBimModel | undefined
  isOpenDeleteBSBimModel: boolean
  setIsOpenDeleteBSBimModel: React.Dispatch<React.SetStateAction<boolean>>
  onDelete?(): void
}

export function DeleteBSBimModelDialog({
  bsBimModel,
  isOpenDeleteBSBimModel,
  setIsOpenDeleteBSBimModel,
  onDelete,
}: Readonly<IProps>): React.JSX.Element {
  const { handleDeleteBSBimModel } = useContext(BSBimModelContext)
  const openSuccessSnackbar = useContext(SuccessContext)

  const [error, setError] = useState<any>({})
  const [bsBimModelNameToDelete, setBSBimModelNameToDelete] = useState<string>("")

  const handleChangeDeleteDialog = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target

      setError({ ...error, bsBimModelNameToDelete: undefined })
      setBSBimModelNameToDelete(target.value)
    },
    [error]
  )

  const validateProjectNameToDelete: () => boolean = useCallback(() => {
    const newError: Record<string, string> = {}
    let isValid = true
    const nameValue = bsBimModelNameToDelete?.trim()

    if (!nameValue?.length) {
      newError.bsBimModelNameToDelete = "Veuillez remplir ce champ"
      isValid = false
    }

    if (nameValue !== bsBimModel?.fileName) {
      newError.bsBimModelNameToDelete = "Le nom de la maquette est incorrect"
      isValid = false
    }

    if (nameValue.length > 1000) {
      newError.bsBimModelNameToDelete = "1000 caractères maximum"
      isValid = false
    }

    setError(newError)
    return isValid
  }, [bsBimModel?.fileName, bsBimModelNameToDelete])

  const handleSubmit = useCallback(() => {
    if (validateProjectNameToDelete() && bsBimModel?.id) {
      handleDeleteBSBimModel(bsBimModel?.id)
        .then(() => {
          openSuccessSnackbar("La maquette a bien été supprimé")
          setIsOpenDeleteBSBimModel(false)
        })
        .then(() => {
          if (onDelete) {
            onDelete()
          }
        })
    }
    return Promise.resolve()
  }, [
    bsBimModel?.id,
    handleDeleteBSBimModel,
    onDelete,
    openSuccessSnackbar,
    setIsOpenDeleteBSBimModel,
    validateProjectNameToDelete,
  ])

  const handleClose = useCallback(() => {
    setIsOpenDeleteBSBimModel(false)
  }, [setIsOpenDeleteBSBimModel])

  return (
    <DeleteDialog
      id="deleteBSBimModel"
      title="Confirmer la suppression"
      contentText={
        <>
          <Typography variant="body1" sx={{ fontSize: 19 }}>
            Pour confirmer la suppression, tapez "{bsBimModel?.fileName}" ci-dessous (sans les guillemets)
          </Typography>
          <TextField
            required
            id="bsProjectNameToDelete"
            onChange={handleChangeDeleteDialog}
            value={bsBimModelNameToDelete}
            variant="outlined"
            fullWidth
            error={!!error.bsBimModelNameToDelete}
            helperText={error.bsBimModelNameToDelete}
            sx={{
              mt: 2,
            }}
          />
        </>
      }
      handleClose={handleClose}
      action={handleSubmit}
      isOpen={isOpenDeleteBSBimModel}
    />
  )
}
