import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { Children } from "../../../../components/miscellianous/children"
import { BSVariantCardWithSizeDto } from "../../../dto/beem-shot/BSVariant/BSProjectCardWithSizeDto"
import { BSVariant } from "../../../dto/beem-shot/BSVariant/BSVariant"
import { BSVariantCreationDto } from "../../../dto/beem-shot/BSVariant/BSVariantCreateDto"
import { BSVariantUpdateDto } from "../../../dto/beem-shot/BSVariant/BSVariantUpdateDto"
import { CodeExtrait } from "../../../dto/code-extrait/code-extrait"
import { CodeExtraitCreation } from "../../../dto/code-extrait/code-extrait-creation"
import { CalculStatusEnum } from "../../../enum/calculStatusEnum"
import { useBSCode } from "../../../hooks/beem-shot/useBSCode"
import { useBSVariant } from "../../../hooks/beem-shot/useBSVariant"
import { BSProjectListContext } from "../BSProject/BSProjectListContext"
import { BSBimModelListContext } from "../BSBimModel/BSBimModelContextList"
import { BSProjectContext } from "../BSProject/BSProjectContext"

export const BSVariantContext = React.createContext<BSVariantStore>({} as BSVariantStore)

export function BSVariantContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsProjectId, bsVariantId } = useParams()
  const { createBSVariant, getBSVariants, updateBSVariant, deleteBSVariant } = useBSVariant()
  const { refreshAllVariantCalcul } = useBSCode()

  const { refreshAllBSProjectCard } = useContext(BSProjectListContext)
  const { refreshBimModelListByProjectId } = useContext(BSBimModelListContext)
  const { bsProject } = useContext(BSProjectContext)

  const [bsVariants, setBSVariants] = useState<BSVariant[]>([])
  const [selectedVariant, setSelectedVariant] = useState<BSVariant>()

  const [page, setPage] = useState(1)

  const fetchAllBSVariants: (projectId: string | undefined) => Promise<any> = useCallback(
    (projectId) => {
      if (projectId) {
        return getBSVariants(projectId).then((variants) => {
          if (variants) {
            setBSVariants(variants)
          }
          return variants
        })
      } else {
        setBSVariants([])
        return Promise.resolve()
      }
    },
    [getBSVariants]
  )

  const refreshAllCalcul: (bsBimModelId: string, codesExtraits: CodeExtrait[]) => Promise<void> = useCallback(
    (bsBimModelId, codesExtraits) => {
      const codesExtraitsCreation: CodeExtraitCreation[] = codesExtraits
        .filter((codeExtrait) => codeExtrait.errors.length === 0)
        .map((codeExtrait) => CodeExtraitCreation.fromCodeExtrait(codeExtrait))

      if (bsProjectId) {
        const updatedBSVariants = bsVariants.map((bsVariant) => {
          if (bsVariant.calculStatus === CalculStatusEnum.READY) {
            return {
              ...bsVariant,
              calculStatus: CalculStatusEnum.CALCUL_IN_PROGRESS,
            }
          }
          return bsVariant
        })
        setBSVariants(updatedBSVariants)
        return refreshAllVariantCalcul(bsBimModelId, codesExtraitsCreation, bsProjectId)
      }
      return Promise.resolve()
    },
    [bsProjectId, bsVariants, setBSVariants, refreshAllVariantCalcul]
  )

  const updateCalculStatusState: (status: CalculStatusEnum, variantId: string) => void = useCallback((status, variantId) => {
    setBSVariants((prev) => prev.map((variant) => (variant.id === variantId ? { ...variant, status } : variant)))
  }, [])

  const updateVariant: (bsVariantUpdateDto: BSVariantUpdateDto) => Promise<void | BSVariant> = useCallback(
    (bsVariantUpdateDto: BSVariantUpdateDto) => {
      if (bsProjectId && bsProjectId !== "new") {
        return updateBSVariant(bsVariantUpdateDto).then((updatedBsVariant) => {
          setSelectedVariant(updatedBsVariant)
          setBSVariants((prevState) => prevState.map((item) => (item.id === updatedBsVariant.id ? updatedBsVariant : item)))
          return updatedBsVariant
        })
      }
      return Promise.resolve()
    },
    [bsProjectId, updateBSVariant]
  )

  const deleteVariant: () => Promise<any> = useCallback(() => {
    if (bsVariantId && bsVariantId !== "new") {
      return deleteBSVariant(bsVariantId).then(() => {
        refreshAllBSProjectCard()
        const promise1 = refreshBimModelListByProjectId()
        let promise2: Promise<any> = Promise.resolve()
        if (bsProjectId) {
          promise2 = fetchAllBSVariants(bsProjectId)
        }
        return Promise.all([promise1, promise2])
      })
    }
    return Promise.resolve()
  }, [
    bsProjectId,
    bsVariantId,
    deleteBSVariant,
    fetchAllBSVariants,
    refreshAllBSProjectCard,
    refreshBimModelListByProjectId,
  ])

  useEffect(() => {
    if (bsProjectId) {
      fetchAllBSVariants(bsProjectId)
    }
  }, [bsProjectId, fetchAllBSVariants, bsVariantId, page])

  useEffect(() => {
    if (bsVariantId) {
      const variantFound = bsVariants.find((bsVariant) => bsVariant.id === bsVariantId)
      if (variantFound) {
        setSelectedVariant(variantFound)
      }
    } else {
      setSelectedVariant(undefined)
    }
  }, [bsVariantId, bsVariants])

  const createVariant: (
    bsVariantCreationDto: BSVariantCreationDto,
    sourceVariantId: string | undefined
  ) => Promise<BSVariant> = useCallback(
    (bsVariantCreationDto, sourceVariantId) =>
      createBSVariant(bsVariantCreationDto, sourceVariantId).then((newBsVariant) => {
        setBSVariants((prev) => [...prev, newBsVariant])
        setSelectedVariant(newBsVariant)
        fetchAllBSVariants(bsProject?.id)
        refreshAllBSProjectCard()
        return newBsVariant
      }),
    [createBSVariant, fetchAllBSVariants, bsProject?.id, refreshAllBSProjectCard]
  )

  const bsVariantStore = useMemo(
    () => ({
      bsVariants,
      selectedVariant,
      setSelectedVariant,
      createVariant,
      fetchAllBSVariants,
      updateVariant,
      setBSVariants,
      updateCalculStatusState,
      page,
      setPage,
      deleteVariant,
      refreshAllCalcul,
    }),
    [
      bsVariants,
      createVariant,
      deleteVariant,
      fetchAllBSVariants,
      page,
      selectedVariant,
      updateCalculStatusState,
      updateVariant,
      refreshAllCalcul,
    ]
  )

  return <BSVariantContext.Provider value={bsVariantStore}>{children}</BSVariantContext.Provider>
}

export type BSVariantStore = {
  bsVariants: BSVariant[]
  setBSVariants: React.Dispatch<React.SetStateAction<BSVariant[]>>
  selectedVariant: BSVariant | undefined
  setSelectedVariant: React.Dispatch<React.SetStateAction<BSVariant | undefined>>
  createVariant(bsVariantCreationDto: BSVariantCreationDto, sourceVariantId: string | undefined): Promise<BSVariant>
  fetchAllBSVariants(projectId: string): Promise<BSVariantCardWithSizeDto>
  updateVariant(bsVariantCreationDto: BSVariantCreationDto): Promise<void | BSVariant>
  updateCalculStatusState(status: CalculStatusEnum, variantId: string): void
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  deleteVariant(): Promise<any>
  refreshAllCalcul(bsBimModelId: string, codesExtraits: CodeExtrait[]): Promise<void>
}
