import { Box, Typography } from "@mui/material"
import React from "react"
import { customPalette } from "../../../../theme"

interface IProps {
  label: string
}

export function DashboardSectionTitle({ label }: Readonly<IProps>): React.JSX.Element {
  return (
    <Box sx={{ backgroundColor: customPalette.dashboardBackgroundBlue, paddingX: 2, paddingY: 1, mb: 2 }}>
      <Typography variant="body2" sx={{ fontWeight: "bold", fontSize: 14 }}>
        {label}
      </Typography>
    </Box>
  )
}
