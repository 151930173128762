import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { BSVariantResult } from "../../../dto/beem-shot/BSVariantResult/BSVariantResult"
import { BSVariantContext } from "../BSVariant/BSVariantContext"
import { useBSDashboard } from "../../../hooks/beem-shot/useBSDashboard"
import { Children } from "../../../../components/miscellianous/children"
import { CalculStatusEnum } from "../../../enum/calculStatusEnum"

export const BSVariantResultContext = React.createContext<BSVariantResultStore>({} as BSVariantResultStore)

export function BSVariantResultContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { selectedVariant } = useContext(BSVariantContext)
  const { getBSVariantResult } = useBSDashboard()
  const [bsVariantResult, setBSVariantResult] = useState<BSVariantResult | undefined>(undefined)

  const refreshVariantResult: () => void = useCallback(() => {
    if (selectedVariant?.id && selectedVariant.calculStatus === CalculStatusEnum.READY) {
      getBSVariantResult(selectedVariant?.id).then((response) => {
        setBSVariantResult(response)
      })
    }
  }, [getBSVariantResult, selectedVariant])

  useEffect(() => {
    refreshVariantResult()
  }, [refreshVariantResult])

  const bsVariantResultStore = useMemo(
    () => ({
      bsVariantResult,
      refreshVariantResult,
    }),
    [bsVariantResult, refreshVariantResult]
  )
  return <BSVariantResultContext.Provider value={bsVariantResultStore}>{children}</BSVariantResultContext.Provider>
}

export interface BSVariantResultStore {
  bsVariantResult: BSVariantResult | undefined

  refreshVariantResult(): void
}
