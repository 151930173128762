import { Theme } from "@emotion/react"
import { SxProps, Typography } from "@mui/material"
import React from "react"

export interface ITextInputProps {
  label: string
  fontWeight?: number
  color?: string
  fontSize?: string
  lineHeight?: string
  textDecoration?: "none" | "underline"
  textTransfrom?: "none"
  textAlign?: "left" | "center" | "right"
  sx?: SxProps<Theme>
  wordWrap?: "normal" | "break-word"
}

/**
 * Default component to display text
 *
 * @param  {label} text to display
 */

export function DefaultTypography({
  label,
  fontWeight = 600,
  color = "#000",
  fontSize = "16px",
  lineHeight = "24px",
  textDecoration = "none",
  textTransfrom = "none",
  textAlign = "left",
  wordWrap = "normal",
  sx = {},
}: Readonly<ITextInputProps>): React.JSX.Element {
  return (
    <Typography
      textTransform={textTransfrom}
      sx={{
        fontFamily: "Poppins",
        fontSize,
        fontWeight,
        lineHeight,
        textAlign,
        color,
        textDecoration,
        wordWrap,
        maxWidth: "100%",
        ...sx,
      }}>
      {label}
    </Typography>
  )
}
