import { animated, to } from "@react-spring/web"
import { BulletRectsItemProps } from "@nivo/bullet"
import React from "react"

interface RectWithLabelProps extends BulletRectsItemProps {
  label: string
}

/**
 * Built from :
 * https://github.com/plouc/nivo/blob/master/packages/bullet/src/BulletRectsItem.tsx
 * I just added a label
 */
export function BulletRectsItem({
  animatedProps: { x, y, width, height, color },
  borderColor,
  borderWidth,
  data,
  onMouseEnter,
  onMouseMove,
  onMouseLeave,
  onClick,
  label,
}: RectWithLabelProps): React.JSX.Element {
  return (
    <g>
      {/* Barre principale */}
      <animated.rect
        x={x}
        y={y}
        width={to(width, (value) => Math.max(value, 0))}
        height={to(height, (value) => Math.max(value, 0))}
        fill={color}
        strokeWidth={borderWidth}
        stroke={borderColor}
        onMouseMove={(event) => onMouseMove(data, event)}
        onMouseEnter={(event) => onMouseEnter(data, event)}
        onMouseLeave={(event) => onMouseLeave(data, event)}
        onClick={(event) => onClick(data, event)}
      />

      {/* Label dans un <div> */}
      <animated.foreignObject
        x={x}
        y={y}
        width={to(width, (value) => Math.max(value, 0))}
        height={to(height, (value) => Math.max(value, 0))}>
        <div
          style={{
            fontSize: 12,
            color: "white",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            // pointerEvents: "none" => si tu veux laisser la barre cliquable
          }}>
          {label ?? ""}
        </div>
      </animated.foreignObject>
    </g>
  )
}
