import { useMemo } from "react"
import { appConstants } from "../../appConstants"
import { BSVariant } from "../../dto/beem-shot/BSVariant/BSVariant"
import { BSVariantCreationDto } from "../../dto/beem-shot/BSVariant/BSVariantCreateDto"
import { BSVariantUpdateDto } from "../../dto/beem-shot/BSVariant/BSVariantUpdateDto"
import { resolveUrl } from "../../services/http-service"
import { useHttp } from "../use-http"
import { BSBimModel } from "../../dto/beem-shot/BSBimModel/BSBimModel"
import { BSCodeExtraitCreation } from "../../dto/code-extrait/BSCodeExtraitCreation"
import { CodeExtraitDisplay } from "../../dto/code-extrait/CodeExtraitDisplay"
import { stringToNumber } from "../../services/helper-service"

type BSVariantHook = {
  createBSVariant(beemShotProjectCreationDto: BSVariantCreationDto, sourceVariantId: string | undefined): Promise<BSVariant>
  getBSVariants(projectId: string): Promise<BSVariant[]>
  updateBSVariant(bsVariantUpdateDto: BSVariantUpdateDto): Promise<BSVariant>
  deleteBSVariant(bsVariantId: string): Promise<void>
  getVariantsByBSBimModelId(bsBimModelId: string): Promise<BSVariant[]>
  affectBSBimModelToVariant(
    bsVariantId: string,
    bsBimModelId: string,
    codesExtraits: BSCodeExtraitCreation[]
  ): Promise<BSBimModel>
  fetchCodeExtrait(bsBimModelId: string): Promise<CodeExtraitDisplay[]>
}

export function useBSVariant(): BSVariantHook {
  const { get, put, post } = useHttp()

  return useMemo(
    () => ({
      createBSVariant(bsVariantCreationDto: BSVariantCreationDto, sourceVariantId: string | undefined): Promise<BSVariant> {
        const params = sourceVariantId
          ? [
              {
                key: "sourceVariantId",
                value: sourceVariantId,
              },
            ]
          : []

        return post(appConstants.apiEndpoints.BEEM_SHOT_VARIANT, bsVariantCreationDto, params).then((response) =>
          response.json()
        )
      },
      getBSVariants(projectId: string): Promise<BSVariant[]> {
        return get(`${appConstants.apiEndpoints.BEEM_SHOT_ALL_VARIANT}`, [
          {
            key: "projectId",
            value: projectId,
          },
        ]).then((response) => response.json())
      },
      updateBSVariant(bsVariantUpdateDto: BSVariantUpdateDto): Promise<BSVariant> {
        return put(appConstants.apiEndpoints.BEEM_SHOT_VARIANT_UPDATE, bsVariantUpdateDto).then((response) =>
          response.json()
        )
      },
      deleteBSVariant(bsVariantId: string): Promise<void> {
        return post(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_VARIANT_DELETE, [bsVariantId]), {}).then()
      },
      getVariantsByBSBimModelId(bsBimModelId: string): Promise<BSVariant[]> {
        return get(appConstants.apiEndpoints.BEEM_SHOT_VARIANT_BY_BIM_MODEL, [
          {
            key: "bsBimModelId",
            value: bsBimModelId,
          },
        ]).then((response) => response.json())
      },
      affectBSBimModelToVariant(
        bsVariantId: string,
        bsBimModelId: string,
        codesExtraits: BSCodeExtraitCreation[]
      ): Promise<BSBimModel> {
        return put(
          resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_VARIANT_BIM_MODEL_AFFECTATION, [], { bsVariantId, bsBimModelId }),
          codesExtraits
        )
          .then((response) => response.json())
          .then((data) => BSBimModel.fromDto(data))
      },
      fetchCodeExtrait(bsVariantId: string): Promise<CodeExtraitDisplay[]> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_VARIANT_CODE_EXTRAIT_LIST, []), [
          {
            key: "bsVariantId",
            value: bsVariantId,
          },
        ]).then((r) => r.json())
      },
    }),
    [get, post, put]
  )
}
