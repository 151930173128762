import AddIcon from "@mui/icons-material/Add"
import { Box, Button, Grid, Paper, Typography } from "@mui/material"
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ViewerTypes } from "../../../../components/ifc-displayer/enums/ViewerTypes"
import IfcDisplayer from "../../../../components/ifc-displayer/ifc-displayer"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { adminPagesUrl, pagesUrl } from "../../../../core/appConstants"
import { BimModelFileContext } from "../../../../core/context/bim-model/bim-model-file-context"
import { CalculationContext } from "../../../../core/context/calculation/calculation-context"
import { CodesCompletionsContext } from "../../../../core/context/code-acv/codes-completions-context"
import { ProjectContext } from "../../../../core/context/project/project-context"
import { ProjectRoleContext } from "../../../../core/context/user/project-role-context"
import { VariantContext } from "../../../../core/context/variant-context"
import { CodesCompletionsPerCodeParent } from "../../../../core/data-structures/codes-completions-per-code-parent"
import AdditionalCodeCreation from "../../../../core/dto/additional-code/additional-code-creation"
import { AdditionalCodeForVariant } from "../../../../core/dto/additional-code/additional-code-for-variant"
import AdditionalCodeUpdate from "../../../../core/dto/additional-code/additional-code-update"
import CodeCustom from "../../../../core/dto/code-acv-custom/code-custom"
import CodeReference from "../../../../core/dto/code-acv/code-reference"
import { CodeCompletion } from "../../../../core/dto/code-completion/code-completion"
import { CodeExtrait } from "../../../../core/dto/code-extrait/code-extrait"
import { CalculationStepsEnum } from "../../../../core/enum/calculationStepsEnum"
import { RoleEnum } from "../../../../core/enum/roleEnum"
import { useUpdateCalculateCarbonAdditionalData } from "../../../../core/hooks/calcul-carbon/use-calcul-carbon-additional-data-mutation"
import { useCalculation } from "../../../../core/hooks/use-calculation"
import { useCode } from "../../../../core/hooks/use-code"
import { resolveUrl } from "../../../../core/services/http-service"
import AdditionalAndCompletionModal from "../components/additional-and-completion-modal"
import { CompletionListV1v2 } from "../components/completion-list-v1v2"
import FormAddCompletion from "../components/form-add-completion"
import ProjectCarbonBreadcrumb from "../components/project-carbon-breadcrumb"
import ProjectCarbonCalculatorHeader from "../components/project-carbon-calculator-header"
import SelectVariant from "../components/select-variant"
import CheckIsSame from "../extracted-code-page/check-is-same"
import AdditionalCodeList from "./additional-code-list"
import SynchroV1v2ConfirmationDialog from "./components/synchro-v1v2-confirmation-modal"
import FormAddMaterialToAdditional from "./form-add-material-to-additional"
import { CodeExtraitDisplay } from "../../../../core/dto/code-extrait/CodeExtraitDisplay"

export function AdditionalCodePage(): React.JSX.Element {
  const navigate = useNavigate()
  const { project } = useContext(ProjectContext)
  const { variant1, variant2 } = useContext(VariantContext)
  const { file } = useContext(BimModelFileContext)
  const { calculation } = useContext(CalculationContext)
  const { setCodesCompletionsPerCodeParentV2 } = useContext(CodesCompletionsContext)
  const openErrorSnackbar = useContext(ErrorContext)
  const { hasRole, isOrganizationUltimateUser } = useContext(ProjectRoleContext)
  const { organizationId } = useParams()

  const { loading } = useUpdateCalculateCarbonAdditionalData()
  const { updateSameAdditionalV1V2, copyAdditionalCodeListV1ToV2 } = useCalculation()
  const {
    fetchAdditionalCodesForVariant,
    createAdditionalCode,
    createAdditionalCodeV1V2,
    updateAdditionalCode,
    updateCodesCompletionsForAdditionalCode,
    deleteAdditionalCode,
  } = useCode()

  const [variantCategory, setVariantCategory] = useState<1 | 2>(1)
  const [selectedAdditionalCode, setSelectedAdditionalCode] = useState<CodeCompletion | undefined>(undefined)
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)
  const [additionalCodeForm, setAdditionalCodeForm] = useState<AdditionalCodeCreation>(new AdditionalCodeCreation())
  const [isCodeAddtionalCreation, setIsCodeAddtionalCreation] = useState<boolean>(false)
  const [additionalCodeListV1, setAdditionalCodeListV1] = useState<CodeCompletion[]>([])
  const [additionalCodeListV2, setAdditionalCodeListV2] = useState<CodeCompletion[]>([])
  const [isSameAdditionalV1V2, setIsSameAdditionalV1V2] = useState<boolean>(calculation.sameAdditionalV1V2)
  const [isAdditionalCodesLoading, setIsAdditionalCodesLoading] = useState<boolean>(true)
  const [isAdditionalCodeModalOpen, setIsAdditionalCodeModalOpen] = useState<boolean>(false)
  const [isSynchronizingV1V2, setIsSynchronizingV1V2] = useState<boolean>(false)
  const [openV1V2SynchronizationDialog, setOpenV1V2SynchronizationDialog] = useState<boolean>(false)
  const hasDeleted = useRef<boolean>(false)
  const isWriting = useMemo(() => hasRole([RoleEnum.ADMINISTRATOR, RoleEnum.BE]), [hasRole])

  const handleChangeVariant = useCallback((variant: 1 | 2): void => {
    setVariantCategory(variant)
  }, [])

  const handleClickNextStep = useCallback((): void => {
    isOrganizationUltimateUser
      ? navigate(resolveUrl(adminPagesUrl.PROJECT_CALCULATION_WORKSITE, [organizationId, project?.id]))
      : navigate(resolveUrl(pagesUrl.PROJECT_CALCULATION_WORKSITE, [project?.id]))
  }, [isOrganizationUltimateUser, navigate, organizationId, project?.id])

  const handleClickPreviousStep = useCallback((): void => {
    isOrganizationUltimateUser
      ? navigate(resolveUrl(adminPagesUrl.PROJECT_CALCULATION_EXTRACTED_DATA, [organizationId, project?.id]))
      : navigate(resolveUrl(pagesUrl.PROJECT_CALCULATION_EXTRACTED_DATA, [project?.id]))
  }, [isOrganizationUltimateUser, navigate, organizationId, project?.id])

  useEffect(() => {
    if (calculation?.id) {
      setIsSameAdditionalV1V2(calculation.sameAdditionalV1V2)
    }
  }, [calculation?.id, calculation.sameAdditionalV1V2])

  useEffect(() => {
    if (project.id && variant1.id && variant2.id) {
      setIsAdditionalCodesLoading(true)
      const promise1 = fetchAdditionalCodesForVariant(project.id, variant1.id).then((res) => {
        setAdditionalCodeListV1(res)
      })
      const promise2 = fetchAdditionalCodesForVariant(project.id, variant2.id).then((res) => {
        setAdditionalCodeListV2(res)
      })
      Promise.all([promise1, promise2]).finally(() => setIsAdditionalCodesLoading(false))
    } else {
      setIsAdditionalCodesLoading(false)
    }
  }, [setIsAdditionalCodesLoading, fetchAdditionalCodesForVariant, project.id, variant1.id, variant2.id])

  useEffect(() => {
    // Reset selection everytime we change variant
    resetSelection()
  }, [variantCategory])

  useEffect(() => {
    // Reset selection everytime an element is deleted
    if (hasDeleted.current) {
      resetSelection()
      hasDeleted.current = false
    }
  }, [hasDeleted.current])

  useEffect(() => {
    if (selectedAdditionalCode) {
      const list = variantCategory === 1 ? additionalCodeListV1 : additionalCodeListV2
      const number = list.findIndex((code) => code.id === selectedAdditionalCode.id)
      setSelectedIndex(number)
    } else {
      setSelectedIndex(-1)
    }
  }, [selectedAdditionalCode])

  // The selectedIndex is set accordingly in a useEffect
  function resetSelection(): void {
    const currentList = variantCategory === 1 ? additionalCodeListV1 : additionalCodeListV2
    if (currentList && currentList.length > 0 && currentList.length - 1 < selectedIndex) {
      setSelectedAdditionalCode(currentList[0])
    } else if (!currentList || currentList.length === 0) {
      setSelectedAdditionalCode(undefined)
    } else {
      setSelectedAdditionalCode(currentList[selectedIndex])
    }
  }

  const handleAdditionalCodeCloseModal = (): void => {
    setIsAdditionalCodeModalOpen(false)
  }

  function handleCreateOrUpdateAdditional(): void {
    handleAdditionalCodeCloseModal()

    if (project?.id && !isSynchronizingV1V2) {
      if (isCodeAddtionalCreation) {
        handleCreateAdditional(project.id)
      } else if (selectedAdditionalCode) {
        selectedAdditionalCode.lot = additionalCodeForm.lot
        selectedAdditionalCode.sousLot = additionalCodeForm.sousLot
        selectedAdditionalCode.descriptionFromCodeAcv = additionalCodeForm.descriptionFromCodeAcv
        selectedAdditionalCode.name = additionalCodeForm.name
        handleUpdateAdditionalCode(project.id, selectedAdditionalCode)
      }
    }
  }

  function handleCreateAdditional(projectId: string): void {
    if (!variant1?.id || !variant2?.id) {
      return
    }
    if (isSameAdditionalV1V2 && variantCategory === 1) {
      createAdditionalCodeV1V2(projectId, variant1.id, variant2.id, additionalCodeForm).then((array) => {
        const createdAdditionalCodeV1 = array[0]
        const createdAdditionalCodeV2 = array[1]
        setAdditionalCodeListV1([...additionalCodeListV1, createdAdditionalCodeV1])
        setAdditionalCodeListV2([...additionalCodeListV2, createdAdditionalCodeV2])
        selectCode(createdAdditionalCodeV1)
      })
    } else if (isSameAdditionalV1V2 && variantCategory === 2) {
      // Create the code
      createAdditionalCode(projectId, variant2.id, additionalCodeForm).then((createdAdditionalCode) => {
        setAdditionalCodeListV2([...additionalCodeListV2, createdAdditionalCode])
        selectCode(createdAdditionalCode)
      })
      // Disable is same
      handleUpdateIsSameValuesV1V2(false)
    } else {
      const variantId = variantCategory === 1 ? variant1.id : variant2.id
      createAdditionalCode(projectId, variantId, additionalCodeForm).then((createdAdditionalCode) => {
        variantCategory === 1
          ? setAdditionalCodeListV1([...additionalCodeListV1, createdAdditionalCode])
          : setAdditionalCodeListV2([...additionalCodeListV2, createdAdditionalCode])
        selectCode(createdAdditionalCode)
      })
    }
  }

  function handleUpdateAdditionalCode(projectId: string, additionalCodeToUpdate: CodeCompletion): void {
    if (!variant1?.id || !variant2?.id) {
      return
    }
    const updateRequest: AdditionalCodeUpdate | undefined = AdditionalCodeUpdate.from(additionalCodeToUpdate)
    if (updateRequest === undefined) {
      return
    }

    if (isSameAdditionalV1V2 && variantCategory === 1) {
      // Update v1+selectedCode
      const indexToUpdate: number = updateOneAdditionalCode(
        projectId,
        variant1.id,
        additionalCodeListV1,
        updateRequest,
        setAdditionalCodeListV1
      )
      // Update v2
      let codeIdV2: string
      if (additionalCodeListV2.length <= indexToUpdate || additionalCodeListV2[indexToUpdate].id === undefined) {
        openErrorSnackbar(new Error("La liste pour la variante 2 est différente de la variante 1"))
        return
      } else {
        codeIdV2 = additionalCodeListV2[indexToUpdate].id as string
      }
      // Do not use the function to avoid select the code in variant 2
      updateAdditionalCode(projectId, variant2.id, { ...updateRequest, id: codeIdV2 }).then((updatedAdditionalCode) => {
        // Code completion from backend doesn't contain material information
        updatedAdditionalCode.material = selectedAdditionalCode?.material
        const newList = [...additionalCodeListV2]
        newList[indexToUpdate] = updatedAdditionalCode
        setAdditionalCodeListV2(newList)
      })
    } else if (isSameAdditionalV1V2 && variantCategory === 2) {
      updateOneAdditionalCode(projectId, variant2.id, additionalCodeListV2, updateRequest, setAdditionalCodeListV2)
      // Disable is same
      handleUpdateIsSameValuesV1V2(false)
    } else {
      const [variantId, additionalCodeList, setAdditionalCodeList] =
        variantCategory === 1
          ? [variant1.id, additionalCodeListV1, setAdditionalCodeListV1]
          : [variant2.id, additionalCodeListV2, setAdditionalCodeListV2]
      updateOneAdditionalCode(projectId, variantId, additionalCodeList, updateRequest, setAdditionalCodeList)
    }
  }

  function updateOneAdditionalCode(
    projectId: string,
    variantId: string,
    listToUpdate: CodeCompletion[],
    updateRequest: AdditionalCodeUpdate,
    setList: Dispatch<SetStateAction<CodeCompletion[]>>
  ): number {
    const indexToUpdate: number = findIndexInList(listToUpdate, updateRequest.id)
    updateAdditionalCode(projectId, variantId, updateRequest).then((updatedAdditionalCode) => {
      // Code completion from backend doesn't contain material information
      updatedAdditionalCode.material = selectedAdditionalCode?.material
      const newList = [...listToUpdate]
      newList[indexToUpdate] = updatedAdditionalCode
      setList(newList)
      selectCode(updatedAdditionalCode)
    })
    return indexToUpdate
  }

  function findIndexInList(list: CodeCompletion[], id: string): number {
    return list.findIndex((element: CodeCompletion) => element.id === id)
  }

  function selectCode(additionalCode: CodeCompletion): void {
    setSelectedAdditionalCode(additionalCode)
  }

  function openCreateAdditionalCodeModal(): void {
    setAdditionalCodeForm(new AdditionalCodeCreation())
    setIsCodeAddtionalCreation(true)
    setIsAdditionalCodeModalOpen(true)
  }

  function openUpdateAdditionalCodeModal(codeCompletion: CodeCompletion): void {
    setIsCodeAddtionalCreation(false)
    const updateForm = AdditionalCodeUpdate.from(codeCompletion)
    if (updateForm) {
      setAdditionalCodeForm(updateForm)
      setIsAdditionalCodeModalOpen(true)
    }
  }

  function handleChangeAdditionalForm(field: string, value: string | undefined): void {
    setAdditionalCodeForm({ ...additionalCodeForm, [field]: value })
  }

  function createNewCodeCompletionFromCustom(
    codeCustomToAdd: CodeCustom,
    codeParent: CodeExtrait | CodeCompletion,
    variant: number
  ): CodeCompletion | undefined {
    if (!variant1?.id || !variant2?.id) {
      return undefined
    }

    const variantId = variant === 1 ? variant1.id : variant2.id
    if (codeParent && selectedAdditionalCode?.variantId) {
      return CodeCompletion.fromAcvCustom(codeCustomToAdd, codeParent, variantId)
    } else {
      return undefined
    }
  }

  function createNewCodeCompletionFromAcv(
    codeAcvToAdd: CodeReference,
    codeParent: CodeExtrait | CodeCompletion,
    variant: number
  ): CodeCompletion | undefined {
    const variantId = variant === 1 ? variant1.id : variant2.id
    if (codeParent && variantId) {
      return CodeCompletion.fromAcv(codeAcvToAdd, codeParent, variantId)
    } else {
      return undefined
    }
  }

  function disableSameValuesIfNeed(): void {
    if (variantCategory === 2 && selectedAdditionalCode) {
      handleUpdateIsSameValuesV1V2(false)
    }
  }

  function handleCheckIsSameValuesV1V2(e: ChangeEvent<HTMLInputElement>): void {
    const checked: boolean = e.target.checked
    if (checked) {
      setOpenV1V2SynchronizationDialog(true)
    } else {
      handleUpdateIsSameValuesV1V2(checked)
    }
  }

  function handleUpdateIsSameValuesV1V2(checked: boolean): Promise<void> {
    if (calculation?.id) {
      setIsSameAdditionalV1V2(checked)

      let promise
      if (checked) {
        promise = copyV1AdditionalCodeToV2(calculation.id)
      } else {
        promise = updateSameAdditionalV1V2(calculation.id, checked)
      }

      return promise
        .then(() => {
          // Shadow update
          calculation.sameAdditionalV1V2 = checked
          setIsSameAdditionalV1V2(checked)
        })
        .catch((err) => {
          setIsSameAdditionalV1V2(calculation.sameAdditionalV1V2)
          openErrorSnackbar(err)
        })
    } else {
      return Promise.resolve()
    }
  }

  function copyV1AdditionalCodeToV2(calculationId: string): Promise<void> {
    setIsSynchronizingV1V2(true)

    return copyAdditionalCodeListV1ToV2(calculationId)
      .then((result: AdditionalCodeForVariant) => {
        setAdditionalCodeListV2(result.additionalCodeList)
        const codesCompletionsPerCodeParent = CodesCompletionsPerCodeParent.from(result.codeCompletionForVariant)
        setCodesCompletionsPerCodeParentV2(codesCompletionsPerCodeParent)
        setIsSynchronizingV1V2(false)
      })
      .catch((e) => {
        setIsSynchronizingV1V2(false)
        throw e
      })
  }

  function handleDeleteAdditionalCode(additionalCode: CodeCompletion): void {
    if (!variant1?.id || !variant2?.id) {
      return
    }

    if (project?.id && additionalCode?.variantId && additionalCode?.id) {
      if (variantCategory === 1 && isSameAdditionalV1V2) {
        const number = findIndexInList(additionalCodeListV1, additionalCode.id)
        deleteAdditionalCode(project.id, variant1.id, additionalCode.id).then(() => {
          setAdditionalCodeListV1(additionalCodeListV1.filter((item) => item.id !== additionalCode.id))
          hasDeleted.current = true
        })
        const idToDeleteV2 = additionalCodeListV2[number].id
        if (idToDeleteV2) {
          deleteAdditionalCode(project.id, variant2.id, idToDeleteV2).then(() => {
            setAdditionalCodeListV2(additionalCodeListV2.filter((item) => item.id !== idToDeleteV2))
          })
        } else {
          openErrorSnackbar(new Error("La liste des codes de la variante 2 n'est pas synchonisée avec la variante 1."))
        }
      } else if (variantCategory === 2 && isSameAdditionalV1V2) {
        deleteAdditionalCode(project.id, variant2.id, additionalCode.id).then(() => {
          setAdditionalCodeListV2(additionalCodeListV2.filter((item) => item.id !== additionalCode.id))
          hasDeleted.current = true
        })
        handleUpdateIsSameValuesV1V2(false)
      } else {
        const [variantId, additionalCodeList, setAdditionalCodeList] =
          variantCategory === 1
            ? [variant1.id, additionalCodeListV1, setAdditionalCodeListV1]
            : [variant2.id, additionalCodeListV2, setAdditionalCodeListV2]
        deleteAdditionalCode(project.id, variantId, additionalCode.id).then(() => {
          setAdditionalCodeList(additionalCodeList.filter((item) => item.id !== additionalCode.id))
          hasDeleted.current = true
        })
      }
    }
  }

  function findV1AdditionalCode(): CodeCompletion | undefined {
    if (selectedIndex !== -1 && additionalCodeListV1.length > selectedIndex) {
      return additionalCodeListV1[selectedIndex]
    }
    return undefined
  }

  function findV2AdditionalCode(): CodeCompletion | undefined {
    if (selectedIndex !== -1 && additionalCodeListV2.length > selectedIndex) {
      return additionalCodeListV2[selectedIndex]
    }
    return undefined
  }

  // This action is different between code extrait and additional code
  function sendCompletionList(
    codeParentIdV1: string,
    codeCompletionListV1: CodeCompletion[],
    codeParentIdV2: string,
    codeCompletionListV2: CodeCompletion[]
  ): Promise<CompletionListV1v2> {
    if (project?.id) {
      if (isSameAdditionalV1V2 && variantCategory === 1) {
        const promiseV1: Promise<CodeCompletion[]> = updateCodesCompletionsForAdditionalCode(
          codeParentIdV1,
          codeCompletionListV1
        )
        const promiseV2: Promise<CodeCompletion[]> = updateCodesCompletionsForAdditionalCode(
          codeParentIdV2,
          codeCompletionListV2
        )
        return Promise.all([promiseV1, promiseV2]).then((a) => ({ listV1: a[0], listV2: a[1] }))
      } else if (variantCategory === 2) {
        if (isSameAdditionalV1V2) {
          handleUpdateIsSameValuesV1V2(false)
        }
        const promiseV2: Promise<CodeCompletion[]> = updateCodesCompletionsForAdditionalCode(
          codeParentIdV2,
          codeCompletionListV2
        )
        return promiseV2.then((listV2) => ({ listV1: codeCompletionListV1, listV2 }))
      } else {
        return updateCodesCompletionsForAdditionalCode(codeParentIdV1, codeCompletionListV1).then((listV1) => ({
          listV1,
          listV2: codeCompletionListV2,
        }))
      }
    }
    return Promise.resolve({ listV1: [], listV2: [] })
  }

  const setSelectedCodeExtrait = useCallback((code: CodeExtraitDisplay): CodeExtraitDisplay => code, [])

  return (
    <>
      <SynchroV1v2ConfirmationDialog
        open={openV1V2SynchronizationDialog}
        setOpen={setOpenV1V2SynchronizationDialog}
        action={() => handleUpdateIsSameValuesV1V2(true)}
      />
      <Grid container>
        <Grid item xs={3}>
          <ProjectCarbonCalculatorHeader />
        </Grid>
        <Grid item xs={7}>
          <ProjectCarbonBreadcrumb
            selected={CalculationStepsEnum.ADDITIONAL_CODE}
            submit={() => Promise.resolve(true)}
            isWriting={isWriting}
          />
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              width: 200,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <Button variant="outlined" onClick={handleClickPreviousStep}>
              Retour
            </Button>
            <Button variant="contained" onClick={handleClickNextStep} disabled={loading}>
              Suivant
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <SelectVariant selectedVariant={variantCategory} handleChangeVariant={handleChangeVariant} />
        </Grid>
        {variantCategory === 2 ? (
          <CheckIsSame
            isSynchronizingCode={isSynchronizingV1V2}
            isChecked={isSameAdditionalV1V2}
            handleUpdateIsSameValuesV1V2={handleCheckIsSameValuesV1V2}
            disabled={!isWriting}
          />
        ) : (
          <Grid item xs={2} />
        )}
      </Grid>
      {/* Modal to create and update additional codes */}
      <AdditionalAndCompletionModal
        isOpen={isAdditionalCodeModalOpen}
        handleCloseModal={handleAdditionalCodeCloseModal}
        handleChange={handleChangeAdditionalForm}
        formValues={additionalCodeForm}
        handleCreateOrUpdate={handleCreateOrUpdateAdditional}
      />
      <Box sx={{ margin: "40px " }}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={4} sx={{ paddingRight: "32px" }}>
            <Button style={{ textTransform: "none" }} onClick={() => openCreateAdditionalCodeModal()}>
              <AddIcon sx={{ mr: 2 }} />
              Ajouter un nouvel élément
            </Button>
            <AdditionalCodeList
              selectedAdditionalCode={selectedAdditionalCode}
              additionalCodeList={variantCategory === 1 ? additionalCodeListV1 : additionalCodeListV2}
              isListLoading={isAdditionalCodesLoading}
              handleClickAcv={selectCode}
              handleEditAdditionalCode={openUpdateAdditionalCodeModal}
              handleDeleteAdditionalCode={handleDeleteAdditionalCode}
              disabled={!isWriting}
            />
          </Grid>
          <Grid item xs={4} sx={{ width: "100%", height: "auto" }}>
            {!file && (
              <Paper
                variant="outlined"
                sx={{
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  textAlign: "center",
                }}>
                <Typography>Modèle BIM</Typography>
              </Paper>
            )}
            {file && (
              <IfcDisplayer
                fullscreenButton
                fullscreenTabButton
                codesExtraits={[]}
                setSelectedCodeExtrait={setSelectedCodeExtrait}
                file={file}
                type={ViewerTypes.CALCULATION}
                height="500px"
              />
            )}
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={12}>
            {selectedAdditionalCode && (
              <Grid container columnSpacing={2}>
                <Grid item xs={4} sx={{ paddingRight: "32px" }}>
                  <FormAddMaterialToAdditional
                    selectedAdditionalCode={selectedAdditionalCode}
                    setSelectedAdditionalCode={setSelectedAdditionalCode}
                    handleUpdateAdditionalCode={handleUpdateAdditionalCode}
                    disabled={!isWriting}
                  />
                </Grid>
                {/*Form to add code completion on the additional code*/}
                <Grid item xs={8} sx={{ pr: 0, pl: 10, pt: 4, pb: 4, backgroundColor: "#ebebeb" }}>
                  <FormAddCompletion
                    selectedCodeParentV1={findV1AdditionalCode()}
                    selectedCodeParentV2={findV2AdditionalCode()}
                    variantCategory={variantCategory}
                    createNewCodeCompletionFromAcv={createNewCodeCompletionFromAcv}
                    createNewCodeCompletionFromCustom={createNewCodeCompletionFromCustom}
                    sendCompletionList={sendCompletionList}
                    disableSameValuesIfNeed={disableSameValuesIfNeed}
                    sameValuesV1V2={isSameAdditionalV1V2}
                    disabled={!isWriting}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
