import { Box, CircularProgress, createTheme, CssBaseline, Grid, ThemeProvider, Typography, useTheme } from "@mui/material"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { CustomBottomNavigation } from "../../../../components/buttons/navigate-button/CustomBottomNavigation"
import { BSIfcDisplayer } from "../../../../components/ifc-displayer/BSIfcDisplayer"
import { ViewerTypes } from "../../../../components/ifc-displayer/enums/ViewerTypes"
import { CachingHelper } from "../../../../components/ifc-displayer/helpers/CachingHelper"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { pagesUrl } from "../../../../core/appConstants"
import { BSBimModelContext } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelContext"
import { BSInputContext } from "../../../../core/context/beem-shot/BSInput/BSInputContext"
import { CodeReferenceContext } from "../../../../core/context/code-acv/CodeReferenceContext"
import { AppNavBarContext, NavbarTypeEnum } from "../../../../core/context/nav-bar/AppNavBarContext"
import { BSBimModel } from "../../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { useBSBimModel } from "../../../../core/hooks/beem-shot/useBSBimModel"
import { resolveUrl } from "../../../../core/services/http-service"
import { BSBimModelSelect } from "./BSBimModelSelect"
import { CodeExtrait } from "../../../../core/dto/code-extrait/code-extrait"
import { CodeExtraitDisplay } from "../../../../core/dto/code-extrait/CodeExtraitDisplay"
import { useViewerAnalyze } from "../../../../core/hooks/viewer/useViewerAnalyze"
import { BSItemContext } from "../../../../core/context/beem-shot/BSItems/BSItemContext"
import { codeExtraitListToCreationDto } from "../../../../core/services/code-service"

export function BSBimModelChoicePage(): React.JSX.Element {
  const theme = useTheme()
  const modifiedTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      background: {
        ...theme.palette.background,
        default: "#ffffff",
        paper: "#ffffff",
      },
    },
  })

  return (
    <ThemeProvider theme={modifiedTheme}>
      <CssBaseline />
      <BSBimModelChoicePageCore />
    </ThemeProvider>
  )
}

function BSBimModelChoicePageCore(): React.JSX.Element {
  const navigate = useNavigate()
  const { bsProjectId, bsVariantId } = useParams()
  const { getAllBimModelByProjectId, getBSBimModelFile } = useBSBimModel()

  const openErrorSnackbar = useContext(ErrorContext)
  const { setTypeNavBar, setPreviousUrl, setStepHeader } = useContext(AppNavBarContext)
  const { isCodeAcvLoading } = useContext(CodeReferenceContext)

  const { affectBSBimModel, isBSBimModelSubmitting } = useContext(BSBimModelContext)
  const { bsInput } = useContext(BSInputContext)
  const { refreshAllBSItems } = useContext(BSItemContext)

  const [selectedBSBimModel, setSelectedBSBimModel] = useState<BSBimModel | undefined>()
  const [isModelFileLoading, setIsModelFileLoading] = useState<boolean>(false)
  const [bimModelFile, setBimModelFile] = useState<File | undefined>()

  const [codesExtraits, setCodesExtraits] = useState<CodeExtrait[]>([])
  const [selectedCodeExtrait, setSelectedCodeExtrait] = useState<CodeExtraitDisplay>()
  const abortControllerRef = useRef<AbortController | null>(null)

  const { handleClickAcv, onChangeModel, progress, setProgress } = useViewerAnalyze(
    codesExtraits,
    setCodesExtraits,
    selectedCodeExtrait,
    setSelectedCodeExtrait
  )

  const changeBimModel: (newBimModel: BSBimModel | undefined) => void = useCallback(
    (newBimModel) => {
      setSelectedBSBimModel(newBimModel)
      // It's very important to set progress and the codes extraits list.
      // Because else, the users can submit with the previous data if they click on the submit button at the wrong time
      setProgress(0)
      setCodesExtraits([])
    },
    [setProgress]
  )

  useEffect(() => {
    if (selectedBSBimModel?.id) {
      setIsModelFileLoading(true)
      getBSBimModelFile(selectedBSBimModel.id, abortControllerRef)
        .then(async (blob) => {
          if (blob) {
            const fileName =
              selectedBSBimModel.fileName && selectedBSBimModel.fileName.length > 0
                ? selectedBSBimModel.fileName
                : "Model.ifc"
            const newFile = new File([blob], fileName, { type: "application/ifc" })
            CachingHelper.cacheFile(newFile)
            setSelectedCodeExtrait(undefined)
            setBimModelFile(newFile)
          }
        })
        .finally(() => setIsModelFileLoading(false))
    }
  }, [bsProjectId, getAllBimModelByProjectId, getBSBimModelFile, selectedBSBimModel?.fileName, selectedBSBimModel?.id])

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.HEAD_WITHOUT_STEP)
    setPreviousUrl(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, bsVariantId]))
    setStepHeader(0)
  }, [bsProjectId, bsVariantId, setPreviousUrl, setStepHeader, setTypeNavBar])

  function cancelAction(): void {
    navigate(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, bsVariantId]))
  }

  const affectSelectedBimModel: () => void = useCallback(() => {
    if (!selectedBSBimModel) {
      openErrorSnackbar(new Error("Veuillez sélectionner une maquette"))
      return
    }
    if (progress !== 100) {
      openErrorSnackbar(new Error("Veuillez attendre la fin du chargement de la maquette"))
      return
    }

    if (codesExtraits.length === 0) {
      openErrorSnackbar(new Error("Pas encore de codes extrait de la maquette"))
      return
    }

    if (bsInput?.bsBimModelId === selectedBSBimModel?.id) {
      navigate(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, bsVariantId]))
    } else if (selectedBSBimModel?.id && bsVariantId) {
      const codeExtraitCreationDto = codeExtraitListToCreationDto(codesExtraits)

      affectBSBimModel(bsVariantId, selectedBSBimModel?.id, codeExtraitCreationDto)
        .then(() => {
          refreshAllBSItems()
        })
        .then(() => {
          navigate(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, bsVariantId]))
        })
    }
  }, [
    affectBSBimModel,
    bsInput?.bsBimModelId,
    bsProjectId,
    bsVariantId,
    codesExtraits,
    navigate,
    openErrorSnackbar,
    progress,
    refreshAllBSItems,
    selectedBSBimModel,
  ])

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={2} height="70vh" pt={2}>
        <Grid item xs={5}>
          <BSBimModelSelect selectedBSBimModel={selectedBSBimModel} setSelectedBSBimModel={changeBimModel} />
        </Grid>

        <Grid item xs={7} sx={{ backgroundColor: "white" }}>
          {!selectedBSBimModel && (
            <Box height="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
              <Typography variant="h6" color="textSecondary">
                Aucune maquette sélectionnée
              </Typography>
              <Typography variant="body2" color="textSecondary" mt={1}>
                Choisissez une maquette dans la liste pour activer la prévisualisation.
              </Typography>
            </Box>
          )}
          {!isCodeAcvLoading && bimModelFile && !isModelFileLoading && (
            <BSIfcDisplayer
              file={bimModelFile}
              onChangeModel={onChangeModel}
              setExternalProgress={setProgress}
              type={ViewerTypes.CODE_VERIFICATION}
              codesExtraits={codesExtraits}
              showFileName
              height="70vh"
              setSelectedCodeExtrait={handleClickAcv}
            />
          )}

          {isModelFileLoading && (
            <Box height="100%" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Grid>

      <CustomBottomNavigation
        actionLabel="Valider"
        actionButton={affectSelectedBimModel}
        isSubmitting={isBSBimModelSubmitting}
        disableActionButton={
          selectedBSBimModel?.id === undefined && !isCodeAcvLoading && !isModelFileLoading && progress !== 100
        }
        cancelLabel="Annuler"
        cancelAction={cancelAction}
      />
    </>
  )
}
