import { v4 as uuidv4 } from "uuid"
import { appConstants } from "../../../core/appConstants"
import { CachedInfo } from "../models/CachedInfo"
import { FullScreenInfo } from "../models/FullscreenInfo"
import { CodeExtraitDisplay } from "../../../core/dto/code-extrait/CodeExtraitDisplay"

export class CachingHelper {
  /**
   * Creates a SHA256 hash of a string
   * @param  {string} text The string to hash
   */
  static async hash(text: string): Promise<string> {
    const utf8 = new TextEncoder().encode(text)
    const hashBuffer = await crypto.subtle.digest("SHA-256", utf8)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    return hashArray.map((bytes) => bytes.toString(16).padStart(2, "0")).join("")
  }

  /**
   * Creates the hash without putting the file in the cache
   * This algo is deterministic. The same file will always give the same hash
   *
   * @param  {File} file The file to generate a hash for
   */
  static async hashFile(file: File): Promise<string> {
    const fileContent = await this.readFileAsText(file)
    return this.hash(fileContent)
  }

  /**
   * Read the contents of a file as a string
   * @param  {File} file The file to read
   * @returns Promise The promise that resolves to the string
   */
  static async readFileAsText(file: File): Promise<string> {
    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
      reader.readAsText(file)
    })
  }

  /**
   * Caches a file with Cache API in user device and returns the hash of the file
   * @param file The file to cache
   * @returns Promise The promise that resolves to the hash of the file
   */
  static async cacheFile(file: File): Promise<string> {
    const fileContent = await this.readFileAsText(file)
    const fileHash = await this.hash(fileContent)

    const item: CachedInfo = {
      hash: fileHash,
      contents: fileContent,
    }
    caches.delete(appConstants.cacheApi.IFC_CACH)
    const response = new Response(JSON.stringify(item), {
      headers: { "Content-Type": "application/json" },
    })
    const cache = await caches.open(appConstants.cacheApi.IFC_CACH)
    cache.put(fileHash, response)
    return fileHash
  }

  /**
   * Gets a file from Cache API in user device using the hash of the file
   * @param hash The hash of the file
   * @param fileName The name of the file
   * @returns Promise The promise that resolves to the file or undefined if the file is not found
   */
  static async getCachedFile(hash?: string, fileName?: string): Promise<File | undefined> {
    if (!hash) {
      return undefined
    }
    const cache: Cache = await caches.open(appConstants.cacheApi.IFC_CACH)
    const cacheKeys: readonly Request[] = await cache.keys()
    const cachekey = cacheKeys.find((key) => key.url.split("/").pop() === hash)
    if (!cachekey) {
      return undefined
    }
    const response: Response | undefined = await cache.match(cachekey)

    if (response) {
      const item: CachedInfo = await response.json()
      const type = "application/ifc"
      const blob = new Blob([item.contents], { type })
      return new File([blob], fileName && fileName.length > 0 ? fileName : "Model.ifc", { type })
    }

    return undefined
  }

  /**
   * Creates a random token for full screen session
   * @returns string a random token using uuid
   */
  static createFullScreenToken(): string {
    return uuidv4()
  }

  /**
   * Caches a full screen session in Cache API in user device and returns the token of the session
   */
  static async setFullScreen(token: string, selectedCodeExtrait?: CodeExtraitDisplay): Promise<void> {
    const info: FullScreenInfo = {
      token,
      selectedCodeExtrait,
    }
    const response = new Response(JSON.stringify(info), {
      headers: { "Content-Type": "application/json" },
    })
    const cache = await caches.open(appConstants.cacheApi.FULLSCREEN_CACH)
    cache.put(token, response)
    localStorage.setItem(token, "false")
    localStorage.setItem(token, "true")
  }

  /**
   * Gets a full screen session from Cache API in user device and returns the token of the session
   * @param token The token of the full screen session
   * @returns Promise The promise that resolves to the full screen session info or undefined if the session is not found
   */
  static async getFullScreen(token: string): Promise<FullScreenInfo | undefined> {
    const cache = await caches.open(appConstants.cacheApi.FULLSCREEN_CACH)
    const cacheKeys = await cache.keys()
    const cachekey = cacheKeys.find((key) => key.url.split("/").pop() === token)
    if (!cachekey) {
      return undefined
    }
    const response = await cache.match(cachekey)

    if (response) {
      const info: FullScreenInfo = await response.json()
      return info
    }

    return undefined
  }

  /**
   * Deletes a full screen session from Cache API in user device
   * @param token The token of the full screen session
   * @returns Promise The promise that resolves to true if the session is deleted or false if the session is not found
   */
  static async deleteFullScreen(token: string): Promise<boolean> {
    const cache = await caches.open(appConstants.cacheApi.FULLSCREEN_CACH)
    const cacheKeys = await cache.keys()
    const cachekey = cacheKeys.find((key) => key.url.split("/").pop() === token)
    if (!cachekey) {
      return false
    }
    return cache.delete(cachekey)
  }
}
