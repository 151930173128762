/**
 * Enum for viewer types to be used in the viewer that can be displayed in different sitiuations in the app
 */
export enum ViewerTypes {
  /** unknown viewer type */
  UNKNOWN = 0,
  /** viewer type for verification of code extraits */
  CODE_VERIFICATION = 1,
  /** viewer type for calculations */
  CALCULATION = 2,
  NO_HIGHLIGHT_CODE = 3,
  FOR_DASHBOARD,
}
