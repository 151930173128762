import { Box, Button, CircularProgress, FormControl, FormLabel } from "@mui/material"
import React from "react"
import { FileInput } from "../../inputs/file-input/FileInput"

export const successfullAddFicheConfiguree = "La fiche configurée a bien été ajouté !"

type IProps = {
  handleClose: () => void
  sendFile(): void
  isSending: boolean
  file: File | undefined
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>
}

export default function ImportFicheConfiguree({
  handleClose,
  sendFile,
  isSending,
  file,
  setFile,
}: Readonly<IProps>): React.JSX.Element {
  function onChange(modelFile: File): void {
    setFile(modelFile)
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      id="rseeFormId"
      component="form"
      onSubmit={() => undefined}
      sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <FormControl>
        <FormLabel>Selectionner un fiche configurée</FormLabel>
        <FileInput
          fieldName="ficheConfigureeInputFile"
          errors={{}}
          onChange={onChange}
          file={file}
          progress={100}
          disabled={false}
          fileType=".xml"
        />
        <Box display="flex" justifyContent="right" sx={{ p: 1 }}>
          <Button onClick={handleClose}>Annuler</Button>
          {!isSending ? (
            <Button variant="contained" onClick={sendFile}>
              Confirmer
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </FormControl>
    </Box>
  )
}
