import { Box, CircularProgress, Grid } from "@mui/material"
import React, { useCallback, useContext, useMemo } from "react"
import { ComputedDatum, PieTooltipProps, ResponsivePie } from "@nivo/pie"
import { BSMaterialResult } from "../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSFilterContext, BSFilterType } from "../filters/BSFilterContext"
import { chooseColor, ISlice } from "./ISlice"
import { displayedLots, lotToColor, lotToLabel } from "../ChartUtils"
import { displayDecimal } from "../../../core/services/helper-service"
import { DonutLegend } from "./DonutLegend"
import { BSDashboardSelectionContext } from "../selection/BSDashboardSelectionContext"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
  impactFieldName: "re2020CarbonImpactPerSurface" | "carbonStockPerSurface"
}

export function ImpactDonutsChart({ allBSMaterialResult, impactFieldName }: Readonly<IProps>): React.JSX.Element {
  const { lotFilter, applyItemFilter, applyCodeOccurrenceFilter, applyProductFilter, isFilterAllChecked, select } =
    useContext(BSFilterContext)
  const { isSelected } = useContext(BSDashboardSelectionContext)

  const fullData: ISlice[] = useMemo(() => {
    const grouped: Record<string, ISlice> = applyItemFilter(
      applyCodeOccurrenceFilter(applyProductFilter(allBSMaterialResult))
    )
      .filter((bsMaterialResult) => displayedLots.includes(bsMaterialResult.lot))
      .filter((bsMaterialResult) => isSelected(bsMaterialResult))
      .reduce<Record<string, ISlice>>(
        (acc: Record<string, ISlice>, curr: BSMaterialResult) => {
          const lot: string = curr.lot
          const impact: number = curr[impactFieldName]

          if (!acc[lot]) {
            acc[lot] = {
              id: lot,
              label: lotToLabel(lot),
              value: 0,
              color: lotToColor(lot),
            }
          }
          acc[lot].value += impact

          return acc
        },
        {} as Record<string, ISlice> // Initial value of the accumulator
      )

    return Object.values(grouped).sort((a: ISlice, b: ISlice) => b.value - a.value)
  }, [applyItemFilter, applyCodeOccurrenceFilter, applyProductFilter, allBSMaterialResult, isSelected, impactFieldName])

  const isAllSelected = useMemo(() => {
    const strings = fullData.map((dataPoint) => dataPoint.id)
    return isFilterAllChecked(BSFilterType.LOT, strings)
  }, [fullData, isFilterAllChecked])

  const renderTooltip = useCallback(({ datum }: PieTooltipProps<ISlice>) => <Box sx={{ display: "none" }} />, [])

  const handleClick: (dataPoint: ISlice) => void = useCallback(
    (dataPoint) => {
      const lot = dataPoint.id
      select(lot, BSFilterType.LOT, isAllSelected)
    },
    [select, isAllSelected]
  )

  const handleArcMouseEnter: (node: ComputedDatum<ISlice>, event: React.MouseEvent<SVGPathElement>) => void = useCallback(
    (node, event) => {
      const target = event.currentTarget as SVGPathElement
      target.style.cursor = "pointer"
    },
    []
  )

  return (
    <Grid
      container
      spacing={2}
      flexDirection="column"
      justifyContent="flex-start"
      sx={{ pl: 2, pr: 2, pb: 2, minHeight: "830px" }}>
      {allBSMaterialResult.length !== 0 ? (
        <>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ height: "300px", width: "400px" }}>
              <ResponsivePie
                data={fullData}
                margin={{ top: 0, right: 80, bottom: 0, left: 80 }}
                sortByValue
                innerRadius={0.7}
                padAngle={1}
                cornerRadius={2}
                activeOuterRadiusOffset={8}
                colors={({ id, data }) => chooseColor(data, lotFilter)}
                borderWidth={1}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 0.2]],
                }}
                tooltip={renderTooltip}
                enableArcLabels={false}
                enableArcLinkLabels
                arcLinkLabel={(dataPoint) => displayDecimal(dataPoint.value, 1)}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                arcLinkLabelsTextColor={{ from: "color" }}
                onClick={(e) => handleClick(e.data)}
                onMouseEnter={handleArcMouseEnter}
              />
            </Box>
          </Grid>
          <DonutLegend fullData={fullData} isAllSelected={isAllSelected} selectLot={handleClick} />
        </>
      ) : (
        <CircularProgress />
      )}
    </Grid>
  )
}
