import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React from "react"
import { ValidateButton } from "../../../../../components/buttons/ValidateButton/ValidateButton"
import { Transition } from "../../../../../components/dialog/BaseDialog"
import { BSBimModel } from "../../../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { BSBimModelInfo } from "./BSBimModelInfo"

interface IProps {
  selectedBsBimModel: BSBimModel | undefined
  openBsBimModelInfo: boolean
  setOpenBsBimModelInfo: React.Dispatch<React.SetStateAction<boolean>>
}

export function BSBimModelInfoDialog({
  selectedBsBimModel,
  openBsBimModelInfo,
  setOpenBsBimModelInfo,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <Dialog
      open={openBsBimModelInfo}
      maxWidth="sm"
      fullWidth
      onClose={() => setOpenBsBimModelInfo(false)}
      TransitionComponent={Transition}>
      <Box
        component="form"
        id="update-quantities"
        onSubmit={() => undefined}
        sx={{ background: "#ffffff", borderRadius: 4 }}>
        <DialogTitle>Informations de la maquette</DialogTitle>
        <DialogContent>
          <BSBimModelInfo selectedBsBimModel={selectedBsBimModel} />
        </DialogContent>
        <DialogActions>
          <ValidateButton label="Fermer" onClick={() => setOpenBsBimModelInfo(false)} />
        </DialogActions>
      </Box>
    </Dialog>
  )
}
