import SearchIcon from "@mui/icons-material/Search"
import TuneIcon from "@mui/icons-material/Tune"
import { Box, CircularProgress, Drawer, Grid, IconButton, InputBase, Typography, Zoom } from "@mui/material"
import React, { useContext } from "react"
import city from "../../../../assets/img/empty-result-city.png"
import { BSProjectCard } from "../../../components/card/beem-shot/BSProjectCard"
import { BaseSelectInput } from "../../../components/inputs/select-input/BaseSelectInput"
import { DefaultTypography } from "../../../components/typography/default-typography"
import { BSProjectListContext } from "../../../core/context/beem-shot/BSProject/BSProjectListContext"
import { BSProjectCardDto } from "../../../core/dto/beem-shot/BSProject/BSProjectCardDto"
import { ProjectStatusEnum, projectStatusEnumLabels } from "../../../core/enum/projectStatusEnum"
import { enumToSelectOptions } from "../../../core/services/helper-service"

export function BSProjectList(): React.JSX.Element {
  const { triggerSearch, bsProjectList, statusFilter, setStatusFilter, isLoading, searchState, setSearchState, setPage } =
    useContext(BSProjectListContext)

  const [openDrawerFilter, setOpenDrawerFilter] = React.useState(false)

  function handleSelectChange(value: ProjectStatusEnum): void {
    setStatusFilter(value)
  }

  function handleSearch(newSearchState: string): void {
    setPage(1)
    setSearchState(newSearchState)
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" sx={{ pt: 2 }}>
      <DefaultTypography label="Estimez l’empreinte carbone de vos projets BIM." color="#070F27" fontWeight={400} />
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        rowGap={1}
        width="100%"
        sx={{ py: 1 }}>
        <Grid
          item
          xs={5}
          component="form"
          onSubmit={triggerSearch}
          sx={{
            flex: 3,
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#ffffff",
            borderRadius: 3,
          }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            value={searchState}
            placeholder="Rechercher par nom, numéro d’affaire, propriétaire"
            inputProps={{ "aria-label": "search" }}
            endAdornment={
              <IconButton type="submit">
                <SearchIcon sx={{ margin: "0 8px" }} />
              </IconButton>
            }
            onChange={(e: { target: { value: any } }) => {
              handleSearch(e.target.value)
            }}
          />
        </Grid>

        <Grid item xs={2} display="flex" flexDirection="row" alignItems="center">
          <BaseSelectInput
            id="bsProjectStatusFilter"
            label="Statut"
            selectedOption={statusFilter}
            options={enumToSelectOptions(ProjectStatusEnum, projectStatusEnumLabels).filter(
              (selectedOption) => selectedOption.value !== ProjectStatusEnum.DELETED
            )}
            mode="direct"
            handleChange={(projectStatus) => handleSelectChange(projectStatus)}
            isBeemShot
            size="small"
          />
          {/*TODO: implémenter le drawer et remettre le bouton de filtres*/}
          {false && (
            <IconButton onClick={() => setOpenDrawerFilter(true)} sx={{ background: "#C9FFE8", borderRadius: 2, ml: 2 }}>
              <TuneIcon fontSize="medium" sx={{ color: "#009069" }} />
            </IconButton>
          )}
        </Grid>
      </Grid>

      {!isLoading && (
        <Grid container columnSpacing={4} alignItems="flex-start" height="100%" rowGap={3} sx={{ pt: 1 }}>
          {bsProjectList.length > 0 ? (
            bsProjectList.map((bsProject: BSProjectCardDto, index) => (
              <Zoom in style={{ transitionDelay: `${index * 150}ms` }} key={bsProject.id}>
                <Grid item xs={12} md={4} lg={4} key={bsProject.id}>
                  <BSProjectCard key={bsProject.id} bsProjectCardInfo={bsProject} hasCoverImage={false} />
                </Grid>
              </Zoom>
            ))
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100%"
              width="100%">
              <Typography fontSize="24px" fontWeight={600} lineHeight="36px" textAlign="center" color="#374771">
                Aucun résultat
              </Typography>
              <Typography variant="body1">Modifier les options de recherche.</Typography>
              <Box sx={{ p: 2 }}>
                <img
                  src={city}
                  alt={city}
                  height="300px"
                  width="max-content"
                  style={{
                    marginLeft: 15,
                    marginTop: -8,
                  }}
                />
              </Box>
            </Box>
          )}
        </Grid>
      )}

      {isLoading && (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <CircularProgress />
        </Box>
      )}

      {false && (
        <Drawer open={openDrawerFilter} onClose={() => setOpenDrawerFilter(false)} anchor="right">
          Have to be implemented ; )
        </Drawer>
      )}
    </Box>
  )
}
