import React, { useContext, useMemo } from "react"
import { TableCell, TableRow } from "@mui/material"
import { displayDecimal, displayNumber, NumericKeys } from "../../../../core/services/helper-service"
import { RowBarChart } from "../GroupedChart/GroupTableBase/RowBarChart"
import { BSMaterialResult } from "../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { customPalette } from "../../../../../theme"
import { selectableStyle } from "../../ChartUtils"
import { BSDashboardSelectionContext } from "../../selection/BSDashboardSelectionContext"

const defaultOrigin = "Estimé"

interface IProps {
  bsMaterialResult: BSMaterialResult
  maxValue: number
  fieldName: NumericKeys<BSMaterialResult>
  isAllCodeOccurrenceSelected: boolean
  isAllProductSelected: boolean
}

export function NormalRow({ bsMaterialResult, maxValue, fieldName }: Readonly<IProps>): React.JSX.Element {
  const { isSelected, isSelectedCodeOccurrence, isSelectedRow, selectCodeOccurrence, selectRow } =
    useContext(BSDashboardSelectionContext)

  const origin: string = useMemo(
    () => (bsMaterialResult.codeOccurrence ? bsMaterialResult.codeOccurrence : defaultOrigin),
    [bsMaterialResult.codeOccurrence]
  )

  const isCodeOccurrenceSelected = useMemo(
    () => isSelectedCodeOccurrence(bsMaterialResult),
    [bsMaterialResult, isSelectedCodeOccurrence]
  )

  const isProductSelected = useMemo(() => isSelectedRow(bsMaterialResult), [bsMaterialResult, isSelectedRow])

  const codeOccurrenceSelectableStyle = useMemo(() => selectableStyle(isCodeOccurrenceSelected), [isCodeOccurrenceSelected])

  const rowSelectableStyle = useMemo(() => selectableStyle(isProductSelected), [isProductSelected])

  const isSelectedForAnyFilter = useMemo(() => isSelected(bsMaterialResult), [bsMaterialResult, isSelected])

  return (
    <TableRow>
      <TableCell align="left">{bsMaterialResult.bsItemLabel}</TableCell>
      <TableCell align="center" onClick={() => selectCodeOccurrence(bsMaterialResult)} sx={codeOccurrenceSelectableStyle}>
        {origin}
      </TableCell>
      <TableCell align="left" onClick={() => selectRow(bsMaterialResult)} sx={{ ...rowSelectableStyle, pl: 0, pr: 0 }}>
        {bsMaterialResult.bsMacroComponentLabel}
      </TableCell>
      <TableCell align="left" onClick={() => selectRow(bsMaterialResult)} sx={rowSelectableStyle}>
        {bsMaterialResult.nomProduit}
      </TableCell>
      <TableCell align="center" onClick={() => selectRow(bsMaterialResult)} sx={{ ...rowSelectableStyle, pl: 0, pr: 0 }}>
        {bsMaterialResult.ficheId}
      </TableCell>
      <TableCell align="center" onClick={() => selectRow(bsMaterialResult)} sx={{ ...rowSelectableStyle, pl: 0, pr: 0 }}>
        {displayNumber(bsMaterialResult.quantity)}
      </TableCell>
      <TableCell
        align="center"
        onClick={() => selectRow(bsMaterialResult)}
        sx={{ ...rowSelectableStyle, color: customPalette.dashboardBlue, pl: 0, pr: 0 }}>
        {bsMaterialResult.ficheUniteLabel}
      </TableCell>
      <TableCell
        align="center"
        onClick={() => selectRow(bsMaterialResult)}
        sx={{ ...rowSelectableStyle, color: customPalette.dashboardRed, pl: 0, pr: 0 }}>
        {displayDecimal(bsMaterialResult[fieldName], 1)}
      </TableCell>
      <TableCell align="left" onClick={() => selectRow(bsMaterialResult)} sx={rowSelectableStyle}>
        <RowBarChart
          maxValue={maxValue}
          bsMaterialResult={bsMaterialResult}
          fieldName={fieldName}
          isSelected={isSelectedForAnyFilter}
        />
      </TableCell>
    </TableRow>
  )
}
