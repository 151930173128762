import React, { useCallback, useContext } from "react"
import { BSMaterialResult } from "../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSFilterContext, BSFilterType } from "../BSFilterContext"
import { BaseFilterSelect } from "./BaseFilterSelect"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
}

export function FilterProductSelect({ allBSMaterialResult }: Readonly<IProps>): React.JSX.Element {
  const { applyLotFilter, applyItemFilter } = useContext(BSFilterContext)

  const applyFilters = useCallback(
    (_bsMaterialList: BSMaterialResult[]): BSMaterialResult[] => applyLotFilter(applyItemFilter(_bsMaterialList)),
    [applyItemFilter, applyLotFilter]
  )

  return (
    <BaseFilterSelect
      allBSMaterialResult={allBSMaterialResult}
      filterType={BSFilterType.PRODUCT}
      applyFilters={applyFilters}
      label="Chercher un produit"
      emptyLabel=""
      valueFieldName="ficheId"
      labelFieldName="nomProduit"
    />
  )
}
