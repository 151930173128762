import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined"
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { IconBeemPilot } from "../../../components/custom-icons/icon-beem-pilot"
import { Children } from "../../../components/miscellianous/children"
import { pagesUrl } from "../../appConstants"
import { AppSideBarContext, UrlStateEnum } from "../side-bar/side-bar-context"

export enum NavbarTypeEnum {
  DEFAULT,
  STEP_HEADER,
  HEAD_WITHOUT_STEP,
  UNKNOWN,
}

export interface BreadcrumbStep {
  label: string
  url?: string
}

export const AppNavBarContext = React.createContext<AppNavBarStore>({} as AppNavBarStore)

export function NavBarContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const location = useLocation()
  const navigate = useNavigate()

  const { urlState } = useContext(AppSideBarContext)

  const [title, setTitle] = useState<string>("")
  const [icon, setIcon] = useState<React.JSX.Element | undefined>()
  const [typeNavBar, setTypeNavBar] = useState<NavbarTypeEnum>(NavbarTypeEnum.DEFAULT)
  const previousUrl = useRef<string | undefined>(pagesUrl.CONTROL_BOARD_HOME)
  const actionOnBackButton = useRef<(() => void) | undefined>(undefined)

  const [stepHeader, setStepHeader] = useState<number>(0)
  const [showProjectStatus, setShowProjectStatus] = useState<boolean>(false)
  const [breadcrumbSteps, setBreadcrumbSteps] = useState<BreadcrumbStep[]>([])

  useEffect(() => {
    if (urlState === UrlStateEnum.BEEM_SHOT) {
      setIcon(
        <Link to={pagesUrl.BEEM_SHOT_HOME}>
          <DonutSmallOutlinedIcon fontSize="medium" sx={{ color: "#0BE2A0", fontWeight: "2px" }} />
        </Link>
      )
    } else if (urlState === UrlStateEnum.BEEM_PILOT) {
      setIcon(
        <Link to={pagesUrl.BEEM_PILOT_HOME}>
          <IconBeemPilot />
        </Link>
      )
    }
  }, [urlState])

  const setPreviousUrl = useCallback((newUrl: string | undefined) => {
    previousUrl.current = newUrl
    actionOnBackButton.current = undefined
  }, [])

  const setActionOnBackButton = useCallback((action: (() => void) | undefined) => {
    actionOnBackButton.current = action
    previousUrl.current = undefined
  }, [])

  /* reset the navbar when the url change */
  useEffect(
    () => () => {
      setTypeNavBar(NavbarTypeEnum.DEFAULT)
      previousUrl.current = `${location.pathname}${location?.search || ""}`
    },
    [location]
  )

  const getPreviousUrl: () => string | undefined = useCallback(() => previousUrl.current, [])
  const getActionOnBackButton: () => (() => void) | undefined = useCallback(() => actionOnBackButton.current, [])

  const navBarNavigateBack = useCallback(() => {
    if (previousUrl.current) {
      navigate(previousUrl.current)
    } else if (actionOnBackButton.current) {
      actionOnBackButton.current()
    }
  }, [navigate])

  const AppNavBarStore: AppNavBarStore = useMemo(
    () => ({
      title,
      setTitle,
      icon,
      setIcon,
      typeNavBar,
      setTypeNavBar,
      setPreviousUrl,
      setActionOnBackButton,
      navBarNavigateBack,
      stepHeader,
      setStepHeader,
      showProjectStatus,
      setShowProjectStatus,
      breadcrumbSteps,
      setBreadcrumbSteps,
    }),
    [
      icon,
      breadcrumbSteps,
      setPreviousUrl,
      setActionOnBackButton,
      navBarNavigateBack,
      showProjectStatus,
      stepHeader,
      title,
      typeNavBar,
    ]
  )

  return <AppNavBarContext.Provider value={AppNavBarStore}>{children}</AppNavBarContext.Provider>
}

export type AppNavBarStore = {
  title: string
  setTitle: React.Dispatch<React.SetStateAction<string>>
  icon: React.JSX.Element | undefined
  setIcon: React.Dispatch<React.SetStateAction<React.JSX.Element | undefined>>
  typeNavBar: NavbarTypeEnum
  setTypeNavBar: React.Dispatch<React.SetStateAction<NavbarTypeEnum>>
  setPreviousUrl(newUrl: string | undefined): void
  setActionOnBackButton(action: (() => void) | undefined): void
  navBarNavigateBack(): void
  stepHeader: number
  setStepHeader: React.Dispatch<React.SetStateAction<number>>
  showProjectStatus: boolean
  setShowProjectStatus: React.Dispatch<React.SetStateAction<boolean>>
  breadcrumbSteps: BreadcrumbStep[]
  setBreadcrumbSteps: React.Dispatch<React.SetStateAction<BreadcrumbStep[]>>
}
