import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material"
import React from "react"
import { FicheConfiguree } from "../../../../core/dto/fiche-configuree/fiche-configuree"
import { IniesRecord } from "../../../../core/dto/material/IniesRecord"
import { MaterialRecord } from "../../../../core/dto/material/MaterialRecord"
import { TypeMaterialEnum } from "../../../../core/enum/typeMaterialEnum"
import { FicheValue } from "../../../../pages/beem-shot/BSVariantDetailsPage/BSCustomizationTab/components/context/SelectionContext"
import { Transition } from "../../../dialog/BaseDialog"
import { BSMaterialLib } from "./BSMaterialLib"

interface IProps {
  open: boolean
  form: FicheValue
  handleCloseMaterialLib(): void
  onSelect(
    selectedRow: IniesRecord | MaterialRecord | FicheConfiguree,
    type: TypeMaterialEnum,
    coefficient: number,
    newQuantity: number
  ): Promise<void>
}

export function BSMaterialLibDialog({ open, form, handleCloseMaterialLib, onSelect }: Readonly<IProps>): React.JSX.Element {
  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={handleCloseMaterialLib}
      sx={{
        "& .MuiDialog-paper": {
          minHeight: 600,
        },
      }}
      TransitionComponent={Transition}>
      <DialogTitle display="flex" justifyContent="center">
        <Typography
          sx={{
            fontFamily: "Poppins, sans-serif",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "36px",
            textAlign: "center",
          }}>
          Importer une déclaration
        </Typography>
      </DialogTitle>
      <DialogContent>
        <BSMaterialLib form={form} handleCloseMaterialLib={handleCloseMaterialLib} onSelect={onSelect} />
      </DialogContent>
    </Dialog>
  )
}
