import { Box, Typography } from "@mui/material"
import React, { SetStateAction } from "react"
import { customPalette } from "../../../../../../theme"

interface IProps {
  displayImpactByItems: boolean
  setDisplayImpactByItems: React.Dispatch<SetStateAction<boolean>>
}

export function ButtonDisplayImpactByItemChart({
  displayImpactByItems,
  setDisplayImpactByItems,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <Box>
      {displayImpactByItems ? (
        <Typography
          sx={{ color: customPalette.dashboardBlue, cursor: "pointer" }}
          onClick={() => setDisplayImpactByItems(false)}>
          - Masquer les impacts par items
        </Typography>
      ) : (
        <Typography
          sx={{ color: customPalette.dashboardBlue, cursor: "pointer" }}
          onClick={() => setDisplayImpactByItems(true)}>
          + Afficher les impacts par items
        </Typography>
      )}
    </Box>
  )
}
