import { Button } from "@mui/material"
import React from "react"

type IProps = {
  onClick(): void
  label: string | undefined
  fullWidth?: boolean
  isSubmitting?: boolean
}

export default function CancelButton({
  onClick,
  label,
  fullWidth = false,
  isSubmitting,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <Button
      variant="contained"
      color="secondary"
      size="medium"
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={isSubmitting}>
      {label}
    </Button>
  )
}
