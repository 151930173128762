import React, { useContext, useMemo, useState } from "react"
import { CircularProgress } from "@mui/material"
import IconFolder from "../../../components/custom-icons/icon-folder"
import { IconRseeGlobalDashboard } from "../../../components/custom-icons/icon-rsee-global-dashboard"
import { ProjectListEmpty } from "../../../components/project-list-empty/projectListEmpty"
import { RseeProjectListContext } from "../../../core/context/beem-pilot/rsee/RseeProjectListContext"
import { CustomTabPanel, TabPanelProps } from "../../../components/tabs/CustomTabPanel"
import RseeOrganizationDashboardTab from "./tabs/OrganizationDashboardPage/RseeOrganizationDashboardTab"
import { RseeProjectList } from "./tabs/RseeProjectList/RseeProjectList"
import { TabEnum } from "../../../components/tabs/tabs"
import { UserContext } from "../../../core/context/user/user-context"
import { OrganizationRoleEnum } from "../../../core/enum/user/organization-role-enum"

export function RseeProjectListTabs(): React.JSX.Element {
  const { user } = useContext(UserContext)
  const { isOneProjectCreated, projectCount, isInitialized, isLoading } = useContext(RseeProjectListContext)

  const [tabs, setTabs] = useState<TabEnum>(TabEnum.RSEE_PROJECTS)

  function handleChange(event: React.SyntheticEvent, newValue: TabEnum): void {
    setTabs(newValue)
  }

  const panels: TabPanelProps[] = useMemo(() => {
    const panelsList: TabPanelProps[] = [
      {
        id: TabEnum.RSEE_PROJECTS,
        Icon: IconFolder,
        label: "Projets",
        chips: projectCount?.toString(),
        content: <RseeProjectList />,
      },
    ]

    if (user?.role === OrganizationRoleEnum.MEGA_USER) {
      panelsList.push({
        id: TabEnum.PILOTAGE_GENERAL,
        Icon: IconRseeGlobalDashboard,
        label: "Pilotage général",
        chips: undefined,
        content: <RseeOrganizationDashboardTab />,
      })
    }

    return panelsList
  }, [projectCount, user?.role])

  if (!isInitialized || isLoading) {
    return <CircularProgress />
  } else if (!isOneProjectCreated.current) {
    return <ProjectListEmpty />
  } else {
    return <CustomTabPanel panels={panels} currentTabId={tabs} handleChangeTab={handleChange} />
  }
}
