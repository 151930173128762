import { Box } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { BSModelFileContext } from "../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { CodeExtraitDisplay } from "../../../core/dto/code-extrait/CodeExtraitDisplay"
import { ViewerTypes } from "../../ifc-displayer/enums/ViewerTypes"
import { BSFilterContext } from "../filters/BSFilterContext"
import { BSMaterialResult } from "../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSIfcDisplayer } from "../../ifc-displayer/BSIfcDisplayer"
import { useBSVariant } from "../../../core/hooks/beem-shot/useBSVariant"
import { BSVariantContext } from "../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { useViewerDisplay } from "../../../core/hooks/viewer/useViewerDisplay"
import { BSDashboardSelectionContext } from "../selection/BSDashboardSelectionContext"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
}

export function BimModelChart({ allBSMaterialResult }: Readonly<IProps>): React.JSX.Element {
  const { fetchCodeExtrait } = useBSVariant()
  const { selectedVariant } = useContext(BSVariantContext)
  const { file } = useContext(BSModelFileContext)
  const { applyLotFilter, applyItemFilter, applyCodeOccurrenceFilter, applyProductFilter } = useContext(BSFilterContext)
  const { isSelected } = useContext(BSDashboardSelectionContext)

  const [codesExtraits, setCodesExtraits] = useState<CodeExtraitDisplay[]>([])
  const [selectedCodeExtrait, setSelectedCodeExtrait] = useState<CodeExtraitDisplay>()

  const { viewer, onChangeModel, progress, setProgress } = useViewerDisplay(
    codesExtraits,
    selectedCodeExtrait,
    setSelectedCodeExtrait
  )

  const selectedData = useMemo(() => {
    const bsMaterialResultSet = new Set<string>()
    applyLotFilter(applyItemFilter(applyCodeOccurrenceFilter(applyProductFilter(allBSMaterialResult))))
      .filter((bsMaterialResult) => isSelected(bsMaterialResult))
      .forEach((bsMaterialResult) => {
        if (bsMaterialResult.codeOccurrence) {
          bsMaterialResultSet.add(bsMaterialResult.codeOccurrence)
        }
      })
    return Array.from(bsMaterialResultSet)
  }, [allBSMaterialResult, applyCodeOccurrenceFilter, applyItemFilter, applyLotFilter, applyProductFilter, isSelected])

  useEffect(() => {
    if (selectedVariant?.id) {
      fetchCodeExtrait(selectedVariant.id).then((_codeExtraits: CodeExtraitDisplay[]) => {
        setCodesExtraits(_codeExtraits)
      })
    }
  }, [selectedVariant, fetchCodeExtrait])

  const dummySelectCode = useCallback((code: CodeExtraitDisplay): CodeExtraitDisplay => {
    console.info("Select code on model. Not implemented yet")
    return code
  }, [])

  useEffect(() => {
    // The progress === 100 is a trick so the useEffect is run again just after loading.
    // Else, the model has colors on item which have no code extrait
    if (progress === 100) {
      viewer?.manager.subsetsManager.highlightCodesExtraitsList(selectedData)
    }
  }, [viewer, selectedData, progress])

  return (
    <Box>
      {file && (
        <BSIfcDisplayer
          file={file}
          type={ViewerTypes.FOR_DASHBOARD}
          codesExtraits={codesExtraits}
          setExternalProgress={setProgress}
          setSelectedCodeExtrait={dummySelectCode}
          onChangeModel={onChangeModel}
          showFileName
          height="500px"
        />
      )}
    </Box>
  )
}
