import { Box, Grid, IconButton, Typography } from "@mui/material"
import React, { useCallback } from "react"
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { BSBimModel } from "../../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { VariantsOverviewList } from "./VariantsOverviewList"
import { VariantsOverviewHeader } from "./VariantsOverviewHeader"
import { customPalette } from "../../../../../theme"
import { BSBimModelInfo } from "./BSBimModelInfo"
import { resolveUrl } from "../../../../core/services/http-service"
import { pagesUrl } from "../../../../core/appConstants"
import { BSDeleteBimModelButton } from "../../components/BSDeleteBimModelButton/BSDeleteBimModelButton"
import { formatToFrenchWithHours } from "../../../../core/services/date-service"
import { TabEnum } from "../../../../components/tabs/tabs"

interface IProps {
  selectedBSBimModel: BSBimModel
}

export function BSBimModelOverview({ selectedBSBimModel }: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()

  const { bsProjectId, bsBimModelId } = useParams()

  const goToImportPage = useCallback(
    () =>
      navigate(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_IMPORT_PAGE, [bsProjectId, bsBimModelId]), {
        state: { from: location.pathname },
      }),
    [bsBimModelId, bsProjectId, navigate, location]
  )

  const onDelete = useCallback(() => {
    navigate(resolveUrl(pagesUrl.BEEM_SHOT_PROJECTS_DETAIL, [bsProjectId], { tab: TabEnum.BS_BIM_MODEL_POOL }))
  }, [bsProjectId, navigate])

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexShrink={1}
      border={2}
      borderRadius={3}
      justifyContent="flex-start"
      flexDirection="column"
      alignContent="flex-start"
      alignItems="center"
      borderColor={customPalette.paperBorder}
      my={3}
      sx={{
        backgroundColor: customPalette.paperBackground,
        height: "70vh",
        overflow: "auto",
        p: 3,
        pt: 0,
        cursor: "default",
      }}>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ pt: 2 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" fontSize={11} fontWeight={400} sx={{ color: customPalette.subtitleGray }}>
            {`Dernière mise à jour le ${formatToFrenchWithHours(selectedBSBimModel.lastModifiedDate)}`}
          </Typography>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={9} display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h4" fontWeight={600} textAlign="center" sx={{ color: customPalette.darkblue }}>
            Surfaces
          </Typography>
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {selectedBSBimModel && <BSDeleteBimModelButton bsBimModelToDelete={selectedBSBimModel} onDelete={onDelete} />}
          <IconButton onClick={goToImportPage}>
            <CreateOutlinedIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <BSBimModelInfo bsBimModel={selectedBSBimModel} />
        </Grid>

        <VariantsOverviewHeader />
        <VariantsOverviewList />
      </Grid>
    </Box>
  )
}
