import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import CancelButton from "../../../../../../components/buttons/CancelButton/CancelButton"
import { ErrorContext } from "../../../../../../components/layout/error-snackbar"
import { TitleFormTypography } from "../../../../../../components/typography/title-form-typography"
import { RseeProjectContext } from "../../../../../../core/context/beem-pilot/rsee/rsee-project-context"
import { ShareLink } from "../../../../../../core/dto/rsee/share-link"
import { useProjects } from "../../../../../../core/hooks/projects/use-projects"
import { RseeDocumentShareLinkDisplayer } from "./RseeDocumentShareLinkDisplayer"
import { Transition } from "../../../../../../components/dialog/BaseDialog"

interface IProps {
  open: boolean
  isWritingMode: boolean

  handleClose(): void
}

export function RseeShareLinkDialog({ open, handleClose, isWritingMode }: Readonly<IProps>): React.JSX.Element {
  const { rseeDocumentId } = useParams()

  const { rseeProject } = useContext(RseeProjectContext)
  const openErrorSnackbar = useContext(ErrorContext)
  const { fetchAllRseeProjectShareLink, fetchAllRseeDocumentShareLink, deleteRseeShareToken } = useProjects()

  const [shareInformationList, setShareInformationList] = useState<ShareLink[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { generateShareLink } = useProjects()

  useEffect(() => {
    refresh()
  }, [])

  function refresh(): void {
    if (rseeDocumentId) {
      fetchAllRseeDocumentShareLink(rseeDocumentId).then((newList) => {
        setShareInformationList(newList)
      })
    }
  }

  const generateLink = useCallback(() => {
    if (rseeDocumentId) {
      setIsSubmitting(true)
      generateShareLink(rseeDocumentId)
        .then(() => {
          refresh()
        })
        .catch((error: Error) => {
          openErrorSnackbar(error)
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    }
  }, [rseeDocumentId])

  function deleteShareInformation(element: ShareLink): Promise<void> {
    return deleteRseeShareToken(element.id).then(() => refresh())
  }

  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={() => handleClose()} TransitionComponent={Transition}>
      <DialogTitle>
        <TitleFormTypography label="Partager le fichier RSEE" />
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} pb={2}>
          <Typography variant="body1">
            Vous souhaitez permettre à des utilisateurs de consulter le tableau de bord du fichier{" "}
            <span style={{ fontWeight: "bold" }}>{rseeProject?.projectName}</span> en créant un lien de partage.
          </Typography>
        </Box>

        <Grid
          container
          alignItems="flex-start"
          maxHeight="50vh"
          rowGap={1}
          border={1}
          borderRadius={3}
          borderColor="#B0C2C9"
          p={2}
          overflow="auto">
          <RseeDocumentShareLinkDisplayer
            shareInformationList={shareInformationList}
            deleteShareLink={deleteShareInformation}
            handleClose={handleClose}
            isWritingMode={isWritingMode}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        {isWritingMode ? (
          <>
            <CancelButton onClick={handleClose} label="Annuler" />
            <Button variant="contained" onClick={generateLink}>
              générer un lien
            </Button>
          </>
        ) : (
          <CancelButton onClick={handleClose} label="Fermer" />
        )}
      </DialogActions>
    </Dialog>
  )
}
