import { CodesVersion } from "../../../enum/codesVersionEnum"
import { stringToDate } from "../../../services/date-service"

export class BSBimModel {
  id: string | undefined = undefined
  fileName = ""
  version = ""
  codesVersion = CodesVersion.VERSION_1_8
  projectId = ""
  errors: string[] = []
  surfaceMoyenneLogement: number | undefined
  modelHashFile?: string
  createdDate?: Date
  lastModifiedDate?: Date
  createdByUser?: string

  surfacePlancher?: number
  surfaceComble: number | undefined
  surfaceCombleB?: boolean
  surfaceRef?: number
  empriseAuSol?: number

  variantCount?: number

  static fromDto(data: any): BSBimModel {
    const bsBimModel = new BSBimModel()
    if (data === undefined) {
      return new BSBimModel()
    }
    bsBimModel.id = data.id
    bsBimModel.fileName = data.fileName ?? ""
    bsBimModel.projectId = data.projectId ?? ""
    bsBimModel.version = data.version ?? ""
    bsBimModel.errors = data.errors ?? []
    bsBimModel.surfaceMoyenneLogement = data.surfaceMoyenneLogement
    bsBimModel.modelHashFile = data.modelHashFile
    bsBimModel.codesVersion = data.codesVersion ?? CodesVersion.VERSION_1_8
    bsBimModel.createdDate = stringToDate(data.createdDate)
    bsBimModel.lastModifiedDate = stringToDate(data.lastModifiedDate)
    bsBimModel.createdByUser = data.createdByUser
    bsBimModel.surfacePlancher = data.surfacePlancher
    bsBimModel.surfaceComble = data.surfaceComble
    bsBimModel.surfaceCombleB = data.surfaceCombleB
    bsBimModel.surfaceRef = data.surfaceRef
    bsBimModel.empriseAuSol = data.empriseAuSol
    bsBimModel.variantCount = data.variantCount
    return bsBimModel
  }
}
