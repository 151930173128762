import React, { useContext, useEffect } from "react"
import { BSCategoriesContext } from "../../../../../../../core/context/beem-shot/BSCategory/BSCategoriesContext"
import { BSItemContext } from "../../../../../../../core/context/beem-shot/BSItems/BSItemContext"
import { CategoryAccordion } from "./CategoryAccordion"
import { useAccordion } from "./useAccordion"
import ViewerIFC from "../../../../../../../components/ifc-displayer/ViewerIFC"

interface IProps {
  viewer: ViewerIFC | undefined
}

export function AdvancedQuestionnaire({ viewer }: Readonly<IProps>): React.JSX.Element {
  const { bsCategories } = useContext(BSCategoriesContext)
  const { selectedBSItem, subCategoriesForCategory } = useContext(BSItemContext)

  const { expanded: categoryExpanded, expand, handleExpanded } = useAccordion()

  useEffect(() => {
    if (selectedBSItem) {
      expand(selectedBSItem?.category.name)
    }
  }, [expand, selectedBSItem])

  return (
    <>
      {bsCategories
        .filter((x) => Object.keys(subCategoriesForCategory).includes(x.name))
        .map((category) => (
          <CategoryAccordion
            key={category.name}
            category={category}
            expandedCategory={categoryExpanded}
            handleExpandedCategory={handleExpanded}
            viewer={viewer}
          />
        ))}
    </>
  )
}
