import { Box, Button, Checkbox, FormControlLabel, Grid } from "@mui/material"
import React, { ChangeEvent, SetStateAction, useCallback, useContext, useMemo, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import CodeExtraitListWithViewer from "../../../../components/code-extrait-list-with-viewer/code-extrait-list-with-viewer"
import ViewerIFC from "../../../../components/ifc-displayer/ViewerIFC"
import { adminPagesUrl, pagesUrl } from "../../../../core/appConstants"
import { CalculationContext, CalculationStore } from "../../../../core/context/calculation/calculation-context"
import { CodesCompletionsContext, CodesCompletionsStore } from "../../../../core/context/code-acv/codes-completions-context"
import {
  CodesExtraitsListContext,
  CodesExtraitsListStore,
} from "../../../../core/context/code-acv/codes-extraits-list-context"
import { ProjectContext, ProjectStore } from "../../../../core/context/project/project-context"
import { ProjectRoleContext } from "../../../../core/context/user/project-role-context"
import { CodesCompletionsPerCodeParent } from "../../../../core/data-structures/codes-completions-per-code-parent"
import CodeCustom from "../../../../core/dto/code-acv-custom/code-custom"
import CodeReference from "../../../../core/dto/code-acv/code-reference"
import { CodeCompletion } from "../../../../core/dto/code-completion/code-completion"
import { CodeExtrait } from "../../../../core/dto/code-extrait/code-extrait"
import { CodeExtraitUpdate } from "../../../../core/dto/code-extrait/code-extrait-update"
import { CalculationStepsEnum } from "../../../../core/enum/calculationStepsEnum"
import { RoleEnum } from "../../../../core/enum/roleEnum"
import { useCode } from "../../../../core/hooks/use-code"
import { resolveUrl } from "../../../../core/services/http-service"
import { CompletionListV1v2 } from "../components/completion-list-v1v2"
import FormAddCompletion from "../components/form-add-completion"
import ProjectCarbonBreadcrumb from "../components/project-carbon-breadcrumb"
import ProjectCarbonCalculatorHeader from "../components/project-carbon-calculator-header"
import SelectVariant from "../components/select-variant"
import CheckIsSame from "./check-is-same"
import ExtractedCodeCoreData from "./extracted-code-core-data"
import FormAddMaterialToExtracted from "./form-add-material-to-extracted"
import { CopyMaterialDialog } from "./components/copy-material-dialog"
import { CodeExtraitDisplay } from "../../../../core/dto/code-extrait/CodeExtraitDisplay"
import { codeToKey } from "../../../../core/services/code-service"

export function ExtractedCodePage(): React.JSX.Element {
  const navigate = useNavigate()

  const { project } = useContext<ProjectStore>(ProjectContext)
  const { calculation } = useContext<CalculationStore>(CalculationContext)
  const { codesExtraits, setCodesExtraitsList, refreshCodeExtrait } =
    useContext<CodesExtraitsListStore>(CodesExtraitsListContext)
  const {
    codesCompletionsPerCodeParentV1,
    codesCompletionsPerCodeParentV2,
    setCodesCompletionsPerCodeParentV2,
    refreshCodeCompletion,
  } = useContext<CodesCompletionsStore>(CodesCompletionsContext)
  const { hasRole, isOrganizationUltimateUser } = useContext(ProjectRoleContext)

  const {
    updateCodeExtrait,
    updateCodesCompletionsCodeExtraitForVariant,
    fetchReuseImageExtractedCode: fetchReuseImage,
    sendExtractCodeMaterialReuseImage,
  } = useCode()

  const { organizationId } = useParams()
  const ifcDisplayerRef = useRef<HTMLDivElement>(null)
  const ifcDisplayerParentRef = useRef<any>(null)
  const [viewer, setViewer] = useState<ViewerIFC | undefined>(undefined)
  const [isSynchronizingCode, setIsSynchronizingCode] = useState<boolean>(false)
  const [variantCategory, setVariantCategory] = useState<1 | 2>(1)
  const [selectedCodeExtrait, setSelectedCodeExtrait] = useState<CodeExtrait | undefined>(undefined)
  const [isCopyMaterialOpen, setIsCopyMaterialOpen] = useState<boolean>(false)

  const isWriting = useMemo(() => hasRole([RoleEnum.ADMINISTRATOR, RoleEnum.BE]), [hasRole])

  const handleChangeVariant = useCallback((variant: 1 | 2): void => {
    setVariantCategory(variant)
  }, [])

  const handleClickNextStep = useCallback((): void => {
    isOrganizationUltimateUser
      ? navigate(resolveUrl(adminPagesUrl.PROJECT_CALCULATION_ADDITIONAL_DATA, [organizationId, project?.id]))
      : navigate(resolveUrl(pagesUrl.PROJECT_CALCULATION_ADDITIONAL_DATA, [project?.id]))
  }, [isOrganizationUltimateUser, navigate, project?.id])

  const handleClickPreviousStep = useCallback((): void => {
    isOrganizationUltimateUser
      ? navigate(resolveUrl(adminPagesUrl.PROJECT_CALCULATION_FLAT_RATES, [organizationId, project?.id]))
      : navigate(resolveUrl(pagesUrl.PROJECT_CALCULATION_FLAT_RATES, [project?.id]))
  }, [isOrganizationUltimateUser, project?.id])

  function handleSelectCodeExtrait(code: CodeExtraitDisplay): CodeExtraitDisplay {
    const newCodeExtrait: CodeExtrait | undefined = codesExtraits.find(
      (codeExtrait) => codeToKey(code) === codeToKey(codeExtrait)
    )
    setSelectedCodeExtrait(newCodeExtrait)
    // Reset variant
    handleChangeVariant(1)
    return code
  }

  function copyReuseImage(): void {
    if (selectedCodeExtrait?.id && selectedCodeExtrait.awsFileKey1 != null) {
      fetchReuseImage(selectedCodeExtrait.id, "1").then(async (blob) => {
        if (blob && project.id && selectedCodeExtrait.id) {
          const fileName = variantCategory === 1 ? selectedCodeExtrait.filename1 : selectedCodeExtrait.filename2
          let newFile: SetStateAction<File | undefined>
          if (fileName) {
            const fileType = variantCategory === 1 ? selectedCodeExtrait.fileType1 : selectedCodeExtrait.fileType2
            newFile = new File([blob], fileName, { type: fileType })
            sendExtractCodeMaterialReuseImage(newFile, project.id, selectedCodeExtrait.id, "2")
          }
        }
      })
    }
  }

  function handleUpdateIsSameValuesV1V2(e: ChangeEvent<HTMLInputElement>): void {
    const checked: boolean = e.target.checked
    if (project?.id && selectedCodeExtrait) {
      const promiseList: Promise<void>[] = []

      if (checked) {
        copyReuseImage()
        selectedCodeExtrait.filename2 = selectedCodeExtrait.filename1

        selectedCodeExtrait.amountVariant2 = selectedCodeExtrait.amountVariant1
        selectedCodeExtrait.reuseVariant2 = selectedCodeExtrait.reuseVariant1
        selectedCodeExtrait.materialIdVariant2 = selectedCodeExtrait.materialIdVariant1
        selectedCodeExtrait.materialVariant2 = selectedCodeExtrait.materialVariant1
        selectedCodeExtrait.hypothesisVariant2 = selectedCodeExtrait.hypothesisVariant1

        const listCompletionVariant1: CodeCompletion[] | undefined =
          codesCompletionsPerCodeParentV1.getForCodeParent(selectedCodeExtrait)
        // Deep copy of the list to modify the ids without changing the original list
        if (listCompletionVariant1) {
          const copiedList = listCompletionVariant1.map((item) => ({ ...item }))
          // We need to remove ids so the codes are new and will be created
          copiedList.forEach((codeCompletion) => {
            codeCompletion.id = undefined
          })
          // This is a deep copy, not a shallow one
          if (selectedCodeExtrait.id) {
            const promise1 = updateCodesCompletionsCodeExtraitForVariant(
              project.id,
              calculation.variantId2,
              selectedCodeExtrait.id,
              copiedList
            ).then((listCreated) => {
              const newCodeCompletionListV2 = CodesCompletionsPerCodeParent.setCodeCompletionList(
                codesCompletionsPerCodeParentV2,
                selectedCodeExtrait,
                listCreated
              )
              setCodesCompletionsPerCodeParentV2(newCodeCompletionListV2)
            })
            promiseList.push(promise1)
          }
        }
      }
      // In all case, sends the checkbox update
      setIsSynchronizingCode(true)
      selectedCodeExtrait.sameValuesV1V2 = checked
      promiseList.push(sendCodeExtraitUpdate())
      Promise.all(promiseList).finally(() => setIsSynchronizingCode(false))
    }
  }

  function createNewCodeCompletionFromCustom(codeCustomToAdd: CodeCustom): CodeCompletion | undefined {
    if (selectedCodeExtrait) {
      const variantId = variantCategory === 1 ? calculation.variantId1 : calculation.variantId2
      return CodeCompletion.fromAcvCustom(codeCustomToAdd, selectedCodeExtrait, variantId)
    } else {
      return undefined
    }
  }

  function createNewCodeCompletionFromAcv(codeAcvToAdd: CodeReference): CodeCompletion | undefined {
    if (selectedCodeExtrait) {
      const variantId = variantCategory === 1 ? calculation.variantId1 : calculation.variantId2
      return CodeCompletion.fromAcv(codeAcvToAdd, selectedCodeExtrait, variantId)
    } else {
      return undefined
    }
  }

  function updateCodesExtraitsList(newCodeExtrait: CodeExtrait): void {
    const newList = [...codesExtraits]
    const indexToUpdate = newList.findIndex((item) => item.id === newCodeExtrait.id)
    if (indexToUpdate !== -1) {
      newList[indexToUpdate] = newCodeExtrait
      setCodesExtraitsList(newList)
    }
  }

  function sendCodeExtraitUpdate(): Promise<void> {
    if (project?.id && selectedCodeExtrait) {
      const codeExtraitUpdate = CodeExtraitUpdate.fromCodeExtrait(selectedCodeExtrait)
      updateCodesExtraitsList(selectedCodeExtrait)
      return updateCodeExtrait(project.id, codeExtraitUpdate)
    } else {
      return Promise.resolve()
    }
  }

  // This action is different between code extrait and additional code
  function sendCompletionList(
    codeParentIdV1: string,
    codeCompletionListV1: CodeCompletion[],
    codeParentIdV2: string,
    codeCompletionListV2: CodeCompletion[]
  ): Promise<CompletionListV1v2> {
    if (project?.id) {
      if (variantCategory === 1 && selectedCodeExtrait?.sameValuesV1V2) {
        const promiseV1 = updateCodesCompletionsCodeExtraitForVariant(
          project.id,
          calculation.variantId1,
          codeParentIdV1,
          codeCompletionListV1
        )
        const promiseV2 = updateCodesCompletionsCodeExtraitForVariant(
          project.id,
          calculation.variantId2,
          codeParentIdV2,
          codeCompletionListV2
        )
        return Promise.all([promiseV1, promiseV2]).then((results) => {
          if (results.length === 2) {
            return { listV1: results[0], listV2: results[1] }
          } else {
            throw new Error("Erreur lors de la mise à jour des variantes")
          }
        })
      } else if (variantCategory === 2) {
        if (selectedCodeExtrait?.sameValuesV1V2) {
          selectedCodeExtrait.sameValuesV1V2 = false
        }
        return updateCodesCompletionsCodeExtraitForVariant(
          project.id,
          calculation.variantId2,
          codeParentIdV2,
          codeCompletionListV2
        ).then((listV2) => ({ listV1: codeCompletionListV1, listV2 }))
      } else if (variantCategory === 1) {
        if (selectedCodeExtrait?.sameValuesV1V2) {
          selectedCodeExtrait.sameValuesV1V2 = false
        }
        return updateCodesCompletionsCodeExtraitForVariant(
          project.id,
          calculation.variantId1,
          codeParentIdV1,
          codeCompletionListV1
        ).then((listV1) => ({ listV1, listV2: codeCompletionListV2 }))
      } else {
        const { variantId, codeParentId, codeCompletionList } =
          variantCategory === 1
            ? {
                variantId: calculation.variantId1,
                codeParentId: codeParentIdV1,
                codeCompletionList: codeCompletionListV1,
              }
            : {
                variantId: calculation.variantId2,
                codeParentId: codeParentIdV2,
                codeCompletionList: codeCompletionListV2,
              }
        return updateCodesCompletionsCodeExtraitForVariant(project.id, variantId, codeParentId, codeCompletionList).then(
          (listV1) => ({ listV1, listV2: codeCompletionListV2 })
        )
      }
    }
    return Promise.resolve({ listV1: [], listV2: [] })
  }

  function disableSameValuesIfNeed(): void {
    if (variantCategory === 2 && selectedCodeExtrait) {
      selectedCodeExtrait.sameValuesV1V2 = false
      sendCodeExtraitUpdate()
    }
  }

  function updateIsFilled(_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    if (selectedCodeExtrait) {
      selectedCodeExtrait.filled = checked
      sendCodeExtraitUpdate()
    }
  }

  function handleClose(): void {
    setIsCopyMaterialOpen(false)
  }

  function refreshCode(): Promise<void> {
    const promise1 = refreshCodeExtrait()
    const promise2 = refreshCodeCompletion()
    return Promise.all([promise1, promise2]).then((result: [CodeExtrait[] | undefined, any]) => {
      const newCodeExtraitList = result[0]
      if (newCodeExtraitList) {
        const updatedCode = newCodeExtraitList.find((code) => code.id === selectedCodeExtrait?.id)
        if (updatedCode) {
          setSelectedCodeExtrait(updatedCode)
        }
      }
    })
  }

  return (
    <>
      <Grid container rowGap={2}>
        <Grid item xs={3}>
          <ProjectCarbonCalculatorHeader />
        </Grid>
        <Grid item xs={7}>
          <ProjectCarbonBreadcrumb
            selected={CalculationStepsEnum.EXTRACTED_CODE}
            submit={() => Promise.resolve(true)}
            isWriting={isWriting}
          />
        </Grid>
        <Grid item xs={2}>
          <Box
            sx={{
              width: 200,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <Button variant="outlined" onClick={handleClickPreviousStep}>
              Retour
            </Button>
            <Button variant="contained" onClick={handleClickNextStep}>
              Suivant
            </Button>
          </Box>
        </Grid>
        {isWriting && (
          <>
            <CopyMaterialDialog open={isCopyMaterialOpen} handleClose={handleClose} refreshCode={refreshCode} />
            <Grid item xs={3}>
              <Button
                variant="outlined"
                onClick={() => {
                  setIsCopyMaterialOpen(true)
                }}>
                Remplacer avec les matériaux d'un autre projet
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Box sx={{ mt: "40px" }}>
        <Grid container columnSpacing={2}>
          <Grid item xs={8}>
            <CodeExtraitListWithViewer
              selectedCodeExtrait={selectedCodeExtrait}
              setSelectedCodeExtrait={handleSelectCodeExtrait}
              viewer={viewer}
              setViewerIFC={setViewer}
              ifcDisplayerRef={ifcDisplayerRef}
              ifcDisplayerParentRef={ifcDisplayerParentRef}
              fullscreenButton
              fullscreenTabButton
            />
          </Grid>

          {selectedCodeExtrait && (
            <Grid item xs={4}>
              <ExtractedCodeCoreData
                selectedCodeExtrait={selectedCodeExtrait}
                setSelectedCodeExtrait={setSelectedCodeExtrait}
                viewer={viewer}
                sendCodeExtraitUpdate={sendCodeExtraitUpdate}
                ifcDisplayerRef={ifcDisplayerRef}
                ifcDisplayerParentRef={ifcDisplayerParentRef}
                disabled={!isWriting}
              />
            </Grid>
          )}
        </Grid>
        {selectedCodeExtrait && (
          <Grid container mb={2} mt={4}>
            <Grid justifyItems="center" alignContent="center" alignItems="center" item xs={4}>
              <Box display="flex" justifyContent="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="validate-data"
                      checked={selectedCodeExtrait.filled}
                      onChange={updateIsFilled}
                      disabled={!isWriting}
                    />
                  }
                  label="Code renseigné"
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <SelectVariant selectedVariant={variantCategory} handleChangeVariant={handleChangeVariant} />
            </Grid>
            {variantCategory === 2 ? (
              <CheckIsSame
                isSynchronizingCode={isSynchronizingCode}
                isChecked={selectedCodeExtrait.sameValuesV1V2}
                handleUpdateIsSameValuesV1V2={handleUpdateIsSameValuesV1V2}
                disabled={!isWriting}
              />
            ) : (
              <Grid item xs={2} />
            )}
          </Grid>
        )}

        {selectedCodeExtrait && (
          <Grid container columnSpacing={2}>
            <Grid item xs={4} sx={{ paddingRight: "32px" }}>
              <FormAddMaterialToExtracted
                selectedCodeExtrait={selectedCodeExtrait}
                setSelectedCodeExtrait={setSelectedCodeExtrait}
                variantCategory={variantCategory}
                sendCodeExtraitUpdate={sendCodeExtraitUpdate}
                disabled={!isWriting}
              />
            </Grid>
            <Grid item xs={8} sx={{ pr: 0, pl: 10, pt: 4, pb: 4, backgroundColor: "#ebebeb" }}>
              <FormAddCompletion
                selectedCodeParentV1={selectedCodeExtrait}
                selectedCodeParentV2={selectedCodeExtrait} // It's the same code for v1 and v2 for "codes extraits"
                variantCategory={variantCategory}
                createNewCodeCompletionFromAcv={createNewCodeCompletionFromAcv}
                createNewCodeCompletionFromCustom={createNewCodeCompletionFromCustom}
                sendCompletionList={sendCompletionList}
                disableSameValuesIfNeed={disableSameValuesIfNeed}
                sameValuesV1V2={selectedCodeExtrait.sameValuesV1V2}
                disabled={!isWriting}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  )
}
