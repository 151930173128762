import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import CancelButton from "../../../../components/buttons/CancelButton/CancelButton"
import { SubmitButton } from "../../../../components/buttons/SubmitButton/SubmitButton"
import { Transition } from "../../../../components/dialog/BaseDialog"
import { BaseSelectInput, SelectOption } from "../../../../components/inputs/select-input/BaseSelectInput"
import { TextInput } from "../../../../components/inputs/text-input/text-input"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import { pagesUrl } from "../../../../core/appConstants"
import { BSProjectContext } from "../../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariantContext } from "../../../../core/context/beem-shot/BSVariant/BSVariantContext"
import { BSVariant } from "../../../../core/dto/beem-shot/BSVariant/BSVariant"
import { BSVariantCreationDto } from "../../../../core/dto/beem-shot/BSVariant/BSVariantCreateDto"
import { BSVariantUpdateDto } from "../../../../core/dto/beem-shot/BSVariant/BSVariantUpdateDto"
import { useForm } from "../../../../core/hooks/form/use-form"
import { required } from "../../../../core/hooks/form/validation"
import { resolveUrl } from "../../../../core/services/http-service"

export interface IForm {
  id: string
  name: string
  description: string
}

export function formToDto(form: IForm, projectId: string): BSVariantCreationDto {
  return {
    name: form.name,
    description: form.description,
    projectId,
  }
}

export function formToDtoUpdate(form: IForm, projectId: string): BSVariantUpdateDto {
  return {
    id: form.id,
    name: form.name,
    description: form.description,
    projectId,
  }
}

interface IProps {
  open: boolean
  bsVariant: BSVariant | undefined
  bsProjectId?: string
  isUpdateMode?: boolean

  handleClose(): void
}

export function BSVariantForm({
  open,
  bsVariant,
  handleClose,
  bsProjectId,
  isUpdateMode = false,
}: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()
  const openSuccessSnackbar = useContext(SuccessContext)
  const { bsVariants, createVariant, updateVariant, fetchAllBSVariants } = useContext(BSVariantContext)
  const { bsProject } = useContext(BSProjectContext)

  const [sourceVariantId, setSourceVariantId] = useState<string | undefined>()

  const baseVariant: BSVariant | undefined = useMemo(() => (isUpdateMode ? bsVariant : undefined), [isUpdateMode, bsVariant])

  const options: SelectOption<string | undefined>[] = useMemo(
    () =>
      bsVariants.map((_bsVariant) => ({
        label: _bsVariant.name,
        value: _bsVariant.id,
      })),
    [bsVariants]
  )

  const dtoToForm = useCallback(
    (dto: BSVariant | undefined): IForm =>
      dto
        ? {
            id: dto.id ? dto.id : "",
            name: dto.name,
            description: dto.description,
          }
        : {
            id: "",
            name: "",
            description: "",
          },
    []
  )

  const submit: (form: IForm) => Promise<any> = useCallback(
    (form: IForm) => {
      if (isUpdateMode && bsProject?.id) {
        return updateVariant(formToDtoUpdate(form, bsProject.id)).then((updatedVariant) => {
          handleClose()
          navigate(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, updatedVariant?.id]))
          openSuccessSnackbar("Les informations de la variante ont été mis à jour.")
        })
      } else if (bsProject?.id) {
        return createVariant(formToDto(form, bsProject.id), sourceVariantId).then((createdVariant) => {
          handleClose()
          if (bsProject.id) {
            fetchAllBSVariants(bsProject.id).then(() => {
              navigate(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProject.id, createdVariant.id]))
            })
          }
          return createdVariant
        })
      }
      return Promise.resolve()
    },
    [
      isUpdateMode,
      bsProject?.id,
      updateVariant,
      navigate,
      bsProjectId,
      openSuccessSnackbar,
      createVariant,
      sourceVariantId,
      handleClose,
      fetchAllBSVariants,
    ]
  )

  const { form, errors, handleChange, handleSubmit, resetForm, setForm, isSubmitting } = useForm(
    baseVariant,
    dtoToForm,
    [required("name")],
    submit
  )

  useEffect(() => {
    resetForm()

    if (bsVariants?.length === 0 && open) {
      setForm((oldForm) => ({ ...oldForm, name: "Variante de base" }))
    }
  }, [bsProjectId, bsVariants, open, resetForm, setForm])

  const onClose = useCallback(() => {
    handleClose()
    resetForm()
    setSourceVariantId(undefined)
  }, [handleClose, resetForm])

  function handleChangeSourceVariant(variantId: string): void {
    setSourceVariantId(variantId)
  }

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "white",
        },
      }}
      TransitionComponent={Transition}>
      <Box component="form" id={isUpdateMode ? "update-bs-variant" : "create-bs-variant"} onSubmit={handleSubmit}>
        <DialogTitle variant="subtitle2">
          <Typography> {isUpdateMode ? "Modifier la variante" : "Nouvelle variante"}</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ pb: bsVariants.length !== 0 && !isUpdateMode ? 3 : 1 }}>
                Informations
              </Typography>

              {bsVariants.length !== 0 && !isUpdateMode && (
                <BaseSelectInput
                  id="sourceVariant"
                  label="Généré à partir de "
                  selectedOption={sourceVariantId}
                  options={options}
                  mode="direct"
                  handleChange={handleChangeSourceVariant}
                  isBeemShot
                  errors={errors}
                />
              )}
              <TextInput
                fieldName="name"
                label="Nom de la nouvelle variante"
                handleChange={handleChange}
                errors={errors}
                form={form}
              />
              <TextInput
                fieldName="description"
                label="Entrez la description de la variante"
                form={form}
                handleChange={handleChange}
                errors={errors}
                rows={8}
                required={false}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose} label="Annuler" />
          {isUpdateMode ? (
            <SubmitButton label="Mettre à jour" formId="update-bs-variant" isSubmitting={isSubmitting} />
          ) : (
            <SubmitButton label="Créer" formId="create-bs-variant" isSubmitting={isSubmitting} />
          )}
        </DialogActions>
      </Box>
    </Dialog>
  )
}
