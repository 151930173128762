import { Grid, Paper } from "@mui/material"
import React from "react"
import { Filters } from "../../../../../../../components/charts/filters/Filters"
import { BSMaterialResult } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { DashboardSectionTitle } from "../../../../../../../components/charts/components/DashboardSectionTitle"
import { FdesNumber } from "../../../../../../../components/charts/kpi/FdesNumber"
import { CarbonImpactPerFdesChart } from "../../../../../../../components/charts/tables/IniesRecordChart/CarbonImpactPerFdesChart"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
}

export function IcConstructionBottomRightPanel({ allBSMaterialResult }: Readonly<IProps>): React.JSX.Element {
  return (
    <Paper sx={{ height: "100%" }}>
      <DashboardSectionTitle label="Détail des FDES" />
      <Grid container spacing={2} sx={{ pl: 2, pr: 2 }}>
        {/* It's weird that columns=15 impacts the children grid item and the parent item-container */}
        <Grid item xs={16} container columns={16} spacing={2} sx={{ pr: 2 }}>
          <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <FdesNumber allBSMaterialResult={allBSMaterialResult} />
          </Grid>
          <Filters allBSMaterialResult={allBSMaterialResult} />
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <CarbonImpactPerFdesChart
            allBSMaterialResult={allBSMaterialResult}
            fieldName="re2020CarbonImpactPerSurface"
            valueColumnLabel="Carbone (m²/Sref)"
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
