import { Box, Grid } from "@mui/material"
import React, { useContext } from "react"
import { Link } from "react-router-dom"
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined"
import { pagesUrl } from "../../../core/appConstants"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSVariant } from "../../../core/dto/beem-shot/BSVariant/BSVariant"
import { formatToFrench } from "../../../core/services/date-service"
import { resolveUrl } from "../../../core/services/http-service"
import { DefaultTypography } from "../../typography/default-typography"
import { BSCardSeuil } from "./BSCardSeuil"

type IProps = {
  bsVariant: BSVariant
}

export function BSVariantCard({ bsVariant }: Readonly<IProps>): React.JSX.Element {
  const { bsProject } = useContext(BSProjectContext)

  function getNavigateUrl(): string {
    return resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProject?.id, bsVariant.id])
  }

  return (
    <Link to={getNavigateUrl()} style={{ width: "100%", minHeight: 100, textDecoration: "none", color: "inherit" }}>
      <Grid
        container
        alignItems="center"
        rowGap={1}
        width="100%"
        sx={{
          backgroundColor: "#fff",
          borderRadius: 3,
          p: 3,
          boxShadow: "2px 4px 20px 0px rgba(0, 0, 0, 0.05)",
          transition: "all 0.2s ease-in-out",
          ":hover": {
            cursor: "pointer",
            boxShadow: 10,
            transform: "translateY(-5px)",
          },
        }}>
        <Grid container item display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Grid item xs={2}>
            <Box
              border={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ borderRadius: 2, background: "#CEDEEF", p: 1, border: 3, borderColor: "white" }}>
              <DonutSmallOutlinedIcon fontSize="medium" sx={{ color: "#4965AC", fontWeight: "2px" }} />
            </Box>
          </Grid>
        </Grid>

        <Grid container justifyContent="left" alignItems="center" width="100%">
          <DefaultTypography label={bsVariant.name} fontSize="16px" lineHeight="24px" fontWeight={600} />
        </Grid>

        <Grid container flexGrow={1} width="100%">
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ border: 2, borderColor: "#F5F8F8", borderRadius: 2, p: 1 }}>
            <DefaultTypography
              label="Bilan de l'impact carbone"
              fontSize="11px"
              lineHeight="26.5px"
              fontWeight={400}
              color="#8398A6"
            />
            <DefaultTypography
              label={
                bsVariant.lastImpactCarbone ? `${bsVariant.lastImpactCarbone?.toFixed(2)} kgeqCO2/m²Sref` : "A calculer"
              }
              fontSize="12px"
              lineHeight="18px"
              fontWeight={400}
            />
          </Grid>
        </Grid>

        <Grid container minHeight={50} borderRadius={2}>
          <BSCardSeuil
            diffIc2022={bsVariant.diffIc2022}
            diffIc2025={bsVariant.diffIc2025}
            diffIc2028={bsVariant.diffIc2028}
            diffIc2031={bsVariant.diffIc2031}
            higherSeuilValide={bsVariant.higherSeuilValide}
            calculStatus={bsVariant.calculStatus}
          />
        </Grid>

        <Grid container display="flex" justifyContent="space-between" width="100%" alignItems="center">
          <DefaultTypography
            label={`Mise à jour le ${formatToFrench(new Date(bsVariant.lastModifiedDate))}`}
            fontSize="12px"
            lineHeight="18px"
            fontWeight={400}
            color="#8398A6"
          />
        </Grid>
      </Grid>
    </Link>
  )
}
