import { Box } from "@mui/material"
import React, { useContext } from "react"
import { AppNavBarContext, NavbarTypeEnum } from "../../core/context/nav-bar/AppNavBarContext"
import "./app-navbar-v2.scss"
import { DefaultNavbar } from "./component/DefaultNavbar"
import { HeaderWithoutStep } from "./component/HeaderWithoutStep"
import { StepHeaderNavbar } from "./component/StepHeaderNavbar"

export function AppNavbar(): React.JSX.Element {
  const { typeNavBar } = useContext(AppNavBarContext)

  switch (typeNavBar) {
    case NavbarTypeEnum.DEFAULT:
      return <DefaultNavbar />
    case NavbarTypeEnum.STEP_HEADER:
      return <StepHeaderNavbar />
    case NavbarTypeEnum.HEAD_WITHOUT_STEP:
      return <HeaderWithoutStep />
    default:
      return <Box />
  }
}
