import InfoIcon from "@mui/icons-material/Info"
import { Grid, Icon, Typography } from "@mui/material"
import React from "react"

export function VariantsOverviewHeader(): React.JSX.Element {
  return (
    <>
      <Grid item xs={1} />
      <Grid item xs={10} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h4" fontWeight={600} textAlign="center" sx={{ color: "#40548D" }}>
          Récapitulatif des variantes liées
        </Typography>
      </Grid>

      <Grid item xs={1} />
      <Grid item xs={12} display="flex" justifyContent="center" gap={1}>
        <Icon>
          <InfoIcon />
        </Icon>
        <Typography variant="body2">
          Visualiser les variantes associées à la maquette sélectionnée. Après avoir choisi une maquette, vous pouvez
          examiner les variantes liées et ajuster les informations si nécessaire.
        </Typography>
      </Grid>
    </>
  )
}
