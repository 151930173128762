import { useMemo } from "react"
import { appConstants } from "../../appConstants"
import { BSProject } from "../../dto/beem-shot/BSProject/BSProject"
import { BSProjectCardDto } from "../../dto/beem-shot/BSProject/BSProjectCardDto"
import { BSProjectCreationDto } from "../../dto/beem-shot/BSProject/BSProjectCreationDto"
import { BSProjectUpdateDto } from "../../dto/beem-shot/BSProject/BSProjectUpdateDto"
import { BSVariant } from "../../dto/beem-shot/BSVariant/BSVariant"
import { Department } from "../../enum/departments"
import { ProjectStatusEnum } from "../../enum/projectStatusEnum"
import { streamToBlob } from "../../services/file-service"
import { resolveUrl } from "../../services/http-service"
import { useHttp } from "../use-http"

type BsProjectHook = {
  getBSProjects(
    status: ProjectStatusEnum,
    actualPage: number,
    limit: number,
    search: string
  ): Promise<{ bsProjectCardDtoList: BSProjectCardDto[]; bsProjectCount: number }>
  createBSProject(beemShotProjectCreationDto: BSProjectCreationDto): Promise<BSProject>
  getBSProject(beemShotProjectId: string): Promise<BSProject | undefined>
  updateBSProject(beemShotProjectUpdateDto: BSProjectUpdateDto): Promise<BSProject>
  deleteBSProject(bsProjectId: string): Promise<Response>
  getStartEstimating(bsVariantId: string): Promise<Response>
  sendCoverImageFile(coverImageFile: File, bsProjectId: string): Promise<Response>
  fetchCoverImage(bsProjectId: string): Promise<Blob | undefined>
  deleteCoverImage(bsProjectId: string): Promise<Response>
  listenIsCalculationReady(bsVariant: BSVariant): Promise<boolean>
  fetchBSVarianteDashboardUrl(): Promise<string>
  fetchDepartments(): Promise<Department[]>
}

export function useBSProject(): BsProjectHook {
  const { get, put, post, postFile, deleteRequest } = useHttp()

  return useMemo(
    () => ({
      getBSProjects(
        status: ProjectStatusEnum,
        actualPage: number,
        limit: number,
        search: string
      ): Promise<{ bsProjectCardDtoList: BSProjectCardDto[]; bsProjectCount: number }> {
        const queryProjectParams = [
          {
            key: "status",
            value: status,
          },
          {
            key: "limit",
            value: limit.toString(),
          },
          {
            key: "actualPage",
            value: actualPage.toString(),
          },
          {
            key: "search",
            value: search,
          },
        ]
        const queryCountParams = [
          {
            key: "status",
            value: status,
          },
          {
            key: "actualPage",
            value: actualPage.toString(),
          },
          {
            key: "search",
            value: search,
          },
        ]

        const fetchBSProjectList = get(
          resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_PROJECTS, [], {}),
          queryProjectParams
        ).then((response) => response.json())
        const fetchBSProjectCount = get(
          resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_PROJECTS_COUNT, [], {}),
          queryCountParams
        ).then((response) => response.json())

        return fetchBSProjectList.then(async (bsProjectCardDtoList) => {
          const bsProjectCount = await fetchBSProjectCount
          return { bsProjectCardDtoList, bsProjectCount }
        })
      },
      createBSProject(bsProjectCreationDto: BSProjectCreationDto): Promise<BSProject> {
        return post(appConstants.apiEndpoints.BEEM_SHOT_PROJECT, bsProjectCreationDto)
          .then((response) => response.json())
          .then((data) => BSProject.fromDto(data))
      },
      getBSProject(bsProjectId: string): Promise<BSProject | undefined> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_PROJECT_ID, [bsProjectId])).then((response) => {
          if (response.status === 404) {
            return Promise.resolve(undefined)
          } else {
            return response.json().then((data) => BSProject.fromDto(data))
          }
        })
      },
      updateBSProject(beemShotProjectUpdateDto: BSProjectUpdateDto): Promise<BSProject> {
        return put(appConstants.apiEndpoints.BEEM_SHOT_PROJECT, beemShotProjectUpdateDto)
          .then((response) => response.json())
          .then((data) => BSProject.fromDto(data))
      },
      deleteBSProject(bsProjectId: string): Promise<Response> {
        return deleteRequest(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_PROJECT_ID, [bsProjectId]))
      },
      getStartEstimating(bsVariantId: string): Promise<Response> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_PROJECT_ID_START_ESTIMATE, [bsVariantId]))
      },
      sendCoverImageFile(coverImageFile: File, bsProjectId: string): Promise<Response> {
        const formData = new FormData()
        formData.append("coverImageFile", coverImageFile)
        formData.append("bsProjectId", bsProjectId)
        return postFile(`${appConstants.apiEndpoints.BEEM_SHOT_COVER_IMAGE}`, formData)
      },
      fetchCoverImage(bsProjectId: string): Promise<Blob | undefined> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_COVER_IMAGE, []), [
          {
            key: "bsProjectId",
            value: bsProjectId,
          },
        ])
          .then((res) => streamToBlob(res))
          .catch(() => undefined)
      },
      deleteCoverImage(bsProjectId: string): Promise<Response> {
        return deleteRequest(`${appConstants.apiEndpoints.BEEM_SHOT_COVER_IMAGE}`, [
          {
            key: "projectId",
            value: bsProjectId,
          },
        ])
      },
      listenIsCalculationReady(bsVariant: BSVariant): Promise<boolean> {
        return get(`${appConstants.apiEndpoints.BEEM_SHOT_CALCULATION_READY}`, [
          {
            key: "bsVariantId",
            value: bsVariant.id ?? "",
          },
        ])
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(`Error: http status code ${response.status}`)
            }
            return response.text()
          })
          .then((responseText) => responseText !== "false")
      },
      fetchBSVarianteDashboardUrl(): Promise<string> {
        return get(resolveUrl(appConstants.apiEndpoints.BEEM_SHOT_DASHBOARD_VARIANTE_URL, [])).then((response) =>
          response.text()
        )
      },
      fetchDepartments(): Promise<Department[]> {
        return get(resolveUrl(`${appConstants.apiEndpoints.BEEM_SHOT}/department`, [])).then((response) => response.json())
      },
    }),
    [get, put, post, postFile, deleteRequest]
  )
}
