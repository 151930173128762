import React, { forwardRef } from "react"
import "./styles.scss"
import { BSIfcDisplayerCore, IfcDisplayerProps } from "./BSIfcDisplayerCore"
import { IfcDisplayerErrorBoundary } from "./ifc-displayer-error-boundary"

function BSIfcDisplayer1(props: Readonly<IfcDisplayerProps>, ref: any): React.JSX.Element {
  const { height } = props
  return (
    <IfcDisplayerErrorBoundary height={height}>
      <BSIfcDisplayerCore {...props} />
    </IfcDisplayerErrorBoundary>
  )
}

export const BSIfcDisplayer = forwardRef(BSIfcDisplayer1)
