import { Paper } from "@mui/material"
import React from "react"
import { BSMaterialResult } from "../../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { DashboardSectionTitle } from "../../../../../../../components/charts/components/DashboardSectionTitle"
import { BSSortedBarChart } from "../../../../../../../components/charts/BSItemCarbonImpactChart/BSSortedBarChart"
import { customPalette } from "../../../../../../../../theme"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
}

export function BiosourceBottomLeftfPanel({ allBSMaterialResult }: Readonly<IProps>): React.JSX.Element {
  return (
    <Paper sx={{ height: "100%" }}>
      <DashboardSectionTitle label="Points biosourcé par items" />
      <BSSortedBarChart
        allBSMaterialResult={allBSMaterialResult}
        columnLabel="% sur le total biosourcé"
        fieldName="carbonStockPerSurface"
        highestColor={customPalette.noHeat}
        middleColor={customPalette.lowHeat}
        lowestColor={customPalette.highHeat}
      />
    </Paper>
  )
}
