import React, { useContext, useMemo } from "react"
import { BSMaterialResultContext } from "../../../../../../core/context/beem-shot/BSMaterialResult/BSMaterialResultContext"
import { BSItem } from "../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { BSMaterialResultFormDto } from "../../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResultFormDto"
import { codeToKey } from "../../../../../../core/services/code-service"
import { BSMaterialResultRow } from "./BSMaterialResultRow"

interface IProps {
  bsItem: BSItem
}

export function BSMaterialResultList({ bsItem }: Readonly<IProps>): React.JSX.Element {
  const { bsMaterialResultFormDto: bsMaterialResultWithOverriddenAndCustomDatas } = useContext(BSMaterialResultContext)

  const materialResultList: BSMaterialResultFormDto[] = useMemo(
    () =>
      bsMaterialResultWithOverriddenAndCustomDatas
        .filter((bsMaterialResult: BSMaterialResultFormDto) => bsMaterialResult.itemType === bsItem.type.name)
        .filter((bsMaterialResult: BSMaterialResultFormDto) => {
          if (bsItem.codeExtrait) {
            return bsMaterialResult.codeOccurrence === codeToKey(bsItem.codeExtrait)
          } else {
            return bsMaterialResult.codeOccurrence === undefined
          }
        }),
    [bsItem.codeExtrait, bsItem.type.name, bsMaterialResultWithOverriddenAndCustomDatas]
  )

  return (
    <>
      {materialResultList.map((bsMaterialResult: BSMaterialResultFormDto) => (
        <BSMaterialResultRow key={bsMaterialResult.id} bsItem={bsItem} bsMaterialResult={bsMaterialResult} />
      ))}
    </>
  )
}
