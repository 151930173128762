import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { ErrorContext } from "../../../components/layout/error-snackbar"
import { currentCodeVersion } from "../../appConstants"
import CodeReference from "../../dto/code-acv/code-reference"
import { CodesVersion } from "../../enum/codesVersionEnum"
import { useCode } from "../../hooks/use-code"
import { UserContext } from "../user/user-context"

export const CodeReferenceContext = React.createContext<CodeReferenceStore>({} as CodeReferenceStore)

type IProps = {
  projectCodeVersion: CodesVersion
  children?: React.ReactNode
}

export function CodeReferenceContextProvider({ projectCodeVersion, children }: IProps): React.JSX.Element {
  const { fetchCodeAcvExtraitMap } = useCode()

  const openErrorSnackbar = useContext(ErrorContext)
  const { user } = useContext(UserContext)

  const [codeAcvExtraitPerVersion, setCodeAcvExtraitPerVersion] = useState<Map<CodesVersion, Map<string, CodeReference>>>(
    new Map()
  )
  const [isCodeAcvLoading, setIsCodeAcvLoading] = useState<boolean>(false)

  const isCodesLoadingPerVersionRef = useRef<Map<CodesVersion, boolean>>(new Map())

  function updateMap(key: CodesVersion, value: Map<string, CodeReference>): void {
    setCodeAcvExtraitPerVersion((previousMap) => {
      const newMap = new Map(previousMap)
      newMap.set(key, value)
      return newMap
    })
  }

  const setNoCodeLoading = useCallback((): void => {
    let isSomeCodeLoading = false
    isCodesLoadingPerVersionRef.current.forEach((value) => {
      if (value) {
        isSomeCodeLoading = true
      }
    })
    if (!isSomeCodeLoading) {
      setIsCodeAcvLoading(false)
    }
  }, [])

  const fetchCodeVersion = useCallback(
    (versionToFetch: CodesVersion): void => {
      setIsCodeAcvLoading(true)
      isCodesLoadingPerVersionRef.current.set(versionToFetch, true)
      fetchCodeAcvExtraitMap(versionToFetch)
        .then((codeNameToCodeObj) => {
          updateMap(versionToFetch, codeNameToCodeObj)
        })
        .catch((e) => {
          openErrorSnackbar(e)
        })
        .finally(() => {
          isCodesLoadingPerVersionRef.current.set(versionToFetch, false)
          setNoCodeLoading()
        })
    },
    [fetchCodeAcvExtraitMap, openErrorSnackbar, setNoCodeLoading]
  )

  useEffect(() => {
    // We always need code 1.6 because it's for the tutorial BIM model
    fetchCodeVersion(CodesVersion.VERSION_1_6)
  }, [fetchCodeVersion])

  useEffect(() => {
    // Start fetching codes of the current version
    if (
      user !== undefined &&
      codeAcvExtraitPerVersion.get(currentCodeVersion) === undefined &&
      !isCodesLoadingPerVersionRef.current.get(currentCodeVersion)
    ) {
      fetchCodeVersion(currentCodeVersion)
    }
  }, [codeAcvExtraitPerVersion, fetchCodeVersion, user])

  useEffect(() => {
    // Start fetching code project codesVersion if needed
    if (
      user !== undefined &&
      projectCodeVersion &&
      projectCodeVersion !== currentCodeVersion &&
      codeAcvExtraitPerVersion.get(projectCodeVersion) === undefined &&
      !isCodesLoadingPerVersionRef.current.get(projectCodeVersion)
    ) {
      fetchCodeVersion(projectCodeVersion)
    }
  }, [codeAcvExtraitPerVersion, fetchCodeVersion, projectCodeVersion, user])

  const codeAcvStore: CodeReferenceStore = useMemo(
    () => ({ codeAcvExtraitPerVersion, isCodeAcvLoading }),
    [codeAcvExtraitPerVersion, isCodeAcvLoading]
  )

  return <CodeReferenceContext.Provider value={codeAcvStore}>{children} </CodeReferenceContext.Provider>
}

export type CodeReferenceStore = {
  codeAcvExtraitPerVersion: Map<CodesVersion, Map<string, CodeReference>>
  isCodeAcvLoading: boolean
}
