import { lightenColor } from "../../../core/services/color-service"

export interface ISlice {
  id: string // Lot enum
  label: string
  value: number
  color: string
}

export function chooseColor(data: ISlice, selectedLots: Map<string | undefined, boolean>): string {
  return selectedLots.size === 0 || selectedLots.get(data.id) ? data.color : lightenColor(data.color, 0.3)
}
