import { ProjectStatusEnum } from "../../../enum/projectStatusEnum"
import { stringToDate } from "../../../services/date-service"

export class BSProjectResult {
  id: string
  organizationId: string
  organizationName: string
  projectId: string
  projectName: string
  projectStatus: ProjectStatusEnum
  licenseDate: Date | undefined

  constructor(data: any) {
    this.id = data.id
    this.organizationId = data.organizationId
    this.organizationName = data.organizationName
    this.projectId = data.projectId
    this.projectName = data.projectName
    this.projectStatus = data.projectStatus
    console.log("data.licenseDate", stringToDate(data.licenseDate))
    this.licenseDate = stringToDate(data.licenseDate)
  }
}
