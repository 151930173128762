import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { ErrorContext } from "../../../../components/layout/error-snackbar"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import { Children } from "../../../../components/miscellianous/children"
import { BSBimModel } from "../../../dto/beem-shot/BSBimModel/BSBimModel"
import { BSBimModelUpdate } from "../../../dto/beem-shot/BSBimModel/BSBimModelUpdate"
import { useBSBimModel } from "../../../hooks/beem-shot/useBSBimModel"
import { BSInputContext } from "../BSInput/BSInputContext"
import { BSBimModelListContext } from "./BSBimModelContextList"
import { useBSVariant } from "../../../hooks/beem-shot/useBSVariant"
import { BSCodeExtraitCreation } from "../../../dto/code-extrait/BSCodeExtraitCreation"
import { BSVariantContext } from "../BSVariant/BSVariantContext"
import { CalculStatusEnum } from "../../../enum/calculStatusEnum"
import { BSProjectContext } from "../BSProject/BSProjectContext"

export const BSBimModelContext = React.createContext<BSModelStore>({} as BSModelStore)

export function BSModelContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { bsBimModelId } = useParams()

  const { putBSBimModel, getBimModelById, deleteBSBimModelFile } = useBSBimModel()
  const { affectBSBimModelToVariant } = useBSVariant()

  const { bsProject } = useContext(BSProjectContext)
  const { bsInput, updateBSInputModelId, refreshBSInput } = useContext(BSInputContext)
  const { updateCalculStatusState, fetchAllBSVariants } = useContext(BSVariantContext)
  const { removeBimModelFromList, addOrReplaceBimModelInList, refreshBimModelListByProjectId } =
    useContext(BSBimModelListContext)
  const openErrorSnackbar = useContext(ErrorContext)
  const openSuccessSnackbar = useContext(SuccessContext)

  const [bsBimModel, setBSBimModel] = useState<BSBimModel | undefined>(undefined)
  const [bsBimModelListByProject, setBSBimModelListByProject] = useState<BSBimModel[]>([])
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [isBSBimModelLoading, setIsBSBimModelLoading] = useState<boolean>(false)
  const [isBSBimModelSubmitting, setIsBSBimModelSubmitting] = useState<boolean>(false)

  const bsBimModelIdRef = useRef<string | undefined>(undefined)

  /**
   * This function is useful to keep synchronized bsBimModel and bsBimModelIdRef
   * setBSBimModel must never be called. Call this function instead
   * bsBimModelIdRef is useful because when we fetch the file, it takes time.
   * So, sometimes, we start fetching the file, and before it is downloaded, we update the bim model.
   * When the file finish downloading, we need to check if bsBimModelIdRef.current is the same as the one we used to fetch
   * The state variable "bsBimModel" cannot be used in a reliable way. It is often not updated yet
   */
  const setStateBSBimModel: (newBSBimModel: BSBimModel | undefined) => void = useCallback(
    (newBSBimModel: BSBimModel | undefined) => {
      setBSBimModel(newBSBimModel)
      bsBimModelIdRef.current = newBSBimModel?.id
    },
    []
  )

  const refreshBimModel = useCallback(() => {
    let idToFetch
    if (bsBimModelId === "new") {
      idToFetch = undefined
    } else if (bsBimModelId) {
      idToFetch = bsBimModelId
    } else if (bsInput?.bsBimModelId) {
      idToFetch = bsInput.bsBimModelId
    } else {
      idToFetch = undefined
    }

    if (idToFetch) {
      setIsBSBimModelLoading(true)
      getBimModelById(idToFetch)
        .then((bimModelResponse) => {
          if (bimModelResponse) {
            setStateBSBimModel(bimModelResponse)
          } else {
            setStateBSBimModel(undefined)
          }
        })
        .catch((e) => {
          openErrorSnackbar(e)
        })
        .finally(() => {
          setIsBSBimModelLoading(false)
        })
    } else {
      setStateBSBimModel(undefined)
    }
  }, [bsBimModelId, bsInput?.bsBimModelId, getBimModelById, openErrorSnackbar, setStateBSBimModel])

  useEffect(() => {
    refreshBimModel()
  }, [refreshBimModel])

  const handleDeleteBSBimModel: (modelId: string) => Promise<void> = useCallback(
    (modelId: string) => {
      if (!isDeleting) {
        setIsDeleting(true)
        return deleteBSBimModelFile(modelId)
          .then(() => removeBimModelFromList(modelId))
          .then(() => openSuccessSnackbar("La maquette a bien été supprimé "))
          .finally(() => {
            setIsDeleting(false)
          })
      }
      return Promise.resolve()
    },
    [deleteBSBimModelFile, isDeleting, openSuccessSnackbar, removeBimModelFromList]
  )

  const affectBSBimModel: (
    bsVariantId: string,
    newBSBimModelId: string,
    codesExtraits: BSCodeExtraitCreation[]
  ) => Promise<any> = useCallback(
    (bsVariantId: string, newBSBimModelId: string, codeExtraitCreationList: BSCodeExtraitCreation[]) => {
      setIsBSBimModelSubmitting(true)
      return affectBSBimModelToVariant(bsVariantId, newBSBimModelId, codeExtraitCreationList)
        .then((newBSBimModel: BSBimModel) => {
          setStateBSBimModel(newBSBimModel)
          if (newBSBimModel.id) {
            updateBSInputModelId(newBSBimModel.id)
          }
          updateCalculStatusState(CalculStatusEnum.WAITING_FOR_CONFIGURATION, bsVariantId)
          // Here, we also need to update the current selected model file. But it's already handle by a useEffect in BSBimModelFileContext
        })
        .then(() => {
          // refresh BimModelList is asynchroneous. We don't wait for the response
          refreshBimModelListByProjectId()
          return refreshBSInput()
        })
        .finally(() => {
          setIsBSBimModelSubmitting(false)
        })
    },
    [
      affectBSBimModelToVariant,
      updateBSInputModelId,
      setStateBSBimModel,
      updateCalculStatusState,
      refreshBimModelListByProjectId,
      refreshBSInput,
    ]
  )

  const updateBSBimModel: (bsModelUpdateDto: BSBimModelUpdate) => Promise<BSBimModel> = useCallback(
    async (bsModelUpdateDto) => {
      const response: BSBimModel = await putBSBimModel(bsModelUpdateDto)
      setStateBSBimModel(response)
      addOrReplaceBimModelInList(response)
      if (bsProject?.id) {
        await fetchAllBSVariants(bsProject.id)
      }
      openSuccessSnackbar("Les informations ont été mises à jour avec succès")
      return response
    },
    [addOrReplaceBimModelInList, bsProject, fetchAllBSVariants, openSuccessSnackbar, putBSBimModel, setStateBSBimModel]
  )

  const bsModelStore: BSModelStore = useMemo(
    () => ({
      bsBimModel,
      bsBimModelIdRef,
      isBSBimModelLoading,
      isBSBimModelSubmitting,
      bsBimModelListByProject,
      handleDeleteBSBimModel,
      setBSBimModelListByProject,
      affectBSBimModel,
      updateBSBimModel,
      setStateBSBimModel,
    }),
    [
      isBSBimModelLoading,
      bsBimModel,
      isBSBimModelSubmitting,
      bsBimModelListByProject,
      handleDeleteBSBimModel,
      affectBSBimModel,
      updateBSBimModel,
      setStateBSBimModel,
    ]
  )
  return <BSBimModelContext.Provider value={bsModelStore}>{children}</BSBimModelContext.Provider>
}

export interface BSModelStore {
  bsBimModel: BSBimModel | undefined
  bsBimModelIdRef: React.MutableRefObject<string | undefined>
  isBSBimModelLoading: boolean
  isBSBimModelSubmitting: boolean

  handleDeleteBSBimModel(modelId: string): Promise<void>
  affectBSBimModel(bsVariantId: string, newBSBimModelId: string, codesExtraits: BSCodeExtraitCreation[]): Promise<any>
  updateBSBimModel(bsModelUpdateDto: BSBimModelUpdate, projectId: string): Promise<BSBimModel>
  setStateBSBimModel(newBSBimModel: BSBimModel | undefined): void
}
