import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { Children } from "../../../../components/miscellianous/children"
import { ProgressBarContext } from "../../../../pages/beem-pilot/RseeProjectDetailPage/RseeDocumentDashboardPage/context/ProgressBarContext"
import { RseeDocument } from "../../../dto/rsee/rsee-document"
import { RseeImportLog } from "../../../dto/rsee/rsee-import-log"
import { RseeImportSummary } from "../../../dto/rsee/rsee-import-summary"
import { RseeProject } from "../../../dto/rsee/rsee-project"
import { ErrorLevelEnum } from "../../../enum/rsee/errorLevelEnum"
import { ImportStateEnum } from "../../../enum/rsee/importStateEnum"
import { RseeCategoryErrorEnum } from "../../../enum/rsee/rseeCategoryErrorEnum"
import { RseeSummaryTypeEnum } from "../../../enum/rsee/rseeSummaryTypeEnum"
import { useRsee } from "../../../hooks/rsee/use-rsee"

export const RseeDocumentContext = React.createContext<RseeDocumentStore>({} as RseeDocumentStore)

export default function RseeDocumentContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const { rseeProjectId, rseeDocumentId } = useParams()
  const {
    fetchRseeDocument,
    fetchDashboardUrl,
    listenIsDocumentReady,
    fetchRseeImportLogList,
    updateRseeDocument,
    updateRseeFileName,
  } = useRsee()
  const { resetProgressBar } = useContext(ProgressBarContext)

  const [summaryImport, setSummaryImport] = useState<RseeImportSummary>(new RseeImportSummary())
  const [isSummaryLoaded, setIsSummaryLoaded] = useState<boolean>(false)
  const [importState, setImportState] = useState<ImportStateEnum>(ImportStateEnum.UNKNOWN)
  const [dataCompState, setDataCompState] = useState<ImportStateEnum>(ImportStateEnum.UNKNOWN)
  const [rsetState, setRsetState] = useState<ImportStateEnum>(ImportStateEnum.UNKNOWN)
  const [rsenvState, setRsenvState] = useState<ImportStateEnum>(ImportStateEnum.UNKNOWN)
  const [fromPage, setFromPage] = useState<RseeSummaryTypeEnum>(RseeSummaryTypeEnum.RSEE_DASHBOARD)

  const [rseeDocument, setRseeDocument] = useState<RseeDocument | undefined>()
  const [isRseeDocumentLoading, setIsRseeDocumentLoading] = useState<boolean>(false)
  const [dashboardUrl, setDashboardUrl] = useState<string>("")
  const [isDataReady, setIsDataReady] = useState<boolean>(false)
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null)

  const refreshUrl: () => void = useCallback(() => {
    if (rseeDocument?.id) {
      fetchDashboardUrl(rseeDocument.versionTtb).then((newDashboardUrl) => {
        setDashboardUrl(newDashboardUrl)
      })
    }
  }, [fetchDashboardUrl, rseeDocument])

  const refreshRseeDocument: () => Promise<void> = useCallback(() => {
    if (rseeDocumentId && rseeDocumentId !== "new") {
      setIsRseeDocumentLoading(true)
      return fetchRseeDocument(rseeDocumentId)
        .then((newRseeDocument) => {
          setRseeDocument(newRseeDocument)
        })
        .finally(() => {
          setIsRseeDocumentLoading(false)
        })
    } else {
      return Promise.resolve()
    }
  }, [rseeDocumentId, fetchRseeDocument])

  const getRseeSummaryList: () => void = useCallback(() => {
    if (rseeProjectId && rseeDocumentId) {
      fetchRseeImportLogList(rseeProjectId, rseeDocumentId).then((newImportList: RseeImportLog[]) => {
        const summary = new RseeImportSummary()
        newImportList.forEach((importLog) => {
          if (importLog.rseeCategoryErrorEnum === RseeCategoryErrorEnum.DATAS_COMP) {
            summary.datacomp.push(importLog)
          } else if (importLog.rseeCategoryErrorEnum === RseeCategoryErrorEnum.RSET) {
            summary.rset.push(importLog)
          } else if (importLog.rseeCategoryErrorEnum === RseeCategoryErrorEnum.RSENV) {
            summary.rsenv.push(importLog)
          } else {
            summary.unknown.push(importLog)
          }
        })
        setSummaryImport(summary)
        setIsSummaryLoaded(true)
      })
    }
  }, [rseeProjectId, rseeDocumentId, fetchRseeImportLogList])

  useEffect(() => {
    if (isSummaryLoaded) {
      let newImportErrorLevel: ImportStateEnum = ImportStateEnum.SUCCESS
      let newDataCompErrorLevel: ImportStateEnum = ImportStateEnum.SUCCESS
      let newRsetErrorLevel: ImportStateEnum = ImportStateEnum.SUCCESS
      let newRsenvErrorLevel: ImportStateEnum = ImportStateEnum.SUCCESS

      newImportErrorLevel = updateErrorLevel(summaryImport.datacomp, newImportErrorLevel)
      newImportErrorLevel = updateErrorLevel(summaryImport.rset, newImportErrorLevel)
      newImportErrorLevel = updateErrorLevel(summaryImport.rsenv, newImportErrorLevel)

      newDataCompErrorLevel = updateErrorLevel(summaryImport.datacomp, newDataCompErrorLevel)
      newRsetErrorLevel = updateErrorLevel(summaryImport.rset, newRsetErrorLevel)
      newRsenvErrorLevel = updateErrorLevel(summaryImport.rsenv, newRsenvErrorLevel)

      setImportState(newImportErrorLevel)
      setDataCompState(newDataCompErrorLevel)
      setRsetState(newRsetErrorLevel)
      setRsenvState(newRsenvErrorLevel)
    }
  }, [summaryImport, isSummaryLoaded])

  function updateErrorLevel(importLogList: RseeImportLog[], currentImportState: ImportStateEnum): ImportStateEnum {
    let state: ImportStateEnum = currentImportState
    importLogList.forEach((logItem) => {
      if (logItem.errorLevelEnum === ErrorLevelEnum.WARNING && state === ImportStateEnum.SUCCESS) {
        state = ImportStateEnum.WARNING
      } else if (logItem.errorLevelEnum === ErrorLevelEnum.CRITICAL) {
        state = ImportStateEnum.CRITICAL
      }
    })
    return state
  }

  useEffect(() => {
    try {
      refreshRseeDocument()
    } catch (e) {
      console.error("catch", e)
      throw e
    }
  }, [refreshRseeDocument])

  useEffect(() => {
    refreshUrl()
  }, [refreshUrl])

  const checkIfDocumentReady = useCallback((): void => {
    console.info("timer tick", rseeDocument?.id, intervalIdRef.current)
    if (rseeDocument?.id) {
      listenIsDocumentReady(rseeDocument?.id)
        .then((isReady: boolean) => {
          if (isReady) {
            clearTimer()
            setIsDataReady(isReady)
          }
        })
        .catch((e) => {
          console.error("catch erreur:", e)
          setIsDataReady(true)
          clearTimer()
        })
    }
  }, [listenIsDocumentReady, rseeDocument?.id])

  useEffect(() => {
    if (rseeDocument?.id) {
      listenIsDocumentReady(rseeDocument?.id)
        .then((isReady: boolean) => {
          setIsDataReady(isReady)
          if (!isReady && !intervalIdRef.current) {
            intervalIdRef.current = setInterval(checkIfDocumentReady, 5000)
            console.info("set timer", intervalIdRef.current) // Keep log until we are sure that the feature is 100% stable
          }
        })
        .catch((e) => {
          console.error("catch erreur:", e)
          setIsDataReady(true)
          clearTimer()
        })
    }

    return () => clearTimer()
  }, [listenIsDocumentReady, rseeDocument])

  function clearTimer(): void {
    console.info("clear timer", intervalIdRef.current)
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
      intervalIdRef.current = null
    }
  }

  const updateDocument = useCallback(
    (file: File) => {
      if (file && rseeDocumentId) {
        return updateRseeDocument(file, rseeDocumentId).then((updatedRseeProject: RseeProject) => {
          if (updatedRseeProject?.id) {
            resetProgressBar(rseeDocumentId)
            return refreshRseeDocument()
          } else {
            return Promise.resolve()
          }
        })
      } else {
        return Promise.resolve()
      }
    },
    [refreshRseeDocument, rseeDocumentId, updateRseeDocument, resetProgressBar]
  )

  const updateFileName = useCallback(
    (fileName: string) => {
      if (rseeDocument?.id) {
        updateRseeFileName(rseeDocument?.id, fileName).then(() => refreshRseeDocument())
      }
    },
    [rseeDocument?.id, updateRseeFileName, refreshRseeDocument]
  )

  const rseeDocumentHook: RseeDocumentStore = useMemo(
    (): RseeDocumentStore => ({
      rseeDocument,
      isRseeDocumentLoading,
      dashboardUrl,
      isDataReady,
      refreshUrl,
      refreshRseeDocument,
      getRseeSummaryList,
      summaryImport,
      setSummaryImport,
      isSummaryLoaded,
      setIsSummaryLoaded,
      importState,
      dataCompState,
      rsetState,
      rsenvState,
      updateDocument,
      updateFileName,
    }),
    [
      rseeDocument,
      isRseeDocumentLoading,
      dashboardUrl,
      isDataReady,
      refreshUrl,
      refreshRseeDocument,
      getRseeSummaryList,
      summaryImport,
      isSummaryLoaded,
      importState,
      dataCompState,
      rsetState,
      rsenvState,
      updateDocument,
      updateFileName,
    ]
  )

  return <RseeDocumentContext.Provider value={rseeDocumentHook}>{children}</RseeDocumentContext.Provider>
}

export type RseeDocumentStore = {
  rseeDocument?: RseeDocument
  isRseeDocumentLoading: boolean
  dashboardUrl: string
  isDataReady: boolean
  refreshUrl(): void
  refreshRseeDocument(): Promise<void>
  getRseeSummaryList(): void
  summaryImport: RseeImportSummary
  setSummaryImport: React.Dispatch<React.SetStateAction<RseeImportSummary>>
  isSummaryLoaded: boolean
  setIsSummaryLoaded: React.Dispatch<React.SetStateAction<boolean>>
  importState: ImportStateEnum
  dataCompState: ImportStateEnum
  rsetState: ImportStateEnum
  rsenvState: ImportStateEnum
  updateDocument(file: File): Promise<void>
  updateFileName(fileName: string): void
}
