import { Box, Paper, Typography } from "@mui/material"
import React from "react"
import { displayDecimal } from "../../../core/services/helper-service"

interface LabelValue {
  label: string
  value: number
  unit: React.ReactNode
  isCompliant: boolean
  percentGap: number
}

export interface IProps {
  labelValueList: LabelValue[]
}

export function BarChartTooltip({ labelValueList }: Readonly<IProps>): React.JSX.Element {
  return (
    <Paper>
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2 }}>
        {labelValueList.map((labelValue, i) => (
          <Typography key={labelValue.label}>
            {labelValue.label} <b>{displayDecimal(labelValue.value, 1)}</b> {labelValue.unit}{" "}
            {labelValue.isCompliant ? "Conforme" : "Non Conforme"} {displayDecimal(labelValue.percentGap, 1)}%
          </Typography>
        ))}
      </Box>
    </Paper>
  )
}
