import { Box, Grid } from "@mui/material"
import React from "react"
import { DefaultTypography } from "../../../../../components/typography/default-typography"
import { TextInputTypography } from "../../../../../components/typography/textInputTypography"
import { BSBimModel } from "../../../../../core/dto/beem-shot/BSBimModel/BSBimModel"

interface IProps {
  selectedBsBimModel: BSBimModel | undefined
  verticalAlignment?: boolean
}

export function BSBimModelInfo({ selectedBsBimModel, verticalAlignment = false }: Readonly<IProps>): React.JSX.Element {
  return (
    <Grid container spacing={1} sx={{ backgroundColor: "white" }} justifyContent="strech">
      <Grid item xs={verticalAlignment ? 12 : 6}>
        <Box sx={{ border: 2, borderColor: "#F5F8F8", borderRadius: 2, p: 2, backgroundColor: "#F5F5F5", height: "100%" }}>
          <TextInputTypography label="Version" />
          <DefaultTypography label={selectedBsBimModel?.version ?? ""} fontSize="14px" />
        </Box>
      </Grid>

      <Grid item xs={verticalAlignment ? 12 : 6}>
        <Box sx={{ border: 2, borderColor: "#F5F8F8", borderRadius: 2, p: 2, backgroundColor: "#F5F5F5", height: "100%" }}>
          <TextInputTypography label="Surface utile ou SHAB (m²)" />
          <DefaultTypography label={selectedBsBimModel?.surfaceRef?.toString() ?? "0"} fontSize="14px" />
        </Box>
      </Grid>

      <Grid item xs={verticalAlignment ? 12 : 6}>
        <Box sx={{ border: 2, borderColor: "#F5F8F8", borderRadius: 2, p: 2, backgroundColor: "#F5F5F5", height: "100%" }}>
          <TextInputTypography label="Surface plancher" />
          <DefaultTypography label={selectedBsBimModel?.surfacePlancher?.toString() ?? ""} fontSize="14px" />
        </Box>
      </Grid>

      <Grid item xs={verticalAlignment ? 12 : 6}>
        <Box sx={{ border: 2, borderColor: "#F5F8F8", borderRadius: 2, p: 2, backgroundColor: "#F5F5F5", height: "100%" }}>
          <TextInputTypography label="Emprise au sol" />
          <DefaultTypography label={selectedBsBimModel?.empriseAuSol?.toFixed(1) ?? "0"} fontSize="14px" />
        </Box>
      </Grid>

      {selectedBsBimModel?.surfaceCombleB && (
        <Grid item xs={verticalAlignment ? 12 : 6}>
          <Box sx={{ border: 2, borderColor: "#F5F8F8", borderRadius: 2, p: 2, backgroundColor: "#F5F5F5", height: "100%" }}>
            <TextInputTypography label="Surface des combles aménagés" />
            <DefaultTypography label={selectedBsBimModel?.surfaceComble?.toString() ?? ""} fontSize="14px" />
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
