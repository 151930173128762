import { useMemo } from "react"
import { useHttp } from "../use-http"
import { appConstants } from "../../appConstants"
import { BSProjectResult } from "../../dto/beem-shot/BSProjectResult/BSProjectResult"
import { BSMaterialResult } from "../../dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { BSVariantResult, bsVariantResultFromDto } from "../../dto/beem-shot/BSVariantResult/BSVariantResult"
import { BiosourceeLevel } from "../../dto/BiosourceLevel/BiosourceLevel"

interface BSDashboardHook {
  getBSProjectResult(bsProjectId: string): Promise<BSProjectResult>
  getBSVariantResult(bsVariantId: string): Promise<BSVariantResult>
  getBSMaterialResultList(bsVariantId: string): Promise<BSMaterialResult[]>
  fetchBiosourceThresholds(usage: string): Promise<any>
}

export function useBSDashboard(): BSDashboardHook {
  const { get } = useHttp()

  return useMemo(
    () => ({
      getBSProjectResult(bsProjectId: string): Promise<BSProjectResult> {
        return get(appConstants.apiEndpoints.BEEM_SHOT_DASHBOARD_PROJECT_RESULT, [
          { key: "bsProjectId", value: bsProjectId },
        ])
          .then((response) => response.json())
          .then((data) => new BSProjectResult(data))
      },
      getBSVariantResult(bsVariantId: string): Promise<BSVariantResult> {
        return get(appConstants.apiEndpoints.BEEM_SHOT_DASHBOARD_VARIANT_RESULT, [
          { key: "bsVariantId", value: bsVariantId },
        ])
          .then((response) => response.json())
          .then((data) => bsVariantResultFromDto(data))
      },
      getBSMaterialResultList(bsVariantId: string): Promise<BSMaterialResult[]> {
        return get(appConstants.apiEndpoints.BEEM_SHOT_DASHBOARD_MATERIAL_RESULT_LIST, [
          { key: "bsVariantId", value: bsVariantId },
        ]).then((response) => response.json())
      },
      fetchBiosourceThresholds(usage: string): Promise<BiosourceeLevel> {
        return get(appConstants.apiEndpoints.BEEM_SHOT_DASHBOARD_BIOSOURCE_THRESHOLDS, [
          { key: "usage", value: usage },
        ]).then((response) => response.json())
      },
    }),
    [get]
  )
}
