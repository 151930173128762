import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined"
import { Box, Grid, IconButton } from "@mui/material"
import React, { useContext, useMemo } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { pagesUrl } from "../../../core/appConstants"
import { BSProjectContext } from "../../../core/context/beem-shot/BSProject/BSProjectContext"
import { BSBimModel } from "../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { formatToFrench } from "../../../core/services/date-service"
import { resolveUrl } from "../../../core/services/http-service"
import { DefaultTypography } from "../../typography/default-typography"
import { BSBimModelInfo } from "../../../pages/beem-shot/BSBimModelDetailPage/components/BSBimModelInfo"
import { BSDeleteBimModelButton } from "../../../pages/beem-shot/components/BSDeleteBimModelButton/BSDeleteBimModelButton"

type IProps = {
  bsBimModel: BSBimModel
}

export function BSBimModelCard({ bsBimModel }: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()

  const { bsProject } = useContext(BSProjectContext)

  const navigateUrl = useMemo(
    () => resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_DETAIL_PAGE, [bsProject?.id, bsBimModel.id]),
    [bsBimModel.id, bsProject?.id]
  )

  return (
    <Link to={navigateUrl} style={{ textDecoration: "none", color: "inherit" }}>
      <Grid
        container
        rowGap={1}
        width="100%"
        alignItems="center"
        sx={{
          backgroundColor: "#fff",
          height: "100%",
          borderRadius: 3,
          p: 3,
          boxShadow: "2px 4px 20px 0px rgba(0, 0, 0, 0.05)",
          transition: "all 0.2s ease-in-out",
          ":hover": {
            cursor: "pointer",
            boxShadow: 10,
            transform: "translateY(-5px)",
          },
        }}>
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item xs={2}>
            <Box
              border={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ borderRadius: 2, background: "#CEDEEF", p: 1, px: 3, border: 3, borderColor: "white" }}>
              <HomeWorkOutlinedIcon fontSize="medium" sx={{ color: "#4965AC", fontWeight: "2px" }} />
            </Box>
          </Grid>
          <Grid item xs={3} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <IconButton
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                navigate(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_IMPORT_PAGE, [bsProject?.id, bsBimModel?.id]), {
                  state: { from: location.pathname },
                })
              }}>
              <CreateOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                navigate(resolveUrl(pagesUrl.BEEM_SHOT_BIM_MODEL_DETAIL_PAGE, [bsProject?.id, bsBimModel?.id]))
              }}>
              <FormatListBulletedIcon />
            </IconButton>
            <BSDeleteBimModelButton bsBimModelToDelete={bsBimModel} />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="left" alignItems="center" width="100%">
          <DefaultTypography
            wordWrap="break-word"
            label={bsBimModel.fileName}
            fontSize="16px"
            lineHeight="24px"
            fontWeight={600}
          />
        </Box>

        <BSBimModelInfo bsBimModel={bsBimModel} />

        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
          <DefaultTypography
            label={`Mise à jour le ${formatToFrench(bsBimModel.lastModifiedDate)}`}
            fontSize="12px"
            lineHeight="18px"
            fontWeight={400}
            color="#8398A6"
          />

          <DefaultTypography
            label={`Utilisé par ${bsBimModel.variantCount} variantes`}
            fontSize="12px"
            lineHeight="18px"
            fontWeight={400}
            color="#8398A6"
          />
        </Box>
      </Grid>
    </Link>
  )
}
