import { Box, Grid, Grow, Typography } from "@mui/material"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { resolveUrl } from "../../../core/services/http-service"

interface IProps {
  icon: React.JSX.Element | undefined
  number: number
  moduleName: string
  backgroundColor: string
  link: string
  textHover: React.ReactElement
}

export function HomeCardIndicator({
  icon,
  number,
  moduleName,
  backgroundColor,
  link,
  textHover,
}: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()
  const [hover, setHover] = useState(false)

  return (
    <Grid
      container
      onClick={() => navigate(resolveUrl(link, [], {}))}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      px={2}
      sx={{
        borderRadius: 2,

        backgroundColor,
        boxShadow: "2px 4px 20px 0px rgba(0, 0, 0, 0.05)",
        transition: "all 0.2s ease-in-out",
        ":hover": {
          cursor: "pointer",
          boxShadow: 10,
          transform: "translateY(-5px)",
        },
      }}>
      <Grid item xs={6}>
        <Box borderRadius={2} p={2}>
          <Box>
            {icon}
            <Box display="flex" alignItems="center" gap={1} pl={6}>
              <Typography variant="h3">{number}</Typography>
              <Typography variant="body2"> projets </Typography>
            </Box>

            <Typography variant="subtitle1" pl={6}>
              {moduleName}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={6}>
        {hover && (
          <Grow in style={{ transitionDelay: `${50}ms` }}>
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              {textHover}
            </Box>
          </Grow>
        )}
      </Grid>
    </Grid>
  )
}
