import { Box } from "@mui/material"
import React, { useContext, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { BSInputContext } from "../../../../core/context/beem-shot/BSInput/BSInputContext"
import { BSBimModel } from "../../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { BSBimModelHeader } from "./components/BSBimModelSelectHeader"
import { BSBimModelSelectList } from "./components/BSBimModelSelectList"
import { BSBimModelListContext } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelContextList"
import { customPalette } from "../../../../../theme"

interface IProps {
  selectedBSBimModel: BSBimModel | undefined
  setSelectedBSBimModel(bsBimModel: BSBimModel | undefined): void
}

export function BSBimModelSelect({ selectedBSBimModel, setSelectedBSBimModel }: Readonly<IProps>): React.JSX.Element {
  const location = useLocation()

  const { bsBimModelListByProject } = useContext(BSBimModelListContext)
  const { bsInput } = useContext(BSInputContext)

  useEffect(() => {
    const state = location?.state?.addedBimModelId
    if (state) {
      const addedModel = bsBimModelListByProject.find((bimModel) => bimModel.id === location?.state?.addedBimModelId)
      setSelectedBSBimModel(addedModel)
    } else {
      const affectedBsBimModel = bsBimModelListByProject.find((bimModel) => bimModel.id === bsInput?.bsBimModelId)
      setSelectedBSBimModel(affectedBsBimModel)
    }
  }, [bsBimModelListByProject, bsInput?.bsBimModelId, location?.state?.addedBimModelId, setSelectedBSBimModel])

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexShrink={1}
      border={2}
      borderRadius={3}
      justifyContent="flex-start"
      flexDirection="column"
      alignContent="flex-start"
      alignItems="center"
      borderColor={customPalette.paperBorder}
      my={3}
      sx={{
        backgroundColor: customPalette.paperBackground,
        height: "70vh",
        overflow: "auto",
        p: 3,
        pt: 0,
        cursor: "default",
      }}>
      <BSBimModelHeader
        title="Choisir la maquette"
        subTitle="Veuillez choisir une maquette dans la liste pour poursuivre le calcul. Vous pourrez ensuite vérifier et ajuster ces
          informations si nécessaire afin de calculer l'impact carbone avec précision."
      />
      <BSBimModelSelectList selectedBSBimModel={selectedBSBimModel} setSelectedBSBimModel={setSelectedBSBimModel} />
    </Box>
  )
}
