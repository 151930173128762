import { Box } from "@mui/material"
import { BulletRectsItemProps, BulletTooltipProps, ResponsiveBullet } from "@nivo/bullet"
import React, { useCallback, useMemo } from "react"
import { BarChartTooltip } from "./BarChartTooltip"
import { ThresholdData } from "./ThresholdData"
import { calculatePercent } from "../ChartUtils"
import { RangeComponent } from "./RangeComponent"

interface IProps {
  biosource: number
  thresholdLvl1: number
  thresholdLvl2: number
  thresholdLvl3: number
}

export function BiosourceThresholdChart({
  biosource,
  thresholdLvl1,
  thresholdLvl2,
  thresholdLvl3,
}: Readonly<IProps>): React.JSX.Element {
  const percentLvl1: number = useMemo(() => calculatePercent(biosource, thresholdLvl1) - 100, [biosource, thresholdLvl1])
  const percentLvl2: number = useMemo(() => calculatePercent(biosource, thresholdLvl2) - 100, [biosource, thresholdLvl2])
  const percentLvl3: number = useMemo(() => calculatePercent(biosource, thresholdLvl3) - 100, [biosource, thresholdLvl3])

  const data: ThresholdData[] = useMemo(() => {
    if (biosource > thresholdLvl3 * 1.3) {
      return [
        {
          id: "",
          ranges: [thresholdLvl1, thresholdLvl2, thresholdLvl3],
          measures: [biosource],
          markers: [biosource],
        },
      ]
    } else {
      return [
        {
          id: "",
          ranges: [thresholdLvl1, thresholdLvl2, thresholdLvl3, thresholdLvl3 * 1.3],
          measures: [biosource],
          markers: [biosource],
        },
      ]
    }
  }, [biosource, thresholdLvl1, thresholdLvl2, thresholdLvl3])

  const tooltipFct = useCallback(
    (dataPoint: BulletTooltipProps): React.JSX.Element => (
      <BarChartTooltip
        labelValueList={[
          {
            label: "Niveau 1",
            unit: <span>(kgC/m²Sref)</span>,
            value: thresholdLvl1,
            isCompliant: biosource > thresholdLvl1,
            percentGap: percentLvl1,
          },
          {
            label: "Niveau 2",
            unit: <span>(kgC/m²Sref)</span>,
            value: thresholdLvl2,
            isCompliant: biosource > thresholdLvl2,
            percentGap: percentLvl2,
          },
          {
            label: "Niveau 3",
            unit: <span>(kgC/m²Sref)</span>,
            value: thresholdLvl3,
            isCompliant: biosource > thresholdLvl3,
            percentGap: percentLvl3,
          },
        ]}
      />
    ),
    [biosource, percentLvl1, percentLvl2, percentLvl3, thresholdLvl1, thresholdLvl2, thresholdLvl3]
  )

  const rangeComponent = useCallback(
    (props: BulletRectsItemProps) => (
      <RangeComponent {...props} thresholdLabel={["", "Niveau 1 🥉", "Niveau 2🥈", "Niveau 3🥇"]} />
    ),
    []
  )

  return (
    <Box sx={{ height: "200px", mt: 3 }}>
      <ResponsiveBullet
        data={data}
        margin={{ top: 50, right: 90, bottom: 50, left: 90 }}
        spacing={46}
        rangeColors="seq:greens"
        measureColors={["rgba(0, 0, 0, 0.35)"]}
        markerColors={["#000"]}
        tooltip={tooltipFct}
        markerComponent={() => null}
        rangeComponent={rangeComponent}
        animate
      />
    </Box>
  )
}
