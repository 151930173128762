import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { SuccessContext } from "../../../../components/layout/success-snackbar"
import { Children } from "../../../../components/miscellianous/children"
import { SelectionContext } from "../../../../pages/beem-shot/BSVariantDetailsPage/BSCustomizationTab/components/context/SelectionContext"
import { BSCustomMaterialCreationDto } from "../../../dto/beem-shot/BSMaterialResult/BSCustomMaterialCreationDto"
import { BSMaterialResultFormDto } from "../../../dto/beem-shot/BSMaterialResult/BSMaterialResultFormDto"
import { BSMaterialResultUpdateDto } from "../../../dto/beem-shot/BSMaterialResult/BSMaterialResultUpdateDto"
import { useBSMaterialResults } from "../../../hooks/beem-shot/useBSMaterialResults"
import { BSProjectContext } from "../BSProject/BSProjectContext"
import { BSVariantContext } from "../BSVariant/BSVariantContext"
import { BSVariantResultContext } from "../BSVariantResult/BSVariantResultContext"

export const BSMaterialResultContext = React.createContext<BsMaterialResultStore>({} as BsMaterialResultStore)

export function BSMaterialContextProvider({ children }: Readonly<Children>): React.JSX.Element {
  const {
    fetchBSMaterialResult,
    updateBSMaterialQuantities,
    resetBSMaterialQuantities,
    resetAllBSMaterialQuantities,
    addBSOverriddenMaterial,
    putEnable,
    postCustomMaterial,
    deleteCustomMaterial,
  } = useBSMaterialResults()

  const openSuccessSnackbar: (message: string) => void = useContext(SuccessContext)
  const { bsProject } = useContext(BSProjectContext)
  const { selectedVariant } = useContext(BSVariantContext)
  const { refreshVariantResult } = useContext(BSVariantResultContext)
  const { replaceAll, setReplaceAll, replaceAllCoefficient, setReplaceAllCoefficient } = useContext(SelectionContext)

  const [bsMaterialResultFormDto, setBSMaterialResultFormDto] = useState<BSMaterialResultFormDto[]>([])
  const [materialUpdatingDisable, setMaterialUpdatingDisable] = useState<Record<string, boolean>>({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (selectedVariant?.id) {
      fetchBSMaterialResult(selectedVariant?.id).then((newBSMaterialResultFormDtoList) => {
        setBSMaterialResultFormDto(newBSMaterialResultFormDtoList)
      })
    }
  }, [fetchBSMaterialResult, selectedVariant?.id, bsProject])

  const getAllMaterialResult: (variantId: string | undefined) => Promise<void> = useCallback(
    (variantId) => {
      if (variantId) {
        refreshVariantResult()
        return fetchBSMaterialResult(variantId).then((newBSMaterialResultFormDtoList) =>
          setBSMaterialResultFormDto(newBSMaterialResultFormDtoList)
        )
      }
      return Promise.resolve()
    },
    [fetchBSMaterialResult, refreshVariantResult]
  )

  const resetMaterial: (variantId: string, materialResultId: string) => Promise<void> = useCallback(
    (variantId, materialResultId) =>
      resetBSMaterialQuantities(variantId, materialResultId).then(() => {
        if (selectedVariant?.id) {
          getAllMaterialResult(selectedVariant?.id)
        }
      }),
    [getAllMaterialResult, resetBSMaterialQuantities, selectedVariant?.id]
  )

  const resetAllMaterial: (variantId: string) => Promise<void> = useCallback(
    (variantId) =>
      resetAllBSMaterialQuantities(variantId).then(() => {
        if (selectedVariant?.id) {
          getAllMaterialResult(selectedVariant?.id)
        }
      }),
    [getAllMaterialResult, resetAllBSMaterialQuantities, selectedVariant?.id]
  )

  const deleteMaterial: (bsMaterialResult: BSMaterialResultFormDto) => Promise<void> = useCallback(
    (bsMaterialResult) => {
      if (bsMaterialResult.customMaterialId) {
        return deleteCustomMaterial(bsMaterialResult.customMaterialId).then(() => {
          if (selectedVariant?.id) {
            getAllMaterialResult(selectedVariant?.id)
          }
        })
      }
      return Promise.resolve()
    },
    [deleteCustomMaterial, getAllMaterialResult, selectedVariant?.id]
  )

  const updateMaterialQuantities: (variantId: string, materialResultId: string, quantity: string) => Promise<void> =
    useCallback(
      (variantId, materialResultId, quantity) =>
        updateBSMaterialQuantities(variantId, materialResultId, quantity).then(() => {
          if (selectedVariant?.id) {
            getAllMaterialResult(selectedVariant?.id)
          }
        }),
      [getAllMaterialResult, selectedVariant?.id, updateBSMaterialQuantities]
    )

  const updateIniesRecordMaterial: (bsUpdateMaterialResult: BSMaterialResultUpdateDto) => Promise<void> = useCallback(
    (bsUpdateMaterialResult) => {
      setIsSubmitting(true)
      if (selectedVariant?.id) {
        const variantId = selectedVariant.id
        return addBSOverriddenMaterial(variantId, bsUpdateMaterialResult, replaceAll, replaceAllCoefficient)
          .then(() => {
            getAllMaterialResult(variantId)
          })
          .then(() => {
            openSuccessSnackbar("Le matériau a bien été modifié !")
          })
          .finally(() => {
            setIsSubmitting(false)
            setReplaceAll(false)
            setReplaceAllCoefficient(false)
          })
      } else {
        return Promise.resolve()
      }
    },
    [
      selectedVariant?.id,
      addBSOverriddenMaterial,
      replaceAll,
      replaceAllCoefficient,
      getAllMaterialResult,
      openSuccessSnackbar,
      setReplaceAll,
      setReplaceAllCoefficient,
    ]
  )

  const setEnable: (bsMaterialResultId: string, newValue: boolean) => void = useCallback(
    (bsMaterialResultId, newValue) => {
      setMaterialUpdatingDisable((prevState) => ({ ...prevState, [bsMaterialResultId]: true }))
      putEnable(bsMaterialResultId, newValue)
        .then((bsMaterialResult: BSMaterialResultFormDto) => {
          refreshVariantResult()
          setBSMaterialResultFormDto((prevState: BSMaterialResultFormDto[]) =>
            prevState.map((result) => (result.id === bsMaterialResultId ? bsMaterialResult : result))
          )
        })
        .finally(() => {
          setMaterialUpdatingDisable((prevState) => ({ ...prevState, [bsMaterialResultId]: false }))
        })
    },
    [putEnable, refreshVariantResult]
  )

  const addCustomMaterial: (bsCustomMaterialCreation: BSCustomMaterialCreationDto) => Promise<void> = useCallback(
    (bsCustomMaterialCreation) =>
      postCustomMaterial(bsCustomMaterialCreation).then((newBSMaterialResultFormDtoList) => {
        refreshVariantResult()
        setBSMaterialResultFormDto((prevState) => [...prevState, newBSMaterialResultFormDtoList])
      }),
    [postCustomMaterial, refreshVariantResult]
  )

  const bsMaterialResultStore = useMemo(
    () => ({
      isSubmitting,
      bsMaterialResultFormDto,
      updateMaterialQuantities,
      getAllMaterialResult,
      resetMaterial,
      resetAllMaterial,
      setEnable,
      materialUpdatingDisable,
      updateIniesRecordMaterial,
      addCustomMaterial,
      deleteMaterial,
    }),
    [
      isSubmitting,
      bsMaterialResultFormDto,
      updateMaterialQuantities,
      getAllMaterialResult,
      resetMaterial,
      resetAllMaterial,
      setEnable,
      materialUpdatingDisable,
      updateIniesRecordMaterial,
      addCustomMaterial,
      deleteMaterial,
    ]
  )
  return <BSMaterialResultContext.Provider value={bsMaterialResultStore}>{children}</BSMaterialResultContext.Provider>
}

export type BsMaterialResultStore = {
  bsMaterialResultFormDto: BSMaterialResultFormDto[]
  materialUpdatingDisable: Record<string, boolean>
  isSubmitting: boolean
  updateMaterialQuantities(variantId: string, materialResultId: string, quantity: string): Promise<void>
  getAllMaterialResult(variantId: string): Promise<void>
  resetMaterial(variantId: string, materialResultId: string): Promise<void>
  resetAllMaterial(variantId: string): Promise<void>
  updateIniesRecordMaterial(bsUpdateMaterialResult: BSMaterialResultUpdateDto): Promise<void>
  setEnable(bsMaterialResultId: string, newValue: boolean): void
  addCustomMaterial(bsMaterial: BSCustomMaterialCreationDto): Promise<void>
  deleteMaterial(bsMaterialResult: BSMaterialResultFormDto): Promise<void>
}
