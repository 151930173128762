import React from "react"
import { BulletRectsItemProps } from "@nivo/bullet"
import { BulletRectsItem } from "../components/BulletRectsItem"

interface IProps extends BulletRectsItemProps {
  thresholdLabel: string[]
}

export function RangeComponent({
  index,
  animatedProps,
  x,
  y,
  color,
  borderColor,
  borderWidth,
  width,
  height,
  data,
  onMouseMove,
  onClick,
  onMouseEnter,
  onMouseLeave,
  thresholdLabel,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <BulletRectsItem
      index={index}
      animatedProps={animatedProps}
      x={x}
      y={y}
      color={color}
      borderColor={borderColor}
      borderWidth={borderWidth}
      width={width}
      height={height}
      data={data}
      onMouseMove={onMouseMove}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      label={thresholdLabel[index]}
    />
  )
}
