import { Grid } from "@mui/material"
import React from "react"
import { FilterItemSelect } from "./filterSelect/FilterItemSelect"
import { BSMaterialResult } from "../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { FilterCodeOccurrenceSelect } from "./filterSelect/FilterCodeOccurrenceSelect"
import { FilterProductSelect } from "./filterSelect/FilterProductSelect"
import { FilterLotSelect } from "./filterSelect/FilterLotSelect"
import { ResetFilterButton } from "./filterSelect/ResetFilterButton"

interface IProps {
  allBSMaterialResult: BSMaterialResult[]
}

export function Filters({ allBSMaterialResult }: Readonly<IProps>): React.JSX.Element {
  return (
    <>
      <Grid item xs={3}>
        <FilterLotSelect allBSMaterialResult={allBSMaterialResult} />
      </Grid>
      <Grid item xs={3}>
        <FilterItemSelect allBSMaterialResult={allBSMaterialResult} />
      </Grid>
      <Grid item xs={3}>
        <FilterCodeOccurrenceSelect allBSMaterialResult={allBSMaterialResult} />
      </Grid>
      <Grid item xs={3}>
        <FilterProductSelect allBSMaterialResult={allBSMaterialResult} />
      </Grid>
      <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <ResetFilterButton />
      </Grid>
    </>
  )
}
