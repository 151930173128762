import InfoIcon from "@mui/icons-material/Info"
import { Box, Icon, Typography } from "@mui/material"
import React from "react"
import { BSBimModel } from "../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { VariantsOverviewList } from "../../../pages/beem-shot/BSBimModelDetailPage/components/VariantsOverviewList"
import { BaseDialog } from "../../dialog/BaseDialog"

type IProps = {
  bsBimModel: BSBimModel | undefined
  openDeleteBSBimModelWithMoreVariant: boolean
  setOpenDeleteBSBimModelWithMoreVariant: React.Dispatch<React.SetStateAction<boolean>>
  onDelete?(): void
}

export function DeleteBSBimModelWithMoreVariantDialog({
  bsBimModel,
  openDeleteBSBimModelWithMoreVariant,
  setOpenDeleteBSBimModelWithMoreVariant,
  onDelete,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <BaseDialog
      title={`Suppression de la maquette "${bsBimModel?.fileName}"`}
      dialogContent={
        <>
          <Box display="flex" justifyContent="center" gap={1}>
            <Icon>
              <InfoIcon />
            </Icon>
            <Typography variant="body2" fontSize="13px">
              Cette maquette ne peut pas être supprimée car elle est actuellement affectée à une variantes ou plus. Pour
              pouvoir la supprimer, commencez par la dissocier de toutes les variantes concernées.
            </Typography>
          </Box>
          <VariantsOverviewList selectedBSBimModel={bsBimModel} />
        </>
      }
      handleClose={() => setOpenDeleteBSBimModelWithMoreVariant(false)}
      open={openDeleteBSBimModelWithMoreVariant}
    />
  )
}
