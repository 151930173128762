import { createTheme, CssBaseline, ThemeProvider, useTheme } from "@mui/material"
import React, { useContext, useEffect } from "react"
import { useParams } from "react-router-dom"
import { pagesUrl } from "../../../../core/appConstants"
import { AppNavBarContext, NavbarTypeEnum } from "../../../../core/context/nav-bar/AppNavBarContext"
import { useQuery } from "../../../../core/hooks/miscellaneous/use-query"
import { resolveUrl } from "../../../../core/services/http-service"
import { BSCalculationForm } from "./BSCalculationForm/BSCalculationForm"

export function BSCalculationPage(): React.JSX.Element {
  const { setTypeNavBar, setPreviousUrl, setStepHeader } = useContext(AppNavBarContext)
  const { bsProjectId } = useParams()
  const { bsVariantId } = useParams()
  const query = useQuery()

  const theme = useTheme()
  const modifiedTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      background: {
        ...theme.palette.background,
        default: "#ffffff",
        paper: "#ffffff",
      },
    },
  })

  useEffect(() => {
    setTypeNavBar(NavbarTypeEnum.HEAD_WITHOUT_STEP)
    setPreviousUrl(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [bsProjectId, bsVariantId]))
    setStepHeader(0)
  }, [bsProjectId, bsVariantId, query, setPreviousUrl, setStepHeader, setTypeNavBar])

  return (
    <ThemeProvider theme={modifiedTheme}>
      <CssBaseline />
      <BSCalculationForm />
    </ThemeProvider>
  )
}
