import { Box } from "@mui/material"
import { ResponsiveBar } from "@nivo/bar"
import React, { useMemo } from "react"
import { BSMaterialResult } from "../../../../../core/dto/beem-shot/BSMaterialResult/BSMaterialResult"
import { NumericKeys } from "../../../../../core/services/helper-service"
import { lotToColor } from "../../../ChartUtils"
import { lightenColor } from "../../../../../core/services/color-service"

interface IProps {
  maxValue: number
  fieldName: NumericKeys<BSMaterialResult>
  bsMaterialResult: BSMaterialResult
  isSelected: boolean
}

export function RowBarChart({ bsMaterialResult, fieldName, maxValue, isSelected }: Readonly<IProps>): React.JSX.Element {
  const color: string = useMemo(
    () => (isSelected ? lotToColor(bsMaterialResult.lot) : lightenColor(lotToColor(bsMaterialResult.lot), 0.2)),
    [bsMaterialResult.lot, isSelected]
  )

  return (
    <Box sx={{ height: "30px", width: "100px" }}>
      {/* Hide the Responsive bar for positive value close to 0, it's an optimisation because rendering 100 charts is slow
      and when the value is close to 0, the chart is almost invisible anyway */}
      {(bsMaterialResult[fieldName] >= 1 || bsMaterialResult[fieldName] <= 1) && (
        <ResponsiveBar
          data={[{ materialId: "", value: bsMaterialResult[fieldName] }]}
          keys={["value"]}
          indexBy=""
          margin={{}}
          padding={0}
          layout="horizontal"
          valueScale={{ type: "linear" }}
          colors={color}
          maxValue={maxValue}
          enableLabel={false}
          enableGridY={false}
          legends={[]}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={null}
          animate={false}
          isInteractive={false}
        />
      )}
    </Box>
  )
}
