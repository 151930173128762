import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"
import { ChangeEvent } from "react"
import { SelectOption } from "../../components/inputs/select-input/BaseSelectInput"
import { appConstants } from "../appConstants"

function stringIsNumber(value: any): boolean {
  return Number.isNaN(Number(value))
}

export function stringToNumber(value: string | undefined): number {
  if (!value) {
    return 0
  } else {
    const number = parseFloat(value)
    return isNumber(number) ? number : 0
  }
}

export function isFloat(value: string): boolean {
  const number = parseFloat(value)
  return !(Number.isNaN(number) || !appConstants.regex.number.test(value))
}

export function displayNumber(number: number | undefined): string {
  if (!number || !isNumber(number)) {
    return "0"
  } else {
    return number.toFixed(0)
  }
}

export function isNumber(value: number | undefined): boolean {
  return typeof value === "number" && Number.isFinite(value)
}

export function displayDecimal(number: number | undefined, decimalNumber = 2): string {
  if (!number || Number.isNaN(number) || !isNumber(number)) {
    return "0"
  } else {
    return number.toFixed(decimalNumber)
  }
}

const collator = new Intl.Collator("fr", {
  usage: "search",
  sensitivity: "base",
  ignorePunctuation: true,
})

// Turn enum into array
export function enumToArray(enumme: any): any[] {
  // It is for an enum containing numerical values
  return Object.keys(enumme)
    .filter(stringIsNumber)
    .map((key) => enumme[key])
}

export function stringEnumToArray<T extends Record<string, string>>(enumType: T): string[] {
  const list: string[] = []
  for (const key in enumType) {
    if (Object.hasOwn(enumType, key)) {
      list.push(enumType[key])
    }
  }
  return list
}

export function enumToSelectOptions<T extends Record<string, any>>(
  enumObj: T,
  labelMap: { [key in keyof T]: string }
): SelectOption<T[keyof T]>[] {
  return Object.entries(enumObj).map(([key, value]) => ({
    label: labelMap[key],
    value: value as T[keyof T],
  }))
}

export function enumArrayToSelectOptions<T extends string>(enumObj: T[], labelMap: Record<T, string>): SelectOption<T>[] {
  return enumObj.map((enumValue: T) => ({
    value: enumValue,
    label: labelMap[enumValue],
  }))
}

export function equalIgnoreCase(a: string, b: string): boolean {
  return collator.compare(a, b) === 0
}

export function compareIgnoreCase(a: string, b: string): number {
  return collator.compare(a, b)
}

export function removeElementAtIndex<Type>(array: Type[], index: number): Array<Type> {
  const newArray = [...array]
  newArray.splice(index, 1)
  return newArray
}

export function toMap<T>(list: T[], serialize: (item: T) => string): Map<string, T> {
  const hashMap = new Map<string, T>()

  list.forEach((item: T) => {
    hashMap.set(serialize(item), item)
  })

  return hashMap
}

export function licenseNumberToString(formValue: number | undefined): string {
  if (formValue === undefined) {
    return "0"
  } else if (formValue === -1) {
    return appConstants.miscellaneous.LABEL_UNLIMITED
  } else {
    return String(formValue)
  }
}

export function licenseStringValueToNumber(value: string | undefined, defaultValue = 0): number {
  let result: number

  if (value === undefined || value === "") {
    result = defaultValue
  } else if (value === appConstants.miscellaneous.LABEL_UNLIMITED || value === "-1" || value === "i" || value === "I") {
    result = -1
  } else if (value === "-") {
    result = Number.NaN
  } else {
    const number = parseInt(value, 10)
    if (number < 0) {
      result = Number.NaN
    } else if (!isNumber(number)) {
      result = Number.NaN
    } else {
      result = number
    }
  }

  return result
}

export function getFullNameLabel(givenName: string | undefined, familyName: string | undefined): string {
  return `${familyName ?? ""} ${givenName ?? ""}`
}

export function identity<T>(value: T): T {
  return value
}

export function eventFromValue(id: string, value: any): ChangeEvent<HTMLInputElement> {
  return {
    target: {
      id,
      value,
    },
  } as ChangeEvent<HTMLInputElement>
}

export function trim(msg: string | undefined): string | undefined {
  return msg ? msg.trim() : undefined
}

export async function downloadPDF(htmlElement: HTMLElement, pdfName: string): Promise<any> {
  const mainCanvas: HTMLCanvasElement = await html2canvas(htmlElement, {
    scale: 2, // Meilleure qualité
    useCORS: true, // Set to true if you need to avoid CORS on external pictures
    logging: false, // Disable logging
    backgroundColor: "#ffffff",
  })
  printImageToPdf(mainCanvas, pdfName)
}

export function printImageToPdf(mainCanvas: HTMLCanvasElement, pdfName: string): void {
  const canvasWidth = mainCanvas.width
  const totalDashboardHeightPx = mainCanvas.height

  // eslint-disable-next-line new-cap
  const pdf = new jsPDF("portrait", "px", "a4")
  const pdfWidth = pdf.internal.pageSize.getWidth()
  const pdfHeight = pdf.internal.pageSize.getHeight()

  const imgWidth = pdfWidth - 20 // Marges de 10mm de chaque côté
  const ratioX = imgWidth / canvasWidth
  const imgHeight = totalDashboardHeightPx * ratioX // Hauteur de l'image dans le pdf, maintenir le ratio hauteur/largeur
  const pageNumber = Math.ceil(imgHeight / pdfHeight)

  const chunkHeight = totalDashboardHeightPx / pageNumber
  const pdfChunkHeight = chunkHeight * ratioX

  for (let pageIndex = 0; pageIndex < pageNumber; pageIndex += 1) {
    const chunkCanvas: HTMLCanvasElement = document.createElement("canvas")
    const chunkContext = chunkCanvas.getContext("2d")
    if (!chunkContext) {
      console.error("Impossible de générer un canvas 2D")
      return
    }

    chunkCanvas.width = canvasWidth
    const remainingHeight = totalDashboardHeightPx - pageIndex * chunkHeight
    chunkCanvas.height = Math.min(chunkHeight, remainingHeight)

    chunkContext.drawImage(
      mainCanvas,
      0,
      pageIndex * chunkHeight,
      canvasWidth,
      chunkCanvas.height,
      0,
      0,
      canvasWidth,
      chunkCanvas.height
    )

    const chunkDataUrl = chunkCanvas.toDataURL("image/jpeg", 0.9)

    pdf.addImage(chunkDataUrl, "JPG", 0, 0, imgWidth, pdfChunkHeight)

    if (pageIndex < pageNumber - 1) {
      pdf.addPage()
    }
  }

  pdf.save(pdfName) // Télécharger le fichier
}

export function screenshotToPDF(pdfName: string): void {
  navigator.mediaDevices.getDisplayMedia({ video: true }).then((stream) => {
    const video = document.createElement("video")

    if (video) {
      video.srcObject = stream
      video.onloadedmetadata = async () => {
        video.play()
        const canvas: HTMLCanvasElement = document.createElement("canvas")
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        canvas.getContext("2d")?.drawImage(video, 0, 0)

        // const imageData = canvas.toDataURL("image/png")
        stream.getTracks().forEach((track) => track.stop()) // Arrêter la capture après la capture

        printImageToPdf(canvas, pdfName)
      }
    }
  })
}

export function saveAsFile(blob: Blob, fileName: string): void {
  const url = URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()

  // Nettoyage de l'URL
  URL.revokeObjectURL(url)
  document.body.removeChild(a)
}

export function sum(arrayOfNumbers: number[]): number {
  return arrayOfNumbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
}

export type NumericKeys<T> = {
  [K in keyof T]: T[K] extends number ? K : never
}[keyof T]
