import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined"
import { Box, Grid, Typography } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { pagesUrl } from "../../../../core/appConstants"
import { BSModelFileContext } from "../../../../core/context/beem-shot/BSBimModel/BSBimModelFileContext"
import { BSBimModel } from "../../../../core/dto/beem-shot/BSBimModel/BSBimModel"
import { BSVariant } from "../../../../core/dto/beem-shot/BSVariant/BSVariant"
import { useBSVariant } from "../../../../core/hooks/beem-shot/useBSVariant"
import { resolveUrl } from "../../../../core/services/http-service"

interface IProps {
  selectedBSBimModel?: BSBimModel | undefined
}

export function VariantsOverviewList({ selectedBSBimModel }: Readonly<IProps>): React.JSX.Element {
  const navigate = useNavigate()
  const { bsBimModelId } = useParams()
  const { getVariantsByBSBimModelId } = useBSVariant()

  const { file } = useContext(BSModelFileContext)

  const [variantsByBSBimModelId, setVariantsByBSBimModelId] = useState<BSVariant[]>([])

  useEffect(() => {
    if (bsBimModelId && bsBimModelId !== "new") {
      getVariantsByBSBimModelId(bsBimModelId).then((x) => setVariantsByBSBimModelId(x))
    } else if (selectedBSBimModel?.id) {
      getVariantsByBSBimModelId(selectedBSBimModel?.id).then((x) => setVariantsByBSBimModelId(x))
    }
  }, [bsBimModelId, file, getVariantsByBSBimModelId, selectedBSBimModel?.id])

  return (
    <Grid item xs={12} container justifyContent="center" p={4} rowGap={1}>
      {variantsByBSBimModelId.length === 0 ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography color="textSecondary">Cette maquette n'est utilisé sur aucune variante.</Typography>
        </Box>
      ) : (
        variantsByBSBimModelId.map((x) => (
          <Grid
            item
            key={x.id}
            container
            borderRadius={3}
            boxShadow={5}
            p={1}
            onClick={() => navigate(resolveUrl(pagesUrl.BEEM_SHOT_VARIANTS_DETAIL, [x.projectId, x.id]))}
            sx={{
              ":hover": { cursor: "pointer", color: "#0BE2A0" },
              borderRadius: "10px !important", // Parler du problème à Wanda
              backgroundColor: "#ffffff",
              boxShadow: "unset",
            }}>
            <Grid item xs={11} display="flex" justifyContent="flex-start" alignItems="center">
              <Typography
                pl={2}
                variant="subtitle2"
                fontSize={14}
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}>
                {x.name}
              </Typography>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
              <ArrowCircleRightOutlinedIcon />
            </Grid>
          </Grid>
        ))
      )}
    </Grid>
  )
}
