import { Grid, Toolbar } from "@mui/material"
import React from "react"
import { theme } from "../../../../theme"
import { BackButtonNavbar } from "../../buttons/back-button/BackButtonNavbar"
import "../app-navbar-v2.scss"

export function HeaderWithoutStep(): React.JSX.Element {
  return (
    <Toolbar
      disableGutters
      sx={{
        color: "white",
        borderRadius: 5,
        backgroundColor: "#070F27",
        zIndex: -5000,
        ...theme.mixins.toolbar,
      }}>
      <Grid container display="flex" justifyContent="space-between" alignContent="center">
        <BackButtonNavbar />
        <Grid item xs={7} />
        <Grid item xs={2} />
      </Grid>
    </Toolbar>
  )
}
